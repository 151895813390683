import React, { useState, useEffect } from "react";
import { Container, Label, Col, Row, Input, Card, CardBody, CardSubtitle, CardTitle, Button } from "reactstrap";
import { Bar, Line, Pie, HorizontalBar } from 'react-chartjs-2'
import moment from 'moment'
import 'moment/locale/th'
import _ from 'lodash'

const ProblemRepairByGuest = ({ orders, histories, allUser }) => {
  const [selectGuest, setSelectGuest] = useState("ทั้งหมด");

  const filterUser = _.filter(allUser, (_user) => {
    return (
      // _user.role === "MECHANIC"
      _user.role === "USER"

    )
  })


  const generateProblemRepair = () => {

    const problemGraphData = []
    const problemGraphCount = []


    if (selectGuest === "ทั้งหมด") { //นับของลูกค้าทุกคน
      _.map(orders, (order) => {

        const filterProblem = _.find(problemGraphData, (problem, index) => {
          return (
            problem === order.problem
          )
        })

        if (filterProblem === undefined) {
          problemGraphData.push(order.problem)
          problemGraphCount.push(1)

        } else {
          problemGraphCount[problemGraphData.indexOf(order.problem)] = problemGraphCount[problemGraphData.indexOf(order.problem)] + 1;
        }


      })

      _.map(histories, (history) => {


        const filterProblem = _.find(problemGraphData, (problem, index) => {
          return (
            problem === history.orders.problem
          )
        })

        if (filterProblem === undefined) {
          problemGraphData.push(history.orders.problem)
          problemGraphCount.push(1)

        } else {
          problemGraphCount[problemGraphData.indexOf(history.orders.problem)] = problemGraphCount[problemGraphData.indexOf(history.orders.problem)] + 1;
        }



      })
    } else { //นับตามรายชื่อลูกค้า
      _.map(orders, (order) => {

        if (order.user_name === selectGuest) {
          const filterProblem = _.find(problemGraphData, (problem, index) => {
            return (
              problem === order.problem
            )
          })

          if (filterProblem === undefined) {
            problemGraphData.push(order.problem)
            problemGraphCount.push(1)

          } else {
            problemGraphCount[problemGraphData.indexOf(order.problem)] = problemGraphCount[problemGraphData.indexOf(order.problem)] + 1;
          }
        }



      })

      _.map(histories, (history) => {

        if (history.orders.user_name === selectGuest) {

          const filterProblem = _.find(problemGraphData, (problem, index) => {
            return (
              problem === history.orders.problem
            )
          })

          if (filterProblem === undefined) {
            problemGraphData.push(history.orders.problem)
            problemGraphCount.push(1)

          } else {
            problemGraphCount[problemGraphData.indexOf(history.orders.problem)] = problemGraphCount[problemGraphData.indexOf(history.orders.problem)] + 1;
          }
        }


      })
    }

    const problem = {
      name: problemGraphData,
      inventory: problemGraphCount
    }

    return problem

  }
  const problemRepairData = generateProblemRepair()

  const repairAmount = {
    labels: problemRepairData.name,
    datasets: [
      {
        label: 'ปัญหางานซ่อม',
        backgroundColor:
          '#003A75',

        hoverBackgroundColor:
          '#000080',

        data: problemRepairData.inventory
      }
    ]
  }

  const showGuest = () => {
    return (
      _.map(filterUser, (user) => {
        return (
          <option>{user.user_title}{user.user_firstname} {user.user_lastname}</option>
        )
      })
    )
  }

  return (
    <Card>
      <CardBody>
        <h4 className="text-left"><strong>สถิติการแจ้งซ่อมตามลูกค้า</strong></h4>

        <Input
          type="select"
          size="sm"
          value={selectGuest}
          onChange={(e) => setSelectGuest(e.target.value)}
        >
          <option>ทั้งหมด</option>
          {showGuest()}

        </Input>
        <br />
        <Bar
          data={repairAmount}
          options={{
            legend: {
              display: true,
              position: 'bottom'
            },

          }}
          width={50}
          height={20}
        />
      </CardBody>
    </Card>
  )
}
export default ProblemRepairByGuest;