import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as actions from '../../actions'
import { useHistory, Link } from 'react-router-dom'
import { authen } from '../../assets/api/firebase'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'
import {
  Row,
  Col,
  Input,
  InputGroup,
  Button,
  Form,
  Container,
} from 'reactstrap'
import { useForm } from 'react-hook-form'

const Login = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const authData = useSelector((state) => state.auth)
  const user = useSelector((state) => state.user)
  const info = useSelector((state) => state.info)
  const { register, handleSubmit } = useForm()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [message, setMessage] = useState('')

  const onPasswordLogin = (data) => {
    const firebase = authen
    firebase
      .auth()
      .signInWithEmailAndPassword(data.email, data.password)
      .then(() => {
        if ( authData != false && user === null){
          dispatch(actions.getUser(authData.uid))
        }
        dispatch(actions.getUser(firebase.auth().currentUser.uid))
        history.push('/login/register')
      })
      .catch((err) => {
        if (err.code == 'auth/user-not-found') alert('ไม่พบบัญชีผู้ใช้ในระบบ')
        else if (err.code == 'auth/wrong-password') {
          alert('รหัสผ่านไม่ถูกต้อง')
        } else if (err.code == 'auth/invalid-email') {
          alert('รูปเเบบอีเมลไม่ถูกต้อง')
        }
      })
  }
  const uiConfig = {
    signInFlow: 'redirect',
    signInSuccessUrl: '/login/register',
    signInOptions: [
      {
        provider: authen.auth.GoogleAuthProvider.PROVIDER_ID,
      },
      {
        provider: authen.auth.FacebookAuthProvider.PROVIDER_ID,
        scopes: ['public_profile', 'email'],
      },
    ],
   
    callbacks: {
      signInSuccessWithAuthResult: async (res) => {
        await dispatch(actions.getUser(res.user.uid))
        console.log('SigIn Success | user', user)
      },
    },
  }
  
  return (
    <Container
      style={{
        backgroundColor: '#ffffff',
        boxShadow: '1px 1px 3px #d9d9d9',

        padding: '30px',
        maxWidth: '500px',
      }}
      className="light-th"
    >
      <Row>
        <Col md="5" lg>
          <h4>เข้าสู่ระบบ</h4>
        </Col>
        <Col md="7" style={{ fontSize: '12px' }} className="text-right mt-2">
          เพิ่งเคยเข้ามาในระบบใช่หรือไม่{' '}
          <Button
            color="link"
            tag={Link}
            style={{ fontSize: '12px' }}
            className="text-danger"
            to={'/login/email-register'}
          >
            สมัครใหม่
          </Button>
        </Col>
      </Row>

      <hr />
      <Form onSubmit={onPasswordLogin}>
        <InputGroup size="lg">
          <Input
            style={{ fontSize: '15px' }}
            type="text"
            placeholder="Email"
            name="email"
            innerRef={register}
            onChange={(event) => {
              setEmail(event.target.value)
            }}
          />
        </InputGroup>
        <br />
        <InputGroup size="lg">
          <Input
            style={{ fontSize: '15px' }}
            type="password"
            placeholder="รหัสผ่าน"
            innerRef={register}
            name="password"
            onChange={(event) => {
              setPassword(event.target.value)
            }}
          />
        </InputGroup>
        {message ? (
          <p className="light-th text-danger pt-1">{message}</p>
        ) : (
          <p className="pt-1">
            <br />
          </p>
        )}
        <Button
          className="mt-3 login-btn "
          style={{ border: 'none' }}
          size="lg"
          type="submit"
          block
          onClick={handleSubmit(onPasswordLogin)}
          disabled={!email || !password}
        >
          <span className="light-th" style={{ fontSize: '15px' }}>
            เข้าสู่ระบบ
          </span>
        </Button>
        <br />{' '}
        <div className="text-right">
          <Button
            color="link"
            tag={Link}
            style={{ fontSize: '12px' }}
            className="text-danger"
            to={'/login/forgotten-password'}
          >
            ลืมรหัสผ่านใช่หรือไม่
          </Button>
        </div>
       
        <span>
          <StyledFirebaseAuth
            uiConfig={uiConfig}
            firebaseAuth={authen.auth()}
          />
        </span>
      </Form>
    </Container>
  )
}
export default Login