import {
  CATEGORIES_FETCH,
  CATEGORIES_CREATE,
  CATEGORIES_REMOVE,
} from "../actions/types";
export default function (state = [], action) {
  switch (action.type) {
    case CATEGORIES_FETCH:
      return action.payload;
    case CATEGORIES_CREATE:
      return state;
    case CATEGORIES_REMOVE:
      return action.payload;
    default:
      return state; // ค่าเดิม
  }
}