import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBirthdayCake, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";
import { authen } from "../../assets/api/firebase";
import * as actions from "../../actions";

import {
    Navbar,
    Button,
    NavbarToggler,
    Collapse,
    Nav,
    NavItem,
    NavLink,
    Badge,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownItem,
    DropdownMenu,
    Dropdown,
    Label,
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import * as roles from "../../util/UserRole";

const Topbar = ({ toggleTopbar, topbarIsOpen, topbarVisibility }) => {

    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const history = useHistory();
    const [width, setWidth] = React.useState(window.innerWidth);
    const breakpoint = 620;
    const system = useSelector((state) => state.system);
    const [systemData, setSystemData] = useState([])

    let contratorAccess;
    let mechanicAccess;
    let storeAccess;
    let adminAccess;
    let superuserAccess;

    if (!user) {
        contratorAccess = false;
        mechanicAccess = false;
        storeAccess = false;
        adminAccess = false;
        superuserAccess = false;
    } else {
        contratorAccess =
            user.role == roles.CONTRATOR_ROLE
                ? true
                : false;
        mechanicAccess =
            user.role == roles.MECHANIC_ROLE || user.role == roles.ADMIN_ROLE || user.role == roles.SUPER_USER_ROLE
                ? true
                : false;
        storeAccess =
            user.role == roles.STORE_ROLE
                ? true
                : false;
        adminAccess =
            user.role == roles.ADMIN_ROLE || user.role == roles.SUPER_USER_ROLE
                ? true
                : false;
        superuserAccess =
            user.role == roles.SUPER_USER_ROLE
                ? true
                : false;
    }

    useEffect(() => {
        dispatch(actions.systemsFetch());
        return () => { };
    }, []);

    useEffect(() => {
        setSystemData(system[0])
        window.addEventListener('resize', () => setWidth(window.innerWidth))
        return () => { };
    }, [topbarVisibility, system]);

    const showImageProfile = () => {
        if (user.user_image != null) {
            return (
                <>
                    <img
                        className="rounded-circle"
                        style={{ height: 25 }}
                        src={user.user_image}
                        alt=""
                    />
                </>
            );
        } else {
            return (
                <>
                    <img
                        className="rounded-circle"
                        style={{ height: 25 }}
                        src={require("../../assets/images/profile.png")}
                        alt=""
                    />
                </>
            );
        }

    }
    return width < breakpoint ? (
        //Mobile
        <>
            {topbarVisibility && (
                <Navbar
                    color="light"
                    light
                    className="navbar shadow-sm p-3 mb-5 bg-white rounded sticky-top"
                    expand="md"
                >

                    {systemData != undefined ? (
                        <img
                            src={systemData.system_image}
                            className="img-fluid ml-2 mr-3"
                            style={{ height: '50px' }}
                        />

                    ) : (

                        <img
                            src={require("../../assets/images/logo-eservice.png")}
                            className="img-fluid ml-2 mr-3"
                            style={{ height: '50px' }}
                        />
                    )}

                    <Link to="/" onClick={() => { toggleTopbar() }}>
                        {systemData != undefined ? (
                            <h2
                                className="shadow-sm p-2 bg-light rounded light-th"
                                style={{ fontSize: '150%' }}
                            >{systemData.system_name}
                            </h2>

                        ) : (

                            <h2
                                className="shadow-sm p-2 bg-light rounded light-th"
                                style={{ fontSize: '150%' }}
                            >E&E SERVICE
                            </h2>
                        )}

                    </Link>

                    <NavbarToggler onClick={toggleTopbar} />
                    <Collapse isOpen={topbarIsOpen} navbar>
                        <Nav className="ml-auto" navbar>
                            {user != null ? (
                                <>
                                    <NavItem>
                                        {(user != null) && (!storeAccess) && (!contratorAccess)? (
                                            <>

                                                <NavLink tag={Link} to={"/repair_notification"} onClick={() => { toggleTopbar() }}> แจ้งซ่อม </NavLink>


                                            </>
                                        ) : (
                                            <>
                                            </>
                                        )}
                                    </NavItem>
                                    <NavItem>
                                        {(user != null) && (!contratorAccess && !storeAccess && !mechanicAccess && !adminAccess && !superuserAccess) ? (
                                            <>

                                                <NavLink tag={Link} to={"/status"} onClick={() => { toggleTopbar() }}> ติดตามสถานะ </NavLink>


                                            </>
                                        ) : (
                                            <>
                                            </>
                                        )}
                                    </NavItem>
                                    <NavItem>
                                        {(user != null) && (!contratorAccess && !storeAccess && !mechanicAccess && !adminAccess && !superuserAccess) ? (
                                            <>

                                                <NavLink tag={Link} to={"/history/" + user.id} onClick={() => { toggleTopbar() }}> ประวัติการซ่อม </NavLink>


                                            </>
                                        ) : (
                                            <>
                                            </>
                                        )}
                                    </NavItem>
                                    {storeAccess === true ? (
                                        <>
                                            <NavItem>
                                                <NavLink tag={Link} to={"/management/borrow"} onClick={() => { toggleTopbar() }}> รายการเบิกอุปกรณ์ </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink tag={Link} to={"/management/return"} onClick={() => { toggleTopbar() }}> รายการคืนอุปกรณ์ </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <UncontrolledDropdown nav inNavbar>
                                                    <DropdownToggle nav>
                                                        จัดการระบบ <FontAwesomeIcon icon={faAngleDown} />{" "}
                                                    </DropdownToggle>

                                                    <DropdownMenu right>

                                                        <DropdownToggle nav className="ml-4">
                                                            จัดการคลังวัสดุและอุปกรณ์
                                                                    <DropdownItem tag={Link} to={"/management/products"}>
                                                                <NavLink onClick={() => { toggleTopbar() }}> เพื่ม/ลด วัสดุ </NavLink>
                                                            </DropdownItem>
                                                            <DropdownItem tag={Link} to={"/management/equipment"}>
                                                                <NavLink onClick={() => { toggleTopbar() }}> เพื่ม/ลด อุปกรณ์ </NavLink>
                                                            </DropdownItem>
                                                            <DropdownItem tag={Link} to={"/management/categories"}>
                                                                <NavLink onClick={() => { toggleTopbar() }}> เพื่ม/ลด หมวดหมู่วัสดุและอุปกรณ์ </NavLink>
                                                            </DropdownItem>

                                                        </DropdownToggle>
                                                        <DropdownItem tag={Link} to={"/management/stock"}>
                                                            <NavLink onClick={() => { toggleTopbar() }}> จัดการสต๊อกวัสดุและอุปกรณ์ </NavLink>
                                                        </DropdownItem>
                                                        <DropdownItem tag={Link} to={"/management/product_history"}>
                                                            <NavLink onClick={() => { toggleTopbar() }}> ประวัติการเบิกจ่ายอุปกรณ์ </NavLink>
                                                        </DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </NavItem>
                                        </>) : (
                                        <>
                                        </>
                                    )}
                                    {contratorAccess === true ? (
                                        <>
                                            {/* <NavItem>
                                                <NavLink tag={Link} to={"/management/job"} onClick={() => { toggleTopbar() }}> รับงาน </NavLink>
                                            </NavItem> */}
                                            <NavItem>
                                                <NavLink tag={Link} to={"/management/wait"} onClick={() => { toggleTopbar() }}> งานที่รอการอนุมัติ </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink tag={Link} to={"/management/borrow"} onClick={() => { toggleTopbar() }}> รายการเบิกอุปกรณ์ </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink tag={Link} to={"/management/repair_record"} onClick={() => { toggleTopbar() }}> บันทึกการซ่อม </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink tag={Link} to={"/management/deliver_work"} onClick={() => { toggleTopbar() }}> ส่งมอบงาน </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink tag={Link} to={"/management/return"} onClick={() => { toggleTopbar() }}> รายการคืนอุปกรณ์ </NavLink>
                                            </NavItem>
                                            {/* <NavItem>
                                                <NavLink tag={Link} to={"/report/knowledge"} onClick={() => { toggleTopbar() }}> การจัดการองค์ความรู้ </NavLink>
                                            </NavItem> */}
                                        </>) : (
                                        <>
                                        </>
                                    )}
                                    {mechanicAccess == true ? (
                                        <>
                                            <NavItem>
                                                <NavLink tag={Link} to={"/management/job"} onClick={() => { toggleTopbar() }}> รับงาน </NavLink>
                                            </NavItem>

                                            <NavItem>
                                                <NavLink tag={Link} to={"/management/repair"} onClick={() => { toggleTopbar() }}> ประเมินราคาเบื้องต้น </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink tag={Link} to={"/management/borrow"} onClick={() => { toggleTopbar() }}> รายการเบิกอุปกรณ์ </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink tag={Link} to={"/management/repair_record"} onClick={() => { toggleTopbar() }}> บันทึกการซ่อม </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink tag={Link} to={"/management/deliver_work"} onClick={() => { toggleTopbar() }}> ส่งมอบงาน </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink tag={Link} to={"/management/return"} onClick={() => { toggleTopbar() }}> รายการคืนอุปกรณ์ </NavLink>
                                            </NavItem>
                                            {/* <NavItem>
                                                <NavLink tag={Link} to={"/report/knowledge"} onClick={() => { toggleTopbar() }}> การจัดการองค์ความรู้ </NavLink>
                                            </NavItem> */}
                                            {/* {adminAccess === true ? (
                                                <>
                                                    <NavItem>
                                                        <NavLink tag={Link} to={"/report/knowledge"} onClick={() => { toggleTopbar() }}> การจัดการองค์ความรู้ </NavLink>
                                                    </NavItem>
                                                </>) : (
                                                <>
                                                </>
                                            )} */}
                                            {adminAccess == true ? (
                                                <>
                                                    {/* <NavItem>
                                                 <NavLink tag={Link} to={"/management/borrow" }> รายการเบิกอุปกรณ์ </NavLink>
                                            </NavItem> */}

                                                    <NavItem>
                                                        {superuserAccess == true ? (
                                                            <>

                                                                {/* <NavLink tag={Link} to={"/management/estimate_price" }> ประเมินราคา </NavLink> */}

                                                            </>
                                                        ) : (
                                                            <>
                                                            </>
                                                        )}
                                                    </NavItem>
                                                    {/* <NavItem>
                                                        <NavLink tag={Link} to={"/management/document"}> เอกสาร </NavLink>
                                                    </NavItem> */}
                                                    <NavItem>
                                                        {superuserAccess == true ? (
                                                            <>
                                                                <UncontrolledDropdown nav inNavbar>
                                                                    <DropdownToggle nav>
                                                                        รายงาน <FontAwesomeIcon icon={faAngleDown} />{" "}
                                                                    </DropdownToggle>
                                                                    <DropdownMenu right>
                                                                        <DropdownItem tag={Link} to={"/report/document"}>
                                                                            <NavLink onClick={() => { toggleTopbar() }}> รายงานการส่งมอบงาน </NavLink>
                                                                        </DropdownItem>
                                                                        <DropdownItem tag={Link} to={"/report/repair"}>
                                                                            <NavLink onClick={() => { toggleTopbar() }}> รายงานการแจ้งซ่อม </NavLink>
                                                                        </DropdownItem>
                                                                        <DropdownItem tag={Link} to={"/report/knowledge"}>
                                                                            <NavLink onClick={() => { toggleTopbar() }}> การจัดการองค์ความรู้ </NavLink>
                                                                        </DropdownItem>
                                                                        {/* <DropdownItem tag={Link} to={"/report/product"}>
                                                                                <NavLink> รายงานเบิกจ่ายอุปกรณ์ </NavLink>
                                                                        </DropdownItem> */}
                                                                        {/* <DropdownItem tag={Link} to={"/report/finance"}>
                                                                               <NavLink> รายงานการเงิน </NavLink>
                                                                          </DropdownItem> */}
                                                                    </DropdownMenu>
                                                                </UncontrolledDropdown>

                                                            </>
                                                        ) : (
                                                            <>
                                                            </>
                                                        )}
                                                    </NavItem>
                                                    <NavItem>
                                                        <UncontrolledDropdown nav inNavbar>
                                                            <DropdownToggle nav>
                                                                จัดการระบบ <FontAwesomeIcon icon={faAngleDown} />{" "}
                                                            </DropdownToggle>

                                                            <DropdownMenu right>

                                                                <DropdownToggle nav className="ml-4">
                                                                    จัดการคลังวัสดุและอุปกรณ์
                                                                    <DropdownItem tag={Link} to={"/management/products"}>
                                                                        <NavLink onClick={() => { toggleTopbar() }}> เพื่ม/ลด วัสดุ </NavLink>
                                                                    </DropdownItem>
                                                                    <DropdownItem tag={Link} to={"/management/equipment"}>
                                                                        <NavLink onClick={() => { toggleTopbar() }}> เพื่ม/ลด อุปกรณ์ </NavLink>
                                                                    </DropdownItem>
                                                                    <DropdownItem tag={Link} to={"/management/categories"}>
                                                                        <NavLink onClick={() => { toggleTopbar() }}> เพื่ม/ลด หมวดหมู่วัสดุและอุปกรณ์ </NavLink>
                                                                    </DropdownItem>

                                                                </DropdownToggle>
                                                                <DropdownItem tag={Link} to={"/management/stock"}>
                                                                    <NavLink onClick={() => { toggleTopbar() }}> จัดการสต๊อกวัสดุและอุปกรณ์ </NavLink>
                                                                </DropdownItem>
                                                                <DropdownItem tag={Link} to={"/management/repairtype"}>
                                                                    <NavLink onClick={() => { toggleTopbar() }}> จัดการประเภทงานซ่อม </NavLink>
                                                                </DropdownItem>
                                                                <DropdownToggle nav className="ml-4">
                                                                    จัดการประวัติ
                                                                    <DropdownItem tag={Link} to={"/management/history"}>
                                                                        <NavLink onClick={() => { toggleTopbar() }}> ประวัติการแจ้งซ่อม </NavLink>
                                                                    </DropdownItem>
                                                                    <DropdownItem tag={Link} to={"/management/product_history"}>
                                                                        <NavLink onClick={() => { toggleTopbar() }}> ประวัติการเบิกจ่ายอุปกรณ์ </NavLink>
                                                                    </DropdownItem>
                                                                </DropdownToggle>
                                                                <DropdownItem tag={Link} to={"/management/guest"}>
                                                                    <NavLink onClick={() => { toggleTopbar() }}> ข้อมูลลูกค้า </NavLink>
                                                                </DropdownItem>

                                                                {superuserAccess == true ? (
                                                                    <>

                                                                        <DropdownItem tag={Link} to={"/management/user"}>
                                                                            <NavLink onClick={() => { toggleTopbar() }}> จัดการผู้ใช้งาน </NavLink>
                                                                        </DropdownItem>
                                                                        <DropdownItem tag={Link} to={"/management/system"}>
                                                                            <NavLink onClick={() => { toggleTopbar() }}> ตั้งค่า </NavLink>
                                                                        </DropdownItem>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                    </>
                                                                )}

                                                            </DropdownMenu>
                                                        </UncontrolledDropdown>
                                                    </NavItem>
                                                </>
                                            ) : (
                                                <>
                                                </>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                        </>
                                    )}

                                    <NavItem>
                                        {(user != null) ? (
                                            <>

                                                <NavLink tag={Link} to={"/profile/" + user.id} onClick={() => { toggleTopbar() }}> โปรไฟล์ </NavLink>


                                            </>
                                        ) : (
                                            <>
                                            </>
                                        )}
                                    </NavItem>
                                    <NavItem>
                                        <NavLink tag={Link} onClick={() => { toggleTopbar() }}>
                                            {showImageProfile()}
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink tag={Link} onClick={() => { toggleTopbar() }}>
                                            ชื่อผู้ใช้ : {user.user_firstname} {user.user_lastname}{"  "}
                                            {user.role == "CONTRATOR" ? (
                                                <>
                                                    <Badge color="secondary">Contrator</Badge>
                                                </>
                                            ) : (
                                                ""
                                            )}
                                            {user.role == "MECHANIC" ? (
                                                <>
                                                    <Badge color="warning">Mechanic</Badge>
                                                </>
                                            ) : (
                                                ""
                                            )}
                                            {user.role == "ADMIN" ? (
                                                <>
                                                    <Badge color="info">Admin</Badge>
                                                </>
                                            ) : (
                                                ""
                                            )}
                                            {user.role == "SUPERUSER" ? (
                                                <>
                                                    <Badge color="danger">Super User</Badge>
                                                </>
                                            ) : (
                                                ""
                                            )}

                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            tag={Link}
                                            onClick={() => {
                                                authen.auth().signOut();
                                                history.push("/");
                                                toggleTopbar();
                                            }}
                                        >
                                            ออกจากระบบ
                                        </NavLink>
                                    </NavItem>
                                </>
                            ) : (
                                <>
                                    <NavItem>
                                        <NavLink tag={Link} to={"/login"}>
                                            เข้าสู่ระบบ
                                        </NavLink>
                                    </NavItem>
                                </>
                            )}
                        </Nav>
                    </Collapse>
                </Navbar>
            )}
        </>
    ) : (
        //desktop
        <>
            {topbarVisibility && (
                <Navbar
                    color="light"
                    light
                    className="navbar shadow-sm p-3 mb-5 bg-white rounded sticky-top"
                    expand="md"
                >
                    {/* <img
                        src={require("../../assets/images/logo-eservice.png")}
                        className="img-fluid ml-2 mr-3"
                        style={{ height: '50px' }}
                    /> */}
                    {systemData != undefined ? (
                        <img
                            src={systemData.system_image}
                            className="img-fluid ml-2 mr-3"
                            style={{ height: '50px' }}
                        />

                    ) : (

                        <img
                            src={require("../../assets/images/logo-eservice.png")}
                            className="img-fluid ml-2 mr-3"
                            style={{ height: '50px' }}
                        />
                    )}
                    <Link to="/">
                        {/* <h2
                            className="shadow-sm p-2 bg-light rounded light-th"
                            style={{ fontSize: '150%' }}
                        >E&E SERVICE
                        </h2> */}
                        {systemData != undefined ? (
                            <h2
                                className="shadow-sm p-2 bg-light rounded light-th"
                                style={{ fontSize: '150%' }}
                            >{systemData.system_name}
                            </h2>

                        ) : (

                            <h2
                                className="shadow-sm p-2 bg-light rounded light-th"
                                style={{ fontSize: '150%' }}
                            >E&E SERVICE
                            </h2>
                        )}
                    </Link>

                    <NavbarToggler onClick={toggleTopbar} />
                    <Collapse isOpen={topbarIsOpen} navbar>
                        <Nav className="ml-auto" navbar>
                            {user != null ? (
                                <>
                                    <NavItem>
                                        {(user != null) && (!storeAccess) && (!contratorAccess) ? (
                                            <>
                                                <NavLink tag={Link} to={"/repair_notification"} > แจ้งซ่อม </NavLink>

                                            </>
                                        ) : (
                                            <>
                                            </>
                                        )}
                                    </NavItem>
                                    <NavItem>
                                        {(user != null) && (!contratorAccess && !storeAccess && !mechanicAccess && !adminAccess && !superuserAccess) ? (
                                            <>

                                                <NavLink tag={Link} to={"/status"} > ติดตามสถานะ </NavLink>


                                            </>
                                        ) : (
                                            <>
                                            </>
                                        )}
                                    </NavItem>
                                    <NavItem>
                                        {(user != null) && (!contratorAccess && !storeAccess && !mechanicAccess && !adminAccess && !superuserAccess) ? (
                                            <>

                                                <NavLink tag={Link} to={"/history/" + user.id}> ประวัติการซ่อม </NavLink>


                                            </>
                                        ) : (
                                            <>
                                            </>
                                        )}
                                    </NavItem>

                                    {storeAccess === true ? (
                                        <>
                                            <NavItem>
                                                <NavLink tag={Link} to={"/management/borrow"}> รายการเบิกอุปกรณ์ </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink tag={Link} to={"/management/return"}> รายการคืนอุปกรณ์ </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <UncontrolledDropdown nav inNavbar>
                                                    <DropdownToggle nav>
                                                        จัดการระบบ <FontAwesomeIcon icon={faAngleDown} />{" "}
                                                    </DropdownToggle>

                                                    <DropdownMenu right>

                                                        <DropdownToggle nav className="ml-4">
                                                            จัดการคลังวัสดุและอุปกรณ์
                                                                    <DropdownItem tag={Link} to={"/management/products"}>
                                                                <NavLink > เพื่ม/ลด วัสดุ </NavLink>
                                                            </DropdownItem>
                                                            <DropdownItem tag={Link} to={"/management/equipment"}>
                                                                <NavLink > เพื่ม/ลด อุปกรณ์ </NavLink>
                                                            </DropdownItem>
                                                            <DropdownItem tag={Link} to={"/management/categories"}>
                                                                <NavLink > เพื่ม/ลด หมวดหมู่วัสดุและอุปกรณ์ </NavLink>
                                                            </DropdownItem>

                                                        </DropdownToggle>
                                                        <DropdownItem tag={Link} to={"/management/stock"}>
                                                            <NavLink > จัดการสต๊อกวัสดุและอุปกรณ์ </NavLink>
                                                        </DropdownItem>
                                                        <DropdownItem tag={Link} to={"/management/product_history"}>
                                                            <NavLink> ประวัติการเบิกจ่ายอุปกรณ์ </NavLink>
                                                        </DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </NavItem>
                                        </>) : (
                                        <>
                                        </>
                                    )}

                                    {contratorAccess === true ? (
                                        <>
                                            {/* <NavItem>
                                                <NavLink tag={Link} to={"/management/job"} > รับงาน </NavLink>
                                            </NavItem> */}
                                            <NavItem>
                                                <NavLink tag={Link} to={"/management/wait"}> งานที่รอการอนุมัติ </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink tag={Link} to={"/management/borrow"}> รายการเบิกอุปกรณ์ </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink tag={Link} to={"/management/repair_record"} > บันทึกการซ่อม </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink tag={Link} to={"/management/deliver_work"} > ส่งมอบงาน </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink tag={Link} to={"/management/return"} > รายการคืนอุปกรณ์ </NavLink>
                                            </NavItem>
                                            {/* <NavItem>
                                                <NavLink tag={Link} to={"/report/knowledge"} > การจัดการองค์ความรู้ </NavLink>
                                            </NavItem> */}
                                        </>) : (
                                        <>
                                        </>
                                    )}
                                    {mechanicAccess == true ? (
                                        <>
                                            <NavItem>
                                                <NavLink tag={Link} to={"/management/job"} > รับงาน </NavLink>
                                            </NavItem>

                                            <NavItem>
                                                <NavLink tag={Link} to={"/management/repair"} > ประเมินราคาเบื้องต้น </NavLink>
                                            </NavItem>

                                            <NavItem>
                                                <NavLink tag={Link} to={"/management/borrow"}> รายการเบิกอุปกรณ์ </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink tag={Link} to={"/management/repair_record"} > บันทึกการซ่อม </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink tag={Link} to={"/management/deliver_work"} > ส่งมอบงาน </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink tag={Link} to={"/management/return"} > รายการคืนอุปกรณ์ </NavLink>
                                            </NavItem>
                                            {/* <NavItem>
                                                <NavLink tag={Link} to={"/report/knowledge"} > การจัดการองค์ความรู้ </NavLink>
                                            </NavItem> */}

                                            {/* {adminAccess === true ? (
                                                <>
                                                    <NavItem>
                                                        <NavLink tag={Link} to={"/report/knowledge"} > การจัดการองค์ความรู้ </NavLink>
                                                    </NavItem>
                                                </>) : (
                                                <>
                                                </>
                                            )} */}

                                            {adminAccess == true ? (
                                                <>
                                                    {/* <NavItem>
                                                 <NavLink tag={Link} to={"/management/borrow" }> รายการเบิกอุปกรณ์ </NavLink>
                                            </NavItem> */}

                                                    <NavItem>
                                                        {superuserAccess == true ? (
                                                            <>

                                                                {/* <NavLink tag={Link} to={"/management/estimate_price" }> ประเมินราคา </NavLink> */}

                                                            </>
                                                        ) : (
                                                            <>
                                                            </>
                                                        )}
                                                    </NavItem>
                                                    {/* <NavItem>
                                                        <NavLink tag={Link} to={"/management/document"}> เอกสาร </NavLink>
                                                    </NavItem> */}
                                                    <NavItem>
                                                        {superuserAccess == true ? (
                                                            <>
                                                                <UncontrolledDropdown nav inNavbar>
                                                                    <DropdownToggle nav>
                                                                        รายงาน <FontAwesomeIcon icon={faAngleDown} />{" "}
                                                                    </DropdownToggle>
                                                                    <DropdownMenu right>
                                                                        <DropdownItem tag={Link} to={"/report/document"}>
                                                                            <NavLink> รายงานการส่งมอบงาน </NavLink>
                                                                        </DropdownItem>
                                                                        <DropdownItem tag={Link} to={"/report/repair"}>
                                                                            <NavLink> รายงานการแจ้งซ่อม </NavLink>
                                                                        </DropdownItem>
                                                                        <DropdownItem tag={Link} to={"/report/knowledge"}>
                                                                            <NavLink> การจัดการองค์ความรู้ </NavLink>
                                                                        </DropdownItem>
                                                                        {/* <DropdownItem tag={Link} to={"/report/product"}>
                                                                                <NavLink> รายงานเบิกจ่ายอุปกรณ์ </NavLink>
                                                                        </DropdownItem> */}
                                                                        {/* <DropdownItem tag={Link} to={"/report/finance"}>
                                                                               <NavLink> รายงานการเงิน </NavLink>
                                                                          </DropdownItem> */}
                                                                    </DropdownMenu>
                                                                </UncontrolledDropdown>

                                                            </>
                                                        ) : (
                                                            <>
                                                            </>
                                                        )}
                                                    </NavItem>
                                                    <NavItem>
                                                        <UncontrolledDropdown nav inNavbar>
                                                            <DropdownToggle nav>
                                                                จัดการระบบ <FontAwesomeIcon icon={faAngleDown} />{" "}
                                                            </DropdownToggle>

                                                            <DropdownMenu right>

                                                                <DropdownToggle nav className="ml-4">
                                                                    จัดการคลังวัสดุและอุปกรณ์
                                                                        <DropdownItem tag={Link} to={"/management/products"}>
                                                                        <NavLink > เพื่ม/ลด วัสดุ </NavLink>
                                                                    </DropdownItem>
                                                                    <DropdownItem tag={Link} to={"/management/equipment"}>
                                                                        <NavLink > เพื่ม/ลด อุปกรณ์ </NavLink>
                                                                    </DropdownItem>
                                                                    <DropdownItem tag={Link} to={"/management/categories"}>
                                                                        <NavLink > เพื่ม/ลด หมวดหมู่วัสดุและอุปกรณ์ </NavLink>
                                                                    </DropdownItem>

                                                                </DropdownToggle>
                                                                <DropdownItem tag={Link} to={"/management/stock"}>
                                                                    <NavLink > จัดการสต๊อกวัสดุและอุปกรณ์ </NavLink>
                                                                </DropdownItem>
                                                                <DropdownItem tag={Link} to={"/management/repairtype"}>
                                                                    <NavLink > จัดการประเภทงานซ่อม </NavLink>
                                                                </DropdownItem>
                                                                <DropdownToggle nav className="ml-4">
                                                                    จัดการประวัติ
                                                                    <DropdownItem tag={Link} to={"/management/history"}>
                                                                        <NavLink> ประวัติการแจ้งซ่อม </NavLink>
                                                                    </DropdownItem>
                                                                    <DropdownItem tag={Link} to={"/management/product_history"}>
                                                                        <NavLink> ประวัติการเบิกจ่ายอุปกรณ์ </NavLink>
                                                                    </DropdownItem>
                                                                </DropdownToggle>
                                                                <DropdownItem tag={Link} to={"/management/guest"}>
                                                                    <NavLink > ข้อมูลลูกค้า </NavLink>
                                                                </DropdownItem>

                                                                {superuserAccess == true ? (
                                                                    <>

                                                                        <DropdownItem tag={Link} to={"/management/user"}>
                                                                            <NavLink > จัดการผู้ใช้งาน </NavLink>
                                                                        </DropdownItem>
                                                                        <DropdownItem tag={Link} to={"/management/system"}>
                                                                            <NavLink > ตั้งค่า </NavLink>
                                                                        </DropdownItem>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                    </>
                                                                )}

                                                            </DropdownMenu>
                                                        </UncontrolledDropdown>
                                                    </NavItem>
                                                </>
                                            ) : (
                                                <>
                                                </>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                        </>
                                    )}

                                    <NavItem>
                                        {(user != null) ? (
                                            <>

                                                <NavLink tag={Link} to={"/profile/" + user.id} > โปรไฟล์ </NavLink>


                                            </>
                                        ) : (
                                            <>
                                            </>
                                        )}
                                    </NavItem>
                                    <NavItem>
                                        <NavLink tag={Link} >
                                            {showImageProfile()}
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink tag={Link} >
                                            ชื่อผู้ใช้ : {user.user_firstname} {user.user_lastname}{"  "}
                                            {user.role == "CONTRATOR" ? (
                                                <>
                                                    <Badge color="secondary">Contrator</Badge>
                                                </>
                                            ) : (
                                                ""
                                            )}
                                            {user.role == "MECHANIC" ? (
                                                <>
                                                    <Badge color="warning">Mechanic</Badge>
                                                </>
                                            ) : (
                                                ""
                                            )}
                                            {user.role == "STORE" ? (
                                                <>
                                                    <Badge color="primary">Store</Badge>
                                                </>
                                            ) : (
                                                ""
                                            )}
                                            {user.role == "ADMIN" ? (
                                                <>
                                                    <Badge color="info">Admin</Badge>
                                                </>
                                            ) : (
                                                ""
                                            )}
                                            {user.role == "SUPERUSER" ? (
                                                <>
                                                    <Badge color="danger">Super User</Badge>
                                                </>
                                            ) : (
                                                ""
                                            )}

                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            tag={Link}
                                            onClick={() => {
                                                authen.auth().signOut();
                                                history.push("/");
                                                toggleTopbar();
                                            }}
                                        >
                                            ออกจากระบบ
                                        </NavLink>
                                    </NavItem>
                                </>
                            ) : (
                                <>
                                    <NavItem>
                                        <NavLink tag={Link} to={"/login"}>
                                            เข้าสู่ระบบ
                                        </NavLink>
                                    </NavItem>
                                </>
                            )}
                        </Nav>
                    </Collapse>
                </Navbar>
            )}
        </>
    )


}
export default Topbar;