import React from "react";
import { useSelector, useDispatch } from "react-redux";
import * as roles from "../../util/UserRole";
import * as actions from "../../actions";
import { Input, FormGroup, Button, Col, Row } from "reactstrap";
import { useForm } from "react-hook-form";
import { useHistory, Link } from 'react-router-dom'

const UserTables = ({ user, index }) => {
  const dispatch = useDispatch();
  const { handleSubmit, register, errors } = useForm();
  const history = useHistory();

  const showImageProfile = () => {
    if (user.user_image != null) {
      return (
        <>
          <Row >
            <Col className="mb-1">
              <img
                style={{ height: 80, width: 80 }}
                src={user.user_image}
              />
            </Col>
            <Col md="9" className="text-left">
              {user.user_firstname} {user.user_lastname}
            </Col>

          </Row>
        </>
      );
    } else {
      return (
        <>
          <Row>
            <Col className="mb-1">
              <img
                style={{ height: 80, width: 80 }}
                src={require("../../assets/images/profile.png")}
              />
            </Col>
            <Col md="9" className="text-left">
              {user.user_firstname} {user.user_lastname}
            </Col>
          </Row>
        </>
      );
    }
  }

  return (
    <tr className="text-center">
      <th scope="row">{index}</th>
      <td> {showImageProfile()} </td>
      <td>{user.user_agency}</td>
      <td>{user.user_tel} </td>
      <td>
        <Button
          color="success"
          className="mb-1"
          onClick={() => {
            history.push("/customer_information/" + user.id);
          }}
        >ข้อมูลลูกค้า</Button>
        {" "}
        <Button
          color="primary"
          className="mb-1"
          onClick={() => {
            history.push("/history/" + user.id);
          }}
        >ประวัติการซ่อม</Button>
      </td>


    </tr>
  );
};

export default UserTables;
