
export const USER_ROLE = "USER"

export const CONTRATOR_ROLE = "CONTRATOR"

export const MECHANIC_ROLE = "MECHANIC"

export const ADMIN_ROLE = "ADMIN"

export const STORE_ROLE = "STORE"

export const SUPER_USER_ROLE = "SUPERUSER"