import axios from "axios";
import { SET_USER, GET_USER, RESET_USER, EDIT_USER } from "./types";
import qs from 'qs'

export const setUser = (user) => {
  return (dispatch) => {
    axios.post(process.env.REACT_APP_API_URL + "/user", user).then((res) => {
      console.log("Request Server to Generate User");
      dispatch({ type: SET_USER, payload: res.data });
    });
  };
};

export const getUser = (user_id) => {
  // if (user_id != false) {
    return (dispatch) => {
      axios
        .get(process.env.REACT_APP_API_URL + "/user/" + user_id)
        .then((res) => {
          dispatch({ type: GET_USER, payload: res.data });
        });
    };
  // }

};

export const resetUser = () => {
  return (dispatch) => {
    dispatch({ type: RESET_USER });
  };
};

export const registerLineNotify = (accessCode, uid) => {
  // Send Code to Server
  return (dispatch) => {
    console.log('ส่งข้อมูลไปให้ฝั่ง Server ด้วย Code : ' + accessCode)
    axios
      .put(process.env.REACT_APP_API_URL + '/notify/' + accessCode + '/' + uid)
      .then((res) => {
        if (res.status === 200) {
          console.log('Success On Line Register')
          // dispatch({ type: EDIT_USER, payload: res.data })
        } else {
          console.log('Fail on register')
        }
      })
  }
}

export const editUser = (uid, value) => {
  return (dispatch) => {
    axios
      .put(process.env.REACT_APP_API_URL + "/user/edit/" + uid, value)
      .then(() => {
        axios.get(process.env.REACT_APP_API_URL + "/user/" + uid).then((res) => {
          alert("แก้ไขโปรไฟล์สำเร็จ");
          dispatch({ type: EDIT_USER, payload: res.data });
        });

      })
      .catch((err) => {
        console.log("Error");
        console.error(err);
      });


  }
};