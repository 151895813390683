import React from "react";
import { Input } from "reactstrap";
export default function SearchUser(props) {

  const { allOrder, setOrdersData } = props;
  const onSeach = (word) => {
    const res = allOrder.filter(
      (order) =>
      order.user_name.includes(word) 
      ||  order.user_tel.includes(word)  
      ||  order.place.includes(word) 
      || order.problem.includes(word)
      || order.orderDate.includes(word)
    );
    setOrdersData(res);
  };
  return (
    <div className="d-flex">
      <Input
        style={{ maxWidth: "400px" }}
        className="ml-1"
        type="text"
        placeholder="ค้นหา"
        onChange={(e) => {
          allOrder&&onSeach(e.target.value)
        }}
      />
    </div>
  );
}
