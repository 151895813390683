import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../actions";
import { useForm } from "react-hook-form";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Table
} from "reactstrap";
import hash from "object-hash";
import { authen } from "../../assets/api/firebase";
import _ from "lodash";
import { useHistory, useParams } from "react-router-dom";
import ReProductsListTables from "../../containers/tables/ReProductsListTable"
import SearchProduct from "../Search/SearchProduct";

const ModalReSelectProduct = (props) => {
  const { className, modal, toggle, setSelectProduct, selectProduct, productInventory, selectProductStore, productStoreInventory } = props;
  const dispatch = useDispatch();
  const { register, handleSubmit, err } = useForm();
  const categories = useSelector((state) => state.categories);

  const orders = useSelector((state) => state.orders);
  const products = useSelector((state) => state.products);

  const params = useParams();
  const filterOrder = _.find(orders, (_order) => {
    return (
      _order._id == params.id
    )
  })
  const [productData, setProductData] = useState([])

  useEffect(() => {
    dispatch(actions.productsFetch());
    setProductData(products)
    return () => { }
  }, [])

  useEffect(() => {
    setProductData(products)
    return () => { }
  }, [products])

  const onCreateProduct = (data) => {

    let confirm = window.confirm("ยืนยันการเพิ่มอุปกรณ์")

    if (!confirm) {
      toggle();
    } else {
      let curentSelectCategories = categories.find(category => {
        return category.category_id === data.category_id
      })

      if (data.product_type === "เครื่องมือ") {
        data.product_inventory = 1;
      } else {
        data.product_inventory = 0;
      }

      const name = data.product_name;
      const date = new Date();
      const hashID = hash({
        name: name,
        date: date,
      });

      const uploadTask = authen
        .storage()
        .ref(`EandE/${hashID}`)
        .put(data.product_image[0]);

      uploadTask.on(
        "state_changed",
        (snapshot) => { },
        (error) => {
          console.log(error);
        },
        () => {
          authen
            .storage()
            .ref("EandE")
            .child(hashID)
            .getDownloadURL()
            .then((url) => {

              data.product_image = url
              data.product_id = hashID;
              data.product_category = curentSelectCategories._id;
              console.log(data);
              // dispatch(actions.productCreate(data));
              // dispatch(actions.productsFetch());
              toggle();
              alert("บันทึกสำเร็จ")



            })

        });

    }

  };

  return (
    <div>
      <Modal
        size="lg"
        isOpen={modal}
        toggle={toggle}
        className={className}
      >
        <ModalHeader toggle={toggle}>เลือกอุปกรณ์</ModalHeader>
        <ModalBody>
            <Label style={{ fontSize: "15px", color: "black" }}>ค้นหาอุปกรณ์</Label>

            <SearchProduct allProduct={products} setProductData={setProductData} />
            <br />
            <h4>รายการอุปกรณ์</h4>
            <br />
            <div
              style={{
                maxHeight: "100vh",
                overflowY: "auto",
                backgroundColor: "#ffffff",
                boxShadow: "1px 1px 3px #d9d9d9",
              }}>

              <Table striped bordered hover>
                <thead className='light-th text-center'>
                  <tr>
                    <th>#</th>
                    <th>ชื่ออุปกรณ์</th>
                    <th>รายละเอียด</th>
                    {/* <th>แหล่งจัดซื้อ</th> */}
                    {/* <th>ราคา</th> */}
                    {/* <th>ยอดคงเหลือ</th> */}
                    <th>ดำเนินการ</th>


                  </tr>
                </thead>
                <tbody>
                  {productData.map((product, index) => {
                    return (
                      <>
                        <ReProductsListTables
                          order={filterOrder}
                          product={product}
                          setSelectProduct={setSelectProduct}
                          index={index + 1}
                          toggle={toggle}
                          selectProduct={selectProduct}
                          productInventory={productInventory}
                          selectProductStore={selectProductStore}
                          productStoreInventory={productStoreInventory} 
                          setProductData={setProductData}/>
                      </>
                    );
                   
                  })}
                </tbody>
              </Table>
            </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ModalReSelectProduct;
