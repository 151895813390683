import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Label } from "reactstrap";
import _ from "lodash";

const ReProductsListTable = ({ order, product, index, setSelectProduct, toggle, selectProduct, productInventory, selectProductStore, productStoreInventory, setProductData }) => {
  const products = useSelector((state) => state.products);
  const selectFormStock = (product) => {

    const findProductInStock = _.find(selectProduct, (_select) => {
      return (
        _select._id == product._id
      )
    })

    const findProductInStore = _.find(selectProductStore, (_select) => {
      return (
        _select._id == product._id
      )
    })
    
    if (findProductInStock === undefined && product.product_inventory != 0) { //ถ้าเพิ่มอุปกรณ์ใหม่
      selectProduct.push(product)
      productInventory.push(1)
      toggle()
    }else if(findProductInStock === undefined && product.product_inventory === 0){ //อุปกรณ์ที่เพิ่มใหม่ ไม่มีของในคลังแล้ว
      if (selectProductStore.indexOf(product) == -1) { //ไม่เคยเลือกอุปกรณ์จากร้านค้า
        selectProductStore.push(product)
        productStoreInventory.push(1)
        toggle()
      } else { //เคยเลือกอุปกรณ์จากร้านค้า
        console.log(selectProductStore.indexOf(product));
        productStoreInventory[selectProductStore.indexOf(product)] = productStoreInventory[selectProductStore.indexOf(product)] + 1;

      }
    } else {
      if (product.product_inventory != 0 && parseInt(product.product_inventory) > productInventory[selectProduct.indexOf(findProductInStock)]) { //ยืมอุปกรณ์จากในคลังเพิ่ม อุปกรณ์ในคลังมีมากกว่าที่ขอ
        productInventory[selectProduct.indexOf(findProductInStock)] = productInventory[selectProduct.indexOf(findProductInStock)] + 1;
        toggle()
      } else if (product.product_inventory != 0 && parseInt(product.product_inventory) == productInventory[selectProduct.indexOf(findProductInStock)]) { //ยืมอุปกรณ์จากในคลัง อุปกรณ์ในคลังมีเท่ากับจำนวนที่ขอ
        productInventory[selectProduct.indexOf(findProductInStock)] = productInventory[selectProduct.indexOf(findProductInStock)] + 1;
        toggle()

      } else { //กรณีที่อุปกรณ์ในคลังมีไม่่พอกับจำนวนที่ขอ
        
        if (findProductInStore === undefined && selectProductStore.indexOf(product) == -1) { //ไปซื้อจากร้านค้า
          selectProductStore.push(product)
          productStoreInventory.push(1)
          toggle()
        } else { //เพิ่มจำนวนในร้านค้า
          productStoreInventory[selectProductStore.indexOf(findProductInStore)] = productStoreInventory[selectProductStore.indexOf(findProductInStore)] + 1;

        }

      }
      toggle()
    }
    setProductData(products)
  }

  const selectFormStore = (product) => {

    const findProductInStore = _.find(selectProductStore, (_select) => {
      return (
        _select._id == product._id
      )
    })
    if (findProductInStore === undefined) {
      selectProductStore.push(product)
      productStoreInventory.push(1)
      toggle()
    } else {

      productStoreInventory[selectProductStore.indexOf(findProductInStore)] = productStoreInventory[selectProductStore.indexOf(findProductInStore)] + 1;
      toggle()
    }
    setProductData(products)
  }

  return (
    <tr className="text-center">
      <th scope="row">{index}</th>
      <td>{product.product_name}</td>
      <td>{product.product_detail}</td>
      {/* <td>{product.product_store}</td> */}
      {/* <td>{product.product_price} บาท</td> */}
      {/* <td ><strong className="text-danger">{product.product_inventory} </strong><Label >{product.product_unit}</Label></td> */}
      {/* <td>
        
          <img src={product.product_image} width="10%" />
        
      </td>   */}
      <td>
        <Button
          className="m-1"
          color="success"
          onClick={() => {
            selectFormStock(product)
          }}
        >
          คลัง
        </Button>{" "}
        <Button
          className="m-1"
          color="success"
          onClick={() => {
            selectFormStore(product)
          }}
        >
          ร้านค้า
        </Button>

      </td>

    </tr>
  );
};

export default ReProductsListTable;
