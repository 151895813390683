import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { Container } from "reactstrap";
import { Switch, Route } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import * as actions from "../../actions";
import Topbar from "./Topbar";
import Login from "../../containers/login/Login";
import Register from "../../containers/login/register/Register";
import RegisterFromEmail from "../../containers/login/register/RegisterFromBlank";
import Home from "../../containers/Home";
import ManagementUser from "../../containers/management/ManagementUser"
import ManagementCategories from "../../containers/management/ManagementCategories";
import ManagementProducts from "../../containers/management/ManagementProducts";
import UpdateProduct from "../../containers/update/UpdateProduct";
import RepairNotification from "../../containers/repairNotification/RepairNotification";
import ManagementRepair from "../../containers/management/ManagementRepair";
import Status from "../../containers/status/Status";
import RepairDetail from "../../containers/repairDetail/RepairDetail";
import RepairRecord from "../../containers/repairRecord/RepairRecord";
import GetJobRepair from "../../containers/getJobRepair/GetJobRepair";
import EstimatePrice from "../../containers/estimatePrice/EstimatePrice";
import ManagementBorrow from "../../containers/management/ManagementBorrow";
import ManagementStock from "../../containers/management/ManagementStock";
import ManagementGuest from "../../containers/management/ManagementGuest";
import ManagementHistory from "../../containers/management/ManagementHistory";
import ManagementProductHistory from "../../containers/management/ManagementProductHistory";
import ProductReport from "../../containers/report/ProductReport";
import RepairReport from "../../containers/report/RepairReport";
import FinanceReport from "../../containers/report/FinanceReport";
import DeliveryReport from "../../containers/report/DeliveryReport";
import ForgottenPassword from "../../containers/login/forgottenPassword/ForgottenPassword";
import BorrowProduct from "../../containers/borrowAndReturnProduct/BorrowProduct";
import ReturnProduct from "../../containers/borrowAndReturnProduct/ReturnProduct";
import ManagementRepairtype from "../../containers/management/ManagementRepairtype";
import Profile from "../../containers/profile/Profile";
import UserProfile from "../../containers/profile/UserProfile";
import UserHistory from "../../containers/history/UserHistory";
import EditUserProfile from "../../containers/profile/EditUserProfile";
import DeliverWork from "../../containers/deliverWork/DeliverWork";
import InitialQuotation from "../../containers/exportPDF/initialQuotation/InitialQuotation";
import ManagementDeliverWork from "../../containers/management/ManagementDeliverWork";
import ManagementJob from "../../containers/management/ManagementJob";
import ManagementRepairRecord from "../../containers/management/ManagementRepairRecord";
import ManagementReturn from "../../containers/management/ManagementReturn";
import ManagementWait from "../../containers/management/ManagementWait";
import EditQuotation from "../../containers/update/EditQuotation";
import ReEstimatePrice from "../../containers/reEstimatePrice/ReEstimatePrice";
import EditDeliverWork from "../../containers/deliverWork/EditDeliverWork";
import ProjectDelivery from "../../containers/exportPDF/projectDelivery/ProjectDelivery";
import EditBeforeAndAfterPictures from "../../containers/deliverWork/EditBeforeAndAfterPictures";
import BeforeAndAfterImage from "../../containers/exportPDF/beforeAndAfterImage/BeforeAndAfterImage";
import Quotation from "../../containers/exportPDF/quotation/Quotation";
import CheckJob from "../../containers/checkJob/CheckJob";
import ManagementProductsEquipment from "../../containers/management/ManagementProductsEquipment ";
import UpdateEquioment from "../../containers/update/UpdateEquioment";
import ManagementSystem from "../../containers/management/ManagementSystem";
import NotifyAccept from "../../containers/notify/NotlfyAccept";
import DetailHistory from "../../containers/history/DetailHistory";
import QuotationDocument from "../../containers/exportPDF/document/QuotationDocument";
import DelivelyDocument from "../../containers/exportPDF/document/DeliveryDocument";
import BeforeAndAfterImageDocument from "../../containers/exportPDF/document/BeforeAndAfterImageDocument";
import KnowledgeReport from "../../containers/report/KnowledgeReport";

const Content = ({
    topbarIsOpen,
    toggleTopbar,
    toggleTopbarVisibility,
    topbarVisibility,
}) => {

    const dispatch = useDispatch();
    const system = useSelector((state) => state.system);
    const [systemData, setSystemData] = useState([])

    useEffect(() => {
        dispatch(actions.systemsFetch());

        return () => { };
    }, []);

    useEffect(() => {
        setSystemData(system[0])
        return () => { };
    }, [system]);

    return (
        <Container
            fluid
            className={classNames("content", { "is-open": topbarIsOpen })}
        >
            {systemData != undefined ? (

                <>
                    <Helmet>
                        <title> {systemData.system_name ? systemData.system_name : ''} </title>
                        <link rel="icon" type="image/png" href={systemData.system_image} sizes="16x16" />
                    </Helmet>{' '}
                </>
            ) : (
                <>
                    <Helmet>
                        <title>IARC</title>
                        <link rel="icon" type="image/png" href={require('../../assets/images/logo-tranparant.png')} sizes="16x16" />
                    </Helmet>{' '}
                </>
            )}

            <Topbar
                toggleTopbar={toggleTopbar}
                topbarIsOpen={topbarIsOpen}
                topbarVisibility={topbarVisibility}
            />
            <Switch>
                <Route
                    exact
                    path="/"
                    render={() => <Home />} />

                <Route
                    exact
                    path="/login"
                    render={() => <Login />}
                />

                <Route
                    exact
                    path="/login/register"
                    render={() => (
                        <Register
                            toggleTopbarVisibility={toggleTopbarVisibility}
                            topbarVisibility={topbarVisibility}
                        />
                    )}
                />

                <Route
                    exact
                    path="/login/email-register"
                    render={() => <RegisterFromEmail />}
                />

                <Route
                    exact
                    path="/login/forgotten-password"
                    render={() => <ForgottenPassword />}
                />

                <Route
                    exact
                    path="/management/user"
                    render={() => <ManagementUser />}
                />

                <Route
                    exact
                    path="/management/categories"
                    render={() => <ManagementCategories />}
                />

                <Route
                    exact
                    path="/management/products"
                    render={() => <ManagementProducts />}
                />

                <Route
                    exact
                    path="/management/equipment"
                    render={() => <ManagementProductsEquipment />}
                />

                <Route
                    exact
                    path="/management/product/update/:id"
                    render={() => <UpdateProduct />}
                />

                <Route
                    exact
                    path="/management/equipment/update/:id"
                    render={() => <UpdateEquioment />}
                />

                <Route
                    exact
                    path="/repair_notification"
                    render={() => <RepairNotification />}
                />

                <Route
                    exact
                    path="/management/repair"
                    render={() => <ManagementRepair />}
                />

                <Route
                    exact
                    path="/management/job"
                    render={() => <ManagementJob />}
                />

                <Route
                    exact
                    path="/management/deliver_work"
                    render={() => <ManagementDeliverWork />}
                />

                <Route
                    exact
                    path="/management/borrow"
                    render={() => <ManagementBorrow />}
                />

                <Route
                    exact
                    path="/management/return"
                    render={() => <ManagementReturn />}
                />

                <Route
                    exact
                    path="/management/repair_record"
                    render={() => <ManagementRepairRecord />}
                />

                <Route
                    exact
                    path="/management/stock"
                    render={() => <ManagementStock />}
                />

                <Route
                    exact
                    path="/management/guest"
                    render={() => <ManagementGuest />}
                />

                <Route
                    exact
                    path="/management/history"
                    render={() => <ManagementHistory />}
                />

                <Route
                    exact
                    path="/management/history/:id"
                    render={() => <DetailHistory />}
                />


                <Route
                    exact
                    path="/management/product_history"
                    render={() => <ManagementProductHistory />}
                />

                <Route
                    exact
                    path="/management/repairtype"
                    render={() => <ManagementRepairtype />}
                />

                <Route
                    exact
                    path="/management/system"
                    render={() => <ManagementSystem />}
                />

                <Route
                    exact
                    path="/management/document"
                    render={() => <ManagementSystem />}
                />

                <Route
                    exact
                    path="/management/wait"
                    render={() => <ManagementWait />}
                />

                <Route
                    exact
                    path="/status"
                    render={() => <Status />}
                />

                <Route
                    exact
                    path="/repair_detail/:id"
                    render={() => <RepairDetail />}
                />

                <Route
                    exact
                    path="/repair_notification/:id"
                    render={() => <GetJobRepair />}
                />

                <Route
                    exact
                    path="/repair_record/:id"
                    render={() => <RepairRecord />}
                />

                <Route
                    exact
                    path="/estimate_price/:id"
                    render={() => <EstimatePrice />}
                />

                <Route
                    exact
                    path="/report/repair"
                    render={() => <RepairReport />}
                />

                <Route
                    exact
                    path="/report/product"
                    render={() => <ProductReport />}
                />

                <Route
                    exact
                    path="/report/finance"
                    render={() => <FinanceReport />}
                />

                <Route
                    exact
                    path="/report/document"
                    render={() => <DeliveryReport />}
                />

                <Route
                    exact
                    path="/report/knowledge"
                    render={() => <KnowledgeReport />}
                />

                <Route
                    exact
                    path="/borrow/:id"
                    render={() => <BorrowProduct />}
                />

                <Route
                    exact
                    path="/return/:id"
                    render={() => <ReturnProduct />}
                />

                <Route
                    exact
                    path="/initail_quotation/:id"
                    render={() => <InitialQuotation />}
                />

                <Route
                    exact
                    path="/profile/:id"
                    render={() => <Profile />}
                />

                <Route
                    exact
                    path="/customer_information/:id"
                    render={() => <UserProfile />}
                />

                <Route
                    exact
                    path="/edit/customer_information/:id"
                    render={() => <EditUserProfile />}
                />

                <Route
                    exact
                    path="/history/:id"
                    render={() => <UserHistory />}
                />

                <Route
                    exact
                    path="/deliver_work/:id"
                    render={() => <DeliverWork />}
                />

                <Route
                    exact
                    path="/quotation/edit/:id"
                    render={() => <EditQuotation />}
                />

                <Route
                    exact
                    path="/reestimate_price/:id"
                    render={() => <ReEstimatePrice />}
                />

                <Route
                    exact
                    path="/quotation/:id"
                    render={() => <Quotation />}
                />

                <Route
                    exact
                    path="/delivery/edit/:id"
                    render={() => <EditDeliverWork />}
                />

                <Route
                    exact
                    path="/delivery/:id"
                    render={() => <ProjectDelivery />}
                />

                <Route
                    exact
                    path="/portfolio/edit/:id"
                    render={() => <EditBeforeAndAfterPictures />}
                />

                <Route
                    exact
                    path="/portfolio/:id"
                    render={() => <BeforeAndAfterImage />}
                />

                <Route
                    exact
                    path="/checkJob/:id"
                    render={() => <CheckJob />}
                />

                <Route
                    exact
                    path="/quotationdocument/:id"
                    render={() => <QuotationDocument />}
                />

                <Route
                    exact
                    path="/deliverydocument/:id"
                    render={() => <DelivelyDocument />}
                />

                <Route
                    exact
                    path="/imagedocument/:id"
                    render={() => <BeforeAndAfterImageDocument />}
                />

                <Route
                    exact
                    path="/notify"
                    render={() => <NotifyAccept />}
                />



            </Switch>
        </Container>
    )

}
export default Content;