import React, { useState,useEffect } from "react";
import {
  Row,
  Container,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  CardFooter,
  CardImg,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import {useSelector, useDispatch } from 'react-redux'

const ProductLists = (props) => {
  const { product, onDelProduct} = props;
  const history = useHistory();
  

  const goToUpdate = (product) => {
    history.push({
      pathname: "/management/product/update/" + product._id,
      state: { product: product },
    });
  };
  useEffect(() => {
    return () => {};
  }, [product]);

  const showProductList = () => {
    return (
      product &&
      product.map((_product) => {
        return (
          <Col
            xs="6"
            sm="6"
            md="4"
            lg="2"
            className="p-1"
            maxHeight="250px"
            key={_product._id}
          >
            <Card style={{ cursor: "pointer" }} className="mb-1">
            <CardImg
                top
                width="100%"
                src={_product.product_image}
              />
              <CardBody>
                <CardTitle>
                  <strong>{_product.product_name}</strong>
                </CardTitle>
               
                <CardSubtitle className="text-left text-info pt-2">
                  <t>หมวดหมู่ : {_product.product_category.category_name}</t> 
                </CardSubtitle>
              </CardBody>
              <CardFooter>
                <Row>
                  <Col xs="12" sm="6" className="text-center p-1">
                    <Button
                      color="warning"
                      onClick={() => {
                         goToUpdate(_product);
                      }}
                    >
                      เเก้ไข
                    </Button>
                  </Col>
                  <Col xs="12" sm="6" className="text-center p-1">
                    <Button
                      color="danger"
                      onClick={() => {
                        onDelProduct(_product._id);
                      }}
                    >
                      ลบ
                    </Button>
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Col>
        );
      })
    );
  };
  return product ? (
    <>
      <div style={{ backgroundColor: "#f5f5f5" }}>
        <hr />
        {product.length ? (
          <>
            {" "}
            <Container fluid>
              <Row>{showProductList()}</Row>
            </Container>
          </>
        ) : (
          <>
            <div
              className="light-th text-danger"
              style={{ textAlign: "center", fontSize: "20px" }}
            >
              <br />
              ยังไม่มีวัสดุ
            </div>
          </>
        )}
      </div>
    </>
  ) : (
    <>
      <div
        className="light-th text-danger"
        style={{
          textAlign: "center",
          fontSize: "20px",
          backgroundColor: "#f5f5f5",
        }}
      >
        <br />
        โทเค็นของร้านค้าไม่ถูกต้อง
      </div>
    </>
  );
};

export default ProductLists;
