import React, { useState, useEffect } from "react";
import { Button, Container, Input, Table } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import * as role from "../../util/UserRole";
import { useHistory, useParams } from "react-router-dom";
import * as actions from '../../actions'
import * as UserStatus from "../../util/UserStatus";
import _ from "lodash";
import { useForm } from "react-hook-form";
import { authen } from "../../assets/api/firebase";
import hash from "object-hash";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import TextField from '@material-ui/core/TextField';

// ช่างใส่รายละเอียดการซ่อม

const RepairRecord = () => {
  const orders = useSelector((state) => state.orders);
  const user = useSelector((state) => state.user);
  const repairtype = useSelector((state) => state.repairtype);
  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams();
  const { register, handleSubmit, err } = useForm();
  const [imageLenght, setImageLenght] = useState([]);
  const allUser = useSelector((state) => state.userManagement);

  const filter = createFilterOptions();

  const allowAccess = user
    ? user.role == role.MECHANIC_ROLE ||
      user.role == role.CONTRATOR_ROLE ||
      user.role == role.ADMIN_ROLE ||
      user.role == role.SUPER_USER_ROLE
      ? true
      : false
    : history.push('/login');

  const filterOrder = _.find(orders, (_order) => {
    return (
      _order._id == params.id
    )
  })

  const repairtype_name = filterOrder.type;

  const filterType = _.find(repairtype, (_type) => {
    return (
      _type.repairtype_name == repairtype_name
    )
  })
  const [value, setValue] = useState(filterOrder.problem);
  const repairtype_problem = filterType.repairtype_problem;

  useEffect(() => {
    dispatch(actions.fetchAllUser());
    dispatch(actions.repairtypesFetch());
    return () => { }
  }, [])

  const onSuccess = (data) => {

    let confirm = window.confirm("ยืนยันการบันทึกการซ่อม")

    const filterProblem = _.find(repairtype_problem, (problem) => {
      return (
        problem == value
      )
    })

    if (value === null) { //ถ้าไม่ได้ใส่ปัญหาให้ใช้ของเดิม
      data.repairtype_problem = filterOrder.problem
    }
    else if (filterProblem === undefined && value === filterOrder.problem) { //ถ้าไม่มีการเปลี่ยนปัญหา
      data.repairtype_problem = value;
    }
    else if (filterProblem === undefined) { //ถ้ามีการเพิ่มปัญหาใหม่
      data.repairtype_problem = value.repairtype_problem;
      dispatch(actions.editRepairtypeProblem(filterType._id, data))
    }
    else {   //ถ้ามีการเลือกปัญหาใหม่
      data.repairtype_problem = value.repairtype_problem;
    }

    if (confirm) {
      //ถ้าใส่รูป
      let imageSuccessCount = 0
      if (data.image[0] != null) {
        for (let i = 0; i < data.image.length; i++) {

          const name = data.image[i].name;
          const date = new Date();
          const pictureName = hash({
            name: name,
            date: date,
          });

          const uploadTask = authen
            .storage()
            .ref(`EandE/${pictureName}`)
            .put(data.image[i]);
          uploadTask.on(
            "state_changed",
            (snapshot) => { },
            (error) => {
              console.log(error);
            },
            () => {
              authen
                .storage()
                .ref("EandE")
                .child(pictureName)
                .getDownloadURL()
                .then((url) => {
                  imageLenght[i] = url;
                  imageSuccessCount++
                  if (imageSuccessCount === data.image.length) {
                    data.imageFormMechanic = imageLenght;
                    data.statusMechanic = UserStatus.MECHANIC_COMPLETED;
                    data.repairRecordDate = new Date()

                    const confirmToken = "CONFIRM"
                    const confirmDataNotification = "งานแจ้งซ่อมของลูกค้าชื่อ " + filterOrder.user_name +
                      " หน่วยงาน " + filterOrder.user_agency + "ได้ทำการซ่อมเรียบร้อยแล้ว โปรดประเมินราคาอีกครั้ง"

                    dispatch(actions.orderPut(filterOrder._id, data));
                    dispatch(actions.notifyMultiSend(confirmDataNotification, confirmToken, allUser))

                    dispatch(actions.ordersFetch(user));
                  }

                });
            }
          );

        }
      } else {
        data.repairRecordDate = new Date()
        data.statusMechanic = UserStatus.MECHANIC_COMPLETED;

        const confirmToken = "CONFIRM"
        const confirmDataNotification = "งานแจ้งซ่อมของลูกค้าชื่อ " + filterOrder.user_name +
          " หน่วยงาน " + filterOrder.user_agency + "ได้ทำการซ่อมเรียบร้อยแล้ว โปรดประเมินราคาอีกครั้ง"

        dispatch(actions.orderPut(filterOrder._id, data));
        dispatch(actions.notifyMultiSend(confirmDataNotification, confirmToken, allUser))
        dispatch(actions.ordersFetch(user));
      }

      let navigate = window.confirm("เข้าสู่หน้าส่งมอบงานต่อหรือไม่")
      if (navigate) {
        history.push("/management/deliver_work");
      } else {
        history.push("/management/repair_record");
      }
    }
  };

  if (allowAccess) {
    return (
      <>
        <Container fluid className="light-th"
          style={{
            backgroundColor: "#ffffff",
            boxShadow: "1px 1px 3px #d9d9d9",
            padding: "30px",
            marginTop: "50px",
            maxWidth: "700px",
          }}
        >
          <div >
            <h2 className="text-center">บันทึกข้อมูลการซ่อม</h2>
            <br />
            <form>
              <h6>ประเภทงานซ่อม <strong>{filterOrder.type}</strong></h6>
              <br />

              <Autocomplete
                value={value}
                onChange={(event, newValue) => {
                  if (typeof newValue === 'string') {
                    setValue({
                      repairtype_problem: newValue,
                    });
                  } else if (newValue && newValue.inputValue) {
                    // Create a new value from the user input
                    setValue({
                      repairtype_problem: newValue.inputValue,
                    });
                  } else {
                    setValue(newValue);
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);

                  // Suggest the creation of a new value
                  if (params.inputValue !== '') {
                    filtered.push({
                      inputValue: params.inputValue,
                      repairtype_problem: `เพิ่ม "${params.inputValue}"`,
                    });
                  }

                  return filtered;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                options={repairtype_problem}
                getOptionLabel={(option) => {
                  // Value selected with enter, right from the input
                  if (typeof option === 'string') {
                    return option;
                  }
                  // Add "xxx" option created dynamically
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  // Regular option
                  return option.repairtype_problem;
                }}
                renderOption={(option) => option.repairtype_problem}

                freeSolo
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="ปัญหา" name="problem" defaultValue={filterOrder.problem} innerRef={register} />
                )}
              />

              <br />
              <Input
                placeholder="สาเหตุ"
                name="cause"
                innerRef={register}
              />
              <br />
              <Input
                type="textarea"
                name="solutions"
                placeholder="วิธีแก้ไข"
                innerRef={register}
              />
              <br />
              <Input
                type="textarea"
                name="suggestion"
                placeholder="ข้อเสนอแนะ"
                innerRef={register}
              />
              <br />
              <Input
                name="image"
                type="file"
                accept="image/*"
                multiple
                innerRef={register}
              />
              <br />

              <div className="text-center">
                <Button
                  color="success"
                  onClick={handleSubmit(onSuccess)}
                  block
                >
                  บันทึก</Button>
              </div>
            </form>
          </div>
        </Container>

      </>
    )
  } else {
    return (
      <>
      </>
    )
  }
}
export default RepairRecord;