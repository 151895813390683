import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileAlt } from '@fortawesome/free-solid-svg-icons'
import { useHistory, Link } from 'react-router-dom'
import { Badge, Button, Row, Alert } from 'reactstrap';
import * as UserStatus from "../../util/UserStatus";
import * as UserRole from "../../util/UserRole";

const HistoryTables = ({ eachHistory, index }) => {
    const history = useHistory();
    const [History, setHistory] = useState(eachHistory.orders);

    const showByMechanic = () => {
        if (History.byMechanic !== UserRole.USER_ROLE) {
            return (
                <>
                    <Badge color="primary">แจ้งซ่อมผ่าน {History.nameByMechanic}</Badge>
                </>
            )
        }

    }

    const showReason = () => {
        if (History.mechanic_reason != null) {
            return (
                <div>
                    <p className="ml-2"> เนื่องจาก {History.mechanic_reason}</p>
                </div>
            )
        }else if (History.causeCancle != null) {
            return (
                <div>
                    <p className="ml-2"> เนื่องจาก {History.causeCancle}</p>
                </div>
            )
        }
    }

    return (
        <tr className="text-center">
            <th scope="row" className="text-center">{index}</th>
            <td>

                {new Date(History.orderDate).toLocaleDateString("th", { day: '2-digit', month: '2-digit', year: "numeric" })}
                <p>เวลา {new Date(History.orderDate).toLocaleTimeString("th", { hour: '2-digit', minute: '2-digit' })} น. </p>
            </td>
            <td>
                {History.quotationId}
            </td>
            <td className="text-left">
                <strong>ชื่อ {History.user_name} {showByMechanic()} </strong>
                <p className="mb-1"><strong>โทร</strong> {History.user_tel}</p>
                <p><strong>สถานที่</strong> {History.place}</p>
                <p><strong>ผู้รับผิดชอบ</strong> {History.mechanic_name}</p>
            </td>
            <td>{History.statusUser} {showReason()}
            </td>
            <td>
                <Button
                    color="primary"
                    onClick={() => { history.push("/management/history/" + eachHistory._id) }}
                >ดูรายละเอียด</Button>
            </td>
        </tr>
    )
}
export default HistoryTables;