import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../actions";
import { useForm } from "react-hook-form";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  InputGroup,
  Input,
  ModalFooter,
  Button,
} from "reactstrap";
import hash from "object-hash";

const ModalCreateRepairtype = (props) => {
  const dispatch = useDispatch();
  const { className, modal, toggle } = props;
  const [unmountOnClose, setUnmountOnClose] = useState(true);
  const { register, handleSubmit, err } = useForm();

  const onCreateRepairtype = (data) => {

    const name = data.category_name;
    const date = new Date();
    const hashID = hash({
      name: name,
      date: date,
    });
    data.repairtype_id = hashID;

    toggle();
    console.log(data);
    dispatch(actions.createRepairtype(data));
    dispatch(actions.repairtypesFetch());
    window.location.reload();

  };

  return (
    <div>
      <Modal
        isOpen={modal}
        toggle={toggle}
        className={className}
      >
        <ModalHeader toggle={toggle}>เพิ่มประเภทงานซ่อม</ModalHeader>
        <ModalBody>
            <InputGroup size="lg">
              <Input
                type="text"
                style={{ fontSize: "15px" }}
                placeholder="ชื่อประเภทงานซ่อม"
                name="repairtype_name"
                innerRef={register}
              />
            </InputGroup >
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSubmit(onCreateRepairtype)}>
            เพิ่ม
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            ยกเลิก
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ModalCreateRepairtype;
