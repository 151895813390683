import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../actions";
import * as role from "../../util/UserRole";
import { Container, Table, Col } from "reactstrap";
import AllUserTables from "../tables/AllUserTables";
import { useHistory } from "react-router-dom"
import TablePagination from "../../components/TablePagination/TablePagination"
import SearchUser from "../../components/Search/SearchUser"

export default function ManagementUser() {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const allUser = useSelector((state) => state.userManagement);
  const [userData, setUserData] = useState([]);
  const [pageSize, setPageSize] = useState(10)
  const [currentPage, setCurrentPage] = useState(0)

  const allowAccess = user
    ? user.role == role.SUPER_USER_ROLE
      ? true
      : false
    : history.push("/login");


  useEffect(() => {
    dispatch(actions.fetchAllUser());
    dispatch(actions.getUser(user.id))

    return () => { };
  }, []);

  useEffect(() => {
    if(Array.isArray(user)){
      dispatch(actions.getUser(user[0].id))
    }
    setUserData(allUser)
    return () => { };
  }, [allUser]);

  if (allowAccess) {
    return (
      <Container fluid className="light-th">
        <h2 className="text-center"> จัดการผู้ใช้งาน </h2>
        <Col className="text-left">
          <SearchUser allUser={allUser} setUserData={setUserData} />
        </Col>
        <hr />
        { userData.length === 0 ? (
          <>
            <div className="container col-md-8 text-center">
              <br />
              <br />
              <br />
              <h2 className="text-danger">ไม่พบผู้ใช้งาน</h2>
            </div>
          </>
        ) : (
          <>
            <div
              style={{
                maxHeight: "100vh",
                overflowY: "auto",
                backgroundColor: "#ffffff",
                boxShadow: "1px 1px 3px #d9d9d9",
              }}>
              <Table striped bordered hover responsive>
                <thead>
                  <tr className="text-center">
                    <th>#</th>
                    <th>ชื่อสมาชิก</th>
                    <th>อีเมล</th>
                    <th>หมายเลขโทรศัพท์</th>
                    <th>สถานะ</th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(userData) ? (
                    <>
                      {userData
                        .slice(
                          currentPage * pageSize,
                          (currentPage + 1) * pageSize
                        )
                        .map((eachUser, index) => {
                          return (
                            <AllUserTables
                              user={eachUser}
                              index={index + 1}
                              actor={user}
                   
                            />
                          );
                        })}
                    </>
                  ) : (
                    <>
                      <h2>No Data Found</h2>
                    </>
                  )}
                </tbody>
              </Table>
            </div>
            <div className="d-flex justify-content-center m-4 ">
              <TablePagination
                pagesCount={Math.ceil(userData.length / pageSize)}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </div>
          </>)}
      </Container>
    );
  } else {
    return (
      <Container fluid className="light-th">
        <div className="container col-md-8 text-center">
          <h1 className="text-danger"> ขออภัย </h1>
          <h3>
            คุณไม่มีสิทธิในการเข้าถึง <br />
              กรุณาลงทะเบียนด้วย User ที่ได้รับสิทธิเป็นผู้ดูแลระบบ
          </h3>
        </div>
      </Container>
    )
  }

}
