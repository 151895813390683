import React, { useState, useEffect } from "react";
import { Container, Table, Row, Col, Badge, Input, Label, InputGroup, Button } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import * as roles from "../../util/UserRole";
import { useHistory, useParams } from "react-router-dom";
import * as actions from '../../actions'
import * as UserStatus from "../../util/UserStatus";
import _ from "lodash";
import { useForm } from "react-hook-form";
import { authen } from "../../assets/api/firebase";
import hash from "object-hash";

// รายละเอียดข้อมูลการซ่อม ดูประวัติการซ่อมย้อนหลัง
const EditUserProfile = () => {
    const users = useSelector((state) => state.userManagement);
    const history = useHistory()
    const dispatch = useDispatch()
    const params = useParams();

    const user = _.find(users, (_user) => {
        return (
            _user.id === params.id
        )
    })

    useEffect(() => {
        // dispatch(actions.fetchAllUser());
        return () => { };
    }, []);

    useEffect(() => {
        //  dispatch(actions.fetchAllUser());
        return () => { };
    }, [users]);

    const { register, handleSubmit, errors } = useForm({
        defaultValues: {
            user_title: user.user_title,
            user_firstname: user.user_firstname,
            user_lastname: user.user_lastname,
            user_tel: user.user_tel,
            user_address: user.user_address,
            user_email: user.user_email,
            user_fax: user.user_fax,
            user_agency: user.user_agency,
            paymentTerms: user.paymentTerms,
            taxpayerNumber: user.taxpayerNumber,
            credit : user.credit,
        }
    });

    const onSubmit = (data) => {
        // console.log(data);
        if (data.user_image[0] != null) {
            const name = data.user_name;
            const date = new Date();
            const hashID = hash({
                name: name,
                date: date,
            });

            const uploadTask = authen
                .storage()
                .ref(`EandE/${hashID}`)
                .put(data.user_image[0]);

            uploadTask.on(
                "state_changed",
                (snapshot) => { },
                (error) => {
                    console.log(error);
                },
                () => {
                    authen
                        .storage()
                        .ref("EandE")
                        .child(hashID)
                        .getDownloadURL()
                        .then((url) => {

                            data.user_image = url;
                            data.role = user.role;
                            dispatch(actions.updateUser(user.id, data));
                            alert("แก้ไขโปรไฟล์สำเร็จ");
                            history.push("/customer_information/" + params.id);

                        })

                });
        } else {
            data.role = user.role;
            data.user_image = user.user_image;
            dispatch(actions.updateUser(user.id, data));
            alert("แก้ไขโปรไฟล์สำเร็จ");
            history.push("/customer_information/" + params.id);

        }
    }

    return (
        <>
            <Container fluid className="light-th"
                style={{
                    marginTop: "50px",
                    maxWidth: "750px",
                }}
            >
                <div
                    style={{
                        backgroundColor: "#ffffff",
                        boxShadow: "1px 1px 3px #d9d9d9",
                        padding: "30px",
                    }}>
                    <hr />
                    <h2 className="text-left text-danger">โปรไฟล์</h2>
                    <hr />
                    <br />
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Col className="ml-2 mr-2 ">

                            <Label>ชื่อผู้ติดต่อ</Label>
                            <InputGroup>
                                <Input
                                    style={{ fontSize: "15px" }}
                                    type="select"
                                    placeholder="คำนำหน้าชื่อ"
                                    name="user_title"
                                    innerRef={register}
                                >
                                    <option>นาย</option>
                                    <option>นาง</option>
                                    <option>นางสาว</option>
                                </Input>
                                <Input
                                    style={{ fontSize: "15px" }}
                                    type="text"
                                    placeholder="ชื่อ"
                                    name="user_firstname"
                                    innerRef={register}
                                />
                                <Input
                                    style={{ fontSize: "15px" }}
                                    type="text"
                                    placeholder="นามสกุล"
                                    name="user_lastname"
                                    innerRef={register}
                                />
                            </InputGroup>

                            <Label className="mt-2">หน่วยงาน</Label>
                            <Input
                                style={{ fontSize: "15px" }}
                                type="text"
                                placeholder="หน่วยงาน"
                                name="user_agency"
                                innerRef={register}
                            />
                            <Label className="mt-2">หมายเลขผู้เสียภาษี / หมายเลขบัตรประชาชน</Label>
                            <Input
                                style={{ fontSize: "15px" }}
                                type="number"
                                placeholder="หมายเลขผู้เสียภาษี / หมายเลขบัตรประชาชน"
                                name="taxpayerNumber"
                                innerRef={register}
                            />
                            <Label className="mt-2">เบอรโทร</Label>
                            <Input
                                style={{ fontSize: "15px" }}
                                type="number"
                                placeholder="เบอรโทร"
                                name="user_tel"
                                innerRef={register}
                            />

                            <Label className="mt-2">แฟกซ์</Label>
                            <Input
                                style={{ fontSize: "15px" }}
                                type="text"
                                placeholder="แฟกซ์"
                                name="user_fax"
                                innerRef={register}
                            />

                            <Label className="mt-2">อีเมล</Label>
                            <Input
                                style={{ fontSize: "15px" }}
                                type="email"
                                placeholder="อีเมล"
                                name="user_email"
                                innerRef={register}
                            />

                            <Label className="mt-2">ที่อยู่</Label>
                            <Input
                                style={{ fontSize: "15px" }}
                                type="textarea"
                                placeholder="ที่อยู่"
                                name="user_address"
                                innerRef={register}
                            />
                            <Label className="mt-2">กำหนดยืนราคา (วัน)</Label>
                            <Input
                                style={{ fontSize: "15px" }}
                                type="select"
                                placeholder="กำหนดยืนราคา (วัน)"
                                name="paymentTerms"
                                innerRef={register}
                            >
                                <option>7</option>
                                <option>15</option>
                                <option>30</option>
                                <option>45</option>
                                <option>60</option>
                            </Input>
                            <Label className="mt-2">เครดิต</Label>
                            <Input
                                style={{ fontSize: "15px" }}
                                type="select"
                                placeholder="เครดิต"
                                name="credit"
                                innerRef={register}
                            >
                                <option>เงินสด</option>
                                <option>7 วัน</option>
                                <option>15 วัน</option>
                                <option>30 วัน</option>
                                <option>45 วัน</option>
                                <option>60 วัน</option>
                            </Input>

                            <Label className="mt-2">รูปภาพโปรไฟล์</Label>
                            <Input
                                style={{ fontSize: "15px" }}
                                type="file"
                                name="user_image"
                                innerRef={register}
                            />
                            <Label className="text-secondary mt-1 ml-2">ถ้าต้องการใช้รูปภาพเดิม ไม่ต้องอัพโหลดรูปภาพใหม่</Label>
                        </Col>
                        <div className="mt-4 mr-2 text-right">
                            <Button
                                color="warning"
                                type="submit"
                            >
                                แก้ไขโปรไฟล์
                        </Button>{" "}
                            <Button
                                color="secondary"
                                onClick={() => { history.push("/") }}
                            >
                                ยกเลิก
                        </Button>
                        </div>
                    </form>











                </div>
            </Container>

        </>
    )

}
export default EditUserProfile;