import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Container,
  Row,
  Col,
  Input,
  Label,
  Button,
  InputGroup,
} from 'reactstrap'
import { useForm } from 'react-hook-form'
import * as actions from '../../actions'
import { authen } from "../../assets/api/firebase";
import hash from "object-hash";
import { useHistory } from "react-router-dom";
import * as role from "../../util/UserRole";
import _ from "lodash";

export default function RepairNotificationFromUserDesktop() {
  const { register, handleSubmit } = useForm()
  const user = useSelector((state) => state.user)
  const dispatch = useDispatch()
  const history = useHistory();
  const [imageLenght, setImageLenght] = useState([]);
  const repairtype = useSelector((state) => state.repairtype);
  const [width, setWidth] = React.useState(window.innerWidth);
  const breakpoint = 620;
  const [selectRepairtype, setSelectRepairtype] = useState([]);
  const allUser = useSelector((state) => state.userManagement);
  const day = new Date();
  const timelogs = day.getDate().toString() + "/" + day.getMonth().toString() + "/" + parseInt(day.getFullYear() + 543) + " " + day.getHours().toString() + ":" + day.getMinutes().toString() + "น.";

  const allowAccess = user
    ? user.role == role.USER_ROLE ||
      user.role == role.CONTRATOR_ROLE ||
      user.role == role.MECHANIC_ROLE ||
      user.role == role.ADMIN_ROLE ||
      user.role == role.SUPER_USER_ROLE
      ? true
      : false
    : history.push('/login');

  useEffect(() => {
    dispatch(actions.repairtypesFetch());
    dispatch(actions.fetchAllUser());
    window.addEventListener('resize', () => setWidth(window.innerWidth));
    return () => { }
  }, []);

  const repairtypeList = () => {
    if (Array.isArray(repairtype)) {
      return repairtype.map((type) => {
        return (
          <option value={type.repairtype_name}>
            {" "}
            {type.repairtype_name}{" "}
          </option>
        );
      });
    }
  };

  const problemList = () => {
    if (repairtype.length != 0) {
      if (selectRepairtype.length !== 0) {
        const filterType = _.find(repairtype, (type) => {
          return (
            type.repairtype_name === selectRepairtype
          )
        })

        let problem = filterType.repairtype_problem;
        if (Array.isArray(problem)) {
          return problem.map((problem) => {
            return (
              <option value={problem.repairtype_problem}>
                {" "}
                {problem.repairtype_problem}{" "}
              </option>
            );
          });
        }
      } else {
        setSelectRepairtype(repairtype[0].repairtype_name);
      }
    }
  };

  const repairNotification = (data) => {
    let confirm = window.confirm("ยืนยันการแจ้งซ่อม")

    const typeId = _.find(repairtype, (_type) => {
      return (
        _type.repairtype_name == data.type
      )
    })

    const problemId = _.find(typeId.repairtype_problem, (_repairtype) => {
      return (
        _repairtype.repairtype_problem == data.problem
      )
    })
    data.typeId = typeId._id;
    data.problemId = problemId._id;
    if (confirm) {

      //ถ้าใส่รูป
      let imageSuccessCount = 0
      if (data.image[0] != null) {
        for (let i = 0; i < data.image.length; i++) {

          const name = data.image[i].name;
          const date = new Date();
          const pictureName = hash({
            name: name,
            date: date,
          });

          const uploadTask = authen
            .storage()
            .ref(`EandE/${pictureName}`)
            .put(data.image[i]);
          uploadTask.on(
            "state_changed",
            (snapshot) => { },
            (error) => {
              console.log(error);
            },
            () => {
              authen
                .storage()
                .ref("EandE")
                .child(pictureName)
                .getDownloadURL()
                .then((url) => {
                  imageLenght[i] = url;
                  imageSuccessCount++
                  if (imageSuccessCount === data.image.length) {
                    data.imageFormUser = imageLenght;
                    data.user_image = user.user_image;
                    data.user_id = user.id;
                    data.user_token = user.accessToken;
                    data.byMechanic = user.role;
                    data.nameByMechanic = user.user_firstname + " " + user.user_lastname;
                    data.quotationId = "-";


                    const token = "REPAIR NOTIFICATION"
                    const dataNotification = timelogs + "มีงานแจ้งซ่อมเข้ามาใหม่ผ่าน " + user.user_firstname + " " + user.user_lastname

                    dispatch(actions.notifyMultiSend(dataNotification, token, allUser))
                    dispatch(actions.createOrder(data))
                    dispatch(actions.ordersFetch(user));
                    history.push("/status");
                  }

                });
            }
          );

        }
      } else {
        data.user_image = user.user_image;
        data.user_id = user.id;
        data.user_token = user.accessToken;
        data.byMechanic = user.role;
        data.nameByMechanic = user.user_firstname + " " + user.user_lastname;
        data.quotationId = "-";

        const token = "REPAIR NOTIFICATION"
        const dataNotification = timelogs + "ได้รับงานแจ้งซ่อมเข้ามาใหม่ผ่าน " + user.user_firstname + " " + user.user_lastname
        dispatch(actions.notifyMultiSend(dataNotification, token, allUser))
        dispatch(actions.createOrder(data))
        dispatch(actions.ordersFetch(user));
        history.push("/status");
      }
    }
  }

  if (allowAccess) {
    return (
      //desktop
      <Container
        style={{
          maxWidth: "750px",
        }} >
        <h2 className='text-center light-th'> แจ้งซ่อมออนไลน์ </h2>
        <br />
        <div
          className='p-4'
          style={{
            backgroundColor: '#ffffff',
            boxShadow: '1px 1px 3px #d9d9d9',
          }}
        >
          <form onSubmit={handleSubmit(repairNotification)}>
            <Row className='m-1'>

              <Col >
                <h4 className='light-th'> ข้อมูลผู้แจ้ง</h4>
                <br />
                <Row>
                  <Col>
                    <Label>ชื่อผู้แจ้ง</Label>
                  </Col>
                  <Col>
                    <Label>หน่วยงาน</Label>
                  </Col>
                </Row>

                <InputGroup>
                  <Input
                    name='user_name'
                    type='text'
                    placeholder="ชื่อผู้แจ้ง"
                    defaultValue={user.user_title + user.user_firstname + " " + user.user_lastname}
                    innerRef={register}
                  />
                  <Input
                    name='user_agency'
                    type='text'
                    placeholder="หน่วยงาน"
                    defaultValue={user.user_agency}
                    innerRef={register}
                  />
                </InputGroup>
                <br />
                <Row>
                  <Col>
                    <Label>เบอร์โทรศัพท์</Label>
                  </Col>
                  <Col>
                    <Label>แฟกซ์</Label>
                  </Col>
                </Row>
                <InputGroup>
                  <Input
                    name='user_tel'
                    type='number'
                    placeholder="เบอร์โทรศัพท์"
                    defaultValue={user.user_tel}
                    innerRef={register}
                  />
                  <Input
                    name='user_fax'
                    type='number'
                    placeholder="แฟกซ์"
                    defaultValue={user.user_fax}
                    innerRef={register}
                  />
                </InputGroup>
                <br />
                <Label>อีเมล</Label>
                <Input
                  name='user_email'
                  type='email'
                  placeholder="อีเมล"
                  defaultValue={user.user_email}
                  innerRef={register}
                />
                <br />
                <Label>สถานที่่</Label>
                <Input
                  name='place'
                  type='textarea'
                  placeholder="สถานที่่"
                  defaultValue={user.user_address}
                  innerRef={register}
                />
                <br />
                <Label>กำหนดยืนราคา  (วัน)</Label>
                <Input
                  style={{ fontSize: "15px" }}
                  type="select"
                  placeholder="กำหนดยืนราคา"
                  defaultValue={user.paymentTerms}
                  name="paymentTerms"
                  innerRef={register}
                >
                  <option>7</option>
                  <option>15</option>
                  <option>30</option>
                  <option>45</option>
                  <option>60</option>
                </Input>
                <br />
                <Label>เครดิต</Label>
                <Input
                  style={{ fontSize: "15px" }}
                  type="select"
                  placeholder="เครดิต"
                  defaultValue={user.credit}
                  name="credit"
                  innerRef={register}
                >
                  <option>เงินสด</option>
                  <option>7 วัน</option>
                  <option>15 วัน</option>
                  <option>30 วัน</option>
                  <option>45 วัน</option>
                  <option>60 วัน</option>
                </Input>
                <br />
                <h4 className='light-th'> ข้อมูลการแจ้งซ่อม</h4>
                <br />
                <Label>โครงการ</Label>
                <Input
                  name='project'
                  type='text'
                  placeholder="ชื่อโครงการ"
                  innerRef={register}
                />
                <br />
                <Label>ประเภทงานซ่อม</Label>
                <Input
                  type='select'
                  name='type'
                  innerRef={register}
                  onChange={e => setSelectRepairtype(e.target.value)}>

                  {repairtypeList()}
                </Input>
                <br />
                <Label>ปัญหา/งานซ่อม</Label>
                <Input
                  name='problem'
                  type='select'
                  placeholder="ปัญหา/งานซ่อม"
                  innerRef={register}
                >
                  {problemList()}
                </Input>
                <br />
                <Label>ความเร่งด่วน</Label>
                <Input
                  name='speed'
                  type='select'
                  innerRef={register}
                >
                  <option>ปกติ</option>
                  <option>เร่งด่วน</option>
                </Input>
                <br />
                <Label>รายละเอียด </Label>
                <Input
                  type='textarea'
                  name='detail'
                  placeholder="รายละเอียด"
                  innerRef={register}
                />
                <br />
                <Label>รูปภาพ </Label>
                <Input
                  type='file'
                  name='image'
                  accept="image/*"
                  multiple
                  innerRef={register}
                />
              </Col>

            </Row>
            <br />
            <Row className='m-1'>
              <Col>
                <Button color='success' block> ยืนยัน</Button>
              </Col>
            </Row>
          </form>
        </div>
      </Container>
    )
  } else {
    return (
      <>
      </>
    )
  }
}