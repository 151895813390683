export const SET_USER = "set_user";
export const GET_USER = "get_user";
export const RESET_USER = "reset_user";
export const FETCH_USER = "fetch_user";
export const EDIT_USER = "edit_user";
export const UPDATE_USER = "update_user";
export const CREATE_USER = "create_user";
export const GET_USERDATA = "get_userdata";

export const AUTH_STATE = "auth_state";

export const CATEGORIES_CREATE = "categories_create";
export const CATEGORIES_FETCH = "categories_fetch";
export const CATEGORIES_REMOVE = "categories_remove";

export const PRODUCTS_FETCH = "products_fetch";
export const PRODUCT_FETCH = "product_fetch";
export const PRODUCT_CREATE = "product_create";
export const PRODUCT_EDIT = "product_edit";
export const PRODUCT_REMOVE = "product_remove";
export const PRODUCTS_RESET = "products_reset";

export const ORDER_RESET = 'order_reset'
export const ORDERS_FETCH = 'orders_fetch'
export const ORDERS_DELETE = 'orders_delete'
export const ORDERS_PUT = 'orders_put'
export const ORDER_CREATE = 'order_create'

export const PRODUCTLOG_FETCH = 'productlog_fetch'
export const PRODUCTLOGS_FETCH = 'productlogs_fetch'
export const PRODUCTLOG_BORROW = 'productlog_borrow'
export const PRODUCTLOG_RETURN = 'productlog_return'


export const HISTORY_GET = 'history_get'
export const HISTORY_USER_GET = 'history_user_get'
export const HISTORY_RESET = 'history_reset'

export const REPAIRTYPE_CREATE = "repairtype_create";
export const REPAIRTYPES_FETCH = "repairtypes_fetch";
export const REPAIRTYPE_REMOVE = "repairtype_remove";
export const REPAIRTYPE_EDIT = "repairtype_edit";

export const QUOTATION_CREATE = "quotation_create";
export const QUOTATIONS_FETCH = "quotation_fetch";
export const QUOTATION_EDIT = "quotation_edit";

export const SYSTEM_CREATE = "system_create";
export const SYSTEM_FETCH = "system_fetch";
export const SYSTEM_EDIT = "system_edit";

export const NOTIFY = "notify";
