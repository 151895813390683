import React, { useState, useEffect } from "react";
import { Container, Table, Row, Col, Badge, Label, InputGroup, Card, ListGroupItem, ListGroup, Button, Input } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams, useLocation } from "react-router-dom";
import _ from "lodash";
import MaterialTables from "../../tables/MaterialTables";
import OtTables from "../../tables/OtTables";
import * as actions from '../../../actions'
import Checkbox from '@material-ui/core/Checkbox';

// ใบส่งมอบงาน
const DeliveryDocument = () => {
  const orders = useSelector((state) => state.orders);
  const user = useSelector((state) => state.user);
  const history = useHistory()
  const location = useLocation();
  const dispatch = useDispatch()
  const params = useParams();
  const [width, setWidth] = React.useState(window.innerWidth);
  const breakpoint = 620;
  const system = useSelector((state) => state.system);
  const [systemData, setSystemData] = useState([])
  const History = location.state.History;

  useEffect(() => {
    dispatch(actions.systemsFetch());
    window.addEventListener('resize', () => setWidth(window.innerWidth));
    return () => { }
  }, [])

  useEffect(() => {
    setSystemData(system[0])
    return () => { };
  }, [system]);

  function printDiv(divName) {
    var printContents = document.getElementById(divName).innerHTML;
    var originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;

    window.print();

    document.body.innerHTML = originalContents;
    window.location.reload()

  }

  const showButton = () => {
    return (
      <>
        <Row className="print-disable">
          <Col xs="2">
            <Button
              outline
              color="secondary"
              onClick={
                history.goBack
              }
            >ย้อนกลับ</Button>
          </Col>
          <Col xs="7">
            <h2 className="text-center">ใบส่งมอบงาน</h2>
          </Col>
          <Col className="text-right">
            <Button color="info" onClick={() => printDiv("printarea")}>พิมพ์</Button>{" "}
          </Col>
        </Row>
      </>
    )

  }

  const showButtonOnMobile = () => {

    return (
      <>
        <Row className="print-disable">
          <Col xs="3">
            <Button
              size="sm"
              outline
              color="secondary"
              onClick={
                history.goBack
              }
            >ย้อนกลับ</Button>
          </Col>
          <Col xs="6">
            <h5 className="text-center">ใบส่งมอบงาน</h5>
          </Col>
          <Col className="text-right">
            <Button size="sm" color="info" onClick={() => printDiv("printarea")}>พิมพ์</Button>{" "}
          </Col>
        </Row>
      </>
    )

  }

  const showSign = () => {
    return (
      <>
        <Table bordered hover responsive >
          <tr>
            <td>
              <div style={{ padding: "20px" }}>
                <Row >
                  <Col><p>ผู้รับนัดหมาย ลงชื่อ</p></Col>
                  <Col md="7" className="text-left">
                    <img
                      src={History.deliveryWorkSign}
                      width="50%"
                    />
                  </Col>
                </Row>
                <h6 className="text-center">({History.appoinmentRecipient})</h6>
                <h6 className="mt-2 text-center">วันที่ {new Date(History.orderDate).toLocaleDateString("th")}</h6>
              </div>
            </td>
            <td>
              <div style={{ padding: "20px" }}>
                <h6>ผู้ปฏิบัติงาน (หจก.อี.แอนด์.อี.เซอร์วิส) </h6>
                <Row >
                  <Col><p>ลงชื่อ</p></Col>
                  <Col md="8" className="text-left">
                    <img
                      src={History.mechanicSign}
                      width="50%"
                    />
                  </Col>
                </Row>
                <h6 className="text-center">({History.supervisor})</h6>
                <h6 className="mt-2 text-center">วันที่ {new Date(History.quotationDate).toLocaleDateString("th")}</h6>
              </div>
            </td>
          </tr>
          <tbody>
            <td>
              <div style={{ padding: "20px" }}>
                <h6>ความเห็นของผู้ตรวจรับงาน (เจ้าของโครงการ) </h6>
                <h6>หมายเหตุ {History.deliveryWorkComment}</h6>
                <Row >
                  <Col><p>ลงชื่อ</p></Col>
                  <Col md="8" className="text-left">
                    <img
                      src={History.deliveryWorkSign}
                      width="50%"
                    /></Col>
                </Row>
                <h6 className="text-center">({History.inspector})</h6>
                <h6 className="mt-2 text-center">วันที่ {new Date(History.comfirmDeliverWork).toLocaleDateString("th")}</h6>
              </div>
            </td>
            <td>
              <div style={{ padding: "20px" }}>
                <h6>ผู้ควบคุมงาน (หจก.อี.แอนด์.อี.เซอร์วิส) </h6>
                <h6 className="mt-3 text-center">ลงชื่อ..............................................................................................</h6>
                <h6 className="text-center">({History.projectManager})</h6>
                <h6 className="mt-2 text-center">วันที่..........................................................</h6>
              </div>
            </td>
          </tbody>
        </Table>
      </>
    )

  }

  const showSignOnMoblie = () => {
    return (
      <>
        <ListGroup>
          <ListGroupItem>
            <div style={{ padding: "10px" }}>
            <Row >
                <Col><p>ผู้รับนัดหมาย ลงชื่อ</p></Col>
                <Col md="8" className="text-center">
                  <img
                    src={History.deliveryWorkSign}
                    width="50%"
                  />
                </Col>
              </Row>
              <p className="mt-2 text-center">({History.appoinmentRecipient})</p>
              <p className="mt-2 text-center">วันที่ {new Date(History.orderDate).toLocaleDateString("th")}</p>
            </div>
          </ListGroupItem>
          <ListGroupItem>
            <div style={{ padding: "10px" }}>
              <p>ผู้ปฏิบัติงาน (หจก.อี.แอนด์.อี.เซอร์วิส) </p>
              <Row >
                <Col><p>ลงชื่อ</p></Col>
                <Col md="8" className="text-center">
                  <img
                    src={History.mechanicSign}
                    width="50%"
                  />
                </Col>
              </Row>
              <p className="mt-2 text-center">({History.supervisor})</p>
              <p className="mt-2 text-center">วันที่ {new Date(History.quotationDate).toLocaleDateString("th")}</p>
            </div>
          </ListGroupItem>
          <ListGroupItem>
            <div style={{ padding: "10px" }}>
              <p>ความเห็นของผู้ตรวจรับงาน (เจ้าของโครงการ) </p>
              <p>หมายเหตุ {History.deliveryWorkComment}</p>
              <Row >
                  <Col><p>ลงชื่อ</p></Col>
                  <Col md="8" className="text-center">
                    <img
                      src={History.deliveryWorkSign}
                      width="50%"
                    /></Col>
                </Row>
              <p className="mt-2 text-center">({History.inspector})</p>
              <p className="mt-2 text-center">วันที่ {new Date(History.comfirmDeliverWork).toLocaleDateString("th")}</p>
            </div>
          </ListGroupItem>
          <ListGroupItem>
            <div style={{ padding: "10px" }}>
              <p>ผู้ควบคุมงาน (หจก.อี.แอนด์.อี.เซอร์วิส) </p>
              <p className="mt-3 text-center">ลงชื่อ..............................................................................................</p>
              <p className="text-center">({History.projectManager})</p>
              <p className="mt-2 text-center">วันที่..........................................................</p>
            </div>
          </ListGroupItem>
        </ListGroup>
      </>
    )

  }

  if (width < breakpoint) {
    return (
      //desktop
      <>
        <Container fluid className="light-th"
          id="printarea"
          style={{
            marginTop: "50px",
            maxWidth: "900px",
          }}
        >
          {showButtonOnMobile()}
          <br />
          <div
            style={{
              backgroundColor: "#ffffff",
              boxShadow: "1px 1px 3px #d9d9d9",
              padding: "30px",
            }}>
            <div className="page-header">

              <Row>
                <Col xs="3" className="mt-2">
                  <img
                    src={systemData.company_logo}
                    width="150%"
                  />
                </Col>
                <Col>
                  <Label style={{ fontSize: "10px" }}><u>{systemData.company_name_thai}</u></Label>
                  <Label style={{ fontSize: "10px" }}>{systemData.company_name_eng}</Label>
                  <Label style={{ fontSize: "10px" }}>{systemData.company_address_thai}</Label>
                  <Label style={{ fontSize: "10px" }}>{systemData.company_address_eng}</Label>
                  <Label style={{ fontSize: "10px" }}><strong>Tel :</strong> {systemData.company_tel} <strong>Fax :</strong> {systemData.company_fax} <strong>เลขประจำตัวผู้เสียภาษี</strong> {systemData.company_taxpayer_number}</Label></Col>
              </Row>

              <hr/>
              <div className="text-center">
                <h4 ><strong>ใบส่งมอบงาน</strong></h4>
              </div>
              <br />

              <Row>
                <Col></Col>
                <Col>
                  <Label>เอกสารฉบับที่ {History.quotationId}</Label>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Label>โครงการ {History.project}</Label>
                  <Label>เรียน {History.user_name}</Label>
                </Col>
                <Col>
                  <Label>วันที่รับงาน {new Date(History.getJobDate).toLocaleDateString("th")}</Label>
                  <Label>เวลา {new Date(History.getJobDate).toLocaleTimeString("th")} น.</Label>
                </Col>
              </Row>
              <Label>ข้าพเจ้ามีความประสงค์ขอส่งมอบงานตามรายละเอียดข้างล่างนี้</Label>
              {/* <br /> */}
            </div>

            <div className="header-space"></div>
            <div className="page-content">
              <Table bordered hover responsive style={{ fontSize: "18px" }} className="mt-4 text-center" size="sm">
                <thead className="light-th">
                  <tr>
                    <th>ที่</th>
                    <th>อาคาร</th>
                    <th>ระบบงาน</th>
                    <th>รายละเอียดการส่งมอบ</th>
                    <th>ปริมาณ</th>
                    <th>หน่วย</th>
                    <th colSpan="2">ผลการตรวจรับงาน</th>
                  </tr>
                </thead>
                <tbody>
                  {History.deliveryWorkList.map((_delivery, index) => {
                    return (
                      <>
                        <tr scope="row">
                          <td >{index + 1}</td>
                          <td>{_delivery.building}</td>
                          <td>{_delivery.workSystem}</td>
                          <td><div>{_delivery.detail}</div></td>
                          <td>{_delivery.amount}</td>
                          <td>{_delivery.unit}</td>
                          <td>
                            <InputGroup >
                              <Checkbox size="small" disabled checked={History.checkDeliveryWork[index] === "ผ่าน"} />
                              <Label className="ml-1 mt-1">ผ่าน</Label>
                            </InputGroup>
                          </td>
                          <td>
                            <InputGroup >
                              <Checkbox size="small" disabled checked={History.checkDeliveryWork[index] === "แก้ไข"} />
                              <Label className="ml-1 mt-1">แก้ไข</Label>
                            </InputGroup>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </Table>
              <br />
              <h6>จึงเรียนมาเพื่อขอสั่งมอบงวดงาน (สำหรับนัดส่งมอบล่วงหน้า)</h6>
              <br />


              {showSignOnMoblie()}

            </div>
          </div>
        </Container>

      </>
    )


  } else {
    return (
      //desktop
      <>
        <Container fluid className="light-th"
          id="printarea"
          style={{
            marginTop: "50px",
            maxWidth: "900px",
          }}
        >
          {showButton()}
          <br />
          <div
            style={{
              backgroundColor: "#ffffff",
              boxShadow: "1px 1px 3px #d9d9d9",
              padding: "30px",
            }}>
            <div className="page-header">

              <Row >
                <Col>
                  <div >
                    <img
                      src={systemData.company_logo}
                      width="100%"
                    />
                  </div>
                </Col>
                <Col md="9">
                  <h4><u>{systemData.company_name_thai}</u></h4>
                  <h5>{systemData.company_name_eng}</h5>
                  <h6>{systemData.company_address_thai}</h6>
                  <h6>{systemData.company_address_eng}</h6>
                  <h6><strong>Tel :</strong> {systemData.company_tel} <strong>Fax :</strong> {systemData.company_fax} <strong>เลขประจำตัวผู้เสียภาษี</strong> {systemData.company_taxpayer_number}</h6>
                </Col>
              </Row>

              <hr
              // style={{ border: " 1px solid" }}
              />
              <div className="text-center">
                <h4 ><strong>ใบส่งมอบงาน</strong></h4>
              </div>
              <br />

              <Row>
                <Col></Col>
                <Col sm="5">
                  <h6>เอกสารฉบับที่ {History.quotationId}</h6>
                </Col>
              </Row>

              <Row>
                <Col>
                  <h6>โครงการ {History.project}</h6>
                  <h6>เรียน {History.user_name}</h6>
                </Col>
                <Col sm="5">
                  <h6>วันที่รับงาน {new Date(History.getJobDate).toLocaleDateString("th")}</h6>
                  <h6>เวลา {new Date(History.getJobDate).toLocaleTimeString("th")} น.</h6>
                </Col>
              </Row>
              <h6>ข้าพเจ้ามีความประสงค์ขอส่งมอบงานตามรายละเอียดข้างล่างนี้</h6>
              {/* <br /> */}
            </div>

            <div className="header-space"></div>
            <div className="page-content">
              <Table bordered hover responsive style={{ fontSize: "18px" }} className="mt-4 text-center" size="sm">
                <thead className="light-th">
                  <tr>
                    <th>ที่</th>
                    <th>อาคาร</th>
                    <th>ระบบงาน</th>
                    <th>รายละเอียดการส่งมอบ</th>
                    <th>ปริมาณ</th>
                    <th>หน่วย</th>
                    <th colSpan="2">ผลการตรวจรับงาน</th>
                  </tr>
                </thead>
                <tbody>
                  {History.deliveryWorkList.map((_delivery, index) => {
                    return (
                      <>
                        <tr scope="row">
                          <td >{index + 1}</td>
                          <td>{_delivery.building}</td>
                          <td>{_delivery.workSystem}</td>
                          <td><div>{_delivery.detail}</div></td>
                          <td>{_delivery.amount}</td>
                          <td>{_delivery.unit}</td>
                          <td>
                            <InputGroup >
                              <Checkbox size="small" disabled checked={History.checkDeliveryWork[index] === "ผ่าน"} />
                              <Label className="ml-1 mt-1">ผ่าน</Label>
                            </InputGroup>
                          </td>
                          <td>
                            <InputGroup >
                              <Checkbox size="small" disabled checked={History.checkDeliveryWork[index] === "แก้ไข"} />
                              <Label className="ml-1 mt-1">แก้ไข</Label>
                            </InputGroup>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </Table>
              <br />
              <h6>จึงเรียนมาเพื่อขอสั่งมอบงวดงาน (สำหรับนัดส่งมอบล่วงหน้า)</h6>
              <br />

              {showSign()}

            </div>
          </div>
        </Container>

      </>
    )

  }
}

export default DeliveryDocument;