import axios from 'axios'
import {
  ORDER_RESET,
  ORDERS_PUT,
  ORDERS_FETCH,
  ORDERS_DELETE,
  ORDER_CREATE,
} from './types'

import * as roles from '../util/UserRole'
import * as UserStatus from '../util/UserStatus'

export const orderPut = (id, values) => {

  return (dispatch) => {
    axios
      .put(process.env.REACT_APP_API_URL + "/order/" + id, values)
      .then((res) => {

        if (res.data != null) {


          axios
            .get(process.env.REACT_APP_API_URL + "/order/" + res.data.user_id) //ดึงรายการที่อัพเดตใหม่เเล้ว
            .then((res) => {


              if (res.data.statusUser === UserStatus.USER_REFUSEDWORK
                || res.data.statusUser === UserStatus.USER_CANCEL
                || res.data.statusMechanic === UserStatus.MECHANIC_FINISHED
                || values.statusUser === UserStatus.USER_CANCEL
                || values.statusUser === UserStatus.USER_REFUSEDWORK
                || values.statusMechanic === UserStatus.MECHANIC_FINISHED

              ) {
                // handlingWork(res.data._id);
                axios
                  .get(process.env.REACT_APP_API_URL + "/history/keep/" + id)
                  .then((res) => {
                    console.log("เก็บข้อมูลไว้ใน History แล้ว | ", res);

                  });
                // window.location.reload();

              }



              // axios
              //   .get(process.env.REACT_APP_API_URL + "/order/all")
              //   .then((res) => {
              //     dispatch({ type: ORDERS_PUT, payload: res.data });
              //   });


            });
        }
      });


  }
}

export const ordersFetch = (user) => {
  let uid = user.id
  const allowAccess =
    user.role == roles.CONTRATOR_ROLE ||
      user.role === roles.MECHANIC_ROLE ||
      user.role === roles.STORE_ROLE ||
      user.role === roles.ADMIN_ROLE ||
      user.role === roles.SUPER_USER_ROLE
      ? true
      : false

  if (allowAccess) {
    return (dispatch) => {
      axios.get(process.env.REACT_APP_API_URL + '/order/all').then((res) => {
        dispatch({ type: ORDERS_FETCH, payload: res.data })
      })
    }
  } else {
    return (dispatch) => {
      axios
        .get(process.env.REACT_APP_API_URL + '/order/user/' + uid)
        .then((res) => {
          dispatch({ type: ORDERS_FETCH, payload: res.data })
        })
    }
  }
}

export const orderDelete = (id, uid) => {
  return (dispatch) => {
    axios.delete(process.env.REACT_APP_API_URL + '/order/' + id).then((res) => {
      axios.get(process.env.REACT_APP_API_URL + '/order/' + uid).then((res) => {
        dispatch({ type: ORDERS_DELETE, payload: res.data })
      })
    })
  }
}

export const ordersReset = () => {
  return (dispatch) => {
    dispatch({ type: ORDER_RESET })
  }
}

export const createOrder = (data) => {
  return (dispatch) => {
    axios
      .post(process.env.REACT_APP_API_URL + "/order", data)
      .then((res) => {
        dispatch({ type: ORDER_CREATE, payload: res.data });
      })
      .catch((err) => {
        console.log("Error Creating Order");
        console.error(err);
      });
  };
};

export const handlingWork = (id) => {
  // console.log("id", id);
  return (dispatch) => {
    axios
      .get(process.env.REACT_APP_API_URL + "/history/keep/" + id)
      .then((res) => {
        console.log("เก็บข้อมูลไว้ใน History แล้ว | ", res);
        window.location.reload();
      });
  }

}

