import React, { useState, useEffect } from "react";
import { Container, Label, Col, Row, Input, Card, CardBody, CardSubtitle, CardTitle, Button } from "reactstrap";
import { Bar, Line, Pie, HorizontalBar } from 'react-chartjs-2'
import moment from 'moment'
import 'moment/locale/th'
import _ from 'lodash'

const RepairStatusByMechanic = ({ orders, histories, allUser }) => {


  const generateMechanicName = () => {
    const mechanic_name = []
    const filterUser = _.filter(allUser, (_user) => {
      return (
        _user.role === "CONTRATOR"

      )
    })
    _.map(filterUser, (_mechanic) => {
      mechanic_name.push(_mechanic.user_title + " " + _mechanic.user_firstname + " " + _mechanic.user_lastname)
    })
    return mechanic_name;
  }




  const generateMechanicTask = () => {

    const mechanic_name = []
    const filterUser = _.filter(allUser, (_user) => {
      return (
        _user.role === "CONTRATOR"

      )
    })
    _.map(filterUser, (_mechanic) => {
      mechanic_name.push(_mechanic.user_title + _mechanic.user_firstname + " " + _mechanic.user_lastname)
    })
    const mechanic_task = [];

    _.map(mechanic_name, (mechanic, index) => {
      mechanic_task[index] = 0
    })

    for (let count_task = 0; count_task < mechanic_name.length; count_task++) {

      _.map(orders, (order) => {
        if (order.mechanic_name != undefined) {
          if (order.mechanic_name === mechanic_name[count_task]) {
            mechanic_task[count_task] = mechanic_task[count_task] + 1;
          }

        }
      })

    }

    return mechanic_task


  }

  const generateMechanicSuccessTask = () => {

    const mechanic_name = []
    const filterUser = _.filter(allUser, (_user) => {
      return (
        _user.role === "CONTRATOR"
      )
    })
    _.map(filterUser, (_mechanic) => {
      mechanic_name.push(_mechanic.user_title + _mechanic.user_firstname + " " + _mechanic.user_lastname)
    })
    const success_task = [];

    _.map(mechanic_name, (mechanic, index) => {
      success_task[index] = 0
    })

    for (let count_task = 0; count_task < mechanic_name.length; count_task++) {

      _.map(histories, (history) => {
        if (history.orders.mechanic_name != undefined && history.orders.statusUser === "ดำเนินการเสร็จสิ้น") {
          if (history.orders.mechanic_name === mechanic_name[count_task]) {
            success_task[count_task] = success_task[count_task] + 1;
          }

        }
      })


    }

    return success_task;

  }

  const mechanic_name = generateMechanicName()
  const mechanic_task = generateMechanicTask()
  const success_task = generateMechanicSuccessTask()

  const repairStatusByPerson = {
    labels: mechanic_name, //ชื่อช่าง
    datasets: [
      {
        label: 'งานที่เสร็จแล้ว',
        backgroundColor:
          '#009658',

        hoverBackgroundColor:
          '#00BA2D',

        data: success_task //งานที่เสร็จแล้ว
      },
      {
        label: 'งานที่กำลังดำเนินการ',
        backgroundColor:
          '#BB0103',

        hoverBackgroundColor:
          '#F00505',

        data: mechanic_task //งานที่กำลังดำเนินการ
      }
    ]
  }

  return (
    <Card>
      <CardBody>
        <h4 className="text-left"><strong>สถานะการแจ้งซ่อมตามช่าง</strong></h4>
        <HorizontalBar
          data={repairStatusByPerson}
          options={{
            legend: {
              display: true,
              position: 'right'
            },
            scales: {
              yAxes: [
                {
                  stacked: true,
                  ticks: {
                    beginAtZero: true,
                  },
                },
              ],
              xAxes: [
                {
                  stacked: true,
                },
              ],
            },
          }}
          width={50}
          height={20}
        />
      </CardBody>
    </Card>
  )
}
export default RepairStatusByMechanic;