import React, { useState, useEffect } from "react";
import { Container } from "reactstrap";
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import * as actions from '../../actions'
import DetailStatusDesktop from "./detail/DetailStatusDesktop";
import DetailStatusMobile from "./detail/DetailStatusMobile";
import ModalCancle from "../../components/modal/ModalCancle";
import ModalCancleJob from "../../components/modal/ModalCancleJob";
import ModalBargain from "../../components/modal/ModalBargain";
import * as UserStatus from "../../util/UserStatus";

const Status = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const user = useSelector((state) => state.user)
  const orders = useSelector((state) => state.orders)
  const [orderList, setOrderList] = useState([])
  const [order, setOrder] = useState([]);
  const count = useState(0);
  const [quatation, setQuatation] = useState([]);
  const [width, setWidth] = React.useState(window.innerWidth);
  const breakpoint = 620;
  const [modalCancle, setModalCancle] = useState(false);
  const [modalCancleJob, setModalCancleJob] = useState(false);
  const [modalBargain, setModalBargain] = useState(false);
  const allUser = useSelector((state) => state.userManagement);
  const [mechanic_token, setMechanic_token] = useState([]);

  const toggleModalCancle = (id) => {

    if (id !== null) {
      setOrder(id);
      setModalCancle(!modalCancle);
    }
  };

  const toggleModalCancleJob = (id, token) => {
    if (id !== null) {
      setOrder(id);
      setMechanic_token(token)
      setModalCancleJob(!modalCancleJob);
    }
  };

  const toggleModalBargain = (id, token) => {
    if (id !== null) {
      setOrder(id);
      setMechanic_token(token)
      setModalBargain(!modalBargain);
    }
  };

  useEffect(() => {
    if (user === null) {
      history.push('/login')
    } else {
      dispatch(actions.fetchAllUser());
      dispatch(actions.ordersFetch(user))
      window.addEventListener('resize', () => setWidth(window.innerWidth));
    }
    return () => { }
  }, [])

  useEffect(() => {
    if (user != null) {
      dispatch(actions.fetchAllUser());
      dispatch(actions.ordersFetch(user))
      setOrderList(orders)
      window.addEventListener('resize', () => setWidth(window.innerWidth));
    }
    return () => { }
  }, [])

  useEffect(() => {
    setOrderList(orders);
    window.addEventListener('resize', () => setWidth(window.innerWidth));
    return () => { }
  }, [orders, allUser])

  const comfirmOrder = (data) => {

    const notify = {
      message: "งานแจ้งซ่อมของลูกค้าชื่อ " + data.user_name +
        " หน่วยงาน " + data.user_agency + "ได้ยืนยันการแจ้งซ่อมเรียบร้อยแล้ว",

    }
    const notifyData = {
      data: notify,
      token: data.token
    }

    const token = "STORE"
    const dataNotification = "งานแจ้งซ่อมของลูกค้าชื่อ " + data.user_name +
      " หน่วยงาน " + data.user_agency + "ได้ยืนยันการแจ้งซ่อมเรียบร้อยแล้ว โปรดจัดเตรียมอุปกรณ์สำหรับงานแจ้งซ่อมนี้"

    const confirmToken = "CONFIRM"
    const confirmDataNotification = "งานแจ้งซ่อมของลูกค้าชื่อ " + data.user_name +
      " หน่วยงาน " + data.user_agency + "ได้ยืนยันการแจ้งซ่อมเรียบร้อยแล้ว"

    dispatch(actions.notifyMultiSendStore(dataNotification, token, allUser))
    dispatch(actions.notifySend(notifyData));
    dispatch(actions.notifyMultiSend(confirmDataNotification, confirmToken, allUser))
    dispatch(actions.orderPut(data._id, data.status))


  }

  const showQuatation = () => {
    if (quatation.length !== 0) {
      if (count[0] === 0 && (width < breakpoint === true)) { //mobile
        let id = quatation;
        setQuatation([]);

        window.open("https://eeservice.eappsoft.net/initail_quotation/" + id)

        count[0] = 1;
      } else if (count[0] === 0 && (width < breakpoint === false)) { //desktop
        let id = quatation;
        setQuatation([]);


        history.push("/initail_quotation/" + id);

        console.log(width < breakpoint);
        count[0] = 1;
      }
    }
  }

  const checkJob = (id) => {
    history.push("/checkJob/" + id)
  }
  return width < breakpoint ? (
    //mobile
    <Container fluid className="light-th">
      <ModalCancle
        modal={modalCancle}
        toggle={toggleModalCancle}
        order={order}
        setOrder={setOrder}
      />
      <ModalCancleJob
        modal={modalCancleJob}
        toggle={toggleModalCancleJob}
        order={order}
        setOrder={setOrder}
        mechanic_token={mechanic_token}
        allUser={allUser}
      />
      <ModalBargain
        modal={modalBargain}
        toggle={toggleModalBargain}
        order={order}
        setOrder={setOrder}
        mechanic_token={mechanic_token}
        allUser={allUser}
      />
      <div className="text-center">
        <h2>สถานะการซ่อม</h2>
        <br />
      </div>
      {orderList.length === 0 ? (
        <>
          <div className="container col-md-8 text-center">
            <br />
            <br />
            <br />
            <h5 className="text-danger">ไม่มีรายการแจ้งซ่อม</h5>
          </div>
        </>
      ) : (
        <>
          {orderList.map((_order) => {
            if (_order.statusUser != UserStatus.USER_FINISHED) {
              return (
                <>
                  <DetailStatusMobile
                    order={_order}
                    setOrder={setOrder}
                    setQuatation={setQuatation}
                    toggleModalCancle={toggleModalCancle}
                    toggleModalCancleJob={toggleModalCancleJob}
                    toggleModalBargain={toggleModalBargain}
                    comfirmOrder={comfirmOrder}
                    checkJob={checkJob}

                  />
                  {showQuatation()}
                </>
              )
            }
          })}
        </>
      )}


    </Container>
  ) : (
    //desktop
    <Container fluid className="light-th">
      <ModalCancle
        modal={modalCancle}
        toggle={toggleModalCancle}
        order={order}
        setOrder={setOrder}
      />

      <ModalCancleJob
        modal={modalCancleJob}
        toggle={toggleModalCancleJob}
        order={order}
        setOrder={setOrder}
        mechanic_token={mechanic_token}
        allUser={allUser}
      />

      <ModalBargain
        modal={modalBargain}
        toggle={toggleModalBargain}
        order={order}
        setOrder={setOrder}
        mechanic_token={mechanic_token}
        allUser={allUser}
      />
      <div className="text-center">
        <h2>สถานะการซ่อม</h2>
        <br />
      </div>

      {orderList.length === 0 ? (
        <>
          <div className="container col-md-8 text-center">
            <br />
            <br />
            <br />
            <h5 className="text-danger">ไม่มีรายการแจ้งซ่อม</h5>
          </div>
        </>
      ) : (
        <>
          {orderList.map((_order) => {
            if (_order.statusUser != UserStatus.USER_FINISHED) {
              return (
                <>
                  <DetailStatusDesktop
                    order={_order}
                    setOrder={setOrder}
                    setQuatation={setQuatation}
                    toggleModalCancle={toggleModalCancle}
                    toggleModalCancleJob={toggleModalCancleJob}
                    toggleModalBargain={toggleModalBargain}
                    comfirmOrder={comfirmOrder}
                    checkJob={checkJob}
                  />
                  {showQuatation()}
                </>
              )
            }
          })}
        </>
      )}


    </Container>
  )
}
export default Status;