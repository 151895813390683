import React from "react";
import { Input } from "reactstrap";
export default function SearchDelivery(props) {

  const { History, setHistoryData } = props;
  const onSeach = (word) => {
    const res = History.filter(
      (history) =>
      (history.orders.user_name.includes(word) 
      || history.orders.quotationId.includes(word) )
      &&  history.orders.quotationId != "-"
    );
    setHistoryData(res);
  };
  return (
    <div className="d-flex">
      <Input
        style={{ maxWidth: "400px" }}
        className="ml-1"
        type="text"
        placeholder="ค้นหา"
        onChange={(e) => {
          History&&onSeach(e.target.value)
        }}
      />
    </div>
  );
}
