import { AUTH_STATE } from "./types"
import { authen } from "../../src/assets/api/firebase";

const firebase = authen // For more Understandable

export function authState(authData) {
    return { type: AUTH_STATE, payload: authData }
}

export const authCurrent = (id) => {
    return (dispatch) => firebase.auth()
        .getUsers([
            { uid: id },
        ])
        .then((res) => {
            dispatch({ type: AUTH_STATE, payload: res.data });
        })

}
