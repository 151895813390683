import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../actions";
import { Container, Button, Row, Col } from "reactstrap";
import ModalCreateProduct from "../../components/modal/ModalCreateEquipment";
import * as role from "../../util/UserRole";
import { useHistory } from "react-router-dom";
import ProductLists from "../lists/EquipmentProductLists"
import Categories from "../lists/EquipmentLists"
import _ from "lodash";

export default function ManagementProductsEquipment() {
  const history = useHistory();
  const dispatch = useDispatch();
  const products = useSelector((state) => state.products);
  const categories = useSelector((state) => state.categories);
  const user = useSelector((state) => state.user);
  const [product, setProduct] = useState([]);

  const equipment = _.filter(products, (_product) => {
    return (
      _product.product_price === "0"
    )
  })

  // สำหรับ reRender หน้าใหม่
  const [reRender, setReRender] = useState(false);

  // กำหนดสิทธิของหน้าจัดการสินค้าให้สำหรับ Admin และ Superuser
  const allowAccess = user
    ? user.role == role.STORE_ROLE ||
      user.role == role.ADMIN_ROLE ||
      user.role == role.SUPER_USER_ROLE
      ? true
      : false
    : history.push("/login");

  const [modalCreateProduct, setModalCreateProduct] = useState(false); // props สำหรับเปิดปิด modal


  useEffect(() => {
    dispatch(actions.productsFetch());
    dispatch(actions.categoriesFetch());
    setProduct(equipment);
    return () => { };
  }, []);

  useEffect(() => {
    setProduct(equipment);
    return () => { };
  }, [products]);

  const toggleModalCreateProduct = () => {
    setModalCreateProduct(!modalCreateProduct); // method สำหรับ set เปิดหรือปิด modal
  }

  const deleteProduct = async (_id) => {
    let confim = window.confirm("ยืนยันการลบหรือไม่");
    if (confim) {
      dispatch(actions.productRemove(_id));
      console.log("ลบอุปกรณ์เเล้ว", _id);
      setTimeout(() => {
        setReRender(true);
      }, 100);
      console.log(reRender);
    } else {
      console.log("ยกเลิกการลบอุปกรณ์");
    }
  };

  // Allow this Page show only Administrator
  if (allowAccess) {
    return (
      <Container fluid className="light-th">
        <ModalCreateProduct
          modal={modalCreateProduct}
          toggle={toggleModalCreateProduct}
          setReRender={setReRender}
        />

        <div className="  text-center">
          <h2>จัดการอุปกรณ์</h2>
          <Row >
            <Col sm="12" md="6" className="text-left d-flex ">
              <Categories
                categories={categories}
                products={equipment}
                setProduct={setProduct}
              />
            </Col>

            <Col sm="12" md="6" className="text-right ">
              <Button
                className="btn-success"
                onClick={() => {
                  toggleModalCreateProduct(); // เปิด modal สำหรับเพิ่มสินค้า
                }}
              >
                เพิ่มอุปกรณ์
              </Button>{" "}
            </Col>
          </Row>
        </div>
        <ProductLists product={product} onDelProduct={deleteProduct} />
      </Container>
    );
  } else {
    return (
      <Container fluid className="light-th">
        <div className="container col-md-8 text-center">
          <h1 className="text-danger"> ขออภัย </h1>
          <h3>
            คุณไม่มีสิทธิในการเข้าถึงระบบ  <br />
            กรุณาลงทะเบียนด้วย User ที่ได้รับสิทธิเป็นผู้ดูแลระบบ
          </h3>
        </div>
      </Container>
    );
  }
}
