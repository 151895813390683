import {
    REPAIRTYPES_FETCH,
    REPAIRTYPE_CREATE,
    REPAIRTYPE_REMOVE,
  } from "../actions/types";
  export default function (state = [], action) {
    switch (action.type) {
      case  REPAIRTYPES_FETCH:
        return action.payload;
      case REPAIRTYPE_CREATE:
        return state;
      case  REPAIRTYPE_REMOVE:
        return action.payload;
      default:
        return state; // ค่าเดิม
    }
  }
  