import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as roles from "../../util/UserRole";
import * as actions from "../../actions";
import { Input, FormGroup, Button, Col } from "reactstrap";
import { useForm } from "react-hook-form";

const AllUserTables = ({ user, index, actor }) => {
  const dispatch = useDispatch();
  const { handleSubmit, register, errors } = useForm();

  useEffect(() => {
    dispatch(actions.fetchAllUser());
    dispatch(actions.getUser(actor.id))
    return () => { }
  }, []);

  const onChangeRole = (value) => {
    // console.log(value);
    dispatch(actions.editUserData(value.id, value,actor.id));
    dispatch(actions.getUser(actor.id))
  };

  const deleteUser = (value) => {
    // console.log(value.id);
    if (value.role == roles.SUPER_USER_ROLE) {
      window.alert(
        " ห้ามลบผู้ใช้ที่มีสิทธิเป็น Super User ถ้าต้องการโปรดตรวจสอบให้แน่ชัด และแก้ไขผู้ใช้คนดังกล่าวเป็นสถานะอย่างอื่นก่อน"
      );
      return;
    }
    let confirm = window.confirm("ยืนยันการลบผู้ใช้ออกจากระบบ");
    if (confirm) {
      dispatch(actions.deleteUser(value.id,actor.id));
      dispatch(actions.getUser(actor.id))
    }
  };

  const showImageProfile = () => {
    if (user.user_image != null) {
      return (
        <>
          <img
            style={{ height: 100, width: 100 }}
            src={user.user_image}
          />
          <br />
          {user.user_firstname} {user.user_lastname}
        </>
      );
    } else {
      return (
        <>
          <img
            style={{ height: 100, width: 100 }}
            src={require("../../assets/images/profile.png")}
          />
          <br />
          {user.user_firstname} {user.user_lastname}
        </>
      );
    }
  }

  const showRole = (role) => {
    if(role === roles.USER_ROLE){
      return(
      <>
      <option>{role}</option>
      <option> {roles.CONTRATOR_ROLE} </option>
      <option> {roles.MECHANIC_ROLE} </option>
      <option> {roles.STORE_ROLE} </option>
      <option> {roles.ADMIN_ROLE} </option>
      <option> {roles.SUPER_USER_ROLE} </option>
      </>
    );
    }
    else if (role === roles.CONTRATOR_ROLE){
      return(
        <>
        <option>{role}</option>
        <option> {roles.USER_ROLE} </option>
        <option> {roles.MECHANIC_ROLE} </option>
        <option> {roles.STORE_ROLE} </option>
        <option> {roles.ADMIN_ROLE} </option>
        <option> {roles.SUPER_USER_ROLE} </option>
        </>
      );
    }
    else if (role === roles.MECHANIC_ROLE){
      return(
        <>
        <option>{role}</option>
        <option> {roles.USER_ROLE} </option>
        <option> {roles.CONTRATOR_ROLE} </option>
        <option> {roles.STORE_ROLE} </option>
        <option> {roles.ADMIN_ROLE} </option>
        <option> {roles.SUPER_USER_ROLE} </option>
        </>
      );
    }
    else if (role === roles.STORE_ROLE){
      return(
        <>
        <option>{role}</option>
        <option> {roles.USER_ROLE} </option>
        <option> {roles.CONTRATOR_ROLE} </option>
        <option> {roles.MECHANIC_ROLE} </option>
        <option> {roles.ADMIN_ROLE} </option>
        <option> {roles.SUPER_USER_ROLE} </option>
        </>
      );
    }
    else if (role === roles.ADMIN_ROLE){
      return(
        <>
        <option>{role}</option>
        <option> {roles.USER_ROLE} </option>
        <option> {roles.CONTRATOR_ROLE} </option>
        <option> {roles.MECHANIC_ROLE} </option>
        <option> {roles.STORE_ROLE} </option>
        <option> {roles.SUPER_USER_ROLE} </option>
        </>
      );
    }
    else if (role === roles.SUPER_USER_ROLE){
      return(
        <>
        <option>{role}</option>
        <option> {roles.USER_ROLE} </option>
        <option> {roles.CONTRATOR_ROLE} </option>
        <option> {roles.MECHANIC_ROLE} </option>
        <option> {roles.STORE_ROLE} </option>
        <option> {roles.ADMIN_ROLE} </option>
        </>
      );
    }
    
  }

  return (
    <tr className="text-center">
      <th scope="row">{index}</th>
      <td >
        {showImageProfile()}
        {/* {user.user_firstname} {user.user_lastname}  */}
      </td>
      <td> {user.user_email} </td>
      <td>{user.user_tel} </td>

      <td className="text-left">
        {" "}
        <form>
          <FormGroup className="d-flex">
            <Col lg={6}>
              <Input
                type="select"
                name="role"
                defaultValue={user.role}
                innerRef={register}
              >
                {showRole(user.role)}
            
              </Input>
              <Input
                type="text"
                hidden
                name="id"
                value={user.id}
                innerRef={register}
              />
            </Col>
            <Col>
              <Button
                className="m-1"
                color="warning"
                type="button"
                onClick={handleSubmit(onChangeRole)}
              >
                แก้ไข
              </Button>
              <Button
                color="danger"
                type="button"
                onClick={handleSubmit(deleteUser)}
              >
                ลบ
              </Button>
            </Col>
          </FormGroup>{" "}
        </form>
      </td>
    </tr>
  );
};

export default AllUserTables;
