import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import axios from 'axios'
import * as actions from '../../actions'
import { useForm } from 'react-hook-form'
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  InputGroup,
  Label,
} from 'reactstrap'
import { useHistory, useParams, useLocation } from "react-router-dom";

const ModalAddStock = (props) => {
  const { className, modal, toggle } = props
  const dispatch = useDispatch()
  const { register, handleSubmit, err } = useForm()
  const products = useSelector((state) => state.products)
  const history = useHistory();
  const user = useSelector((state) => state.user);

  const onAddStock = (data) => {
    
    let confirm = window.confirm('ยืนยันการเพิ่มอุปกรณ์')
    
    if (confirm) {
     products.find(_product =>{
        if(_product._id === data._id){
         

           let dataLogs = {
             type: "เพิ่มสต๊อก",
             product: {
              _id: _product._id,
              product_name: _product.product_name,
              prev_inventory:  parseInt(_product.product_inventory),
              next_inventory: parseInt(_product.product_inventory) + parseInt(data.product_inventory),
              inventory: parseInt(data.product_inventory),
              product_store: _product.product_store,
              product_unit: _product.product_unit,
             },

             transactionDate : new Date(),
             handler: user.user_firstname + " " + user.user_lastname,
             referenceOrder: "-",
             quotationId: "-",

           }  
           data.product_inventory = parseInt(_product.product_inventory) + parseInt(data.product_inventory);
           dispatch(actions.editStockProduct(dataLogs))
           dispatch(actions.productEdit(_product._id, data));
           dispatch(actions.productsFetch())
        }
     })
    
      toggle()
      alert('บันทึกสำเร็จ')
    }
  }

  
  const productList = () => {
    return (
      <>
        {products.map((_product) => {
          return (
            <option key={_product._id} value={_product._id}>
              {_product.product_name}
            </option>
          )
        })}
      </>
    )
  }
  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}>เพิ่มสต๊อกอุปกรณ์</ModalHeader>
        <ModalBody>
          <form>
            <Label>เลือกอุปกรณ์</Label>
            <InputGroup size="lg">
              <Input
                style={{ fontSize: '15px' }}
                type="select"
                name="_id" //เเสดงผลเป็นชื่อสินค้า เเต่ค่าที่ใช้ในการรับส่งข้อมูลจะเป็น product_id
                innerRef={register}
              >
                {productList()}
              </Input>
            </InputGroup>
            <br />
            <Label>จำนวนอุปกรณ์</Label>
            <InputGroup size="lg">
              <Input
                style={{ fontSize: '15px' }}
                type="text"
                placeholder="จำนวน"
                defaultValue="0"
                name="product_inventory"
                innerRef={register}
              />
            </InputGroup>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSubmit(onAddStock)}>
            เพิ่ม
          </Button>{' '}
          <Button color="secondary" onClick={toggle}>
            ยกเลิก
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default ModalAddStock
