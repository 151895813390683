import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Button, Card, CardBody, Alert, Label, Table, InputGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import * as role from "../../util/UserRole";
import { useHistory, useParams } from "react-router-dom";
import * as actions from '../../actions'
import * as UserStatus from "../../util/UserStatus";
import _ from "lodash";
import ModalAddDeliverWork from "../../components/modal/ModalAddDeliverWork";
import { TrashIcon, } from 'evergreen-ui'
import SignaturePad from "react-signature-canvas";

const EditDeliverWork = () => {
  const orders = useSelector((state) => state.orders);
  const user = useSelector((state) => state.user);
  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams();
  const [width, setWidth] = React.useState(window.innerWidth);
  const breakpoint = 620;
  const [reload, setReload] = useState(true);

  useEffect(() => {
    // console.log("Use Effect:",reload);
    dispatch(actions.ordersFetch(user))
    window.addEventListener('resize', () => setWidth(window.innerWidth));
    return () => { }
  }, [reload])

  const allowAccess = user
    ? user.role == role.MECHANIC_ROLE ||
      user.role == role.CONTRATOR_ROLE ||
      user.role == role.ADMIN_ROLE ||
      user.role == role.SUPER_USER_ROLE
      ? true
      : false
    : history.push('/login');

  const filterOrder = _.find(orders, (_order) => {
    return (
      _order._id == params.id
    )
  })

  const [deliverWork, setDeliverWork] = useState(filterOrder.deliveryWorkList); // เก็บรายการส่งมอบงาน
  const [appoinmentRecipient, setAppoinmentRecipient] = useState([]); //ผู้รับนัดหมาย
  const [supervisor, setSupervisor] = useState([]); //ผู้ควบคุมงาน
  const [inspector, setInspector] = useState([]); //ผู้ตรวจรับงาน
  const [projectManager, setProjectManager] = useState([]); //ผู้จัดการโครงการ
  const [count, setCount] = useState(0);
  const [modalAddDeliverWork, setModalAddDeliverWork] = useState(false); // props สำหรับเปิดปิด modal
  const toggleModalAddDeliverWork = () => {
    setModalAddDeliverWork(!modalAddDeliverWork); // method สำหรับ set เปิดหรือปิด modal
  }

  const [imageURL, setImageURL] = useState(filterOrder.mechanicSign);
  const sigCanvas = useRef({})
  const clear = () => sigCanvas.current.clear();

  const save = () => {
    setImageURL(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"))
    setOpen(!open);
  }

  const [open, setOpen] = useState(false);
  const toggleOpen = () => {
    setOpen(!open);
  }
  const setDeliveryWork = () => {

    if (filterOrder.deliveryWork === true && count === 0) { //แก้ไขการส่งมอบงาน

      appoinmentRecipient[0] = filterOrder.user_name;
      supervisor[0] = filterOrder.user_name;
      inspector[0] = filterOrder.user_name;
      projectManager[0] = filterOrder.projectManager;
      setCount(1);
    }
    else { //ส่งมอบงานครั้งแรก

      appoinmentRecipient[0] = filterOrder.user_name;
      supervisor[0] = filterOrder.mechanic_name;
      inspector[0] = filterOrder.user_name;

    }
  }

  const handleOnChangeAppoinmentRecipient = (value) => {

    appoinmentRecipient[0] = value
    setAppoinmentRecipient(appoinmentRecipient)

  }
  const handleOnChangeSupervisor = (value) => {

    supervisor[0] = value
    setSupervisor(supervisor);

  }
  const handleOnChangeInspector = (value) => {

    inspector[0] = value
    setInspector(inspector)

  }
  const handleOnChangeProjectManager = (value) => {

    projectManager[0] = value
    setProjectManager(projectManager)
  }

  const showDeliverWork = (delivery) => {
    if (delivery.length === 0) {
      return (
        <>
          <div className="text-center">
            <br />
            <Label
              className="text-danger"
              style={{ fontSize: "30px" }}
            >ยังไม่มีรายการส่งมอบงาน</Label>
          </div>
        </>
      );
    } else {
      return (
        <>

          <Table striped bordered hover responsive style={{ fontSize: "18px" }} className="mt-4 text-center">
            <thead className="light-th" >
              <tr>
                <th>ที่</th>
                <th>อาคาร</th>
                <th>ระบบงาน</th>
                <th>รายละเอียดการส่งมอบ</th>
                <th>ปริมาณ</th>
                <th>หน่วย</th>
                <th>ดำเนินการ</th>
              </tr>
            </thead>
            <tbody>
              {delivery.map((_delivery, index) => {
                return (
                  <>
                    <tr>
                      <th >{index + 1}</th>
                      <td>{_delivery.building}</td>
                      <td>{_delivery.workSystem}</td>
                      <td>{_delivery.detail}</td>
                      <td>{_delivery.amount}</td>
                      <td>{_delivery.unit}</td>
                      <td><TrashIcon
                        className=" text-right"
                        color="danger"
                        onClick={() => {
                          let confirmRemove = window.confirm("ยืนยันการลบ")
                          if (confirmRemove) {
                            delivery.splice(delivery.indexOf(_delivery), 1); //ลบออก
                            setReload(!reload);
                          }
                        }}
                        size={30} /></td>
                    </tr>
                  </>
                );
              })} </tbody>
          </Table>
        </>
      )
    }

  }

  if (allowAccess) {
    return (
      <>
        <ModalAddDeliverWork
          modal={modalAddDeliverWork}
          toggle={toggleModalAddDeliverWork}
          deliverWork={deliverWork}
          setDeliverWork={setDeliverWork}
        />
        <Container fluid className="light-th"
          style={{
            marginTop: "50px",
            maxWidth: "900px",
          }}
        >
          <Row>
            <Col xs="2">
              <Button
                outline
                color="secondary"
                onClick={
                  history.goBack
                }
              >ย้อนกลับ</Button>
            </Col>
            <Col xs="8">
              <h2 className="text-center">ใบส่งมอบงาน</h2>
            </Col>
          </Row>


          <br />
          <div style={{
            backgroundColor: "#ffffff",
            boxShadow: "1px 1px 3px #d9d9d9",
            padding: "30px",
          }}>
            <Label
              style={{ fontSize: "25px" }}
            >ข้อมูลการส่งมอบงาน</Label>
            <br />

            {setDeliveryWork()}

            <Row>
              <Col>
                <Label>ผู้รับนัดหมาย</Label>

              </Col>
              <Col>
                <Label>ผู้ปฏิบัติงาน</Label>

              </Col>
            </Row>
            <InputGroup size="lg">
              <Input
                style={{ fontSize: "15px" }}
                placeholder="ผู้รับนัดหมาย"
                onChange={e => handleOnChangeAppoinmentRecipient(e.target.value)}
                value={appoinmentRecipient}
              />
              <Input
                style={{ fontSize: "15px" }}
                placeholder="ผู้ควบคุมงาน"
                onChange={e => handleOnChangeSupervisor(e.target.value)}
                value={supervisor}
              />
            </InputGroup>
            <br />
            <Row>
              <Col>
                <Label>ผู้ตรวจรับงาน</Label>
              </Col>
              <Col>
                <Label>ผู้ควบคุมงาน</Label>
              </Col>
            </Row>
            <InputGroup size="lg">
              <Input
                style={{ fontSize: "15px" }}
                placeholder="ผู้ตรวจรับงาน"
                onChange={e => handleOnChangeInspector(e.target.value)}
                value={inspector}
              />
              <Input
                style={{ fontSize: "15px" }}
                placeholder="ผู้จัดการโครงการ"
                onChange={e => handleOnChangeProjectManager(e.target.value)}
                value={projectManager}
              />
            </InputGroup>
            <br />
            <Label
              style={{ fontSize: "25px" }}
            >รายการส่งมอบงาน</Label>
            <Button
              className="ml-2"
              color="success"
              onClick={() => {
                toggleModalAddDeliverWork(); // เปิด modal สำหรับเพิ่มรายการส่งมอบงาน
              }}
            >เพิ่ม</Button>
            {showDeliverWork(deliverWork)}
            <br />

            <Label
              style={{ fontSize: "25px" }}
            >ลายเซ็น</Label>

            <Button className="ml-2" color="primary" onClick={() => toggleOpen()}>เพิ่มลายเซ็น</Button>

            <Modal isOpen={open} toggle={toggleOpen}>
              <ModalHeader>ลายเซ็น</ModalHeader>
              <ModalBody>
                <SignaturePad
                  ref={sigCanvas}
                  canvasProps={{ width: 450, height: 200 }} />
              </ModalBody>
              <ModalFooter>
                <Button color="success" onClick={save}>บันทึก</Button>{" "}
                <Button color="warning" onClick={clear}>ล้างค่า</Button>{" "}
                <Button onClick={() => toggleOpen()}>ยกเลิก</Button>

              </ModalFooter>
            </Modal>
            <br />
            <br />
            {imageURL ? (
              <img
                src={imageURL}
                alt="my signature"
                style={{
                  display: "block",
                  margin: "0 auto",
                  border: "1px solid black",
                  width: "300px"
                }}
              />
            ) : null}
            <br />
            <div className="text-right">
              <Button
                onClick={() => {
                  if (deliverWork.length === 0) {
                    alert("โปรดเพิ่มรายการส่งมอบงาน")
                  } else {

                    if (imageURL === undefined) {
                      alert("โปรดเพิ่มลายเซ็น")
                    } else {
                      let comfirm = window.confirm("ยืนยันการบันทึกใบส่งมอบงาน")
                      if (comfirm) {
                        const data = {
                          deliveryWork: true,
                          deliveryWorkList: deliverWork,
                          appoinmentRecipient: appoinmentRecipient[0],
                          supervisor: supervisor[0],
                          inspector: inspector[0],
                          projectManager: projectManager[0],
                          mechanicSign: imageURL,

                        }
                        dispatch(actions.orderPut(filterOrder._id, data))
                        dispatch(actions.ordersFetch(user))
                        history.push("/deliver_work/" + filterOrder._id)
                      }
                    }
                  }
                }}
                color="success"
              >
                บันทึก
            </Button>
            </div>

          </div>
        </Container>

      </>
    )
  } else {
    return (
      <>
      </>
    )
  }
}
export default EditDeliverWork;