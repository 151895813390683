import React, { useState, useEffect } from "react";
import { Container, Label, Col, Row, Input, Card, CardBody, CardSubtitle, CardTitle, Button } from "reactstrap";
import { Bar, Line, Pie, HorizontalBar } from 'react-chartjs-2'
import moment from 'moment'
import 'moment/locale/th'
import _ from 'lodash'

const RepairStatus = ({ orders, histories }) => {

  const generateMechanicSuccessTask = () => {



    const task_status = [0, 0, 0, 0, 0];

    _.map(orders, (order) => {
      if (order.statusUser === "แจ้งซ่อมเรียบร้อยแล้ว รอช่างรับงาน") {
        task_status[0] = task_status[0] + 1

      } else { //กำลังดำเนินการ
        task_status[1] = task_status[1] + 1

      }
    })


      _.map(histories, (history) => {
      if (history.orders.statusUser === "ดำเนินการเสร็จสิ้น") {
        task_status[2] = task_status[0] + 1

      }
      if (history.orders.statusUser === "ยกเลิกงานซ่อม") {
        task_status[3] = task_status[1] + 1

      }
      if (history.orders.statusUser === "ช่างปฏิเสธงานซ่อม") {
        task_status[4] = task_status[2] + 1

      }
    })

    return task_status;

  }

  const task_status = generateMechanicSuccessTask()

  const repairStatus = {
    labels: ['ยังไม่รับงาน', 'กำลังดำเนินการซ่อม', 'ดำเนินการเสร็จสิ้น', 'ลูกค้ายกเลิกงานซ่อม', 'ช่างปฏิเสธงานซ่อม',],
    datasets: [
      {
        label: 'สถานะการแจ้งซ่อม',
        backgroundColor: [
          '#FA86F2',
          '#3796DF',
          '#62F163',
          '#F75F5E',
          '#FFD45B'
        ],
        hoverBackgroundColor: [
          '#ED30CD',
          '#2154EE',
          '#00E54B',
          '#FF4040',
          '#FFA431'
        ],
        data: task_status
      }
    ]
  }

  return (
    <Card>
      <CardBody>
        <h4 className="text-left"><strong>สถิติการแจ้งซ่อมทั้งหมด</strong></h4>
        <Pie
          data={repairStatus}
          options={{
            legend: {
              display: true,
              position: 'right'
            },

          }}
          width={50}
          height={20}
        />
      </CardBody>
    </Card>
  )
}
export default RepairStatus;