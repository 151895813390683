import React, { useState, useEffect } from "react";
import { Container, Table, Row, Col, Badge, Label, InputGroup, Card, Button, Input,CardBody } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import * as role from "../../util/UserRole";
import { useHistory, useParams } from "react-router-dom";
import * as actions from '../../actions'
import * as UserStatus from "../../util/UserStatus";
import _ from "lodash";
import MaterialTables from "../tables/MaterialTables";
import OtTables from "../tables/OtTables";
import { useForm } from "react-hook-form";
import { Switch, TextInput, Strong, } from 'evergreen-ui'
import EditProductAndServiceCharge from "./EditProductAndServiceCharge";
import EditWorkingConditions from "./EditWorkingConditions";
import EditOverview from "./EditOverview";

// แก้ใบเสนอราคาเบื้องต้น
const EditQuotation = () => {
  const orders = useSelector((state) => state.orders);
  const user = useSelector((state) => state.user);
  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams();
  const [width, setWidth] = React.useState(window.innerWidth);
  const breakpoint = 620;

  const [filterOrder, setfilterOrder] = useState(_.find(orders, (_order) => {
    return (
      _order._id == params.id
    )
  })
  )
  const material = filterOrder.material[0];
  const estimate = filterOrder.estimate_price[0];

  const indexInventory = material.product.length;
  const [taxFree, setTaxFree] = useState(filterOrder.taxFree);
  const [noOperatingCost, setNoOperatingCost] = useState(filterOrder.noOperatingCost);
  const [reload, setReload] = useState(true);

  const [selectProduct, setSelectProduct] = useState(filterOrder.productFormStock[0].product); //เก็บอุปกรณ์ที่เลือกจากคลัง
  const [selectProductStore, setSelectProductStore] = useState(filterOrder.productFormStore[0].product); //เก็บอุปกรณ์ที่เลือกจากร้านค้า
  const [productInventory, setProductInventory] = useState(filterOrder.productFormStock[0].inventory);
  const [productStoreInventory, setProductStoreInventory] = useState(filterOrder.productFormStore[0].inventory);

  const [productBorrow, setProductBorrow] = useState([]);  //เก็บอุปกรณ์ที่เลือกจากคลังและร้านค้า
  const [productBorrowInventory, setProductBorrowInventory] = useState([]);
  const [productCost, setProductCost] = useState([]);  //เก็บอุปกรณ์ที่เป็นวัสดุ
  const [productCostInventory, setProductCostInventory] = useState([]);
  const [cost, setCost] = useState([]); //ค่าแรงต่อชิ้น

  const [ot, setOt] = useState(estimate.ot); //เก็บค่าสภาพการทำงาน
  const [price, setPrice] = useState([0]); //มูลค่างาน
  const [vat, setVat] = useState([]); //ภาษี
  const [operatingCost, setOperatingCost] = useState(filterOrder.estimate_price[0].operatingCost); //ค่าดำเนินงาน

  const [username, setUsername] = useState(filterOrder.user_name);
  const [useragency, setUseragency] = useState(filterOrder.user_agency);
  const [project, setProject] = useState(filterOrder.project);
  const [usertel, setUsertel] = useState(filterOrder.user_tel);
  const [userfax, setUserfax] = useState(filterOrder.user_fax);
  const [paymentTerms, setPaymentTerms] = useState(filterOrder.paymentTerms);
  const [credit, setCredit] = useState(filterOrder.credit);
  // const [expirationDate, setExpirationDate] = useState(new Date(filterOrder.expirationDate));
  const [quotationId, setQuotationId] = useState(filterOrder.quotationId);
  const [quotationDate, setQuotationDate] = useState(new Date(filterOrder.quotationDate).toLocaleDateString("th"));

  useEffect(() => {
    // console.log("Use Effect:", reload);
    window.addEventListener('resize', () => setWidth(window.innerWidth));

    return () => { }
  }, [reload])

  const handleOnChangePaymentTerms = (value) => {
    if (value != null) {
      setPaymentTerms(value);
      // if (value != "") {
      //   setExpirationDate(new Date(new Date().setDate(new Date(filterOrder.quotationDate).getDate() + parseInt(value))))
      // }
    }

  }

  const handleOnCredit = (value) => {
    if (value != null) {
      setCredit(value);

    }

  }

  const showEdit = () => {
    if (filterOrder.bargain.length != 0)
      return (
        <>
          <Row>
            <Col>
              <Card style={{ backgroundColor: "#FEF9E7", borderColor: '#F1C40F' }} className="mb-2">

                <CardBody style={{ fontSize: "25px" }} >
                  <h4>ราคาที่ลูกค้าต้องการ : {filterOrder.bargain} บาท</h4>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </>
      )
  }

  return (
    <>

      <Container fluid className="light-th"
        style={{
          marginTop: "50px",
          maxWidth: "900px",
        }}
      >

        <Row>
          <Col >
            <Button
              outline
              color="secondary"
              onClick={
                history.goBack
              }
            >ย้อนกลับ</Button>
          </Col>
          <Col xs="8">
            <h2 className="text-left">แก้ไขใบเสนอราคา</h2>
          </Col>


        </Row>

        <br />
        <div
          style={{
            backgroundColor: "#ffffff",
            boxShadow: "1px 1px 3px #d9d9d9",
            padding: "30px",
          }}>
          {showEdit()}
          <h4>ข้อมูลลูกค้า</h4>
          <br />
          <InputGroup size="lg">
            <Input
              disabled
              style={{ fontSize: "15px" }}
              type="text"
              placeholder="เลขที่"
              value={quotationId}
            />
            <Input
              disabled
              style={{ fontSize: "15px" }}
              type="text"
              placeholder="วันที่"
              value={quotationDate}
            />
          </InputGroup>
          <br />
          <InputGroup size="lg">
            <Input
              style={{ fontSize: "15px" }}
              type="text"
              placeholder="ชื่อลูกค้า"
              onChange={e => setUseragency(e.target.value)}
              value={username}
            />
            <Input
              style={{ fontSize: "15px" }}
              type="text"
              placeholder="หน่วยงาน"
              onChange={e => setUseragency(e.target.value)}
              value={useragency}
            />
          </InputGroup>
          <br />
          <InputGroup size="lg">
            <Input
              style={{ fontSize: "15px" }}
              type="text"
              placeholder="เบอร์โทร"
              onChange={e => setUsertel(e.target.value)}
              value={usertel}
            />
            <Input
              style={{ fontSize: "15px" }}
              type="text"
              placeholder="แฟกซ์"
              onChange={e => setUserfax(e.target.value)}
              value={userfax}
            />
          </InputGroup>
          <br />
          <InputGroup size="lg">
            <Col><Label>กำหนดยืนราคา (วัน)</Label></Col>
            <Col><Label>เครดิต</Label></Col>
          </InputGroup>
          <InputGroup size="lg">
            <Input
              style={{ fontSize: "15px" }}
              type="select"
              placeholder="กำหนดยืนราคา (วัน)"
              onChange={e => handleOnChangePaymentTerms(e.target.value)}
              value={paymentTerms}
            >
              <option>7</option>
              <option>15</option>
              <option>30</option>
              <option>45</option>
              <option>60</option>
            </Input>
            {/* <Input
              disabled
              style={{ fontSize: "15px" }}
              type="text"
              placeholder="กำหนดชำระเงิน"
              value={expirationDate.toLocaleDateString("th")}
            /> */}
            <Input
              style={{ fontSize: "15px" }}
              type="select"
              // placeholder="กำหนดยืนราคา ( วัน )"
              placeholder="เครดิต"
              onChange={e => handleOnCredit(e.target.value)}
              value={credit}
            >
              <option>เงินสด</option>
              <option>7 วัน</option>
              <option>15 วัน</option>
              <option>30 วัน</option>
              <option>45 วัน</option>
              <option>60 วัน</option>
            </Input>
          </InputGroup>
          <br />
          <InputGroup size="lg">
            <Input
              style={{ fontSize: "15px" }}
              type="text"
              placeholder="ชื่อโครงการ"
              onChange={e => setProject(e.target.value)}
              value={project}
            />
          </InputGroup>
          <br />
          <EditProductAndServiceCharge
            selectProduct={selectProduct}
            selectProductStore={selectProductStore}
            setSelectProduct={setSelectProduct}
            setProductInventory={setProductInventory}
            setProductStoreInventory={setProductStoreInventory}
            productInventory={productInventory}
            productStoreInventory={productStoreInventory}
            reload={reload}
            setReload={setReload}
            productBorrow={productBorrow}
            productBorrowInventory={productBorrowInventory}
            productCost={productCost}
            productCostInventory={productCostInventory}
            cost={cost}
            setProductBorrow={setProductBorrow}
            setProductBorrowInventory={setProductBorrowInventory}
            setProductCost={setProductCost}
            setProductCostInventory={setProductCostInventory}
            setCost={setCost}
            setPrice={setPrice}
            setVat={setVat}
            setOperatingCost={setOperatingCost}
            ot={ot}
            price={price}
            vat={vat}
            taxFree={taxFree}
            setTaxFree={setTaxFree}
            noOperatingCost={noOperatingCost}
            setNoOperatingCost={setNoOperatingCost}
            operatingCost={operatingCost}
            setOperatingCost={setOperatingCost}
          />

          <br />
          <EditWorkingConditions
            ot={ot}
            setOt={setOt}
            reload={reload}
            setReload={setReload}
          />
          <br />

          <EditOverview
            productBorrow={productBorrow}
            productBorrowInventory={productBorrowInventory}
            cost={cost}
            productCost={productCost}
            productCostInventory={productCostInventory}
            ot={ot}
            price={price}
            vat={vat}
            operatingCost={operatingCost}
            setOperatingCost={setOperatingCost}
            taxFree={taxFree}
            setTaxFree={setTaxFree}
            noOperatingCost={noOperatingCost}
            setNoOperatingCost={setNoOperatingCost}
            reload={reload}
            setReload={setReload}
          />


          <div className="text-right">
            <Button
              onClick={() => {

                let comfirm = window.confirm("ยืนยันการแก้ไขใบเสนอราคา")
                if (comfirm) {

                  //ปกติ
                  const dataNormal = {
                    user_name: username,
                    user_agency: useragency,
                    project: project,
                    user_tel: usertel,
                    user_fax: userfax,
                    paymentTerms: paymentTerms,
                    credit: credit,
                    // expirationDate: expirationDate,

                    statusUser: UserStatus.USER_ESTIMATEPRICE,
                    statusMechanic: UserStatus.MECHANIC_ESTIMATEPRICE,

                    productBorrow: { //อุปกรณ์ที่ยืม
                      product: productBorrow,
                      inventory: productBorrowInventory,
                    },
                    productFormStore: { //อุปกรณ์จากร้านค้า
                      product: selectProductStore,
                      inventory: productStoreInventory,
                    },
                    productFormStock: { //อุปกรณ์จากคลัง
                      product: selectProduct,
                      inventory: productInventory,
                    },
                    material: { //วัสดุทั้งหมดที่เบิก
                      product: productCost,
                      inventory: productCostInventory,
                    },
                    estimate_price: { //การคิดราคาเบื้องต้น
                      cost: cost.slice(0, productCost.length), //ค่าบริการ
                      ot: ot, //ค่าสภาพการทำงาน
                      vat: vat, //ค่าภาษี
                      priceOfProduct: price[0], //ค่าวัสดุ
                      operatingCost: operatingCost, //เปอร์เซ็นของค่าดำเนินการ
                      operatingPrice: (operatingCost / 100 * price[0]).toFixed(2), //ค่าดำเนินการ
                    },
                    IntroductoryPrice: (vat[0] + price[0] + operatingCost / 100 * price[0]).toFixed(2), //ราคารวม
                    taxFree: taxFree,   //เปิด/ปิด คิดค่าภาษี
                    noOperatingCost: noOperatingCost, //เปิด/ปิด ค่าดำเนินการ
                  }

                  //เร่งด่วน
                  const dataUrgent = {
                    user_name: username,
                    user_agency: useragency,
                    project: project,
                    user_tel: usertel,
                    user_fax: userfax,
                    paymentTerms: paymentTerms,
                    credit: credit,
                    // expirationDate: expirationDate,

                    statusMechanic: UserStatus.MECHANIC_ESTIMATEPRICE,
                    productBorrow: { //อุปกรณ์ที่ยืม
                      product: productBorrow,
                      inventory: productBorrowInventory,
                    },
                    productFormStore: { //อุปกรณ์จากร้านค้า
                      product: selectProductStore,
                      inventory: productStoreInventory,
                    },
                    productFormStock: { //อุปกรณ์จากคลัง
                      product: selectProduct,
                      inventory: productInventory,
                    },
                    material: { //วัสดุทั้งหมดที่เบิก
                      product: productCost,
                      inventory: productCostInventory,
                    },
                    estimate_price: { //การคิดราคาเบื้องต้น
                      cost: cost.slice(0, productCost.length), //ค่าบริการ
                      ot: ot, //ค่าสภาพการทำงาน
                      vat: vat, //ค่าภาษี
                      priceOfProduct: price[0], //ค่าวัสดุ
                      operatingCost: operatingCost, //เปอร์เซ็นของค่าดำเนินการ
                      operatingPrice: (operatingCost / 100 * price[0]).toFixed(2), //ค่าดำเนินการ
                    },
                    IntroductoryPrice: (vat[0] + price[0] + operatingCost / 100 * price[0]).toFixed(2), //ราคารวม
                    taxFree: taxFree,   //เปิด/ปิด คิดค่าภาษี
                    noOperatingCost: noOperatingCost, //เปิด/ปิด ค่าดำเนินการ
                  }

                  const notify ={
                    message: "ขณะนี้ได้แก้ไขการประเมินราคาสำหรับงานแจ้งซ่อมของท่านเรียบร้อยแล้ว",
                   
                  }
                  const notifyData ={
                    data:notify,
                    token: filterOrder.user_token
                  }

                  if (filterOrder.speed === "ปกติ") {
                    dispatch(actions.notifySend(notifyData))
                    dispatch(actions.orderPut(filterOrder._id, dataNormal))
                    history.push("/management/repair")
                  } 
                }

              }}
              color="primary">
              บันทึก
              </Button>
          </div>

        </div>
      </Container>
    </>
  )

}
export default EditQuotation;