import React, { useState, useEffect } from "react";
import { Button, Container, Table, Input, Alert, Row, Col, Label, Card } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import * as role from "../../util/UserRole";
import { useHistory, useParams } from "react-router-dom";
import * as actions from '../../actions'
import * as UserStatus from "../../util/UserStatus";
import _ from "lodash";
import ModalSelectProduct from "../../components/modal/ModalSelectProduct";
import ModalAddOT from '../../components/modal/ModalAddOT';
import { RemoveIcon, AddIcon, TrashIcon, TextInput, Strong } from 'evergreen-ui'
import EstimatePriceDesktop from "./EstimatePriceDesktop";
import EstimatePriceMobile from "./EstimatePriceMoblie";

// ดูรายละเอียดข้อมูลการซ่อม ประเมินราคาเบื้องต้น

const EstimatePrice = () => {
  const orders = useSelector((state) => state.orders);
  const user = useSelector((state) => state.user);
  const products = useSelector((state) => state.products);
  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams();
  const [selectProduct, setSelectProduct] = useState([]); //เก็บอุปกรณ์ที่เลือกจากคลัง
  const [productInventory, setProductInventory] = useState([]);
  const [reload, setReload] = useState(true);
  const [selectProductStore, setSelectProductStore] = useState([]); //เก็บอุปกรณ์ที่เลือกจากร้านค้า
  const [productStoreInventory, setProductStoreInventory] = useState([]);
  const [page, setPage] = useState(0);
  const [productBorrow, setProductBorrow] = useState([]);  //เก็บอุปกรณ์ที่เลือกจากคลังและร้านค้า
  const [productBorrowInventory, setProductBorrowInventory] = useState([]);
  const [productCost, setProductCost] = useState([]);  //เก็บอุปกรณ์ที่เป็นวัสดุ
  const [productCostInventory, setProductCostInventory] = useState([]);
  const [cost, setCost] = useState([]); //ค่าแรงต่อชิ้น
  const [ot, setOt] = useState([]); //เก็บค่าสภาพการทำงาน
  const [price, setPrice] = useState([0]); //มูลค่างาน
  const [vat, setVat] = useState([]); //ภาษี
  const [operatingCost, setOperatingCost] = useState([]); //ค่าดำเนินงาน

  const [width, setWidth] = React.useState(window.innerWidth);
  const breakpoint = 620;

  const [modalSelectProduct, setModalSelectProduct] = useState(false); // props สำหรับเปิดปิด modal
  const toggleModalSelectProduct = () => {
    setModalSelectProduct(!modalSelectProduct); // method สำหรับ set เปิดหรือปิด modal
  }

  const [modalAddOT, setModalAddOT] = useState(false); // props สำหรับเปิดปิด modal
  const toggleModalAddOT = () => {
    setModalAddOT(!modalAddOT); // method สำหรับ set เปิดหรือปิด modal
  }

  const allowAccess = user
    ? user.role == role.MECHANIC_ROLE ||
      user.role == role.ADMIN_ROLE ||
      user.role == role.SUPER_USER_ROLE
      ? true
      : false
    : history.push('/login');

  const filterOrder = _.find(orders, (_order) => {
    return (
      _order._id == params.id
    )
  })

  useEffect(() => {
    dispatch(actions.productsFetch());
    window.addEventListener('resize', () => setWidth(window.innerWidth));

    return () => {
    }
  }, []);

  useEffect(() => {
    // console.log("Use Effect:",reload);
    window.addEventListener('resize', () => setWidth(window.innerWidth));
    return () => { }
  }, [reload])




  //บันทึกการประเมินราคาเบื้องต้น ประกอบไปด้วย รายการและจำนวนอุปกรณ์ที่ยืมทั้งหมด, คลัง, ร้านค้า, ค่าบริการ , ค่าสภาพการทำงาน , ค่าภาษี , ราคารวม
  //เปลี่ยนสถานะของช่างและผู้ใช้ โดยแยกตามความเร่งด่วนของงานซ่อม 
  // ปกติ ---> ต้องรอลูกค้ายืนยันก่อน ถึงจะสามารถดำเนินการซ่อมได้ ---> ตัดสต็อก หลังจากลูกค้ากดยืนยัน(หน้าเบิกอุปกรณ์)
  // เร่งด่วน ---> ช่างสามารถเบิกอุปกรณืได้เลย ---> ตัดสต็อกได้เลย(หน้าเบิกอุปกรณ์)
  const sendProduct = () => {
    let comfirm = window.confirm("ยืนยันการประเมินราคาเบื้องต้น")
    if (comfirm) {

      //ปกติ
      const dataNormal = {
        statusUser: UserStatus.USER_ESTIMATEPRICE,
        statusMechanic: UserStatus.MECHANIC_ESTIMATEPRICE,

        productBorrow: { //อุปกรณ์ที่ยืม
          product: productBorrow,
          inventory: productBorrowInventory,
        },
        productFormStore: { //อุปกรณ์จากร้านค้า
          product: selectProductStore,
          inventory: productStoreInventory,
        },
        productFormStock: { //อุปกรณ์จากคลัง
          product: selectProduct,
          inventory: productInventory,
        },
        material: { //วัสดุทั้งหมดที่เบิก
          product: productCost,
          inventory: productCostInventory,
        },
        estimate_price: { //การคิดราคาเบื้องต้น
          cost: cost, //ค่าบริการ
          ot: ot, //ค่าสภาพการทำงาน
          vat: vat, //ค่าภาษี
          priceOfProduct: price[0], //ค่าวัสดุ
          operatingCost: operatingCost, //เปอร์เซ็นของค่าดำเนินการ
          operatingPrice: (operatingCost / 100 * price[0]).toFixed(2), //ค่าดำเนินการ
        },
        IntroductoryPrice: (vat[0] + price[0] + operatingCost / 100 * price[0]).toFixed(2), //ราคารวม
      }

      //เร่งด่วน
      const dataUrgent = {
        statusMechanic: UserStatus.MECHANIC_ESTIMATEPRICE,
        // statusMechanic: UserStatus.MECHANIC_REQUEST,
        // statusAdmin: UserStatus.ADMIN_PREPARE,
        productBorrow: { //อุปกรณ์ที่ยืม
          product: productBorrow,
          inventory: productBorrowInventory,
        },
        productFormStore: { //อุปกรณ์จากร้านค้า
          product: selectProductStore,
          inventory: productStoreInventory,
        },
        productFormStock: { //อุปกรณ์จากคลัง
          product: selectProduct,
          inventory: productInventory,
        },
        material: { //วัสดุทั้งหมดที่เบิก
          product: productCost,
          inventory: productCostInventory,
        },
        estimate_price: { //การคิดราคาเบื้องต้น
          cost: cost, //ค่าบริการ
          ot: ot, //ค่าสภาพการทำงาน
          vat: vat, //ค่าภาษี
          priceOfProduct: price[0], //ค่าวัสดุ
          operatingCost: operatingCost, //เปอร์เซ็นของค่าดำเนินการ
          operatingPrice: (operatingCost / 100 * price[0]).toFixed(2), //ค่าดำเนินการ
        },
        IntroductoryPrice: (vat[0] + price[0] + operatingCost / 100 * price[0]).toFixed(2), //ราคารวม
      }

      if (filterOrder.speed === "ปกติ") {
        dispatch(actions.orderPut(filterOrder._id, dataNormal))
        history.push("/management/repair")
      } else {
        dispatch(actions.orderPut(filterOrder._id, dataUrgent))
        // history.push("/management/repair")
        let navigate = window.confirm("เข้าสู่หน้าเบิกอุปกรณ์ต่อหรือไม่")
        if (navigate) {
          history.push("/management/borrow");
        } else {
          history.push("/management/repair");
        }
      }

    }

  }


  const showSelectProduct = (select) => {
    return (
      <>
        {select.map((product) => (
          <>
            <div style={{
              backgroundColor: '#F7F9FD',
              boxShadow: '1px 1px 3px #d9d9d9',
              padding: '30px',
            }}
              className="mb-4 text-center">
              <Row>
                <Col><img src={product.product_image} width="100vw" />
                </Col>
                <Col md="6">
                  <span>{product.product_name}</span>
                  <p style={{ fontSize: "18px", color: "#7B8B9A" }}>รายละเอียด {product.product_detail}
                    <p style={{ fontSize: "18px", color: "#BF0E08" }}>ยอดคงเหลือ {product.product_inventory}</p>
                  </p>
                </Col>
                <Col >
                  <Row>
                    <Button
                      size="sm"
                      color="danger"
                      onClick={() => {
                        if (productInventory[selectProduct.indexOf(product)] != 1) {
                          productInventory[selectProduct.indexOf(product)] = productInventory[selectProduct.indexOf(product)] - 1;
                          setReload(!reload);
                        } else if (productInventory[selectProduct.indexOf(product)] == 1) {
                          let confirmRemove = window.confirm("ยืนยันการลบอุปกรณ์")
                          if (confirmRemove) {

                            productInventory.splice(selectProduct.indexOf(product), 1); //ลบจำนวนออก
                            selectProduct.splice(selectProduct.indexOf(product), 1); //ลบอุปกรณ์ที่เลือก
                            setReload(!reload);

                          }
                        }

                      }}
                    ><RemoveIcon /></Button>
                    <TextInput
                      disabled
                      className="text-center"
                      width={"35%"}
                      name="productInventory"
                      value={productInventory[selectProduct.indexOf(product)]}
                    />
                    <Button

                      size="sm"
                      color="success"
                      onClick={() => {

                        let temp = productInventory;
                        if (temp[selectProduct.indexOf(product)] != product.product_inventory) {
                          temp[selectProduct.indexOf(product)] = temp[selectProduct.indexOf(product)] + 1;
                          setProductInventory(temp);
                          setReload(!reload); //สั่งให้ use effect ทำงาน  
                        } else if (temp[selectProduct.indexOf(product)] == product.product_inventory && selectProductStore.indexOf(product) === -1) {
                          selectProductStore.push(product);
                          productStoreInventory.push(1);
                          setReload(!reload);
                        } else {
                          productStoreInventory[selectProductStore.indexOf(product)] = productStoreInventory[selectProductStore.indexOf(product)] + 1;
                          setReload(!reload);
                        }




                      }}
                    ><AddIcon /></Button>
                  </Row>
                </Col>
                <Col>
                  <TrashIcon
                    className=" text-right"
                    color="danger"
                    onClick={() => {
                      let confirmRemove = window.confirm("ยืนยันการลบอุปกรณ์")
                      if (confirmRemove) {

                        productInventory.splice(selectProduct.indexOf(product), 1); //ลบจำนวนออก
                        selectProduct.splice(selectProduct.indexOf(product), 1); //ลบอุปกรณ์ที่เลือก
                        setReload(!reload);

                      }
                    }}
                    size={30} />
                </Col>
              </Row>
            </div>
          </>
        )
        )}
      </>
    )
  }

  const showTopic = (select) => {
    if (select.length != 0) {
      return <p className="text-left" style={{ fontSize: "18px", color: "#7B8B9A" }}>จัดซื้อจากร้านค้า</p>
    }
  }

  const showSelectProductStore = (select) => {
    return (
      <>
        {showTopic(select)}
        {select.map((product) => {
          return (
            <>
              <div style={{
                backgroundColor: '#F7F9FD',
                boxShadow: '1px 1px 3px #d9d9d9',
                padding: '30px',
              }}
                className="mb-4 text-center">

                <Row>
                  <Col><img src={product.product_image} width="100vw" />
                  </Col>
                  <Col md="6">
                    <span>{product.product_name}</span>
                    <p style={{ fontSize: "18px", color: "#7B8B9A" }}>รายละเอียด {product.product_detail}</p>
                    <Alert>
                      <p style={{ fontSize: "18px", color: "#BF0E08" }}>แหล่งจัดซื้อ : {product.product_store}</p>
                    </Alert>
                  </Col>
                  <Col >
                    <Row>
                      <Button
                        size="sm"
                        color="danger"
                        onClick={() => {
                          if (productStoreInventory[selectProductStore.indexOf(product)] != 1) {
                            productStoreInventory[selectProductStore.indexOf(product)] = productStoreInventory[selectProductStore.indexOf(product)] - 1;
                            setReload(!reload);
                          } else if (productStoreInventory[selectProductStore.indexOf(product)] == 1) {
                            let confirmRemove = window.confirm("ยืนยันการลบอุปกรณ์")
                            if (confirmRemove) {

                              productStoreInventory.splice(selectProductStore.indexOf(product), 1); //ลบจำนวนออก
                              selectProductStore.splice(selectProduct.indexOf(product), 1); //ลบอุปกรณ์ที่เลือก
                              setReload(!reload);

                            }
                          }

                        }}
                      ><RemoveIcon /></Button>
                      <TextInput
                        disabled
                        className="text-center"
                        width={"35%"}
                        name="productInventoryStore"
                        value={productStoreInventory[selectProductStore.indexOf(product)]}
                      />
                      <Button
                        size="sm"
                        color="success"
                        onClick={() => {

                          let temp = productStoreInventory;
                          // if( temp[selectProductStore.indexOf(product)] != product.product_inventory){
                          temp[selectProductStore.indexOf(product)] = temp[selectProductStore.indexOf(product)] + 1;
                          setProductStoreInventory(temp);
                          setReload(!reload); //สั่งให้ use effect ทำงาน  
                          // }




                        }}
                      ><AddIcon /></Button>
                    </Row>
                  </Col>
                  <Col>
                    <TrashIcon
                      className=" text-right"
                      color="danger"
                      onClick={() => {
                        let confirmRemove = window.confirm("ยืนยันการลบอุปกรณ์")
                        if (confirmRemove) {

                          productStoreInventory.splice(selectProductStore.indexOf(product), 1); //ลบจำนวนออก
                          selectProductStore.splice(selectProduct.indexOf(product), 1); //ลบอุปกรณ์ที่เลือก
                          setReload(!reload);

                        }
                      }}
                      size={30} />
                  </Col>
                </Row>
              </div>
            </>
          );
        })}
      </>
    )

  }

  const showListproduct = (productFromStock, productFromStore, productInventoryFromStock, productInventoryFromStore) => {
    if (productFromStock.length === 0 && productFromStore.length === 0) {
      return (
        <>
          <div className="text-center">
            <Label
              className="text-danger"
              style={{ fontSize: "40px" }}
            >กรุณาเลือกวัสดุและอุปกรณ์ก่อน</Label>

          </div>
          <br />
        </>
      );
    } else {
      return (
        <>
          {productFromStock.map((stock) => { //เอาอุปกรณืในร้านค้ากับคลัง มาคิดราคารวมกันที่เดียว
            if (productFromStore.indexOf(stock) === -1) { //ไม่มีเลือกการเลือกอุปกรณ์จากร้านค้า
              productBorrow.push(stock);
              productBorrowInventory.push(productInventoryFromStock[productFromStock.indexOf(stock)])
            } else {       //เลือกอุปกรณ์ทั้งจากร้านค้าและคลัง
              productBorrow.push(stock);
              productBorrowInventory.push(productInventoryFromStock[productFromStock.indexOf(stock)] + productInventoryFromStore[productFromStore.indexOf(stock)])
            }

          })}
          {productFromStore.map((store) => {
            if (productFromStock.indexOf(store) === -1) { //ไม่มีเลือกการเลือกอุปกรณ์จากคลัง
              productBorrow.push(store);
              productBorrowInventory.push(productInventoryFromStore[productFromStore.indexOf(store)])
            }
          })}
          {productBorrow.map((borrow) => {
            if (borrow.product_price != 0) {
              productCost.push(borrow);
              productCostInventory.push(productBorrowInventory[productBorrow.indexOf(borrow)])
              cost.push(0);
            }
          })
          }
          <Table striped bordered hover style={{ fontSize: "18px" }} className="text-center">
            <thead className="light-th" >
              <tr>
                <th>#</th>
                <th>ชื่อวัสดุ</th>
                <th>จำนวน</th>
                <th>ค่าแรง</th>
              </tr>
            </thead>
            <tbody>


              {productCost.map((_product, index) => {
                return (
                  <>
                    <tr>
                      <th >{index + 1}</th>
                      <td>{_product.product_name}</td>
                      <td>{productCostInventory[productCost.indexOf(_product)]}</td>
                      <td>
                        <TextInput
                          name="cost"
                          width={"35%"}
                          className="text-center"
                          type="number"
                          placeholder="0"
                          defaultvalue={cost[productCost.indexOf(_product)]}
                          onChange={event => cost[productCost.indexOf(_product)] = (event.target.value)}
                        />
                      </td>
                    </tr>
                  </>
                );
              })} </tbody>
          </Table>
        </>
      );
    }

  }

  const showOt = (ot) => {
    if (ot.length != 0) {
      return (
        <>
          <br />
          <Table striped bordered hover responsive style={{ fontSize: "18px" }} className="text-center">
            <thead className="light-th" >
              <tr>
                <th>#</th>
                <th>ชื่อ</th>
                <th>จำนวน</th>
                <th>ราคาต่อหน่วย</th>
                <th>รวม</th>
                <th>ดำเนินการ</th>
              </tr>
            </thead>
            <tbody>
              {ot.map((_ot, index) => {
                return (
                  <>
                    <tr>
                      <th >{index + 1}</th>
                      <td>{_ot.ot_name}</td>
                      <td>{_ot.ot_inventory}</td>
                      <td>{_ot.ot_price}</td>
                      <td>{_ot.ot_inventory * _ot.ot_price}</td>
                      <td><TrashIcon
                        className=" text-right"
                        color="danger"
                        onClick={() => {
                          let confirmRemove = window.confirm("ยืนยันการลบ")
                          if (confirmRemove) {
                            ot.splice(ot.indexOf(_ot), 1); //ลบออก
                            setReload(!reload);
                          }
                        }}
                        size={30} /></td>
                    </tr>
                  </>
                );
              })} </tbody>
          </Table>
        </>
      );
    }
  }

  const showOverview = () => {
    if (productBorrow.length != 0) {
      return (
        <>
          <Table striped bordered hover responsive style={{ fontSize: "18px" }} className="text-center">
            <thead className="light-th" >
              <tr>
                <th>#</th>
                <th>ชื่อวัสดุ</th>
                <th>จำนวน</th>
                <th>ราคาต่อหน่วย</th>
                <th>ค่าบริการ</th>
                <th>รวม</th>
              </tr>
            </thead>
            <tbody>

              {productBorrow.map((_product, index) => {
                if (_product.product_price != 0) {
                  // console.log(cost[productBorrow.indexOf(_product)]);
                  return (
                    <>
                      <tr>
                        <th >{index + 1}</th>
                        <td>{_product.product_name}</td>
                        <td>{productBorrowInventory[productBorrow.indexOf(_product)]}</td>
                        <td>{_product.product_price}</td>
                        <td>{cost[productCost.indexOf(_product)]}</td>
                        <td>{parseInt(_product.product_price * productCostInventory[productCost.indexOf(_product)]) + parseInt(cost[productCost.indexOf(_product)] * productCostInventory[productCost.indexOf(_product)])}</td>
                      </tr>
                    </>
                  );
                } else {
                  return (
                    <>
                      <tr>
                        <th >{index + 1}</th>
                        <td>{_product.product_name}</td>
                        <td>{productBorrowInventory[productBorrow.indexOf(_product)]}</td>
                        <td>{0}</td>
                        <td>{0}</td>
                        <td>{0}</td>
                      </tr>
                    </>
                  );
                }
              })} </tbody>
          </Table>
        </>
      );
    }
  }

  const showPartOt = () => {
    if (ot.length != 0) {
      return (
        <>
          <Label
            style={{ fontSize: "25px" }}
          >ค่าสภาพการทำงาน</Label>
          <Table striped bordered hover responsive style={{ fontSize: "18px" }} className="text-center">
            <thead className="light-th" >
              <tr>
                <th>#</th>
                <th>ชื่อ</th>
                <th>จำนวน</th>
                <th>ราคาต่อหน่วย</th>
                <th>รวม</th>
              </tr>
            </thead>
            <tbody>
              {ot.map((_ot, index) => {
                return (
                  <>
                    <tr>
                      <th >{index + 1}</th>
                      <td>{_ot.ot_name}</td>
                      <td>{_ot.ot_inventory}</td>
                      <td>{_ot.ot_price}</td>
                      <td>{_ot.ot_inventory * _ot.ot_price}</td>
                    </tr>
                  </>
                );
              })} </tbody>
          </Table>
        </>
      )
    }
  }

  const showPrice = () => {
    price[0] = 0;
    vat[0] = 0;
    return (
      <>
        {productBorrow.map((_product) => {
          if (_product.product_price != 0) {
            price[0] = price[0] + parseInt(_product.product_price * productCostInventory[productCost.indexOf(_product)]) + parseInt(cost[productCost.indexOf(_product)] * productCostInventory[productCost.indexOf(_product)]);
            vat[0] = price[0] * 0.07;
          }
        })}

        {ot.map((_ot) => {
          price[0] = price[0] + parseInt(_ot.ot_price) * parseInt(_ot.ot_inventory);
          vat[0] = price[0] * 0.07;
        })}

        <Table striped bordered hover responsive style={{ fontSize: "18px" }} className="text-center">
          <thead className="light-th" >
            <tr>
              <th>ชื่อ</th>
              {/* <th>ค่าวัสดุและอุปกรณ์</th> */}
              <th>ร้อยละ</th>
              <th>รวม</th>
            </tr>
          </thead>
          <tbody>

            <>
              <tr>
                <td>ค่าใช้จ่ายและผลกำไร</td>
                {/* <td>{price[0]} บาท</td> */}
                <td>ร้อยละ <TextInput
                  name="operatingCost"
                  width={"35%"}
                  type="number"
                  className="text-center"
                  placeholder="0"
                  onChange={event => setOperatingCost(event.target.value)}
                /> %</td>
                <td>เท่ากับ {((operatingCost) / 100 * parseInt(price[0])).toFixed(2)} บาท</td>

              </tr>
            </>

          </tbody>
        </Table>

        <div >
          <Col sm="12" className="text-right">

            <Label
              style={{ fontSize: "20px" }}
            > ค่าวัสดุและอุปกรณ์ เท่ากับ {price[0]} บาท</Label>
            <br />
            <Label
              style={{ fontSize: "20px" }}
            > ค่าใช้จ่ายและผลกำไร เท่ากับ {(operatingCost / 100 * price[0]).toFixed(2)} บาท</Label>
            <br />
            <Label
              style={{ fontSize: "20px" }}
            > ค่า VAT 7% เท่ากับ {vat[0].toFixed(2)} บาท</Label>
            <br />
            <Label
              style={{ fontSize: "20px" }}
            > รวมทั้งสิ้น <Strong style={{ fontSize: "25px" }} className="text-danger">{(vat[0] + price[0] + operatingCost / 100 * price[0]).toFixed(2)}</Strong> บาท</Label>
          </Col>
        </div>

      </>
    );
  }

  const resetProductBorrow = () => {
    if (productBorrow.length != 0) {
      setProductBorrow([]);
      setProductBorrowInventory([]);
      setProductCost([]);
      setProductCostInventory([]);
      setCost([]);
      setPrice([]);
      setVat([]);
      setOperatingCost([]);
    }
  }

  const pleaseSelectProduct = (productFromStock, productFromStore) => {
    if (productFromStock.length === 0 && productFromStore.length === 0) {
      return (
        <>
          <div className="text-center">
            <Label
              className="text-danger"
              style={{ fontSize: "40px" }}
            >กรุณาเลือกวัสดุและอุปกรณ์</Label>

          </div>
          <br />
        </>
      );
    }
  }

  const skipAddOT = (ot) => {
    if (ot.length === 0) {
      return (
        <>
          <div className="text-center">
            <br />
            <Label
              className="text-danger"
              style={{ fontSize: "40px" }}
            >ยังไม่มีรายการค่าสภาพการทำงาน</Label>
          </div>
        </>
      );
    }
  }

  const showProcess = () => {
    if (page === 0) {
      return (
        <>
          <Row className="ml-2">
            <Label
              style={{ fontSize: "25px" }}
            >ค่าวัสดุและอุปกรณ์</Label>
            <Button
              className="ml-2"
              size="sm"
              color="success"
              onClick={() => {
                toggleModalSelectProduct(); // เปิด modal สำหรับเลือกอุปกรณ์
              }}
            >เพิ่มอุปกรณ์</Button>
          </Row>
          <br />
          <h4 className="ml-2">{pleaseSelectProduct(selectProduct, selectProductStore)}</h4>
          <h4 className="ml-2">{showSelectProduct(selectProduct)}</h4>
          <h4 className="ml-2">{showSelectProductStore(selectProductStore)}</h4>
          <h4 >{resetProductBorrow()}</h4>
        </>
      );
    } else if (page === 1) {
      return (
        <>
          <Label
            className="ml-2"
            style={{ fontSize: "25px" }}
          >ค่าบริการ</Label>
          <h4 className="ml-2">{resetProductBorrow()}</h4>
          <h4 className="ml-2">{showListproduct(selectProduct, selectProductStore, productInventory, productStoreInventory)}</h4>
        </>
      );
    } else if (page === 2) {
      return (
        <>
          <Label
            className="ml-2"
            style={{ fontSize: "25px" }}
          >ค่าสภาพการทำงาน</Label><Button
            className="ml-2"
            color="success"
            onClick={() => {
              toggleModalAddOT(); // เปิด modal สำหรับเพิ่มค่าสภาพการทำงาน
            }}
          >เพิ่ม</Button>
          <br />
          <h4 className="ml-2">{skipAddOT(ot)}</h4>
          <h4 className="ml-2">{showOt(ot)}</h4>

        </>
      );
    } else if (page === 3) {
      return (
        <>
          <Label
            className="ml-2"
            style={{ fontSize: "25px" }}
          >รายการอุปกรณ์</Label>
          {/* <h4 className="ml-4">{resetProductBorrow()}</h4> */}
          <h4 className="ml-2">{showOverview()}</h4>
          <h4 className="ml-2">{showPartOt()}</h4>

          <Label
            className="ml-2"
            style={{ fontSize: "25px" }}
          >ค่าดำเนินงาน</Label>
          <br />
          <h4 className="ml-2">{showPrice()}</h4>
        </>
      );
    }
  }

  const showbutton = () => {
    if (page != 0 && page != 3) {
      return (
        <div className="text-right">
          <br />
          <Button
            outline
            color="secondary"
            onClick={() => {
              setPage(page - 1)
            }}
          >ย้อนกลับ</Button>
          {" "}
          <Button
            outline
            color="primary"
            onClick={() => {
              setPage(page + 1)
            }}
          >หน้าถัดไป</Button>

        </div>);
    } else if (page != 0 && page === 3) {
      return (
        <div className="text-right">
          <br />
          <Button
            outline
            color="secondary"
            onClick={() => {
              setPage(page - 1)
            }}
          >ย้อนกลับ</Button>
          {" "}
          <Button
            outline
            color="success"
            onClick={() => {
              sendProduct();
            }}
          >บันทึก</Button>

        </div>);
    } else {
      return (
        <div className="text-right">
          <Button
            outline
            color="primary"
            onClick={() => {
              setPage(page + 1)
            }}
          >หน้าถัดไป</Button>

        </div>);
    }

  }

  if (allowAccess) {
    return width < breakpoint ? (
      //mobile
      <>
        <EstimatePriceMobile />
      </>
    ) : (
        <>
          <EstimatePriceDesktop />
        </>
      )
  } else {
    return (
      <>
      </>
    )
  }
}
export default EstimatePrice;