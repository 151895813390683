import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../actions";
import * as role from "../../util/UserRole";
import { Container, Col, Table } from "reactstrap";
import { useHistory } from "react-router-dom";
import TablePagination from "../../components/TablePagination/TablePagination";
import DeliveryTables from "../tables/DeliveryTables";
import SearchDelivery from "../../components/Search/SearchDelivery";
import _ from 'lodash'

export default function DeliveryReport() {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const History = useSelector((state) => state.history);
  const [pageSize, setPageSize] = useState(20)
  const [currentPage, setCurrentPage] = useState(0)
  const [historyData, setHistoryData] = useState([]);

  const allowAccess = user
    ? user.role == role.ADMIN_ROLE || user.role == role.SUPER_USER_ROLE
      ? true
      : false
    : history.push("/login");


  useEffect(() => {
    dispatch(actions.getHistory());
    return () => { };
  }, []);

  useEffect(() => {
    if (History.length != 0) {
      const filterHistory = _.filter(History, (_history) => {
        return (
          _history.orders.quotationId != "-"
        )
      })
      setHistoryData(filterHistory)
    }

    return () => { };
  }, [History]);

  if (allowAccess) {
    return (
      <Container fluid className="light-th">
        <h2 className="text-center"> รายงานการส่งมอบงาน </h2>
        <Col className="text-left">
          <SearchDelivery History={History} setHistoryData={setHistoryData} />
        </Col>
        <hr />
        { historyData.length === 0 ? (
          <>
            <div className="container col-md-8 text-center">
              <br />
              <br />
              <br />
              <h2 className="text-danger">ไม่พบรายการส่งมอบงาน</h2>
            </div>
          </>
        ) : (
          <>
            <div
              style={{
                maxHeight: "100vh",
                overflowY: "auto",
                backgroundColor: "#ffffff",
                boxShadow: "1px 1px 3px #d9d9d9",
              }}>
              <Table striped bordered hover responsive>
                <thead>
                  <tr className="text-center">
                    <th>#</th>
                    <th>เลขที่ใบเสนอราคา</th>
                    <th>สถานะการซ่อม</th>
                    <th colSpan={8}>เอกสาร</th>


                  </tr>
                </thead>

                <tbody>
                  {Array.isArray(historyData) ? (
                    <>
                      {historyData
                        .slice(
                          currentPage * pageSize,
                          (currentPage + 1) * pageSize
                        )
                        .map((eachHistory, index) => {
                          return (
                            <DeliveryTables
                              eachHistory={eachHistory}
                              index={index + 1}
                            />
                          );
                        })}
                    </>
                  ) : (
                    <>
                      <h2>ไม่พบรายการส่งมอบงาน</h2>
                    </>
                  )}
                </tbody>
              </Table>
            </div>
            <div className="d-flex justify-content-center m-4 ">
              <TablePagination
                pagesCount={Math.ceil(historyData.length / pageSize)}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </div>
          </>)}
      </Container>
    );
  } else {
    return (
      <Container fluid className="light-th">
        <div className="container col-md-8 text-center">
          <h1 className="text-danger"> ขออภัย </h1>
          <h3>
            คุณไม่มีสิทธิในการเข้าถึง <br />
              กรุณาลงทะเบียนด้วย User ที่ได้รับสิทธิเป็นผู้ดูแลระบบ
          </h3>
        </div>
      </Container>
    )
  }

}
