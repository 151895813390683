import axios from "axios";
import {
  SYSTEM_EDIT,
  SYSTEM_FETCH,
  SYSTEM_CREATE
} from "./types";


export const systemsFetch = () => {
  return (dispatch) => {
    axios.get(process.env.REACT_APP_API_URL + "/system/all").then((res) => {
      dispatch({ type: SYSTEM_FETCH, payload: res.data });
    });
  };
};

export const systemFetch = (id) => {
  return (dispatch) => {
    axios.get(process.env.REACT_APP_API_URL + "/system/" + id).then((res) => {
      dispatch({ type: SYSTEM_FETCH, payload: res.data });
    })
  }
}

export const createSystem = (data) => {
  return (dispatch) => {
    axios
      .post(process.env.REACT_APP_API_URL + "/system", data)
      .then((res) => {
        dispatch({ type: SYSTEM_CREATE, payload: res.data });
      })
      .catch((err) => {
        console.log("Error Creating System");
        console.error(err);
      });
  };
};

export const editSystem = (data) => {
  // console.log(data);
  return (dispatch) => {
    axios
      .put(
        process.env.REACT_APP_API_URL + "/system/" + data._id,
        data
      )
      .then((res) => {
        axios.get(process.env.REACT_APP_API_URL + "/system/all").then((res) => {
          dispatch({ type: SYSTEM_EDIT, payload: res.data });
        });
      })
      .catch((err) => {
        alert("ไม่สามารถแก้ไขข้อมูลได้");
        console.log("Cannot Edit System");
        console.error(err);
      });
  };
};

