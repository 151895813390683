import React, { useState,useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileAlt } from '@fortawesome/free-solid-svg-icons'
import { useHistory, Link } from 'react-router-dom'
import { Badge, Button, Row } from 'reactstrap';
import * as UserStatus from "../../util/UserStatus";
import * as UserRole from "../../util/UserRole";

const OtTables =({ot, inventory , index})=> {
const history = useHistory();


return(
    <tr className="text-center">
        <td scope="row">{index}</td>
        <td className="text-left">{ot.ot_name}</td>
        <td>เหมา</td>
        <td>{ot.ot_inventory}</td>
        <td></td>
        <td></td>
        <td>{ot.ot_inventory}</td>
        <td>{ot.ot_price}</td>
        <td>{parseInt(ot.ot_price * ot.ot_inventory)}</td>
    </tr>
)
}
export default OtTables;