import React, { useState, useEffect } from "react";
import { Container, Table, Row, Col, Badge, Label, InputGroup, Card, Button, } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import _ from "lodash";
import MaterialTables from "../../../tables/MaterialTables";
import OtTables from "../../../tables/OtTables";
import * as actions from '../../../../actions';
import THBText from 'thai-baht-text';


// ใบเสนอราคาเบื้องต้น
const NoOverheadQuotation = ({History}) => {
  const orders = useSelector((state) => state.orders);
  const user = useSelector((state) => state.user);
  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams();
  const [width, setWidth] = React.useState(window.innerWidth);
  const breakpoint = 620;

  useEffect(() => {
    window.addEventListener('resize', () => setWidth(window.innerWidth));
    return () => { }
  }, [])

  const material = History.materialUse[0];
  const estimate = History.reestimate_price[0];
  const indexInventory = material.product.length;


  return (
    //desktop
    <>
     
          <div>
            <Table bordered hover responsive style={{ fontSize: "18px" }} size="sm">
              <thead className="light-th text-center" style={{ backgroundColor: "#EAFAF1" }} >
                <tr>
                  <th className="border-bottom-0" >Item</th>
                  <th className="border-bottom-0">Description</th>
                  <th className="border-bottom-0">Unit</th>
                  <th className="border-bottom-0">Quantity</th>
                  <th colSpan="2">Material</th>
                  <th colSpan="2">Labour</th>
                  <th className="border-bottom-0">Total</th>
                </tr>
              </thead>
              <thead className="light-th text-center" style={{ backgroundColor: "#EAFAF1" }} >
                <tr>
                  <th className="border-top-0"></th>
                  <th className="border-top-0"></th>
                  <th className="border-top-0"></th>
                  <th className="border-top-0"></th>
                  <th>U.cost</th>
                  <th>Cost</th>
                  <th>U.cost</th>
                  <th>Cost</th>
                  <th className="border-top-0"></th>
                </tr>
              </thead>
              <tbody>

                {material.product.map((_material, index) => {
                  return (
                    <>
                      <MaterialTables
                        material={_material}
                        inventory={material.inventory[material.product.indexOf(_material)]}
                        labour={estimate.cost[material.product.indexOf(_material)]}
                        index={index + 1}
                      />
                    </>
                  )
                })}

                {estimate.ot.map((_ot, index) => {

                  return (
                    <>
                      <OtTables
                        ot={_ot}
                        index={indexInventory + index + 1}
                      />
                    </>
                  )
                })}



              </tbody>
              <thead>
                <td className='light-th text-light'>.</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </thead>
              <thead className="text-center">
                <td></td>
                <th>รวม</th>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>{estimate.priceOfProduct}</td>
              </thead>
              <thead>
                <td></td>
                <td>Vat 7%</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className="text-center">{estimate.vat[0].toFixed(2)}</td>
              </thead>
              <thead style={{ backgroundColor: "#d9ffcc" }} className="text-center">
                <tr>
                  <th colSpan={8}>รวมทั้งสิ้น</th>
                  <th>{parseFloat(History.price).toFixed(2)}</th>
                </tr>
              </thead>
            </Table>
          </div>

          <Row>
            <Col>
              <strong>*** เสนอราคารวม VAT ***</strong>
            </Col>

            <Col className="text-right">
            <strong>{THBText(parseFloat(History.price).toFixed(2))}</strong>
            </Col>
          </Row>         
    </>
  )

}
export default NoOverheadQuotation;