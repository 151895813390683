import React, { useState, useEffect } from "react";
import { Container, Label, Col, Row, Input, Card, CardBody, Collapse, CardSubtitle, CardTitle, Button } from "reactstrap";
import * as UserStatus from "../../../util/UserStatus";
import { useHistory, useParams } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CreateIcon from '@material-ui/icons/Create';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import BuildIcon from '@material-ui/icons/Build';
import GavelIcon from '@material-ui/icons/Gavel';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
//แก้ไข ยกเลิกได้ 
const DetailHistoryMobile = ({ History }) => {
  const history = useHistory();
  const classes = makeStyles((theme) => ({
    paper: {
      padding: '6px 16px',
    },
    secondaryTail: {
      backgroundColor: theme.palette.secondary.main,
    },
  }));
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const colorSpeed = () => {
    if (History.speed === "ปกติ") {
      return <strong className="text-success">{History.speed}</strong>
    } else {
      return <strong className="text-danger">{History.speed}</strong>
    }
  }

  const showReason = () => {
    if (History.mechanic_reason != null) {
      return (
        <div>
          <h6 className="ml-2"> เนื่องจาก {History.mechanic_reason}</h6>
        </div>
      )
    } else if (History.causeCancle != null) {
      return (
        <div>
          <h6 className="ml-2"> เนื่องจาก {History.causeCancle}</h6>
        </div>
      )
    }
  }


  const showTimeline = () => {
    return (
      <>
        <h5 className="mb-4"><strong>ไทม์ไลน์ของงานซ่อม</strong></h5>

        <Timeline align="left" >
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot style={{ backgroundColor: "#e91e63" }}>
                <NotificationsActiveIcon />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={1} className={classes.paper} style={{ padding: "6px" }}>
                <Typography variant="body2" color="textSecondary">
                  {new Date(History.orderDate).toLocaleDateString("th", { day: '2-digit', month: '2-digit', year: "numeric" })}
                </Typography>
                <Typography>วันที่แจ้งซ่อม</Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>



          {(History.getJobDate === undefined) ? (
            <>
            </>
          ) : (
            <>
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineConnector />
                  <TimelineDot style={{ backgroundColor: "#ef6c00" }}>
                    <AssignmentIndIcon />
                  </TimelineDot>
                </TimelineSeparator>
                <TimelineContent>
                  <Paper elevation={1} className={classes.paper} style={{ padding: "6px" }}>
                    <Typography variant="body2" color="textSecondary">
                      {new Date(History.getJobDate).toLocaleDateString("th", { day: '2-digit', month: '2-digit', year: "numeric" })}
                    </Typography>
                    <Typography>วันที่รับงาน</Typography>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
            </>
          )}

          {(History.quotationDate === undefined) ? (
            <>
            </>
          ) : (
            <>
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineConnector />
                  <TimelineDot style={{ backgroundColor: "#01579b" }}>
                    <GavelIcon />
                  </TimelineDot>
                </TimelineSeparator>
                <TimelineContent>
                  <Paper elevation={1} className={classes.paper} style={{ padding: "6px" }}>
                    <Typography variant="body2" color="textSecondary">
                      {new Date(History.quotationDate).toLocaleDateString("th", { day: '2-digit', month: '2-digit', year: "numeric" })}
                    </Typography>
                    <Typography>วันที่ประเมินราคา</Typography>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
            </>
          )}

          {(History.cancleDate === undefined) ? (
            <>
            </>
          ) : (
            <>
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineConnector />
                  <TimelineDot style={{ backgroundColor: "#b71c1c" }}>
                    <CloseRoundedIcon />
                  </TimelineDot>
                </TimelineSeparator>
                <TimelineContent>
                  <Paper elevation={1} className={classes.paper} style={{ padding: "6px" }}>
                    <Typography variant="body2" color="textSecondary">
                      {new Date(History.cancleDate).toLocaleDateString("th", { day: '2-digit', month: '2-digit', year: "numeric" })}
                    </Typography>
                    <Typography>วันที่ยกเลิกงานซ่อม</Typography>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
            </>
          )}

          {(History.comfirmRepairDate === undefined) ? (
            <>
            </>
          ) : (
            <>
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineConnector />
                  <TimelineDot style={{ backgroundColor: "#00b8d4" }}>
                    <DoneAllIcon />
                  </TimelineDot>
                </TimelineSeparator>
                <TimelineContent>
                  <Paper elevation={1} className={classes.paper} style={{ padding: "6px" }}>
                    <Typography variant="body2" color="textSecondary">
                      {new Date(History.comfirmRepairDate).toLocaleDateString("th", { day: '2-digit', month: '2-digit', year: "numeric" })}
                    </Typography>
                    <Typography>วันที่ยืนยันการซ่อม</Typography>
                  </Paper>
                </TimelineContent>
              </TimelineItem>

            </>
          )}

          {(History.borrowDate === undefined) ? (
            <>
            </>
          ) : (
            <>
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineConnector />
                  <TimelineDot style={{ backgroundColor: "#8bc34a" }}>
                    <BuildIcon />
                  </TimelineDot>
                </TimelineSeparator>
                <TimelineContent>
                  <Paper elevation={1} className={classes.paper} style={{ padding: "6px" }}>
                    <Typography variant="body2" color="textSecondary">
                      {new Date(History.borrowDate).toLocaleDateString("th", { day: '2-digit', month: '2-digit', year: "numeric" })}
                    </Typography>
                    <Typography>วันที่เบิกอุปกรณ์</Typography>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
            </>
          )}

          {(History.repairRecordDate === undefined) ? (
            <>
            </>
          ) : (
            <>
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineConnector />
                  <TimelineDot style={{ backgroundColor: "#01579b" }}>
                    <CreateIcon />
                  </TimelineDot>
                </TimelineSeparator>
                <TimelineContent>
                  <Paper elevation={1} className={classes.paper} style={{ padding: "6px" }}>
                    <Typography variant="body2" color="textSecondary">
                      {new Date(History.repairRecordDate).toLocaleDateString("th", { day: '2-digit', month: '2-digit', year: "numeric" })}
                    </Typography>
                    <Typography>วันที่บันทึกการซ่อม</Typography>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
            </>
          )}

          {(History.deliveryWorkDate === undefined) ? (
            <>
            </>
          ) : (
            <>
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineConnector />
                  <TimelineDot style={{ backgroundColor: "#3e2723" }}>
                    <AssignmentIcon />
                  </TimelineDot>
                </TimelineSeparator>
                <TimelineContent>
                  <Paper elevation={1} className={classes.paper} style={{ padding: "6px" }}>
                    <Typography variant="body2" color="textSecondary">
                      {new Date(History.deliveryWorkDate).toLocaleDateString("th", { day: '2-digit', month: '2-digit', year: "numeric" })}
                    </Typography>
                    <Typography>วันที่ส่งมอบงาน</Typography>
                  </Paper>
                </TimelineContent>
              </TimelineItem>

            </>
          )}

          {(History.editDeliveryWorkDate === undefined) ? (
            <>
            </>
          ) : (
            <>
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineConnector />
                  <TimelineDot style={{ backgroundColor: "#ffc107" }}>
                    <AssignmentLateIcon />
                  </TimelineDot>
                </TimelineSeparator>
                <TimelineContent>
                  <Paper elevation={1} className={classes.paper} style={{ padding: "6px" }}>
                    <Typography variant="body2" color="textSecondary">
                      {new Date(History.editDeliveryWorkDate).toLocaleDateString("th", { day: '2-digit', month: '2-digit', year: "numeric" })}
                    </Typography>
                    <Typography>วันที่แก้ไขการส่งมอบงาน</Typography>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
            </>
          )}

          {(History.comfirmDeliverWork === undefined) ? (
            <>
            </>
          ) : (
            <>
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineConnector />
                  <TimelineDot style={{ backgroundColor: "#311b92" }}>
                    <AssignmentTurnedInIcon />
                  </TimelineDot>
                </TimelineSeparator>
                <TimelineContent>
                  <Paper elevation={1} className={classes.paper} style={{ padding: "6px" }}>
                    <Typography variant="body2" color="textSecondary">
                      {new Date(History.comfirmDeliverWork).toLocaleDateString("th", { day: '2-digit', month: '2-digit', year: "numeric" })}
                    </Typography>
                    <Typography>วันที่ยืนยันการส่งมอบงาน</Typography>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
            </>
          )}

          {(History.returnDate === undefined) ? (
            <>
            </>
          ) : (
            <>
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineConnector />
                  <TimelineDot style={{ backgroundColor: "green" }}>
                    <CheckIcon />
                  </TimelineDot>
                </TimelineSeparator>
                <TimelineContent>
                  <Paper elevation={1} className={classes.paper} style={{ padding: "6px" }}>
                    <Typography variant="body2" color="textSecondary">
                      {new Date(History.returnDate).toLocaleDateString("th", { day: '2-digit', month: '2-digit', year: "numeric" })}
                    </Typography>
                    <Typography>วันที่คืนอุปกรณ์</Typography>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
            </>
          )}

        </Timeline>
      </>
    )
  }
  const showDocument = () => {
    if (History.statusUser === UserStatus.USER_FINISHED) {
      return (
        <>
          <h5><strong>รายงานการซ่อม</strong></h5>
          <Button
            color="link"
            onClick={() => {
              history.push({
                pathname: '/quotationdocument/' + History._id,
                state: { History: History }
              }
              )
            }}
          >ดูใบเสนอราคา</Button>
          <Button
            color="link"
            onClick={() => {
              history.push({
                pathname: '/deliverydocument/' + History._id,
                state: { History: History }
              }
              )
            }}
          >ดูใบส่งมอบงาน</Button>
          <Button
            color="link"
            onClick={() => {
              history.push({
                pathname: '/imagedocument/' + History._id,
                state: { History: History }
              }
              )
            }}
          >ดูรูปภาพก่อนและหลังซ่อม</Button>
        </>
      )
    } else if (History.quotationId != "-") {
      return (
        <>
          <h5><strong>รายงานการซ่อม</strong></h5>
          <Button
            color="link"
            onClick={() => {
              history.push({
                pathname: '/quotationdocument/' + History._id,
                state: { History: History }
              }
              )
            }}
          >ดูใบเสนอราคา</Button>
        </>
      )
    }
  }

  return (
    <Container>
      <div style={{
        backgroundColor: '#ffffff',
        boxShadow: '1px 1px 3px #d9d9d9',
        padding: '30px',
      }}
        className="mb-4">

        <Row className='ml-1 pt-2 pr-2'>
          <Col sm='8'>
            <Row>
              <Col >
                <Row>
                  <Card style={{ border: "none", boxShadow: "none" }} >
                    <CardTitle >
                      <h6><strong>ปัญหา/งานซ่อม</strong> <strong className="text-danger">{History.problem}</strong></h6>
                    </CardTitle>
                  </Card>
                </Row>

                <Row>
                  <Card style={{ border: "none", boxShadow: "none" }} >
                    <CardSubtitle>
                      <h6 className="mt-1 mr-1" ><strong>รายละเอียด</strong> {History.detail}</h6>
                    </CardSubtitle>
                  </Card>
                </Row>
                <br />
                <Row className="mt-1 mr-1">
                  <Card style={{ border: "none", boxShadow: "none" }}>
                    <CardSubtitle>
                      <h6><strong>สถานที่</strong> {History.place}</h6>
                    </CardSubtitle>
                  </Card>
                </Row>
              </Col>
            </Row>
          </Col>

          <Col sm='4' className="mb-2">
            <Row>

              <h6><strong>สถานะการซ่อม</strong><h5 className='pt-3 pb-2 text-info'><strong>{History.statusUser}</strong></h5></h6>

              {showReason()}
            </Row>
            <div className="text-right">
            </div>
          </Col>
        </Row>

        <Row className="mt-2 ">
          <Col><h6><strong>ประเภทงานซ่อม</strong> <strong className="text-primary">{History.type}</strong></h6></Col>
        </Row>

        <Row className="mt-2 ">
          <Col ><h6><strong>ความเร่งด่วน</strong> {colorSpeed()}</h6></Col>
        </Row>

        <Row>
          <Col className="text-left mt-2" sm="8">
            <p><strong>ชื่อผู้แจ้ง</strong> {History.user_name} </p>
            <p><strong>เบอร์โทรศัพท์</strong> {History.user_tel} </p>
            <p><strong>วันที่แจ้งซ่อม</strong> {new Date(History.orderDate).toLocaleDateString("th")}</p>
          </Col>
          <Col className="text-right">
            <Button color="primary" onClick={toggle}>ดูรายละเอียด</Button>
          </Col>
        </Row>
        <br />
        <Collapse isOpen={isOpen}>
          <Card>
            <CardBody>
              {showTimeline()}
              {showDocument()}

            </CardBody>
          </Card>
        </Collapse>


      </div>

    </Container>
  )
}
export default DetailHistoryMobile;