import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as actions from '../../actions/'
import { Table, Container, Row, Col, Button } from 'reactstrap'
import Categories from '../lists/CategoriesLists'
import * as role from '../../util/UserRole'
import { useHistory } from 'react-router-dom'
import StockTables from '../tables/StockTables'
import ModalAddStocks from '../../components/modal/ModalAddStocks'
import ModalEditStock from '../../components/modal/ModalEditStock'
import TablePagination from "../../components/TablePagination/TablePagination";

export default function ManagementStock() {
  const dispatch = useDispatch()
  const history = useHistory()
  const products = useSelector((state) => state.products)
  const categories = useSelector((state) => state.categories)
  const user = useSelector((state) => state.user)
  const [modalAddStocks, setModalAddStocks] = useState(false) // props สำหรับเปิดปิด modal
  const [modalEditStocks, setModalEditStocks] = useState(false)
  const [product, setProduct] = useState(products)
  const [pageSize, setPageSize] = useState(20)
  const [currentPage, setCurrentPage] = useState(0)

  useEffect(() => {
    dispatch(actions.productsFetch())
    setProduct(products)
    return () => { }
  }, [])

  useEffect(() => {
    setProduct(products)
    return () => { }
  }, [products])

  // method สำหรับ set เปิดหรือปิด modal
  const toggleModalAddStocks = () => setModalAddStocks(!modalAddStocks)
  const toggleModalEditStock = () => setModalEditStocks(!modalEditStocks)

  // อนุญาติให้เข้าถึงได้เฉพาะ ผู้จัดการร้าน และ SUPERUSER
  const allowAccess = user
    ? user.role == role.SUPER_USER_ROLE || user.role == role.ADMIN_ROLE || user.role == role.STORE_ROLE
      ? true
      : false
    : history.push('/')

    if (allowAccess) {
    return (
      <Container fluid className="light-th">
        <div className="container col-md-8 text-center">
          <h2>จัดการสต๊อกวัสดุและอุปกรณ์</h2>
        </div>

        <Row>
          <Col className="text-left d-flex">
            <Categories
              categories={categories}
              products={products}
              setProduct={setProduct}
            />
          </Col>
          <Col className="text-right">
            <Button
              color="success"
              onClick={() => {
                toggleModalAddStocks() // เปิด modal สำหรับเพิ่มสต๊อก
              }}
            >
              เพิ่มสต๊อก
            </Button>{' '}
            <Button
              color="warning"
              onClick={() => {
                toggleModalEditStock()
              }}
            >
              แก้ไขสต๊อก
            </Button>
          </Col>
        </Row>
        <hr />
        <ModalAddStocks
          modal={modalAddStocks}
          toggle={toggleModalAddStocks}
        />
        <ModalEditStock
          modal={modalEditStocks}
          toggle={toggleModalEditStock}
        />
       
        { product.length === 0 ? (
          <>
            <div className="container col-md-8 text-center">
              <br />
              <br />
              <br />
              <h2 className="text-danger">ไม่พบรายการสต๊อกอุปกรณ์</h2>
            </div>
          </>
        ) : (
            <>

              <div
                style={{
                  maxHeight: "100vh",
                  overflowY: "auto",
                  backgroundColor: "#ffffff",
                  boxShadow: "1px 1px 3px #d9d9d9",
                }}>
                <Table striped bordered hover responsive>
                  <thead>
                    <tr className="text-center">
                      <th className="text-center">#</th>
                      <th>ชื่ออุปกรณ์</th>
                      <th className="text-center">ยอดคงเหลือ</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(product) ? (
                      <>
                        {product
                          .slice(
                            currentPage * pageSize,
                            (currentPage + 1) * pageSize
                          )
                          .map((eachProduct, index) => {
                            return (
                              <StockTables
                                key={eachProduct._id}
                                product={eachProduct}
                                index={index + 1}
                              />
                            );
                          })}
                      </>
                    ) : (
                        <>
                          <h2>ไม่พบรายการเบิกจ่ายอุปกรณ์</h2>
                        </>
                      )}
                  </tbody>
                </Table>
              </div>
              <div className="d-flex justify-content-center m-4 ">
                <TablePagination
                  pagesCount={Math.ceil(product.length / pageSize)}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            </>
          )}

      </Container>
    )
  } else {
    return (
      <Container className="light-th">
        <div className="container col-md-8 text-center">
          <h2>การจัดการสต๊อกอุปกรณ์</h2>
          <br />
          <h2 className="text-danger ">ขออภัย</h2>

          <h3>
            คุณไม่มีสิทธิในการเข้าถึงการจัดการสต็อกอุปกรณ์ <br />
            กรุณาลงชื่อเข้าใช้ด้วยบัญชีที่มีสิทธิ
            <br />
            เป็นผู้จัดการ หรือผู้ดูแลระบบ
          </h3>
        </div>
      </Container>
    )
  }
}
