import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../actions";
import * as role from "../../util/UserRole";
import { Container, Col } from "reactstrap";
import { useHistory } from "react-router-dom"

export default function ProductReport() {
  const dispatch = useDispatch();
  const history = useHistory();
  const changeStatus = null;
  const user = useSelector((state) => state.user);
  const allUser = useSelector((state) => state.userManagement);
  const [userData, setUserData] = useState([]);

  const allowAccess = user
    ? user.role == role.SUPER_USER_ROLE
        ? true
        : false
    : history.push("/login");


  useEffect(() => {
    dispatch(actions.fetchAllUser());
    return () => {};
  }, []);

  useEffect(() => {
    setUserData(allUser)
    return () => {};
  }, [allUser]);


  if (allowAccess) {
    return (
      <Container fluid className="light-th">
        <h2 className="text-center"> รายงานเบิกจ่ายอุปกรณ์ </h2>
        <Col className="text-left">
      </Col>
        <br/>
       
      </Container>
    );
  } else {
    return (
      <Container fluid className="light-th">
          <div className="container col-md-8 text-center">
          <h1 className="text-danger"> ขออภัย </h1>
          <h3>
              คุณไม่มีสิทธิในการเข้าถึง <br />
              กรุณาลงทะเบียนด้วย User ที่ได้รับสิทธิเป็นผู้ดูแลระบบ
          </h3>
          </div>
      </Container>
  )
  }
    
}
