import axios from 'axios'
import { PRODUCTLOGS_FETCH, PRODUCTLOG_FETCH, PRODUCTLOG_BORROW, PRODUCTLOG_RETURN } from './types'
import _ from "lodash";

export const productLogsFetch = () => {
  return (dispatch) => {
    axios.get(process.env.REACT_APP_API_URL + '/productLog/all').then((res) => {
      dispatch({ type: PRODUCTLOGS_FETCH, payload: res.data })
    })
  }
}

export const productLogFetch = (id) => {
  return (dispatch) => {
    axios.get(process.env.REACT_APP_API_URL + "/productLog/" + id).then((res) => {
      dispatch({ type: PRODUCTLOG_FETCH, payload: res.data });
    })
  }
}

export const productLogBorrow = (data, productBorrow) => {

  return (dispatch) => {
    const product = [];
    for (let count = 0; count < productBorrow.product.length; count++) {

      if (productBorrow.product[count] != null) {
        axios
          .get(process.env.REACT_APP_API_URL + "/products/" + productBorrow.product[count]._id)
          .then((res) => {

            if (res.data.product_inventory === "0") {
              const inventory = {
                _id: productBorrow.product[count]._id,
                product_name: productBorrow.product[count].product_name,
                prev_inventory: parseInt(res.data.product_inventory),
                next_inventory: parseInt(res.data.product_inventory),
                inventory: productBorrow.inventory[count],
                product_store: productBorrow.product[count].product_store,
                product_from: "ร้านค้า",
                product_unit: res.data.product_unit,

              }
              product[count] = inventory;
              // data.product = product
            } else {
              const inventory = {
                _id: productBorrow.product[count]._id,
                product_name: productBorrow.product[count].product_name,
                prev_inventory: parseInt(res.data.product_inventory),
                next_inventory: parseInt(res.data.product_inventory) - productBorrow.inventory[count],
                inventory: productBorrow.inventory[count],
                product_store: productBorrow.product[count].product_store,
                product_from: "คลัง",
                product_unit: res.data.product_unit,

              }
              product[count] = inventory;
              // data.product = product
            }
            // data.product = product

            data.product = _.filter(product, (_product) => {
              return (
                _product != [] && _product != null
              )
            })

            // if (count === productBorrow.product.length - 1) {
              if (data.product.length === productBorrow.product.length) {
                
                axios
                  .post(process.env.REACT_APP_API_URL + "/productLog", data)
                  .then((res) => {
                    dispatch({ type: PRODUCTLOG_BORROW, payload: res.data });
                  })
                  .catch((err) => {
                    console.log("Error Creating ProductLogs");
                    console.error(err);
                  });

              }
            });
      }
    }
  };

}

export const productAddStoreBorrow = (product, dataLogs, addFormStore, addFormStock, productOfReturn) => {

  if (addFormStore != null && addFormStore != [] && addFormStore.product.length != null) {
    if (addFormStore.product.length === 0) {
      productAddStockBorrow(product, dataLogs, addFormStore, addFormStock, productOfReturn);
    }
    else {
      for (let count = 0; count < addFormStore.product.length; count++) {
        axios
          .get(process.env.REACT_APP_API_URL + "/products/" + addFormStore.product[count]._id)
          .then((res) => {

            const inventory = {
              _id: addFormStore.product[count]._id,
              product_name: addFormStore.product[count].product_name,
              prev_inventory: parseInt(res.data.product_inventory),
              next_inventory: parseInt(res.data.product_inventory),
              inventory: addFormStore.inventory[count],
              product_store: addFormStore.product[count].product_store,
              product_from: "ร้านค้า",
              product_unit: res.data.product_unit,

            }
            product[count] = inventory;
            if (count === addFormStore.product.length - 1) {
              productAddStockBorrow(product, dataLogs, addFormStore, addFormStock, productOfReturn);
            }

          });
      }
    }

  }
}

export const productAddStockBorrow = (product, dataLogs, addFormStore, addFormStock, productOfReturn) => {
  if (addFormStock != null && addFormStock != [] && addFormStock.product.length != null) {
    if (addFormStock.product.length === 0) {
      logReturn(product, dataLogs, productOfReturn);
    }
    for (let count = 0; count < addFormStock.product.length; count++) {

      axios
        .get(process.env.REACT_APP_API_URL + "/products/" + addFormStock.product[count]._id)
        .then((res) => {

          const inventory = {
            _id: addFormStock.product[count]._id,
            product_name: addFormStock.product[count].product_name,
            prev_inventory: parseInt(res.data.product_inventory),
            next_inventory: parseInt(res.data.product_inventory) - addFormStock.inventory[count],
            inventory: addFormStock.inventory[count],
            product_store: addFormStock.product[count].product_store,
            product_from: "คลัง",
            product_unit: res.data.product_unit,

          }
          product[count + addFormStore.product.length] = inventory;
          if (count === addFormStock.product.length - 1) {
            logReturn(product, dataLogs, productOfReturn);
          }

        });
    }

  }
}

export const logReturn = (product, dataLogs, productOfReturn) => {
  const productReturn = [];
  if (productOfReturn != null && productOfReturn != [] && productOfReturn.product.length != null) {

    for (let count = 0; count < productOfReturn.product.length; count++) {

      axios
        .get(process.env.REACT_APP_API_URL + "/products/" + productOfReturn.product[count]._id)
        .then((res) => {

          const filterProduct = _.find(product, (_product) => {
            return (
              _product._id == productOfReturn.product[count]._id
            )
          })

          if (filterProduct != undefined) {
            const inventory = {
              _id: productOfReturn.product[count]._id,
              product_name: productOfReturn.product[count].product_name,
              prev_inventory: parseInt(res.data.product_inventory) - filterProduct.inventory,
              next_inventory: parseInt(res.data.product_inventory) - filterProduct.inventory + productOfReturn.inventory[count],
              inventory: productOfReturn.inventory[count],
              product_store: productOfReturn.product[count].product_store,
              note: dataLogs.note[count],
              product_unit: res.data.product_unit,

            }
            productReturn[count] = inventory;
            // dataLogs.product = productReturn;
            dataLogs.product = _.filter(productReturn, (_product) => {
              return (
                _product != [] && _product != null
              )
            })

          } else {
            const inventory = {
              _id: productOfReturn.product[count]._id,
              product_name: productOfReturn.product[count].product_name,
              prev_inventory: parseInt(res.data.product_inventory),
              next_inventory: parseInt(res.data.product_inventory) + productOfReturn.inventory[count],
              inventory: productOfReturn.inventory[count],
              product_store: productOfReturn.product[count].product_store,
              note: dataLogs.note[count],
              product_unit: res.data.product_unit,

            }
            productReturn[count] = inventory;
            // dataLogs.product = productReturn;

            dataLogs.product = _.filter(productReturn, (_product) => {
              return (
                _product != [] && _product != null
              )
            })

          }

          if (count === productOfReturn.product.length - 1) {
            if (product.length === 0) {
              axios
                .post(process.env.REACT_APP_API_URL + "/productLog", dataLogs)
                .then((res) => {
                  // dispatch({ type: PRODUCTLOG_RETURN, payload: res.data });
                })
                .catch((err) => {
                  console.log("Error Creating ProductLogs");
                  console.error(err);
                });
            } else {
              let data = {
                type: "เบิก",
                product: product,
                transactionDate: dataLogs.transactionDate,
                referenceOrder: dataLogs.referenceOrder,
                handler: dataLogs.handler,
                quotationId: dataLogs.quotationId,

              }
              axios
                .post(process.env.REACT_APP_API_URL + "/productLog", dataLogs)
                .then((res) => {

                  axios
                    .post(process.env.REACT_APP_API_URL + "/productLog", data)
                    .then((res) => {
                      // dispatch({ type: PRODUCTLOG_RETURN, payload: res.data });
                    })
                    .catch((err) => {
                      console.log("Error Creating ProductLogs");
                      console.error(err);
                    });

                })
                .catch((err) => {
                  console.log("Error Creating ProductLogs");
                  console.error(err);
                });

            }
          }

        });
    }

  }

  return (dispatch) => {
    axios.get(process.env.REACT_APP_API_URL + '/productLog/all').then((res) => {
      dispatch({ type: PRODUCTLOGS_FETCH, payload: res.data })
    })
  }
}

export const productLogReturn = (dataLogs, data) => {
  const product = [];
  productAddStoreBorrow(product, dataLogs, data.productAddFormStore, data.productAddFormStock, data.productReturn);

  return (dispatch) => {
    axios.get(process.env.REACT_APP_API_URL + '/order/all').then((res) => {
      dispatch({ type: PRODUCTLOG_RETURN, payload: res.data })
    })
  }
}

export const editStockProduct = (data) => {
  return (dispatch) => {
    axios
      .post(process.env.REACT_APP_API_URL + "/productLog", data)
      .then((res) => {
        dispatch({ type: PRODUCTLOG_FETCH, payload: res.data });
      })
      .catch((err) => {
        console.log("Error Creating ProductLogs");
        console.error(err);
      });
  }
}