import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../actions";
import * as role from "../../util/UserRole";
import { Container, Col, Row, Button, } from "reactstrap";
import { useHistory, useParams } from "react-router-dom";
import DetailHistoryDesktop from "./titleDetail/DetailHistoryDesktop";
import DetailHistoryMobile from "./titleDetail/DetailHistoryMobile";

import _ from "lodash";

export default function UserHistory() {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const [userData, setUserData] = useState([]);
  const [width, setWidth] = React.useState(window.innerWidth);
  const breakpoint = 620;
  const histories = useSelector((state) => state.history)
  const [historyList, setHistoryList] = useState([])
  const params = useParams();

  const allowAccess = user
    ? user.role == role.USER_ROLE || user.role == role.MECHANIC_ROLE || user.role == role.ADMIN_ROLE || user.role == role.SUPER_USER_ROLE
      ? true
      : false
    : history.push("/login");


  useEffect(() => {
    if (user != null) {
      dispatch(actions.getHistoryUser(params));
      setHistoryList(histories)
      window.addEventListener('resize', () => setWidth(window.innerWidth));
    }
    return () => { }
  }, [])

  useEffect(() => {
    setHistoryList(histories);
    window.addEventListener('resize', () => setWidth(window.innerWidth));
    return () => { }
  }, [histories])

  const showButton = () => {
    if (user.role === role.USER_ROLE) {
      return (
        <>
          <h2 className="text-center">ประวัติการซ่อม</h2>
          <br />
        </>
      )
    } else {
      return (
        <>
          <Row className="ml-1">
            <Col className="text-left">
              <Button
                outline
                color="secondary"
                onClick={
                  history.goBack
                }
              >ย้อนกลับ</Button>
            </Col>
            <Col md="7">
              <h2 className="text-left">ประวัติการซ่อม</h2>
            </Col>
          </Row>
          <br />
        </>
      )
    }
  }

  const showButtonOnMoblie = () => {
    if (user.role === role.USER_ROLE) {
      return (
        <>
          <h5 className="text-center">ประวัติการซ่อม</h5>
          <br />
        </>
      )
    } else {
      return (
        <>
          <Row className="ml-1">
            <Col >
              <Button
                outline
                color="secondary"
                onClick={
                  history.goBack
                }
              >ย้อนกลับ</Button>
            </Col>
            <Col xs="8">
              <h5 className="text-left">ประวัติการซ่อม</h5>
            </Col>
          </Row>
          <br />
        </>
      )
    }
  }

  if (allowAccess) {
    return width < breakpoint ? (
      //mobile
      <Container className="light-th">
        {showButtonOnMoblie()}
        { histories.length === 0 ? (
          <>
            <div className="container col-md-8 text-center">
              <br />
              <br />
              <br />
              <h5 className="text-danger">ไม่พบประวัติการแจ้งซ่อม</h5>
            </div>
          </>
        ) : (
          <>
            {
              histories.map((history) => {
                return (
                  <>
                    <DetailHistoryMobile
                      History={history.orders}

                    />
                  </>
                )

              })
            }
          </>
        )}

      </Container>
    ) : (
      //desktop
      <Container className="light-th">
        {showButton()}
        { histories.length === 0 ? (
          <>
            <div className="container col-md-8 text-center">
              <br />
              <br />
              <br />
              <h2 className="text-danger">ไม่พบประวัติการแจ้งซ่อม</h2>
            </div>
          </>
        ) : (
          <>
            {
              histories.map((history) => {
                return (
                  <>
                    <DetailHistoryDesktop
                      History={history.orders}

                    />
                  </>
                )

              })
            }
          </>
        )}
      </Container>
    )
  } else {
    return (
      <Container fluid className="light-th">
        <div className="container col-md-8 text-center">
          <h1 className="text-danger"> ขออภัย </h1>
          <h3>
            คุณไม่มีสิทธิในการเข้าถึง <br />
              กรุณาลงทะเบียนด้วย User ที่ได้รับสิทธิเป็นผู้ดูแลระบบ
          </h3>
        </div>
      </Container>
    )
  }

}
