import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../actions";
import * as role from "../../util/UserRole";
import { Container, Table, Col, Label, Input, Row, InputGroup, Button } from "reactstrap";
import { useHistory } from "react-router-dom"
import { useForm } from 'react-hook-form'
import { TextInput, Select } from 'evergreen-ui'
import { authen } from "../../assets/api/firebase";
import hash from "object-hash";

export default function ManagementSystem() {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const system = useSelector((state) => state.system);

  const { register, handleSubmit } = useForm()
  const [systemData, setSystemData] = useState([])
  const [prefix, setPrefix] = useState([]);
  const [number, setNumber] = useState([]);
  // const [year, setYear] = useState([]);
  const [year, setYear] = useState(new Date().getFullYear() + 543);


  const allowAccess = user
    ? user.role == role.SUPER_USER_ROLE
      ? true
      : false
    : history.push("/login");

  useEffect(() => {
    dispatch(actions.systemsFetch());
    return () => { };
  }, []);

  useEffect(() => {
    setSystemData(system[0])
    if (systemData != undefined) {
      setPrefix(systemData.prefix)
      setNumber(systemData.number)
      // setYear(systemData.year)
    }
    return () => { };
  }, [system]);

  const onchangeData = (data) => {

    if (data.system_image[0] === undefined && data.company_logo[0] === undefined) { //ไม่เปลี่ยนรูป
      data.system_image = systemData.system_image;
      data.company_logo = systemData.company_logo;
      if (system.length === 0) {
        data.prefix = prefix;
        data.number = number;
        data.year = year;
        dispatch(actions.createSystem(data))
        alert("บันทึกสำเร็จ")
      } else {
        data._id = systemData._id;
        data.prefix = prefix;
        data.number = number;
        data.year = year;
        dispatch(actions.editSystem(data))
        alert("บันทึกสำเร็จ")
      }

    } else {

      if (data.system_image[0] != undefined) { //เปลี่ยนรูประบบ
        const name = data.system_image.name;
        const date = new Date();
        const hashID = hash({
          name: name,
          date: date,
        });

        const uploadTask = authen
          .storage()
          .ref(`EandE/${hashID}`)
          .put(data.system_image[0]);

        uploadTask.on(
          "state_changed",
          (snapshot) => { },
          (error) => {
            console.log(error);
          },
          () => {
            authen
              .storage()
              .ref("EandE")
              .child(hashID)
              .getDownloadURL()
              .then((url) => {

                data.system_image = url

                if (data.company_logo[0] != undefined) { //เปลี่ยนรูปโลโก้

                  const name = data.company_logo.name;
                  const date = new Date();
                  const hashID = hash({
                    name: name,
                    date: date,
                  });

                  const uploadTask = authen
                    .storage()
                    .ref(`EandE/${hashID}`)
                    .put(data.company_logo[0]);

                  uploadTask.on(
                    "state_changed",
                    (snapshot) => { },
                    (error) => {
                      console.log(error);
                    },
                    () => {
                      authen
                        .storage()
                        .ref("EandE")
                        .child(hashID)
                        .getDownloadURL()
                        .then((url) => {

                          data.company_logo = url

                          if (system.length === 0) {
                            data.prefix = prefix;
                            data.number = number;
                            data.year = year;
                            dispatch(actions.createSystem(data))
                            alert("บันทึกสำเร็จ")
                          } else {
                            data._id = systemData._id;
                            data.prefix = prefix;
                            data.number = number;
                            data.year = year;
                            dispatch(actions.editSystem(data))
                            alert("บันทึกสำเร็จ")
                          }

                        })

                    });
                } else {
                  data.company_logo = systemData.company_logo; //ไม่เปลี่ยนรูปโลโก้
                  if (system.length === 0) {
                    data.prefix = prefix;
                    data.number = number;
                    data.year = year;
                    dispatch(actions.createSystem(data))
                    alert("บันทึกสำเร็จ")
                  } else {
                    data._id = systemData._id;
                    data.prefix = prefix;
                    data.number = number;
                    data.year = year;
                    dispatch(actions.editSystem(data))
                    alert("บันทึกสำเร็จ")
                  }
                }



              })

          });
      } else if (data.system_image[0] === undefined) { //ไม่เปลี่ยนรูประบบ
        data.system_image = systemData.system_image;

        if (data.company_logo[0] != undefined) { //เปลี่ยนรูปโลโก้

          const name = data.company_logo.name;
          const date = new Date();
          const hashID = hash({
            name: name,
            date: date,
          });

          const uploadTask = authen
            .storage()
            .ref(`EandE/${hashID}`)
            .put(data.company_logo[0]);

          uploadTask.on(
            "state_changed",
            (snapshot) => { },
            (error) => {
              console.log(error);
            },
            () => {
              authen
                .storage()
                .ref("EandE")
                .child(hashID)
                .getDownloadURL()
                .then((url) => {

                  data.company_logo = url

                  if (system.length === 0) {
                    data.prefix = prefix;
                    data.number = number;
                    data.year = year;
                    dispatch(actions.createSystem(data))
                    alert("บันทึกสำเร็จ")
                  } else {
                    data._id = systemData._id;
                    data.prefix = prefix;
                    data.number = number;
                    data.year = year;
                    dispatch(actions.editSystem(data))
                    alert("บันทึกสำเร็จ")
                  }

                })

            });
        } else {
          data.company_logo = systemData.company_logo; //ไม่เปลี่ยนรูปโลโก้
          if (system.length === 0) {
            data.prefix = prefix;
            data.number = number;
            data.year = year;
            dispatch(actions.createSystem(data))
            alert("บันทึกสำเร็จ")
          } else {
            data._id = systemData._id;
            data.prefix = prefix;
            data.number = number;
            data.year = year;
            dispatch(actions.editSystem(data))
            alert("บันทึกสำเร็จ")
          }
        }


      }


    }


  }

  if (allowAccess) {
    return (
      <Container fluid className="light-th"
        style={{
          marginTop: "50px",
          maxWidth: "900px",
        }}>
        <h2 className="text-center"> ตั้งค่า </h2>
        <br />
        <div
          style={{
            backgroundColor: "#ffffff",
            boxShadow: "1px 1px 3px #d9d9d9",
            padding: "30px",
          }}>
          <from onSubmit={handleSubmit(onchangeData)}>
            <h4 className='light-th'>ตั้งค่าระบบ</h4>
            <br />
            <Label>ชื่อระบบ</Label>
            <Input
              name='system_name'
              type='text'
              placeholder="ชื่อระบบ"
              defaultValue={systemData.system_name}
              innerRef={register}
            />
            <br />
            <Label>โลโก้ของระบบ</Label>
            <Input
              name='system_image'
              type='file'
              placeholder="ชื่อระบบ"
              innerRef={register}
            />
            <div className="mt-2 text-secondary">
              <Label>ถ้าต้องการใช้รูปภาพเดิม ไม่ต้องอัพโหลดรูปภาพใหม่</Label>
            </div>
            <br />
            <h4 className='light-th'>ตั้งค่าหัวกระดาษ</h4>
            <br />
            <Row>
              <Col md="3">
                <Label >ชื่อบริษัท (ภาษาไทย)</Label>
              </Col>
              <Col>
                <Input
                  name='company_name_thai'
                  type='text'
                  placeholder="ชื่อบริษัท (ภาษาไทย)"
                  defaultValue={systemData.company_name_thai}
                  innerRef={register}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col md="3">
                <Label >ชื่อบริษัท (ภาษาอังกฤษ)</Label>
              </Col>
              <Col>
                <Input
                  name='company_name_eng'
                  type='text'
                  placeholder="ชื่อบริษัท (ภาษาอังกฤษ)"
                  defaultValue={systemData.company_name_eng}
                  innerRef={register}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col md="3">
                <Label >ที่อยู่บริษัท (ภาษาไทย)</Label>
              </Col>
              <Col>
                <Input
                  name='company_address_thai'
                  type='textarea'
                  placeholder="ที่อยู่บริษัท (ภาษาไทย)"
                  defaultValue={systemData.company_address_thai}
                  innerRef={register}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col md="3">
                <Label >ที่อยู่บริษัท (ภาษาอังกฤษ)</Label>
              </Col>
              <Col>
                <Input
                  name='company_address_eng'
                  type='textarea'
                  placeholder="ที่อยู่บริษัท (ภาษาอังกฤษ)"
                  defaultValue={systemData.company_address_eng}
                  innerRef={register}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col md="3">
                <Label >อีเมล</Label>
              </Col>
              <Col>
                <Input
                  name='company_email'
                  type='text'
                  placeholder="อีเมล"
                  defaultValue={systemData.company_email}
                  innerRef={register}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col md="3">
                <Label >เบอร์โทร</Label>
              </Col>
              <Col>
                <Input
                  name='company_tel'
                  type='text'
                  placeholder="เบอร์โทร"
                  defaultValue={systemData.company_tel}
                  innerRef={register}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col md="3">
                <Label >แฟกซ์</Label>
              </Col>
              <Col>
                <Input
                  name='company_fax'
                  type='text'
                  placeholder="แฟกซ์"
                  defaultValue={systemData.company_fax}
                  innerRef={register}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col md="3"> 
                <Label >หมายเลขผู้เสียภาษี</Label>
              </Col>
              <Col>
                <Input
                  name='company_taxpayer_number'
                  type='text'
                  placeholder="หมายเลขผู้เสียภาษี "
                  defaultValue={systemData.company_taxpayer_number}
                  innerRef={register}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col md="3">
                <Label >โลโก้บริษัท</Label>
              </Col>
              <Col>
                <Input
                  name='company_logo'
                  type='file'
                  placeholder="โลโก้บริษัท"
                  innerRef={register}
                />
                <div className="mt-2 text-secondary">
                  <Label>ถ้าต้องการใช้รูปภาพเดิม ไม่ต้องอัพโหลดรูปภาพใหม่</Label>
                </div>
              </Col>
            </Row>



            <br />
            <h4 className='light-th'>ตั้งค่าเลขที่เอกสาร</h4>
            <br />
            <Row>
              <Col md="2">
                <Label>ใบเสนอราคา</Label>
              </Col>
              <Col className="text-left">
                <TextInput width="25%" placeholder="คำนำหน้า" name="prefix" defaultValue={prefix} onChange={e => setPrefix(e.target.value)} innerRef={register} />{" "}
                <TextInput width="20%" type="number" placeholder="ลำดับ" name="number" defaultValue={number} onChange={e => setNumber(e.target.value)} innerRef={register} />{" "}
                <TextInput width="10%" placeholder="-" className="text-center" disabled innerRef={register} />{" "}
                <TextInput width="20%" placeholder="ปี" name="year" defaultValue={year} disabled onChange={e => setYear(e.target.value)} innerRef={register} />
                {/* <Select defaultValue={year} onChange={event => setYear(event.target.value)}>
                  <option value="543">ปี พ.ศ.</option>
                  <option value="0">ปี ค.ศ.</option>
                </Select> */}
              </Col>
            </Row>
            <br />

            <Button
              className="mt-4"
              block
              color="success"
              type="submit"
              onClick={handleSubmit(onchangeData)}
            >
              บันทึก
          </Button>
          </from>
        </div>
      </Container>
    );
  } else {
    return (
      <Container fluid className="light-th">
        <div className="container col-md-8 text-center">
          <h1 className="text-danger"> ขออภัย </h1>
          <h3>
            คุณไม่มีสิทธิในการเข้าถึง <br />
              กรุณาลงทะเบียนด้วย User ที่ได้รับสิทธิเป็นผู้ดูแลระบบ
          </h3>
        </div>
      </Container>
    )
  }

}
