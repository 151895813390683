import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../actions";
import { useForm } from "react-hook-form";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  InputGroup,
  Input,
  ModalFooter,
  // Button,
} from "reactstrap";
import hash from "object-hash";
import Button from '@material-ui/core/Button';
import { authen } from "../../assets/api/firebase";

const ModalSelectImage = (props) => {
  const { modal, toggle, imageFormUser, imageFormMechanic, imageBefore, imageAfter, imageData, reload, setReload, } = props;
  const { register, handleSubmit, err } = useForm();

  const [openSelect, setOpenSelect] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);

  const onSelectImage = (data) => {

    if (imageData.type === "before") {
      imageBefore[imageData.index] = data;

    } else if (imageData.type === "after") {
      imageAfter[imageData.index] = data;
    }

    setOpenSelect(!openSelect);
    setOpenUpload(true);
    setReload(!reload);
    toggle();


  };

  const onUploadImage = (data) => {
   
    const name = data[0].name;
    const date = new Date();
    const hashID = hash({
      name: name,
      date: date,
    });

    const uploadTask = authen
      .storage()
      .ref(`EandE/${hashID}`)
      .put(data[0]);

    uploadTask.on(
      "state_changed",
      (snapshot) => { },
      (error) => {
        console.log(error);
      },
      () => {
        authen
          .storage()
          .ref("EandE")
          .child(hashID)
          .getDownloadURL()
          .then((url) => {

            if (imageData.type === "before") {
              // console.log("before");
              imageBefore[imageData.index] = url;
              imageFormMechanic.push(url);
              toggle();
            } else if (imageData.type === "after") {
              // console.log("after");
              imageAfter[imageData.index] = url;
              imageFormMechanic.push(url);
              toggle();
            }



          })
      });

  };

  const toggleSelectImage = () => {
    setOpenSelect(!openSelect);
    setOpenUpload(false);
  }

  const showButtonSelectImage = () => {
    if (imageFormUser.length === 0 && imageFormMechanic.length === 0) {
      return (
        <>
        </>
      )
    } else {
      return (
        <>
          <Button variant="contained" style={{ backgroundColor: "#0066ff", color: "#ffffff" }} onClick={toggleSelectImage}>
            เลือกรูปภาพจากคลัง
          </Button>{" "}
        </>
      )
    }
  }

  return (
    <div>
      <Modal
        isOpen={modal}
        toggle={toggle}
        centered={true}
      >
        <ModalHeader toggle={toggle}>เลือกรูปภาพ</ModalHeader>
        <ModalBody className="text-center">
          {showButtonSelectImage()}

          <label htmlFor="contained-button-file">
            <Button
              variant="contained" style={{ backgroundColor: "#2eb82e", color: "#ffffff" }} component="span" >
              <input
                name="upload"
                accept="image/*"
                style={{ display: "none" }}
                id="contained-button-file"
                type="file"
              onChange={(e) => 
                onUploadImage(e.target.files)
              }
              />
              อัพโหลดรูปภาพ
          </Button>
          </label>


        </ModalBody>
        <ModalFooter>
          <Button variant="contained" onClick={toggle}>
            ยกเลิก
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={openSelect} toggle={toggleSelectImage}>
        <ModalHeader>เลือกรูปภาพจากคลัง</ModalHeader>
        <ModalBody>
          {imageFormUser.map(_image => (
            <img src={_image} width="100%" className="mb-4" onClick={() => onSelectImage(_image)} />
          ))}

          {imageFormMechanic.map(_image => (
            <img src={_image} width="100%" className="mb-4" onClick={() => onSelectImage(_image)} />
          ))}
        </ModalBody>

      </Modal>


    </div>
  );
};

export default ModalSelectImage;
