import React, { useState, useEffect } from "react";
import { Col, Container, Table } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import * as role from "../../util/UserRole";
import { useHistory } from "react-router-dom";
import * as actions from '../../actions'
import * as UserStatus from "../../util/UserStatus";
import WaitTables from "../tables/WaitTables";
import SearchOrder from "../../components/Search/SearchOrder";
import _ from "lodash";

// ช่างสามารถดูรายละเอียดข้อมูลการซ่อม ดูประวัติการซ่อมย้อนหลัง
// ตรวจสอบ เบิก/คืนอุปกรณ์ เปลี่ยนสถานะการดำเนินการซ่อม ใส่รายละเอียดการซ่อม ไม่รับงาน
const ManagementWait = () => {
  const orders = useSelector((state) => state.orders);
  const user = useSelector((state) => state.user);
  const history = useHistory()
  const dispatch = useDispatch()
  const [ordersData, setOrdersData] = useState([])


  const allowAccess = user
    ? user.role === role.MECHANIC_ROLE ||
      user.role == role.CONTRATOR_ROLE ||
      user.role === role.ADMIN_ROLE ||
      user.role === role.SUPER_USER_ROLE
      ? true
      : false
    : history.push('/login');

  useEffect(() => {
    dispatch(actions.productsFetch());
    dispatch(actions.ordersFetch(user));
    return () => { }
  }, [])

  useEffect(() => {
    if (orders.length != 0) {
      const filterOrder = _.filter(orders, (_order) => {
        return (
          (_order.statusMechanic === UserStatus.MECHANIC_CHECK) || (_order.statusMechanic === UserStatus.MECHANIC_ESTIMATEPRICE && _order.statusUser === UserStatus.USER_ESTIMATEPRICE)
        )
      })
      setOrdersData(filterOrder)
    }
    return () => { }
  }, [orders])

  if (allowAccess) {
    return (
      <>
        <Container fluid className="light-th">
          <div className="text-center">
            <h2 >งานที่รอการอนุมัติ</h2>
            <Col className="text-left">
              <SearchOrder allOrder={orders} setOrdersData={setOrdersData} />
            </Col>
            <hr />

            {ordersData.length === 0 ? (
              <>
                <div className="container col-md-8 text-center text-danger">
                  <br />
                  <br />
                  <br />
                  <h2>ไม่พบงานที่รอการอนุมัติ</h2>
                </div>

              </>
            ) : (
              <><div
                style={{
                  maxHeight: "100vh",
                  overflowY: "auto",
                  backgroundColor: "#ffffff",
                  boxShadow: "1px 1px 3px #d9d9d9",
                }}>
                <Table striped bordered hover responsive>
                  <thead className='light-th'>
                    <tr>
                      {/* <th>#</th> */}
                      <th>วันที่แจ้งซ่อม</th>
                      <th>ข้อมูลลูกค้า</th>
                      <th>ปัญหา/งานซ่อม</th>
                      {/* <th>สถานะการซ่อม</th> */}
                      {/* {/* <th>ผู้รับผิดชอบ</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {ordersData.map((order, index) => {

                      if (
                        (user.role === role.CONTRATOR_ROLE
                          && order.mechanic_name === user.user_title + user.user_firstname + " " + user.user_lastname)
                        && (
                          (order.statusMechanic === UserStatus.MECHANIC_CHECK) || (order.statusMechanic === UserStatus.MECHANIC_ESTIMATEPRICE && order.statusUser === UserStatus.USER_ESTIMATEPRICE)
                        )
                      ) {
                        return (
                          <>
                            <WaitTables
                              order={order}
                              index={index + 1}
                            />
                          </>
                        );
                      } else if (
                        (user.role !== role.CONTRATOR_ROLE)
                        && (
                          (order.statusMechanic === UserStatus.MECHANIC_CHECK) || (order.statusMechanic === UserStatus.MECHANIC_ESTIMATEPRICE && order.statusUser === UserStatus.USER_ESTIMATEPRICE)
                        )
                      ) {
                        return (
                          <>
                            <WaitTables
                              order={order}
                              index={index + 1}
                            />
                          </>
                        );
                      }
                    })}
                  </tbody>
                </Table>
              </div>

              </>
            )}

          </div>

        </Container>

      </>
    )
  } else {
    return (
      <>
      </>
    )
  }
}
export default ManagementWait;