import React, { useState, useEffect, useRef } from "react";
import { Button, Container, Table, Input, Alert, Row, Col, Label, Card, Modal, ModalBody, ModalHeader, ModalFooter, } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import * as role from "../../util/UserRole";
import { useHistory, useParams } from "react-router-dom";
import * as actions from '../../actions'
import _ from "lodash";
import { RemoveIcon, AddIcon, TrashIcon, TextInput, Strong, Switch } from 'evergreen-ui'
import SignaturePad from "react-signature-canvas";

// ดูรายละเอียดข้อมูลการซ่อม ประเมินราคาเบื้องต้น

const OverviewMobile = ({ productBorrow, productBorrowInventory, cost, productCost, productCostInventory, ot, price, vat, operatingCost, setOperatingCost, taxFree, setTaxFree, noOperatingCost, setNoOperatingCost, userData, setSelectUser, setApprovedPerson, imageURL, setImageURL }) => {
  const orders = useSelector((state) => state.orders);
  const user = useSelector((state) => state.user);
  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams();

  const [width, setWidth] = React.useState(window.innerWidth);
  const breakpoint = 620;

  const allowAccess = user
    ? user.role == role.MECHANIC_ROLE ||
      user.role == role.ADMIN_ROLE ||
      user.role == role.SUPER_USER_ROLE
      ? true
      : false
    : history.push('/login');

  const filterOrder = _.find(orders, (_order) => {
    return (
      _order._id == params.id
    )
  })

  useEffect(() => {
    dispatch(actions.productsFetch());
    window.addEventListener('resize', () => setWidth(window.innerWidth));

    return () => {
    }
  }, []);

  const sigCanvas = useRef({})
  const clear = () => sigCanvas.current.clear();

  const save = () => {
    setImageURL(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"))
    setOpen(!open);
  }

  const [open, setOpen] = useState(false);
  const toggleOpen = () => {
    setOpen(!open);
  }

  const showOverview = () => {
    if (productBorrow.length != 0) {
      return (
        <>
          <Table striped bordered hover responsive style={{ fontSize: "18px" }} className="text-center">
            <thead className="light-th" >
              <tr>
                <th>#</th>
                <th>ชื่อวัสดุ</th>
                <th>จำนวน</th>
                <th>ราคาต่อหน่วย</th>
                <th>ค่าบริการ</th>
                <th>รวม</th>
              </tr>
            </thead>
            <tbody>

              {productBorrow.map((_product, index) => {
                if (_product.product_price != 0) {
                  // console.log(cost[productBorrow.indexOf(_product)]);
                  return (
                    <>
                      <tr>
                        <th >{index + 1}</th>
                        <td>{_product.product_name}</td>
                        <td>{productBorrowInventory[productBorrow.indexOf(_product)]}</td>
                        <td>{_product.product_price}</td>
                        <td>{cost[productCost.indexOf(_product)]}</td>
                        <td>{parseInt(_product.product_price * productCostInventory[productCost.indexOf(_product)]) + parseInt(cost[productCost.indexOf(_product)] * productCostInventory[productCost.indexOf(_product)])}</td>
                      </tr>
                    </>
                  );
                } else {
                  return (
                    <>
                      <tr>
                        <th >{index + 1}</th>
                        <td>{_product.product_name}</td>
                        <td>{productBorrowInventory[productBorrow.indexOf(_product)]}</td>
                        <td>{0}</td>
                        <td>{0}</td>
                        <td>{0}</td>
                      </tr>
                    </>
                  );
                }
              })} </tbody>
          </Table>
        </>
      );
    }
  }

  const showPartOt = () => {
    if (ot.length != 0) {
      return (
        <>
          <Label
            style={{ fontSize: "25px" }}
          >ค่าสภาพการทำงาน</Label>
          <Table striped bordered hover responsive style={{ fontSize: "18px" }} className="text-center">
            <thead className="light-th" >
              <tr>
                <th>#</th>
                <th>ชื่อ</th>
                <th>จำนวน</th>
                <th>ราคาต่อหน่วย</th>
                <th>รวม</th>
              </tr>
            </thead>
            <tbody>
              {ot.map((_ot, index) => {
                return (
                  <>
                    <tr>
                      <th >{index + 1}</th>
                      <td>{_ot.ot_name}</td>
                      <td>{_ot.ot_inventory}</td>
                      <td>{_ot.ot_price}</td>
                      <td>{_ot.ot_inventory * _ot.ot_price}</td>
                    </tr>
                  </>
                );
              })} </tbody>
          </Table>
        </>
      )
    }
  }

  const showPrice = () => {
    price[0] = 0;
    vat[0] = 0;
    return (
      <>
        {productBorrow.map((_product) => {
          if (_product.product_price != 0) {
            price[0] = price[0] + parseInt(_product.product_price * productCostInventory[productCost.indexOf(_product)]) + parseInt(cost[productCost.indexOf(_product)] * productCostInventory[productCost.indexOf(_product)]);
            vat[0] = price[0] * 0.07;
          }
        })}

        {ot.map((_ot) => {
          price[0] = price[0] + parseInt(_ot.ot_price) * parseInt(_ot.ot_inventory);
          vat[0] = price[0] * 0.07;
        })}

      </>
    );
  }

  const showOperatingCost = () => {
    if (noOperatingCost === true) {
      return (
        <>
          <Table striped bordered hover responsive style={{ fontSize: "18px" }} className="text-center">
            <thead className="light-th" >
              <tr>
                <th>ชื่อ</th>
                {/* <th>ค่าวัสดุและอุปกรณ์</th> */}
                <th>ร้อยละ</th>
                <th>รวม</th>
              </tr>
            </thead>
            <tbody>

              <>
                <tr>
                  <td>ค่าใช้จ่ายและผลกำไร</td>
                  {/* <td>{price[0]} บาท</td> */}
                  <td>ร้อยละ <TextInput
                    name="operatingCost"
                    width={"35%"}
                    type="number"
                    className="text-center"
                    placeholder="0"
                    onChange={event => setOperatingCost(event.target.value)}
                  /> %</td>
                  <td>เท่ากับ {((operatingCost) / 100 * parseInt(price[0])).toFixed(2)} บาท</td>

                </tr>
              </>

            </tbody>
          </Table>
        </>
      )
    } else {
      operatingCost = 0;
    }
  }

  const showOverhead = () => {
    if (noOperatingCost === true) {
      return (
        <>
          <Label
            style={{ fontSize: "15px" }}
          > ค่าใช้จ่ายและผลกำไร เท่ากับ {(operatingCost / 100 * price[0]).toFixed(2)} บาท</Label>
          <br />
        </>
      )
    }
  }
  const showTaxFree = () => {
    if (taxFree === true) {
      vat[0] = price[0] * 0.07;
      return (
        <>
          <div >
            <Col sm="12" className="text-right">

              <Label
                style={{ fontSize: "15px" }}
              > ค่าวัสดุและอุปกรณ์ เท่ากับ {price[0]} บาท</Label>
              <br />
              {showOverhead()}
              <Label
                style={{ fontSize: "15px" }}
              > ค่า VAT 7% เท่ากับ {vat[0].toFixed(2)} บาท</Label>
              <br />
              <Label
                style={{ fontSize: "15px" }}
              > รวมทั้งสิ้น <Strong style={{ fontSize: "20px" }} className="text-danger">{(vat[0] + price[0] + operatingCost / 100 * price[0]).toFixed(2)}</Strong> บาท</Label>
            </Col>
          </div>
        </>
      )
    } else {
      vat[0] = 0;
      return (
        <>
          <div >
            <Col sm="12" className="text-right">

              <Label
                style={{ fontSize: "15px" }}
              > ค่าวัสดุและอุปกรณ์ เท่ากับ {price[0]} บาท</Label>
              <br />
              {showOverhead()}
              <Label
                style={{ fontSize: "15px" }}
              > รวมทั้งสิ้น <Strong style={{ fontSize: "20px" }} className="text-danger">{(vat[0] + price[0] + operatingCost / 100 * price[0]).toFixed(2)}</Strong> บาท</Label>
            </Col>
          </div>
        </>
      )
    }
  }

  const showContrator = () => {
    return userData.map((contrator) => {
      return <option value={contrator.id}>{contrator.user_title + contrator.user_firstname + " " + contrator.user_lastname}</option>
    })
  }

  if (allowAccess)
    return (
      <>
        <Label
          className="ml-2"
          style={{ fontSize: "25px" }}
        >รายการอุปกรณ์</Label>
        <h4 className="ml-2">{showOverview()}</h4>
        <h4 className="ml-2">{showPartOt()}</h4>

        <h4 className="ml-2">{showPrice()}</h4>
        <Row className="ml-2">
          <Label
            style={{ fontSize: "25px" }}

          >ค่าดำเนินงาน</Label>
          <Switch
            height={20}
            className="mt-2 ml-2"
            checked={noOperatingCost}
            onChange={e => setNoOperatingCost(!noOperatingCost)}
          />
          <Label
            style={{ fontSize: "25px" }} className="ml-2"
          >VAT</Label>
          <Switch
            height={20}
            className="mt-2 ml-2"
            checked={taxFree}
            onChange={e => setTaxFree(!taxFree)}
          />
        </Row>
        <h4 className="ml-2">{showOperatingCost()}</h4>
        <h4 className="ml-2">{showTaxFree()}</h4>

        <Row className="ml-2">
          <Col><Label
            style={{ fontSize: "20px" }}

          >มอบหมายงานให้</Label>
            <Input
              name="mechanicId"
              type="select"
              defaultValue={filterOrder.mechanicId}
              onChange={event => setSelectUser(event.target.value)}
            >
              {showContrator()}
            </Input></Col>

        </Row>
        <br />
        <Row className="ml-2">

          <Col><Label
            style={{ fontSize: "20px" }}

          >ผู้อนุมัติราคา</Label>
            <Input
              name="approvedPerson"
              type="text"
              placeholder="ชื่อผู้อนุมัติราคา"
              onChange={event => setApprovedPerson(event.target.value)}
            >
            </Input></Col>
        </Row>
        <br />
        <Label
          className="ml-4"
          style={{ fontSize: "20px" }}
        >ลายเซ็นผู้อนุมัติราคา</Label>
        <Button className="ml-2" color="primary" size="sm" onClick={() => toggleOpen()}>เพิ่มลายเซ็น</Button>

        <Modal isOpen={open} toggle={toggleOpen}>
          <ModalHeader>ลายเซ็น</ModalHeader>
          <ModalBody>
            <SignaturePad
              ref={sigCanvas}
              canvasProps={{ width: 450, height: 200 }} />
          </ModalBody>
          <ModalFooter>
            <Button color="success" onClick={save}>บันทึก</Button>{" "}
            <Button color="warning" onClick={clear}>ล้างค่า</Button>{" "}
            <Button onClick={() => toggleOpen()}>ยกเลิก</Button>

          </ModalFooter>
        </Modal>
        <br />
        <br />
        {imageURL ? (
          <img
            src={imageURL}
            alt="my signature"
            style={{
              display: "block",
              margin: "0 auto",
              border: "1px solid black",
              width: "300px"
            }}
          />
        ) : null}
        <br />

      </>
    )

}
export default OverviewMobile;