import axios from "axios";
import { HISTORY_GET, HISTORY_RESET, HISTORY_USER_GET } from "./types";

export const getHistory = () => {
    return (dispatch) => {
        axios.get(process.env.REACT_APP_API_URL + "/history/all").then((res) => {
            dispatch({ type: HISTORY_GET, payload: res.data });
        });
    };
};
export const getHistoryUser = (payload) => {
    return (dispatch) => {
        axios.get(process.env.REACT_APP_API_URL + "/history/user/"+payload.id).then((res) => {
            dispatch({ type: HISTORY_USER_GET, payload: res.data });
        });
    };
};
export const resetHistory = () => {
    return (dispatch) => {
        dispatch({ type: HISTORY_RESET, payload: null });
    };
};
export const getHistoryId = (id) => {
    return (dispatch) => {
        axios.get(process.env.REACT_APP_API_URL + "/history/"+id).then((res) => {
            dispatch({ type: HISTORY_USER_GET, payload: res.data });
        });
    };
};

