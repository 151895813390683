import React, { useState, useEffect } from "react";
import { Container, Table, Row, Col, Badge, Label, InputGroup, Card, Button, Input } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import * as role from "../../util/UserRole";
import { useHistory, useParams } from "react-router-dom";
import * as actions from '../../actions'
import * as UserStatus from "../../util/UserStatus";
import _ from "lodash";
import MaterialTables from "../tables/MaterialTables";
import OtTables from "../tables/OtTables";
import { useForm } from "react-hook-form";
import { Switch, TextInput, Strong, } from 'evergreen-ui'
import EditProductAndServiceCharge from "./EditProductAndServiceCharge";
import EditWorkingConditions from "./EditWorkingConditions";
import EditOverview from "./EditOverview";

// ทำใบเสนอราคา
const ReEstimatePrice = () => {
  const orders = useSelector((state) => state.orders);
  const user = useSelector((state) => state.user);
  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams();
  const [width, setWidth] = React.useState(window.innerWidth);
  const breakpoint = 620;

  const [filterOrder, setfilterOrder] = useState(_.find(orders, (_order) => {
    return (
      _order._id == params.id
    )
  })
  )

  const [count, setCount] = useState(0);
  const [username, setUsername] = useState(filterOrder.user_name);
  const [useragency, setUseragency] = useState(filterOrder.user_agency);
  const [project, setProject] = useState(filterOrder.project);
  const [usertel, setUsertel] = useState(filterOrder.user_tel);
  const [userfax, setUserfax] = useState(filterOrder.user_fax);
  const [paymentTerms, setPaymentTerms] = useState(filterOrder.paymentTerms);
  const [credit, setCredit] = useState(filterOrder.credit);
  // const [expirationDate, setExpirationDate] = useState(new Date(filterOrder.expirationDate));
  const [quotationId, setQuotationId] = useState(filterOrder.quotationId);
  const [quotationDate, setQuotationDate] = useState(new Date(filterOrder.quotationDate).toLocaleDateString("th"));


  const [productBorrow, setProductBorrow] = useState([]);  //เก็บอุปกรณ์ที่เลือกจากคลังและร้านค้า
  const [productBorrowInventory, setProductBorrowInventory] = useState([]);
  const [productCost, setProductCost] = useState([]);  //เก็บอุปกรณ์ที่เป็นวัสดุ
  const [productCostInventory, setProductCostInventory] = useState([]);
  const [cost, setCost] = useState([]); //ค่าแรงต่อชิ้น
  const [price, setPrice] = useState([0]); //มูลค่างาน
  const [vat, setVat] = useState([]); //ภาษี


  const [reload, setReload] = useState(true);
  const [taxFree, setTaxFree] = useState([]);
  const [noOperatingCost, setNoOperatingCost] = useState([]);
  const [selectProduct, setSelectProduct] = useState([]); //เก็บอุปกรณ์ที่เลือกจากคลัง
  const [selectProductStore, setSelectProductStore] = useState([]); //เก็บอุปกรณ์ที่เลือกจากร้านค้า
  const [productInventory, setProductInventory] = useState([]);
  const [productStoreInventory, setProductStoreInventory] = useState([]);
  const [operatingCost, setOperatingCost] = useState([]); //ค่าดำเนินงาน
  const [ot, setOt] = useState([]); //เก็บค่าสภาพการทำงาน
  const [selectproductCost, setSelectProductCost] = useState([]);
  const [selectCost, setSelectCost] = useState([]);

  useEffect(() => {
    // console.log("Use Effect:", reload);
    dispatch(actions.ordersFetch(user))
    window.addEventListener('resize', () => setWidth(window.innerWidth));
    return () => { }
  }, [reload])

  const handleOnChangePaymentTerms = (value) => {
    if (value != null) {
      setPaymentTerms(value);
      // if (value != "") {
      //   setExpirationDate(new Date(new Date().setDate(new Date(filterOrder.quotationDate).getDate() + parseInt(value))))
      // }
    }

  }

  const handleOnCredit = (value) => {
    if (value != null) {
      setCredit(value);

    }

  }

  const setEstimatePrice = () => {
    if (filterOrder.estimatePrice === true && count === 0) { //แก้ไขการประเมินราคา
      setTaxFree(filterOrder.reTaxFree);
      setNoOperatingCost(filterOrder.reNoOperatingCost);
      setSelectProduct(filterOrder.productUseFormStock[0].product);
      setSelectProductStore(filterOrder.productUseFormStore[0].product);
      setProductInventory(filterOrder.productUseFormStock[0].inventory);
      setProductStoreInventory(filterOrder.productUseFormStore[0].inventory);
      setOperatingCost(filterOrder.reestimate_price[0].operatingCost);
      setOt(filterOrder.reestimate_price[0].ot)
      setSelectProductCost(filterOrder.materialUse[0].product);
      setSelectCost(filterOrder.reestimate_price[0].cost);
      setCount(1);
    } else if (filterOrder.estimatePrice === undefined && count === 0) { //ประเมินราคาครั้งแรก ให้ดึงข้อมูลจากการประเมินราคาเบื้องต้น

      setTaxFree(filterOrder.taxFree);
      setNoOperatingCost(filterOrder.noOperatingCost);
      setSelectProduct(filterOrder.productFormStock[0].product);
      setSelectProductStore(filterOrder.productFormStore[0].product);
      setProductInventory(filterOrder.productFormStock[0].inventory);
      setProductStoreInventory(filterOrder.productFormStore[0].inventory);
      setOperatingCost(filterOrder.estimate_price[0].operatingCost);
      setOt(filterOrder.estimate_price[0].ot)
      setSelectProductCost(filterOrder.material[0].product);
      setSelectCost(filterOrder.estimate_price[0].cost);
      setCount(1);

    }
  }


  return (
    <>

      <Container fluid className="light-th"
        style={{
          marginTop: "50px",
          maxWidth: "900px",
        }}
      >

        <Row>
          <Col >
            <Button
              outline
              color="secondary"
              onClick={
                history.goBack
              }
            >ย้อนกลับ</Button>
          </Col>
          <Col xs="7">
            <h2 className="text-left">ใบเสนอราคา</h2>
          </Col>


        </Row>

        <br />
        <div
          style={{
            backgroundColor: "#ffffff",
            boxShadow: "1px 1px 3px #d9d9d9",
            padding: "30px",
          }}>
          {setEstimatePrice()}
          <h4>ข้อมูลลูกค้า</h4>
          <br />
          <InputGroup size="lg">
            <Input
              disabled
              style={{ fontSize: "15px" }}
              type="text"
              placeholder="เลขที่"
              value={quotationId}
            />
            <Input
              disabled
              style={{ fontSize: "15px" }}
              type="text"
              placeholder="วันที่"
              value={quotationDate}
            />
          </InputGroup>
          <br />
          <InputGroup size="lg">
            <Input
              style={{ fontSize: "15px" }}
              type="text"
              placeholder="ชื่อลูกค้า"
              onChange={e => setUseragency(e.target.value)}
              value={username}
            />
            <Input
              style={{ fontSize: "15px" }}
              type="text"
              placeholder="หน่วยงาน"
              onChange={e => setUseragency(e.target.value)}
              value={useragency}
            />
          </InputGroup>
          <br />
          <InputGroup size="lg">
            <Input
              style={{ fontSize: "15px" }}
              type="text"
              placeholder="เบอร์โทร"
              onChange={e => setUsertel(e.target.value)}
              value={usertel}
            />
            <Input
              style={{ fontSize: "15px" }}
              type="text"
              placeholder="แฟกซ์"
              onChange={e => setUserfax(e.target.value)}
              value={userfax}
            />
          </InputGroup>
           <br />
          <InputGroup size="lg">
            <Col><Label>กำหนดยืนราคา (วัน)</Label></Col>
            <Col><Label>เครดิต</Label></Col>
          </InputGroup>
          <InputGroup size="lg">
            <Input
              style={{ fontSize: "15px" }}
              type="select"
              placeholder="กำหนดยืนราคา (วัน)"
              onChange={e => handleOnChangePaymentTerms(e.target.value)}
              value={paymentTerms}
            >
              <option>7</option>
              <option>15</option>
              <option>30</option>
              <option>45</option>
              <option>60</option>
            </Input>
            {/* <Input
              disabled
              style={{ fontSize: "15px" }}
              type="text"
              placeholder="กำหนดชำระเงิน"
              value={expirationDate.toLocaleDateString("th")}
            /> */}
            <Input
              style={{ fontSize: "15px" }}
              type="select"
              // placeholder="กำหนดยืนราคา ( วัน )"
              placeholder="เครดิต"
              onChange={e => handleOnCredit(e.target.value)}
              value={credit}
            >
              <option>เงินสด</option>
              <option>7 วัน</option>
              <option>15 วัน</option>
              <option>30 วัน</option>
              <option>45 วัน</option>
              <option>60 วัน</option>
            </Input>
          </InputGroup>
          <br />
          <InputGroup size="lg">
            <Input
              style={{ fontSize: "15px" }}
              type="text"
              placeholder="ชื่อโครงการ"
              onChange={e => setProject(e.target.value)}
              value={project}
            />
          </InputGroup>
          <br />
          <EditProductAndServiceCharge
            selectProduct={selectProduct}
            selectProductStore={selectProductStore}
            setSelectProduct={setSelectProduct}
            setProductInventory={setProductInventory}
            setProductStoreInventory={setProductStoreInventory}
            productInventory={productInventory}
            productStoreInventory={productStoreInventory}
            reload={reload}
            setReload={setReload}
            productBorrow={productBorrow}
            productBorrowInventory={productBorrowInventory}
            productCost={productCost}
            productCostInventory={productCostInventory}
            cost={cost}
            setProductBorrow={setProductBorrow}
            setProductBorrowInventory={setProductBorrowInventory}
            setProductCost={setProductCost}
            setProductCostInventory={setProductCostInventory}
            setCost={setCost}
            setPrice={setPrice}
            setVat={setVat}
            setOperatingCost={setOperatingCost}
            ot={ot}
            price={price}
            vat={vat}
            taxFree={taxFree}
            setTaxFree={setTaxFree}
            noOperatingCost={noOperatingCost}
            setNoOperatingCost={setNoOperatingCost}
            operatingCost={operatingCost}
            setOperatingCost={setOperatingCost}
            selectproductCost={selectproductCost}
            selectCost={selectCost}
          />

          <br />
          <EditWorkingConditions
            ot={ot}
            setOt={setOt}
            reload={reload}
            setReload={setReload}
          />
          <br />

          <EditOverview
            productBorrow={productBorrow}
            productBorrowInventory={productBorrowInventory}
            cost={cost}
            productCost={productCost}
            productCostInventory={productCostInventory}
            ot={ot}
            price={price}
            vat={vat}
            operatingCost={operatingCost}
            setOperatingCost={setOperatingCost}
            taxFree={taxFree}
            setTaxFree={setTaxFree}
            noOperatingCost={noOperatingCost}
            setNoOperatingCost={setNoOperatingCost}
            reload={reload}
            setReload={setReload}
          />


          <div className="text-right">
            <Button
              onClick={() => {

                let comfirm = window.confirm("ยืนยันการแก้ไขใบเสนอราคา")
                if (comfirm) {

                  //ปกติ
                  const data = {
                    estimatePrice: true,
                    user_name: username,
                    user_agency: useragency,
                    project: project,
                    user_tel: usertel,
                    user_fax: userfax,
                    paymentTerms: paymentTerms,
                    credit: credit,
                    // expirationDate: expirationDate,

                    productUse: { //อุปกรณ์ที่ยืม
                      product: productBorrow,
                      inventory: productBorrowInventory,
                    },
                    productUseFormStore: { //อุปกรณ์จากร้านค้า
                      product: selectProductStore,
                      inventory: productStoreInventory,
                    },
                    productUseFormStock: { //อุปกรณ์จากคลัง
                      product: selectProduct,
                      inventory: productInventory,
                    },
                    materialUse: { //วัสดุทั้งหมดที่เบิก
                      product: productCost,
                      inventory: productCostInventory,
                    },
                    reestimate_price: { //การคิดราคาเบื้องต้น
                      cost: cost.slice(0, productCost.length), //ค่าบริการ
                      ot: ot, //ค่าสภาพการทำงาน
                      vat: vat, //ค่าภาษี
                      priceOfProduct: price[0], //ค่าวัสดุ
                      operatingCost: operatingCost, //เปอร์เซ็นของค่าดำเนินการ
                      operatingPrice: (operatingCost / 100 * price[0]).toFixed(2), //ค่าดำเนินการ
                    },
                    price: (vat[0] + price[0] + operatingCost / 100 * price[0]).toFixed(2), //ราคารวม
                    reTaxFree: taxFree,   //เปิด/ปิด คิดค่าภาษี
                    reNoOperatingCost: noOperatingCost, //เปิด/ปิด ค่าดำเนินการ
                  }
                  dispatch(actions.orderPut(filterOrder._id, data))
                  dispatch(actions.ordersFetch(user))
                  history.push("/deliver_work/" + filterOrder._id)

                }

              }}
              color="primary">
              บันทึก
              </Button>
          </div>

        </div>
      </Container>
    </>
  )

}
export default ReEstimatePrice;