import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../actions";
import { useForm } from "react-hook-form";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
  ModalFooter,
  Button,
  FormGroup
} from "reactstrap";
import hash from "object-hash";
import * as UserStatus from "../../util/UserStatus";
import Rating from '@material-ui/lab/Rating';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useParams } from "react-router-dom";

const ModalStartRating = (props) => {
  const dispatch = useDispatch();
  const { className, modal, toggle, star, setStar, mechanicId, id } = props;
  const { register, handleSubmit, err } = useForm();
  const history = useHistory()
  const user = useSelector((state) => state.user);

  const onCalculatorStarRating = () => {

    dispatch(actions.editStarRating(mechanicId, star, id))

    toggle()
    if (user.role === "USER") {
      history.push("/status")

    } else {
      history.push("/management/deliver_work")

    }

  };

  return (
    <div>
      <Modal
        backdrop={false}
        isOpen={modal}
        toggle={toggle}
        className={className}
        centered={true}

      >
        <ModalHeader toggle={toggle}>ให้คะแนนบริการที่คุณได้รับ</ModalHeader>
        <ModalBody className="text-center">
          <Rating name="starRating" onChange={e => setStar(e.target.value)} precision={0.5} size="large" innerRef={register} />

        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSubmit(onCalculatorStarRating)}>
            ส่ง
          </Button>{" "}
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ModalStartRating;
