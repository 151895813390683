import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../actions";
import * as role from "../../util/UserRole";
import { Container, Col, Card, CardBody, Row, Button } from "reactstrap";
import { useHistory } from "react-router-dom"
import _ from 'lodash'
import RepairStatus from "./graph/RepairStatus";
import RepairNotificationStatistics from "./graph/RepairNotificationStatistics";
import RepairStatusByMechanic from "./graph/RepairStatusByMechanic";
import ProblemRepairByGuest from "./graph/ProblemRepairByGuest";

export default function RepairReport() {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const orders = useSelector((state) => state.orders);
  const histories = useSelector((state) => state.history);
  const allUser = useSelector((state) => state.userManagement);
  const allowAccess = user
    ? user.role == role.SUPER_USER_ROLE
      ? true
      : false
    : history.push("/login");


  useEffect(() => {
    dispatch(actions.fetchAllUser());
    dispatch(actions.ordersFetch(user));
    dispatch(actions.getHistory());
    return () => { };
  }, []);


  if (allowAccess) {
    return (
      <Container className="light-th">
        <h2 className="text-center"> รายงานการแจ้งซ่อม </h2>

        <br />
        <Row>
          <Col className="mt-2">
            <RepairStatus
              orders={orders}
              histories={histories}
            />

          </Col>
          <Col className="mt-2">

            <RepairNotificationStatistics
              orders={orders}
              histories={histories}
            />
          </Col>
        </Row>
        <br />
        <ProblemRepairByGuest
          orders={orders}
          histories={histories}
          allUser={allUser}
        />
        <br />
        <RepairStatusByMechanic
          orders={orders}
          histories={histories}
          allUser={allUser}
        />

      </Container>
    );
  } else {
    return (
      <Container fluid className="light-th">
        <div className="container col-md-8 text-center">
          <h1 className="text-danger"> ขออภัย </h1>
          <h3>
            คุณไม่มีสิทธิในการเข้าถึง <br />
              กรุณาลงทะเบียนด้วย User ที่ได้รับสิทธิเป็นผู้ดูแลระบบ
          </h3>
        </div>
      </Container>
    )
  }

}
