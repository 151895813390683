import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../actions";
import * as role from "../../util/UserRole";
import { Container, Col, Card, CardBody, Row } from "reactstrap";
import { useHistory } from "react-router-dom"
import Chart from "react-apexcharts";
import { Bar, Line, Pie } from 'react-chartjs-2'
import moment from 'moment'
import 'moment/locale/th'

export default function FinanceReport() {
  const dispatch = useDispatch();
  const history = useHistory();
  const changeStatus = null;
  const user = useSelector((state) => state.user);
  const allUser = useSelector((state) => state.userManagement);
  const [userData, setUserData] = useState([]);


  const allowAccess = user
    ? user.role == role.SUPER_USER_ROLE
      ? true
      : false
    : history.push("/login");


  useEffect(() => {
    dispatch(actions.fetchAllUser());
    return () => { };
  }, []);

  useEffect(() => {
    setUserData(allUser)
    return () => { };
  }, [allUser]);


  const generateGraphLabel = () => {
    let yearBeginner = moment().subtract(1, 'years').add(1, 'months')
    let yearlyLabel = []
    for (let months = 0; months < 12; months++) {
      let thatDayLabel = moment(yearBeginner).add(months, 'months').format('MMM YY')
      yearlyLabel.push(thatDayLabel)
    }

    return yearlyLabel
  }
  let graphLabel = generateGraphLabel()

  const data = {
    labels: graphLabel,
    datasets: [
      {
        label: 'รายได้ทั้งหมด',
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: 'rgb(54, 162, 235)',

      },
      {
        label: 'ต้นทุน',
        data: [2, 3, 20, 5, 1, 4],
        backgroundColor: 'rgb(255, 99, 132)',
      },
      {
        label: 'กำไร',
        data: [3, 10, 13, 15, 22, 30],
        backgroundColor: 'rgb(75, 192, 192)',
      },
    ],
  }


  if (allowAccess) {
    return (
      <Container className="light-th">
        <h2 className="text-center"> รายงานการเงิน </h2>
        <Col className="text-left">
        </Col>
        <br />
        <div>
          <Card>
            <CardBody>
              <h4 className="text-left"><strong>สรุปรายได้</strong></h4>
              <Row>
                <Col>
                  รายได้
                </Col>
                <Col>
                  ต้นทุน
                </Col>
                <Col>
                  กำไร
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
        <br />
        <div>
          <Card>
            <CardBody>
              <h4 className="text-left"><strong>รายได้</strong></h4>
              <Bar
                data={data}
                options={{
                  legend: {
                    display: true,
                    position: 'bottom'
                  },
                  scales: {
                    yAxes: [
                      {
                        ticks: {
                          beginAtZero: true,
                        },
                      },
                    ],
                  },

                }}
                width={50}
                height={20}
              />
            </CardBody>
          </Card>
        </div>
      </Container>
    );
  } else {
    return (
      <Container fluid className="light-th">
        <div className="container col-md-8 text-center">
          <h1 className="text-danger"> ขออภัย </h1>
          <h3>
            คุณไม่มีสิทธิในการเข้าถึง <br />
              กรุณาลงทะเบียนด้วย User ที่ได้รับสิทธิเป็นผู้ดูแลระบบ
          </h3>
        </div>
      </Container>
    )
  }

}
