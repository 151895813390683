import React from "react";
import { Input } from "reactstrap";
export default function SearchHistory(props) {

  const { History, setHistoryData } = props;
  const onSeach = (word) => {
    const res = History.filter(
      (history) =>
      history.orders.user_name.includes(word) 
      // || history.orders.user_agency.includes(word)
      // || history.orders.user_tel.includes(word)
      // || history.orders.user_email.includes(word)
      // || history.orders.place.includes(word)
      // || history.orders.machanic_name.includes(word)
      // || history.orders.orderDate.includes(word)
      // || history.orders.type.includes(word)
      // || history.orders.problem.includes(word)
      // || history.orders.speed.includes(word)
      || history.orders.quotationId.includes(word) 
    );
    setHistoryData(res);
  };
  return (
    <div className="d-flex">
      <Input
        style={{ maxWidth: "400px" }}
        className="ml-1"
        type="text"
        placeholder="ค้นหา"
        onChange={(e) => {
          History&&onSeach(e.target.value)
        }}
      />
    </div>
  );
}
