import React, { useState, useEffect } from "react";
import { Container, Table, Row, Col, Badge, Label, InputGroup, Card, CardSubtitle, CardImg, Button, Input, CardTitle } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams, useLocation } from "react-router-dom";
import _ from "lodash";

// โชว์รูปภาพก่อนและหลังซ่อม
const BeforeAndAfterImageDocument = () => {
  const location = useLocation();
  const user = useSelector((state) => state.user);
  const History = location.state.History;
  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams();
  const [width, setWidth] = React.useState(window.innerWidth);
  const breakpoint = 620;

  useEffect(() => {
    window.addEventListener('resize', () => setWidth(window.innerWidth));
    return () => { }
  }, [])

  const [imageBefore, setImageBefore] = useState(History.imageBefore);
  const [imageAfter, setImageAfter] = useState(History.imageAfter);
  const [subtitle, setSubtitle] = useState(History.subtitle);

  function printDiv(divName) {
    var printContents = document.getElementById(divName).innerHTML;
    var originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;

    window.print();

    document.body.innerHTML = originalContents;
    window.location.reload()

  }

  const showButton = () => {

    return (
      <>
        <Row className="print-disable">
          <Col xs="2">
            <Button
              outline
              color="secondary"
              onClick={
                history.goBack
              }
            >ย้อนกลับ</Button>
          </Col>
          <Col xs="7">
            <h2 className="text-center">รูปภาพก่อนและหลังซ่อม</h2>
          </Col>
          <Col className="text-right">
            <Button color="info" onClick={() => printDiv("printarea")}>พิมพ์</Button>{" "}
          </Col>
        </Row>
      </>
    )

  }

  const showButtonOnMobile = () => {

    return (
      <>
        <Row className="print-disable">
          <Col xs="3">
            <Button
              size="sm"
              outline
              color="secondary"
              onClick={
                history.goBack
              }
            >ย้อนกลับ</Button>
          </Col>
          <Col xs="6">
            <h5 className="text-center">รูปภาพก่อนและหลังซ่อม</h5>
          </Col>
          <Col className="text-right">
            <Button size="sm" color="info" onClick={() => printDiv("printarea")}>พิมพ์</Button>{" "}
          </Col>
        </Row>
      </>
    )

  }

  if (width < breakpoint) {
    return (
      //mobile
      <>
        <Container fluid className="light-th"
          id="printarea"
          style={{
            marginTop: "50px",
            maxWidth: "900px",
          }}
        >

          {showButtonOnMobile()}
          <br />
          <div
            style={{
              backgroundColor: "#ffffff",
              boxShadow: "1px 1px 3px #d9d9d9",
              padding: "30px",

            }}>

            <Table bordered hover responsive style={{ fontSize: "15px" }} className="mt-4 text-center" size="sm">
              <thead className="light-th" style={{ backgroundColor: "#EAFAF1", WebkitPrintColorAdjust: "exact" }}>
                <tr>
                  <th>รูปภาพก่อนซ่อม</th>
                  <th>รูปภาพหลังซ่อม</th>
                </tr>
              </thead>
              <tbody>
                {imageBefore.map((before, index) => {
                  return (
                    <>
                      <tr>
                        <td >
                          <Card>
                            <img width="100%" src={before} style={{ marginRight: "auto", marginLeft: "auto" }} />
                            <br />
                            <Input type="textarea" className="text-center" value={subtitle[index]} style={{ border: "none" }} />
                          </Card>
                        </td>
                        <td>
                          <Card>
                            <img width="100%" src={imageAfter[index]} style={{ marginRight: "auto", marginLeft: "auto" }} />
                            <br />
                            <Input type="textarea" className="text-center" value={subtitle[index + 4]} style={{ border: "none" }} />
                          </Card>
                        </td>
                      </tr>
                    </>
                  );
                })}
              </tbody>

            </Table>


          </div>
        </Container>
      </>
    )
  } else {
    return (
      //desktop
      <>
        <Container fluid className="light-th"
          id="printarea"
          style={{
            marginTop: "50px",
            maxWidth: "900px",
          }}
        >

          {showButton()}
          <br />
          <div
            style={{
              backgroundColor: "#ffffff",
              boxShadow: "1px 1px 3px #d9d9d9",
              padding: "30px",

            }}>

            <Table bordered hover responsive style={{ fontSize: "18px" }} className="mt-4 text-center" size="sm">
              <thead className="light-th" style={{ backgroundColor: "#EAFAF1", WebkitPrintColorAdjust: "exact" }}>
                <tr>
                  <th>รูปภาพก่อนซ่อม</th>
                  <th>รูปภาพหลังซ่อม</th>
                </tr>
              </thead>
              <tbody>
                {imageBefore.map((before, index) => {
                  return (
                    <>
                      <tr>
                        <td >
                          <Card>
                            <img width="50%" src={before} style={{ marginRight: "auto", marginLeft: "auto" }} />
                            <br />
                            <CardTitle>{subtitle[index]}</CardTitle>
                          </Card>
                        </td>
                        <td>
                          <Card>
                            <img width="50%" src={imageAfter[index]} style={{ marginRight: "auto", marginLeft: "auto" }} />
                            <br />
                            <CardTitle>{subtitle[(index + 4)]}</CardTitle>
                          </Card>
                        </td>
                      </tr>
                    </>
                  );
                })}
              </tbody>

            </Table>


          </div>
        </Container>
      </>
    )
  }


}
export default BeforeAndAfterImageDocument;