import React, { useState, useEffect } from "react";
import { useHistory, BrowserRouter as Router } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "./actions/";

// import SideBar from "./components/sidebar/SideBar";
import Content from "./components/content/Content";
import Footer from "./components/content/Footer";

import { authen } from "./assets/api/firebase";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "./assets/css/sidebar.css";
import { auth } from "firebase";

const App = () => {
  const [topbarIsOpen, setTopbarOpen] = useState(false);
  const [topbarVisibility, setTopbarVisibility] = useState(true);
  const user = useSelector((state) => state.user);

  const dispatch = useDispatch();
  //const prevScrollpos = 0;
  const toggleTopbar = () => {
    setTopbarOpen(!topbarIsOpen);
  };
  const toggleTopbarVisibility = (state) => {
    setTopbarVisibility(state);
  };
  const handleScroll = (event) => {
    if (window.scrollY !== 0) {
      setTopbarOpen(false);
    }
  };
  useEffect(() => {
    // window.addEventListener('click', () => console.log('document clicked'));
    console.log("App | topbarVisibility ", topbarVisibility);

   
    authen.auth().onAuthStateChanged((res) => {
      let authData = null;
      if (res) {
        authData = {
          displayName: res.displayName,
          email: res.email,
          emailVerified: res.emailVerified,
          photoURL: res.photoURL,
          isAnonymous: res.isAnonymous,
          uid: res.uid,
          providerData: res.providerData,
        };
        dispatch(actions.getUser(res.uid));
        dispatch(actions.authState(authData));
      } else {
        dispatch(actions.resetUser());
      }
    });
    return () => {};
  }, []);
  return (
    <Router>
      <div className="App wrapper"
        onTouchMove={() => {
          setTopbarOpen(false)
        }}
        onWheel={() => {
          setTopbarOpen(false)
        }}>
        {/* <SideBar toggle={toggleSidebar} isOpen={sidebarIsOpen} /> */}
        <Content
          toggleTopbar={toggleTopbar}
          topbarIsOpen={topbarIsOpen}
          toggleTopbarVisibility={toggleTopbarVisibility}
          topbarVisibility={topbarVisibility}
        />
      </div>
      <Footer />
    </Router>
  );
};

export default App;
