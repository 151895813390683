import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Container,
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  Table,
  Button,
  Input,
  NavItem,
  Nav,
  NavLink,
} from "reactstrap";
import * as role from "../../util/UserRole";
import * as actions from "../../actions";
import { useHistory } from "react-router-dom"
import _ from "lodash";
import { useForm } from "react-hook-form";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import KnowledgeDetailDesktop from "./detail/KnowledgeDetailDesktop";
import KnowledgeDetailMobile from "./detail/KnowledgeDetailMobile";
import TablePagination from "../../components/TablePagination/TablePagination";

const KnowledgeReport = () => {

  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const repairtype = useSelector((state) => state.repairtype);
  const [problem, setProblem] = useState([]);
  const [index, setIndex] = useState([]);
  const [reload, setReload] = useState(true);
  const [edit, setEdit] = useState(false);
  const { register, handleSubmit, err } = useForm();
  const History = useSelector((state) => state.history);
  const [historyData, setHistoryData] = useState([]);
  const [pageSize, setPageSize] = useState(10)
  const [currentPage, setCurrentPage] = useState(0)

  const classes = makeStyles({
    root: {
      flexGrow: 1,
      justifyContent: "center",
    },
  });
  const [value, setValue] = React.useState("ทั้งหมด");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Access Control
  const allowAccess = user
    ? user.role == role.CONTRATOR_ROLE ||
      user.role == role.MECHANIC_ROLE ||
      user.role == role.ADMIN_ROLE ||
      user.role == role.SUPER_USER_ROLE
      ? true
      : false
    : history.push("/login");

  // Inside State Setting
  const [selectedRepairtype, setSelectedRepairtype] = useState("");

// console.log(repairtype);
// console.log(selectedRepairtype);
  useEffect(() => {
    dispatch(actions.getHistory());
    dispatch(actions.repairtypesFetch());
    return () => { }
  }, []);

  useEffect(() => {
    if (repairtype.length === 0) {
      dispatch(actions.repairtypesFetch());
      setSelectedRepairtype(repairtype[0])
    }
    if (History.length != 0) {
      const filterHistory = _.filter(History, (_history) => {
        return (
          _history.orders.quotationId != "-" && _history.orders.statusUser === "ดำเนินการเสร็จสิ้น"
        )
      })
      setHistoryData(filterHistory)
    }
    return () => { };
  }, [History,selectedRepairtype]);

  const handleChangeSelect = (type) => {
    setSelectedRepairtype(type);
    setValue("ทั้งหมด");
  };

  const repairtypeList = () => {

    if (Array.isArray(repairtype)) {
      if (selectedRepairtype != undefined && selectedRepairtype != null && selectedRepairtype != [] && selectedRepairtype != "") {
        return repairtype.map((type) => {
          return (
            <ListGroupItem
              tag="a"
              href="#"
              action
              active={selectedRepairtype._id == type._id}
              onClick={() => handleChangeSelect(type)}
            >
              {type.repairtype_name}
            </ListGroupItem>
          );
        });
      }
    } else {
      console.log("Repairtype is not Array");
    }
  };

  const problemList = () => {
    if (selectedRepairtype != undefined && selectedRepairtype != null && selectedRepairtype != [] && selectedRepairtype != "") {
      let repairtype_problem = selectedRepairtype.repairtype_problem;
      if (Array.isArray(repairtype_problem)) {
        return repairtype_problem.map((problem, index) => {
          return (
            <Tab
              key={index + 1}
              label={problem.repairtype_problem}
              value={problem._id}
            />
          );
        });
      }
    }
  };

  const findOrder = _.find(historyData, (_order) => {
    if (selectedRepairtype != undefined && selectedRepairtype != null && selectedRepairtype != [] && selectedRepairtype != "") {
      if (value === "ทั้งหมด") {
        return (
          _order.orders.typeId === selectedRepairtype._id
        )
      } else if (value != "ทั้งหมด") {
        return (
          value == _order.orders.problemId
        )
      }
    }
  })

  const showNotFindData = () => {
    if (findOrder === undefined) {
      return (
        <>
          <br />
          <p className="text-danger text-center">ไม่พบบันทึกการซ่อม</p>
          <br />
        </>
      )
    }
  }

  return (
    <Container
      fluid
      className="light-th">
      <h2 className='text-center'> การจัดการองค์ความรู้ </h2>
      <div>

        {allowAccess ? (
          <>
            <hr />
            <Row>
              <Col sm="4" lg="4" className="text-left mb-4">
                <h3>ประเภทงานซ่อม </h3>
                <ListGroup>
                  {repairtypeList()}
                </ListGroup>
              </Col>
              <Col sm="8" lg="8" className="text-left">
                <br />
                <br />
                <Paper className={classes.root}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"

                  >
                    <Tab
                      key={0}
                      label="ทั้งหมด"
                      value={"ทั้งหมด"}
                    />
                    {problemList()}

                  </Tabs>
                </Paper>
                <br />
                {(selectedRepairtype != undefined  && (selectedRepairtype == "" || selectedRepairtype._id == null)) ? (
                  setSelectedRepairtype(repairtype[0])
                ) : (
                  <>
                    {Array.isArray(historyData) ? (
                      <>
                        {historyData
                          .slice(
                            currentPage * pageSize,
                            (currentPage + 1) * pageSize
                          )
                          .map((eachHistory, index) => {
                            if (eachHistory.orders.typeId === selectedRepairtype._id && eachHistory.orders.problemId === value && eachHistory.orders.statusUser === "ดำเนินการเสร็จสิ้น") {
                              return (
                                <KnowledgeDetailDesktop
                                  order={eachHistory.orders}
                                  key={index}

                                />
                              );
                            } else if (eachHistory.orders.typeId === selectedRepairtype._id && value === "ทั้งหมด" && eachHistory.orders.statusUser === "ดำเนินการเสร็จสิ้น") {
                              return (
                                <KnowledgeDetailDesktop
                                  order={eachHistory.orders}
                                  key={index}
                                />
                              );
                            }
                          })}

                      </>
                    ) : (
                      <>
                        <h2>ไม่พบบันทึกการซ่อม</h2>
                      </>
                    )}


                  </>
                )}
                {showNotFindData()}
                <div className="d-flex justify-content-center m-4 ">
                  <TablePagination
                    pagesCount={Math.ceil(historyData.length / pageSize)}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                  />
                </div>

              </Col>

            </Row>
          </>
        ) : (
          <>
            <h1 className="text-danger"> ขออภัย </h1>
            <h3>
              คุณไม่มีสิทธิในการเข้าถึง <br />
              กรุณาลงทะเบียนด้วย User ที่ได้รับสิทธิเป็นผู้ดูแลระบบ
            </h3>
          </>
        )}
      </div>
    </Container>
  );
};



export default KnowledgeReport;
