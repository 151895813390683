import axios from "axios";
import {
  CATEGORIES_FETCH,
  CATEGORIES_CREATE,
  CATEGORIES_REMOVE,
} from "./types";


export const categoriesFetch = () => {
  return (dispatch) => {
    axios.get(process.env.REACT_APP_API_URL + "/categories/all").then((res) => {
      dispatch({ type: CATEGORIES_FETCH, payload: res.data });
    });
  };
};

export const categoryFetch = (id) => {
  return (dispatch) => {
    axios.get(process.env.REACT_APP_API_URL + "/categories/" + id).then((res) => {
      dispatch({ type: CATEGORIES_FETCH, payload: res.data });
    })
  }
}
  
export const createCategory = (data) => {
    return (dispatch) => {
      axios
        .post(process.env.REACT_APP_API_URL + "/categories", data)
        .then((res) => {
          dispatch({ type: CATEGORIES_CREATE, payload: res.data });
        })
        .catch((err) => {
          console.log("Error Creating Category");
          console.error(err);
        });
    };
  };

  export const editCategoryName = (data) => {
    console.log(data);
    return (dispatch) => {
      axios
        .put(
          process.env.REACT_APP_API_URL + "/categories/" + data._id,
          data
        )
        .then((res) => {
          alert("แก้ไขชื่อสำเร็จ");
          dispatch({ type: CATEGORIES_CREATE, payload: res.data });
          // window.location.reload();
        })
        .catch((err) => {
          alert("ไม่สามารถเปลี่ยนชื่อหมวดหมู่ได้");
          console.log("Cannot Edit Category Name");
          console.error(err);
        });
    };
  };

  export const categoryDelete = (_id) => {
    console.log("User Request to delete Category| ", _id);
  return (dispatch) => {
    axios
      .delete(process.env.REACT_APP_API_URL + "/categories/" + _id)
      .then((res) => {
        axios.get(process.env.REACT_APP_API_URL + "/categories/").then((res) => {
          dispatch({ type: CATEGORIES_REMOVE, payload: res.data });
          window.location.reload();
        });
      })
      .catch((err) => {
        alert("ลบหมวดหมู่ไม่สำเร็จ");
        console.log("Error Remove Category");
        console.error(err);
      });
  };
  };