import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../actions";
import { useForm } from "react-hook-form";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  InputGroup,
  Input,
  ModalFooter,
  Button,
} from "reactstrap";
import hash from "object-hash";

const ModalAddOT = (props) => {
  const dispatch = useDispatch();
  const { className, modal, toggle, ot, setOt } = props;
  const [unmountOnClose, setUnmountOnClose] = useState(true);
  const { register, handleSubmit, err } = useForm();

  const onAddOt = (data) => {

    const inputData = {
      ot_name: data.ot_name,
      ot_inventory: data.ot_inventory,
      ot_price: data.ot_price,
    }
    ot.push(inputData);
    toggle();
  };

  return (
    <div>
      <Modal
        isOpen={modal}
        toggle={toggle}
        className={className}
      >
        <ModalHeader toggle={toggle}>เพิ่มค่าสภาพการทำงาน</ModalHeader>
        <ModalBody>
            <br />
            <Input
              type="text"
              style={{ fontSize: "15px" }}
              placeholder="ชื่อ"
              name="ot_name"
              innerRef={register}
            />
            <br />
            <Input
              min="0"
              type="number"
              style={{ fontSize: "15px" }}
              placeholder="จำนวน"
              name="ot_inventory"
              innerRef={register}
            />
            <br />
            <Input
              min="0"
              type="number"
              style={{ fontSize: "15px" }}
              placeholder="ราคาต่อหน่วย"
              name="ot_price"
              innerRef={register}
            />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSubmit(onAddOt)}>
            เพิ่ม
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            ยกเลิก
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ModalAddOT;
