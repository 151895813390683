import React, { useState, useEffect }
  from "react"; import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../actions";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { authen } from "../../assets/api/firebase";
import hash from "object-hash";
import * as role from "../../util/UserRole";
import {
  Container,
  Row,
  Col,
  Button,
  Input,
  InputGroup,
  Label,
} from "reactstrap";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import TextField from '@material-ui/core/TextField';
import _ from "lodash";

export default function UpdateProduct(props) {
  const location = useLocation();
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();


  const categories = useSelector((state) => state.categories);
  const user = useSelector((state) => state.user);
  const allowAccess = user
    ? user.role == role.STORE_ROLE ||
      user.role == role.ADMIN_ROLE ||
      user.role == role.SUPER_USER_ROLE
      ? true
      : false
    : history.push("/login");

  const product = location.state.product; //ข้อมูลที่ส่งผ่าน history.push
  const { register, handleSubmit, err } = useForm({
    defaultValues: {
      product_name: product.product_name,
      category_id: product.product_category.category_id,
      category_name: product.product_category.category_name,
      product_detail: product.product_detail,
      product_price: product.product_price,
      product_store: product.product_store,
      product_unit: product.product_unit,
      product_brand: product.product_brand,
      product_buy: product.product_buy,

    },
  });

  const filter = createFilterOptions();
  const [value, setValue] = useState(product.product_store);
  const store = product.store_list;

  useEffect(() => {
    dispatch(actions.productFetch(params.id));
    dispatch(actions.productsFetch());

    return () => { };
  }, []);

  const onEditProduct = (data) => {

    let curentSelectCategories = categories.find(category => {
      return category.category_id === data.category_id
    })

    const filterStore = _.find(store, (_store) => {
      return (
        _store.store == value
      )
    })

    if (filterStore === undefined) {
      let newStore = {
        store: value
      }
      store.push(newStore);
    }

    if (data.product_image[0] != null) {
      const name = data.product_name;
      const date = new Date();
      const hashID = hash({
        name: name,
        date: date,
      });

      const uploadTask = authen
        .storage()
        .ref(`EandE/${hashID}`)
        .put(data.product_image[0]);

      uploadTask.on(
        "state_changed",
        (snapshot) => { },
        (error) => {
          console.log(error);
        },
        () => {
          authen
            .storage()
            .ref("EandE")
            .child(hashID)
            .getDownloadURL()
            .then((url) => {

              data.product_image = url
              data.product_category = curentSelectCategories._id;
              data.product_store = value;
              data.store_list = store;
              dispatch(actions.productEdit(product._id, data));
              dispatch(actions.productsFetch());

              history.push("/management/products");



            })

        });
    } else {

      data.product_category = curentSelectCategories._id;
      data.product_image = product.product_image;
      data.product_store = value;
      data.store_list = store;
      dispatch(actions.productEdit(product._id, data));
      dispatch(actions.productsFetch());

      history.push("/management/products");

    }

  };

  const categoriesList = () => {
    if (Array.isArray(categories)) {
      return categories.map((category) => {
        return (
          <option value={category.category_id}>
            {" "}
            {category.category_name}{" "}
          </option>
        );
      });
    }
  };

  if (allowAccess) {
    return (
      <Container
        style={{
          backgroundColor: "#ffffff",
          boxShadow: "1px 1px 3px #d9d9d9",
          padding: "30px",
          marginTop: "50px",
          maxWidth: "500px",
        }}
        className="light-th "
      >
        <form onSubmit={handleSubmit(onEditProduct)}>
          <Row>
            <Col md="5">
              <h3>เเก้ไข</h3>
            </Col>
            <Col
              md="7"
              style={{ fontSize: "12px" }}
              className="text-right mt-1"
            ></Col>
          </Row>

          <hr />
          <br />
          <InputGroup size="lg">
            <Input
              style={{ fontSize: "15px" }}
              type="text"
              placeholder="ชื่ออุปกรณ์"
              name="product_name"
              innerRef={register}
            />
          </InputGroup>
          <br />
          <InputGroup size="lg">
            <Input
              style={{ fontSize: "15px" }}
              type="textarea"
              placeholder="รายละเอียด"
              name="product_detail"
              innerRef={register}
            />
          </InputGroup>
          <br />
          <InputGroup size="md">
            <Input
              type="text"
              placeholder="หน่วยของอุปกรณ์"
              name="product_unit"
              innerRef={register}>
            </Input>
          </InputGroup>
          <br />
          <InputGroup size="md">
            <Input
              type="number"
              placeholder="ราคาซื้อ(ต่อหน่วย)"
              name="product_buy"
              innerRef={register}>
            </Input>
          </InputGroup>
          <br />
          <InputGroup size="md">
            <Input
              type="number"
              placeholder="ราคาขาย(ต่อหน่วย)"
              name="product_price"
              innerRef={register}>
            </Input>
          </InputGroup>
          {/* <div className="mt-1 ml-2 text-secondary">
            <Label>ถ้าเป็นอุปกรณ์ใส่ราคาเท่ากับ 0</Label>
          </div> */}
          <br />
          <InputGroup size="md">
            <Input
              type="text"
              placeholder="ยี่ห้อ"
              name="product_brand"
              innerRef={register}>
            </Input>
          </InputGroup>
          <br />
          {/* <InputGroup size="md">
            <Input
              type="text"
              placeholder="แหล่งจัดซื้อ (ร้านค้า)"
              name="product_store"
              innerRef={register}>
            </Input>
          </InputGroup> */}
          <Autocomplete
            value={value}
            onChange={(event, newValue) => {
              if (typeof newValue === 'string') {
                setValue(
                  newValue,
                );
              } else if (newValue && newValue.inputValue) {
                // Create a new value from the user input
                setValue(
                  newValue.inputValue,
                );
              } else {
                setValue(newValue.store);
              }
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              // Suggest the creation of a new value
              if (params.inputValue !== '') {
                filtered.push({
                  inputValue: params.inputValue,
                  store: `เพิ่ม "${params.inputValue}"`,
                });
              }

              return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            options={store}
            getOptionLabel={(option) => {
              // Value selected with enter, right from the input
              if (typeof option === 'string') {
                return option;
              }
              // Add "xxx" option created dynamically
              if (option.inputValue) {
                return option.inputValue;
              }
              // Regular option 
              return option.store;
            }}
            renderOption={(option) => option.store}

            freeSolo
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label="แหล่งจัดซื้อ (ร้านค้า)" name="product_store" defaultValue={product.product_store} innerRef={register} />
            )}
          />
          <br />
          <InputGroup>
            <Label>หมวดหมู่</Label>
            <Col sm={11}>
              <Input
                type="select"
                name="category_id"

                innerRef={register({

                })}
              >
                {categoriesList()}
              </Input>
            </Col>

          </InputGroup>

          <br />
          <InputGroup size="md">
            <Label>แก้ไขรูปภาพอุปกรณ์</Label>
            <Col sm={11}>
              <Input
                type="file"
                name="product_image"
                accept="image/*"
                innerRef={register}>
              </Input>
            </Col>

          </InputGroup>
          <div className="mt-2 text-secondary">
            <Label>ถ้าต้องการใช้รูปภาพเดิม ไม่ต้องอัพโหลดรูปภาพใหม่</Label>
          </div>


          <Button
            className="mt-4 btn-warning"
            style={{ border: "none" }}
            size="lg"
            block
            type="submit"
          >
            <span className="light-th" style={{ fontSize: "15px" }}>
              เเก้ไข
          </span>
          </Button>
          <Button
            className="mt-2 "
            style={{ border: "none" }}
            size="lg"
            block
            onClick={() => {

              dispatch(actions.productsFetch());
              history.push("/management/products");
            }}
          >
            <span className="light-th" style={{ fontSize: "15px" }}>
              ยกเลิก
          </span>
          </Button>
        </form>
      </Container>
    );
  } else {
    return (
      <>
      </>
    )
  }
}
