import React, { useState, useEffect } from "react";
import { Button, Container, Table, Input, Alert, Row, Col, Label, Card } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import * as role from "../../util/UserRole";
import { useHistory, useParams } from "react-router-dom";
import * as actions from '../../actions'
import _ from "lodash";
import ModalAddOT from '../../components/modal/ModalAddOT';
import { RemoveIcon, AddIcon, TrashIcon, TextInput, Strong } from 'evergreen-ui'

// ดูรายละเอียดข้อมูลการซ่อม ประเมินราคาเบื้องต้น

const EditWorkingConditions = ({ reload, setReload,ot,setOt}) => {
  const orders = useSelector((state) => state.orders);
  const user = useSelector((state) => state.user);
  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams();
  

  const [width, setWidth] = React.useState(window.innerWidth);
  const breakpoint = 620;



  const [modalAddOT, setModalAddOT] = useState(false); // props สำหรับเปิดปิด modal
  const toggleModalAddOT = () => {
    setModalAddOT(!modalAddOT); // method สำหรับ set เปิดหรือปิด modal
    setReload(!reload);
  }

  const allowAccess = user
    ? user.role == role.MECHANIC_ROLE ||
      user.role == role.ADMIN_ROLE ||
      user.role == role.SUPER_USER_ROLE
      ? true
      : false
    : history.push('/login');

  const filterOrder = _.find(orders, (_order) => {
    return (
      _order._id == params.id
    )
  })

  useEffect(() => {
    dispatch(actions.productsFetch());
    window.addEventListener('resize', () => setWidth(window.innerWidth));

    return () => {
    }
  }, []);

  useEffect(() => {
    // console.log("Use Effect:",reload);
    window.addEventListener('resize', () => setWidth(window.innerWidth));
    return () => { }
  }, [reload])

  const showOt = (ot) => {
    if (ot.length != 0) {
      return (
        <>
          <br />
          <Table striped bordered hover responsive style={{ fontSize: "18px" }} className="text-center">
            <thead className="light-th" >
              <tr>
                <th>#</th>
                <th>ชื่อ</th>
                <th>จำนวน</th>
                <th>ราคาต่อหน่วย</th>
                <th>รวม</th>
                <th>ดำเนินการ</th>
              </tr>
            </thead>
            <tbody>
              {ot.map((_ot, index) => {
                return (
                  <>
                    <tr>
                      <th >{index + 1}</th>
                      <td>{_ot.ot_name}</td>
                      <td>{_ot.ot_inventory}</td>
                      <td>{_ot.ot_price}</td>
                      <td>{_ot.ot_inventory * _ot.ot_price}</td>
                      <td><TrashIcon
                        className=" text-right"
                        color="danger"
                        onClick={() => {
                          let confirmRemove = window.confirm("ยืนยันการลบ")
                          if (confirmRemove) {
                            ot.splice(ot.indexOf(_ot), 1); //ลบออก
                            setReload(!reload);
                          }
                        }}
                        size={30} /></td>
                    </tr>
                  </>
                );
              })} </tbody>
          </Table>
        </>
      );
    }
  }

  const skipAddOT = (ot) => {
    if (ot.length === 0) {
      return (
        <>
          <div className="text-center">
            <br />
            <Label
              className="text-danger"
              style={{ fontSize: "30px" }}
            >ยังไม่มีรายการค่าสภาพการทำงาน</Label>
          </div>
        </>
      );
    }
  }

  
  if (allowAccess)
    return (
      <>
        <ModalAddOT
          modal={modalAddOT}
          toggle={toggleModalAddOT}
          ot={ot}
          setOt={setOt}
        />
        <Label
          className="ml-2"
          style={{ fontSize: "25px" }}
        >ค่าสภาพการทำงาน</Label><Button
          className="ml-2"
          color="success"
          onClick={() => {
            toggleModalAddOT(); // เปิด modal สำหรับเพิ่มค่าสภาพการทำงาน
          }}
        >เพิ่ม</Button>
        <br />
        <h4 className="ml-2">{skipAddOT(ot)}</h4>
        <h4 className="ml-2">{showOt(ot)}</h4>

      </>
    )

}
export default EditWorkingConditions;