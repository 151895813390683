import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import * as actions from "../../actions";
import { useForm } from "react-hook-form";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  InputGroup,
  Input,
  ModalFooter,
  Button,
} from "reactstrap";
import _ from "lodash";

const ModalEditCategoryName = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { className, modal, toggle, repairtype } = props;
  const [unmountOnClose, setUnmountOnClose] = useState(true);
  const { register, handleSubmit, err } = useForm();


  const onSubmitNewName = (data) => {

    const replacedCategory = {
      _id: repairtype._id,
      repairtype_id: repairtype.repairtype_id,
      repairtype_name: data.repairtype_name,
    };
    dispatch(actions.editRepairtypeName(replacedCategory));
    dispatch(actions.repairtypesFetch());
    toggle();
    // window.location.reload();
  };

  return (
    <div>
      <Modal
        isOpen={modal}
        toggle={toggle}
        className={className}
      >
        <ModalHeader toggle={toggle}>
          เปลี่ยนชื่อ : {repairtype.repairtype_name}{" "}
        </ModalHeader>
        <ModalBody>
          <InputGroup size="lg">
            <Input
              type="text"
              style={{ fontSize: "15px" }}
              placeholder="ชื่อประเภทงานซ่อมใหม่"
              name="repairtype_name"
              defaultValue={repairtype.repairtype_name}
              innerRef={register}
            />
          </InputGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="warning" onClick={handleSubmit(onSubmitNewName)}>
            แก้ไข
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            ยกเลิก
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ModalEditCategoryName;
