import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../actions";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

const EquipmentLists = (props) => {
  const {categories, products, setProduct} = props;
  const dispatch = useDispatch();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const categoriesLists = () => {
    return (
      <DropdownMenu>
        <DropdownItem onClick={() => {
          setProduct(products)
        }}>
          อุปกรณ์ทั้งหมด
        </DropdownItem>
      <DropdownItem divider />
      {categories  &&
        categories.map((category) => {
          return(
            <DropdownItem
              key={category.category_id}
              onClick={() => getProductListOfCategory(category.category_id)}
            >
              {category.category_name}
            </DropdownItem>
          )
        })
        }
      </DropdownMenu>
    )
  }
  const getProductListOfCategory = (id) => {
    const res = products.filter((_product) => _product.product_category.category_id == id && _product.product_price == "0");

    setProduct(res);
  } 
  return(
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
    <DropdownToggle caret>หมวดหมู่อุปกรณ์</DropdownToggle>
    <DropdownMenu>{categoriesLists}</DropdownMenu>
  </Dropdown>
  )
};
export default EquipmentLists;