import React, { useState, useEffect } from "react";
import { Container, Table, Row, Col, Badge, Button, Card, CardSubtitle, Label } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import * as role from "../../util/UserRole";
import { useHistory, useParams } from "react-router-dom";
import * as actions from '../../actions'
import * as UserStatus from "../../util/UserStatus";
import _ from "lodash";
import { ArrowLeftIcon } from 'evergreen-ui'
import Document from "../exportPDF/document/QuotationDocument";


// รายละเอียดข้อมูลการซ่อม ดูประวัติการซ่อมย้อนหลัง
const RepairDetailMobile = () => {
  const orders = useSelector((state) => state.orders);
  const user = useSelector((state) => state.user);
  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams();
  const [width, setWidth] = React.useState(window.innerWidth);
  const breakpoint = 620;

  const allowAccess = user
    ? user.role == role.MECHANIC_ROLE ||
      user.role == role.CONTRATOR_ROLE ||
      user.role == role.STORE_ROLE ||
      user.role == role.ADMIN_ROLE ||
      user.role == role.SUPER_USER_ROLE
      ? true
      : false
    : history.push('/login');

  const filterOrder = _.find(orders, (_order) => {
    return (
      _order._id == params.id
    )
  })

  useEffect(() => {
    // dispatch(actions.ordersFetch(user));
    window.addEventListener('resize', () => setWidth(window.innerWidth));
    return () => { }
  }, [])

  const showByMechanic = () => {
    if (filterOrder.byMechanic !== role.USER_ROLE) {
      return (
        <>
          <Row>
            <h5 className="ml-3"><strong>{filterOrder.user_name}</strong></h5>
            <Col ><Badge color="info" pill>แจ้งซ่อมผ่าน {filterOrder.nameByMechanic}</Badge></Col>

          </Row>
        </>
      )
    } else {
      return (
        <h5><strong>{filterOrder.user_name}</strong></h5>
      )
    }

  }

  const showByMechanicMoblie = () => {
    if (filterOrder.byMechanic !== role.USER_ROLE) {
      return (
        <>
          <p className="ml-1"><Badge color="info" pill>แจ้งซ่อมผ่าน {filterOrder.nameByMechanic}</Badge></p>

        </>
      )
    }
  }

  const colorSpeed = () => {
    if (filterOrder.speed === "ปกติ") {
      return <strong className="text-success">{filterOrder.speed}</strong>
    } else {
      return <strong className="text-danger">{filterOrder.speed}</strong>
    }
  }

  const showImage = (image) => {
    if (image != 0) {
      return (

        <div >
          <p><strong>รูปภาพ</strong></p>
          <div className="text-center">
            {image.map(_image => (
              <img src={_image} width="70%" className="mb-4" />
            ))}
          </div>
        </div>);
    }
  }

  const showDocument = () => {
    if (filterOrder.statusMechanic === UserStatus.MECHANIC_DELIVERWORK && filterOrder.statusUser === UserStatus.USER_FINISHED) {
      return <Document />
    }
  }

  if (allowAccess) {
    return (
      //moblie
      <>
        <Container fluid className="light-th"
          style={{
            marginTop: "50px",
            maxWidth: "900px",
          }}
        >
          <Row>
            <Col xs="3">
              <Button
                size="sm"
                outline
                color="secondary"
                onClick={
                  history.goBack
                }
              >ย้อนกลับ</Button>
            </Col>
            <Col xs="9"><h5 className="text-left">รายละเอียดการแจ้งซ่อม</h5>

            </Col>
          </Row>

          <br />
          <div
            style={{
              backgroundColor: "#ffffff",
              boxShadow: "1px 1px 3px #d9d9d9",
              padding: "30px",
            }}>

            <br />
            <h5 className="mb-4"><strong>ข้อมูลผู้แจ้ง</strong></h5>
            <div className="text-center mr-4 mb-4">
              <img
                style={{ width: "20%" }}
                src={filterOrder.user_image}
              />
            </div>
            <Row className="ml-1"><strong>ชื่อผู้แจ้ง</strong> <h6 className="ml-2"> <strong>{filterOrder.user_name}</strong></h6>{showByMechanicMoblie()}</Row>

            <Row className="ml-1"><strong>หน่วยงาน</strong> <h6 className="ml-2"> {filterOrder.user_agency}</h6></Row>
            <br />
            <Row className="ml-1"><strong>วันที่แจ้งซ่อม</strong> <h6 className="ml-2 mr-2"> {new Date(filterOrder.orderDate).toLocaleDateString("th")}</h6> เวลา <h6 className="ml-2">{new Date(filterOrder.orderDate).toLocaleTimeString("th", { hour: '2-digit', minute: '2-digit' })} น.</h6></Row>
            <br />

            <Row className="ml-1"><strong>เบอร์โทรศัพท์</strong> <h6 className="ml-2"> {filterOrder.user_tel}</h6></Row>
            <br />

            <Row className="ml-1"><strong>แฟกซ์</strong> <h6 className="ml-2"> {filterOrder.user_fax}</h6></Row>
            <br />

            <Row className="ml-1"><strong>อีเมล</strong> <h6 className="ml-2"> {filterOrder.user_email}</h6></Row>
            <br />

            <Row className="ml-1"><strong>สถานที่</strong> <h6 className="ml-2"> {filterOrder.place}</h6></Row>




            <hr />
            <h5 className="mt-4 mb-4"><strong>ข้อมูลการแจ้งซ่อม</strong></h5>
            <Row className="ml-1"><strong>ประเภทงานซ่อม</strong> <h6 > {filterOrder.type}</h6></Row>
            <br />
            <Row className="ml-1"><strong>ปัญหา/งานซ่อม</strong><h6> <strong className="text-primary ml-2">{filterOrder.problem}</strong></h6></Row>
            <br />
            <Row className="ml-1"><strong>ความเร่งด่วน</strong> <h6 className="ml-2"> {colorSpeed()}</h6></Row>
            <br />
            <Row className="ml-1">
              <strong>รายละเอียด</strong>
              <Col><Card style={{ border: "none", boxShadow: "none" }}><h6>{filterOrder.detail}</h6></Card></Col>
            </Row>

            {showImage(filterOrder.imageFormUser)}
            <br />


          </div>
        </Container>

        {/* {showDocument()} */}

      </>
    )
  } else {
    return (
      <>
      </>
    )
  }
}
export default RepairDetailMobile;