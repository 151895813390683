import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Container,
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  Table,
  Button,
  Input,
} from "reactstrap";
import * as role from "../../util/UserRole";
import * as actions from "../../actions";
import { useHistory } from "react-router-dom"
import ModalCreateRepairtype from "../../components/modal/ModalCreateRepairtype";
import ModalEditRepairtypeName from "../../components/modal/ModalEditRepairtypeName";
import ModalCreateProblem from "../../components/modal/ModalCreateProblem";
import ModalEditProblem from "../../components/modal/ModalEditProblem";
import _ from "lodash";
import { useForm } from "react-hook-form";

const ManagementRepairtype = () => {

  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const repairtype = useSelector((state) => state.repairtype);
  const [problem, setProblem] = useState([]);
  const [index, setIndex] = useState([]);
  const [reload, setReload] = useState(true);
  const [edit, setEdit] = useState(false);
  const { register, handleSubmit, err } = useForm();



  // Access Control
  const allowAccess = user
    ? user.role == role.ADMIN_ROLE ||
      user.role == role.SUPER_USER_ROLE
      ? true
      : false
    : history.push("/login");

  // Inside State Setting
  const [selectedRepairtype, setSelectedRepairtype] = useState("");
  const [modalCreateRepairtype, setModalCreateRepairtype] = useState(false);
  const [modalEditRepairtypeName, setModalEditRepairtypeName] = useState(false);
  const [modalEditProblemName, setModalEditProblemName] = useState(false);
  const [modalCreateProblem, setModalCreateProblem] = useState(false);

  const filterSelect = _.find(repairtype, (_type) => {
    return (
      _type._id == selectedRepairtype._id
    )
  })

  const updateSelect = () => {
    setReload(!reload);
    setSelectedRepairtype(filterSelect);
  }
  // Modal Setting
  const toggleModalCreateRepairtype = () => {
    setModalCreateRepairtype(!modalCreateRepairtype);

  };
  const toggleModalEditRepairtypeName = () => {
    // selectNewRepairtype(selectedRepairtype);

    setModalEditRepairtypeName(!modalEditRepairtypeName);

  };
  const toggleModalEditProblemName = (problem, index) => {
    setProblem(problem);
    setIndex(index);
    setSelectedRepairtype(selectedRepairtype);
    resetSelectProblem(problem);
    setModalEditProblemName(!modalEditProblemName);

    updateSelect();

  };
  const toggleModalCreateProblem = () => {
    setModalCreateProblem(!modalCreateProblem);
    updateSelect();

  };

  useEffect(() => {
    dispatch(actions.repairtypesFetch());
    return () => { }
  }, []);

  useEffect(() => {
    return () => { }
  }, [reload])

  const selectNewProblem = (value) => {

    if (problem === null || problem === []) {
      setProblem(value);
    }
  }

  const resetSelectProblem = (problem) => {
    if (problem != null || problem != []) {
      setProblem(problem);
    } else {
      setProblem(selectedRepairtype.repairtype_problem[index])
    }
  }
  const selectNewRepairtype = (value) => {

    if (value != null) {
      setSelectedRepairtype({
        repairtype_name: value.repairtype_name,
        repairtype_id: value.repairtype_id,
        _id: value._id,
      });
    } else {
      setSelectedRepairtype(repairtype);
    }

  };

  const repairtypeList = () => {
    if (Array.isArray(repairtype)) {
      return repairtype.map((type) => {
        return (
          <ListGroupItem
            tag="a"
            href="#"
            action
            active={selectedRepairtype._id == type._id}
            onClick={() => setSelectedRepairtype(type)}
          >
            {type.repairtype_name}
          </ListGroupItem>
        );
      });
    } else {
      console.log("Repairtype is not Array");
    }
  };



  const deleteRepairtype = (Id) => {
    console.log(Id);
    let confim = window.confirm("ยืนยันที่จะลบประเภทงานซ่อมนี้หรือไม่");
    if (confim) {
      dispatch(actions.repairtypeDelete(Id));
    } else {
      console.log("ยกเลิกการลบ");
    }
    window.location.reload();
  };

  const deleteProblem = (index) => {

    let repairtype_problem = selectedRepairtype.repairtype_problem;
    let confim = window.confirm("ยืนยันที่จะลบปัญหานี้หรือไม่");
    if (confim) {
      repairtype_problem.splice(index, 1);
      const repairtype_data = {
        repairtype_problem: repairtype_problem,
      }
      dispatch(actions.editRepairtypeProblemName(selectedRepairtype._id, repairtype_data));
      dispatch(actions.repairtypesFetch());
    } else {
      console.log("ยกเลิกการลบ");
    }
    // window.location.reload();
  };
  const selectEdit = (index) => {
    setEdit(!edit);
    setIndex(index);
  }
  const editProblem = () => {
    if (edit === true) {
      return problemEdit();
    } else {
      return problemList();
    }
  }

  const problemEdit = () => {
    let repairtype_problem = selectedRepairtype.repairtype_problem;
    if (Array.isArray(repairtype_problem)) {
      return repairtype_problem.map((problem, index) => {
        return (
          <tr>
            <th> {index + 1} </th>
            <td>
              <Input
                type="text"
                style={{ fontSize: "15px" }}
                placeholder="ชื่อปัญหาใหม่"
                name="repairtype_problem"
                defaultValue={problem.repairtype_problem}
                innerRef={register}
              />
            </td>
            <td>
              <Button
                size="sm"
                className="m-1"
                color="primary"
                onClick={() =>
                  // toggleModalEditProblemName(repairtype_problem[repairtype_problem.indexOf(problem)], repairtype_problem.indexOf(problem))
                  setEdit(!edit)

                }
              >

                บันทึก
              </Button>
             
            </td>
          </tr>
        );
      });
    }
  };

  const problemList = () => {
    let repairtype_problem = selectedRepairtype.repairtype_problem;
    if (Array.isArray(repairtype_problem)) {
      return repairtype_problem.map((problem, index) => {
        return (
          <tr>
            <th> {index + 1} </th>
            <td> {problem.repairtype_problem} </td>
            <td>
              <Button
                size="sm"
                className="m-1"
                color="warning"
                onClick={() =>
                  toggleModalEditProblemName(repairtype_problem[repairtype_problem.indexOf(problem)], repairtype_problem.indexOf(problem))
                  // selectEdit(repairtype_problem.indexOf(problem))                
                }
              >

                แก้ไข
              </Button>
              <Button
                size="sm"
                color="danger"
                onClick={() =>
                  deleteProblem(repairtype_problem.indexOf(problem))
                }
              >
                ลบ
                        </Button>
            </td>
          </tr>
        );
      });
    }
  };

  return (
    <Container
      fluid
      className="light-th">
      <h2 className='text-center'> จัดการประเภทงานซ่อม </h2>
      <div>
        <ModalCreateRepairtype
          modal={modalCreateRepairtype}
          toggle={toggleModalCreateRepairtype}
        />

        <ModalEditRepairtypeName
          modal={modalEditRepairtypeName}
          toggle={toggleModalEditRepairtypeName}
          repairtype={selectedRepairtype}
        />

        <ModalCreateProblem
          modal={modalCreateProblem}
          toggle={toggleModalCreateProblem}
          repairtype={selectedRepairtype}
        />

        <ModalEditProblem
          modal={modalEditProblemName}
          toggle={toggleModalEditProblemName}
          repairtype={selectedRepairtype}
          problem={problem}
          index={index}
        />
        {allowAccess ? (
          <>

            <Row>
              <Col className="text-right">
                <Button color="success"
                  onClick={toggleModalCreateRepairtype}
                >
                  เพิ่มประเภทงานซ่อม
                </Button>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col sm="4" lg="4" className="text-left mb-4">
                <h3>รายชื่อประเภทงานซ่อม </h3>
                <ListGroup>{repairtypeList()}</ListGroup>
              </Col>
              <Col sm="8" lg="8" className="text-left">
                {(selectedRepairtype == "" || selectedRepairtype._id == null) ? (
                  <div >
                    <br />
                    <br />
                    <h4 > กรุณาเลือกประเภทงานซ่อมที่ต้องการจะแก้ไข</h4>
                  </div>
                ) : (
                    <>
                      <Row>
                        <Col className="text-left mb-2">
                          <h3> {selectedRepairtype.repairtype_name} </h3>
                          <Button
                            className="m-1"
                            size="sm"
                            color="warning"
                            onClick={() =>
                              toggleModalEditRepairtypeName()}
                          >

                            เปลี่ยนชื่อ
                         </Button>
                          <Button
                            color="danger"
                            size="sm"
                            onClick={() =>
                              deleteRepairtype(selectedRepairtype._id)
                            }
                          >
                            ลบ
                        </Button>
                        </Col>

                        <Col className="text-right " sm="3">
                          {" "}
                          <Button
                            className="m-1"
                            color="success"
                            onClick={() =>
                              toggleModalCreateProblem()}
                          >

                            เพิ่มปัญหา
                      </Button>

                        </Col>
                      </Row>

                      <Table>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>ปัญหา</th>
                            <th>ดำเนินการ</th>
                          </tr>
                        </thead>
                        <tbody>{problemList()}</tbody>
                      </Table>
                    </>
                  )}
              </Col>

            </Row>
          </>
        ) : (
            <>
              <h1 className="text-danger"> ขออภัย </h1>
              <h3>
                คุณไม่มีสิทธิในการเข้าถึง <br />
              กรุณาลงทะเบียนด้วย User ที่ได้รับสิทธิเป็นผู้ดูแลระบบ
            </h3>
            </>
          )}
      </div>
    </Container>
  );
};

export default ManagementRepairtype;
