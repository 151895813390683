import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Container,
  Row,
  Col,
  Input,
  Label,
  Button,
  InputGroup,
  Alert,
  DropdownMenu,
  Dropdown,
  DropdownItem,
  DropdownToggle,
} from 'reactstrap'
import { useForm } from 'react-hook-form'
import * as actions from '../../actions'
import { authen } from "../../assets/api/firebase";
import hash from "object-hash";
import { useHistory } from "react-router-dom";
import * as role from "../../util/UserRole";
import SearchCustomer from '../../components/Search/SearchCustomer'
import ModalCreateUser from "../../components/modal/ModalCreateUser";
import ModalSelectUser from "../../components/modal/ModalSelectUser";
import _ from "lodash";

export default function RepairNotificationFromMechanic() {
  const { register, handleSubmit } = useForm()
  const user = useSelector((state) => state.user)
  const dispatch = useDispatch()
  const history = useHistory();
  const [imageLenght, setImageLenght] = useState([]);
  const repairtype = useSelector((state) => state.repairtype);
  const allUser = useSelector((state) => state.userManagement);
  const [userData, setUserData] = useState([]);
  const [selectUser, setSelectUser] = useState([]);
  const [newUser, setNewUser] = useState([]);
  const [editUser, setEditUser] = useState([]);
  const [saveSelectUser, setSaveSelectUser] = useState([]);
  const [selectRepairtype, setSelectRepairtype] = useState([]);
  const day = new Date();
  const timelogs = day.getDate().toString() + "/" + day.getMonth().toString() + "/" + parseInt(day.getFullYear() + 543) + " " + day.getHours().toString() + ":" + day.getMinutes().toString() + "น.";

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const [modalCreateUser, setModalCreateUser] = useState(false);
  const toggleModalCreateUser = () => {
    setModalCreateUser(!modalCreateUser);

  };

  const [modalSelectUser, setModalSelectUser] = useState(false); // props สำหรับเปิดปิด modal
  const toggleModalSelectUser = () => {
    setModalSelectUser(!modalSelectUser); // method สำหรับ set เปิดหรือปิด modal

  }

  const allowAccess = user
    ? user.role == role.USER_ROLE ||
      user.role == role.CONTRATOR_ROLE ||
      user.role == role.MECHANIC_ROLE ||
      user.role == role.ADMIN_ROLE ||
      user.role == role.SUPER_USER_ROLE
      ? true
      : false
    : history.push('/login');

  useEffect(() => {
    dispatch(actions.repairtypesFetch());
    dispatch(actions.fetchAllUser());
    return () => { }
  }, []);

  useEffect(() => {
    setUserData(allUser)
    return () => { };
  }, [allUser]);


  const repairtypeList = () => {
    if (Array.isArray(repairtype)) {
      return repairtype.map((type) => {
        return (
          <option value={type.repairtype_name}>
            {" "}
            {type.repairtype_name}{" "}
          </option>
        );
      });
    }
  };

  const problemList = () => {
    if (repairtype.length != 0) {

      if (selectRepairtype.length !== 0) {
        const filterType = _.find(repairtype, (type) => {
          return (
            type.repairtype_name === selectRepairtype
          )
        })

        let problem = filterType.repairtype_problem;
        if (Array.isArray(problem)) {
          return problem.map((problem) => {
            return (
              <option value={problem.repairtype_problem}>
                {" "}
                {problem.repairtype_problem}{" "}
              </option>
            );
          });
        }
      } else {
        setSelectRepairtype(repairtype[0].repairtype_name);
      }
    }
  };
  const repairNotification = (data) => {

    if (selectUser.length === 0) {
      alert("กรุณาเลือกรายชื่อลูกค้า")

    } else {

      let confirm = window.confirm("ยืนยันการแจ้งซ่อม")

      const typeId = _.find(repairtype, (_type) => {
        return (
          _type.repairtype_name == data.type
        )
      })

      const problemId = _.find(typeId.repairtype_problem, (_repairtype) => {
        return (
          _repairtype.repairtype_problem == data.problem
        )
      })
      data.typeId = typeId._id;
      data.problemId = problemId._id;


      if (confirm) {
        //ถ้าใส่รูป
        let imageSuccessCount = 0
        if (data.image[0] != null) {
          for (let i = 0; i < data.image.length; i++) {

            const name = data.image[i].name;
            const date = new Date();
            const pictureName = hash({
              name: name,
              date: date,
            });

            const uploadTask = authen
              .storage()
              .ref(`EandE/${pictureName}`)
              .put(data.image[i]);
            uploadTask.on(
              "state_changed",
              (snapshot) => { },
              (error) => {
                console.log(error);
              },
              () => {
                authen
                  .storage()
                  .ref("EandE")
                  .child(pictureName)
                  .getDownloadURL()
                  .then((url) => {
                    imageLenght[i] = url;
                    imageSuccessCount++
                    if (imageSuccessCount === data.image.length) {
                      data.imageFormUser = imageLenght;
                      data.user_image = selectUser.user_image;
                      data.user_name = selectUser.user_title + selectUser.user_firstname + " " + selectUser.user_lastname;
                      data.user_agency = selectUser.user_agency;
                      data.user_tel = selectUser.user_tel;
                      data.user_fax = selectUser.user_fax;
                      data.user_email = selectUser.user_email;
                      data.place = selectUser.user_address;
                      data.paymentTerms = selectUser.paymentTerms;
                      data.credit = selectUser.credit;
                      data.user_id = selectUser.id;
                      data.user_token = selectUser.accessToken;
                      data.mechanic_token = user.accessToken;
                      data.byMechanic = user.role;
                      data.nameByMechanic = user.user_firstname + " " + user.user_lastname;
                      data.quotationId = "-";
                      const token = "REPAIR NOTIFICATION"
                      const dataNotification = timelogs + "ได้รับงานแจ้งซ่อมเข้ามาใหม่ผ่าน " + user.user_firstname + " " + user.user_lastname
                      dispatch(actions.notifyMultiSend(dataNotification, token, allUser))
                      dispatch(actions.createOrder(data));
                      dispatch(actions.ordersFetch(user));
                      history.push("/management/job");
                    }

                  });
              }
            );

          }
        } else {
          data.user_image = selectUser.user_image;
          data.user_name = selectUser.user_title + selectUser.user_firstname + " " + selectUser.user_lastname;
          data.user_agency = selectUser.user_agency;
          data.user_tel = selectUser.user_tel;
          data.user_fax = selectUser.user_fax;
          data.user_email = selectUser.user_email;
          data.place = selectUser.user_address;
          data.paymentTerms = selectUser.paymentTerms;
          data.credit = selectUser.credit;
          data.user_id = selectUser.id;
          data.user_token = selectUser.accessToken;
          data.mechanic_token = user.accessToken;
          data.byMechanic = user.role;
          data.nameByMechanic = user.user_firstname + " " + user.user_lastname;
          data.quotationId = "-";



          const token = "REPAIR NOTIFICATION"
          const dataNotification = timelogs + "มีงานแจ้งซ่อมเข้ามาใหม่ผ่าน " + user.user_firstname + " " + user.user_lastname

          dispatch(actions.notifyMultiSend(dataNotification, token, allUser))
          dispatch(actions.createOrder(data))
          dispatch(actions.ordersFetch(user));
          history.push("/management/job");
        }
      }
    }
  }

  const onchangeDataUser = (data) => {
    dispatch(actions.updateUser(editUser.id, data));


    setSaveSelectUser(selectUser.id);
    setEditUser([]);
    setSelectUser([]);

  }

  const showUser = (user, select) => {

    if (newUser.length != 0 && allUser.length === userData.length + 1) {
      const filterUser = _.find(allUser, (_user) => {
        return (
          _user.id === newUser
        )
      })
      setSelectUser(filterUser);
      setNewUser([]);
    }

    if (select.length != 0 && editUser.length === 0) {
      return (
        <>
          <div>
            <Alert style={{ backgroundColor: "#FFFFFF", borderColor: "#FFFFFF", color: "#000000" }}>
              <Row>
                <Col>
                  <h6>ชื่อลูกค้า : {select.user_title + select.user_firstname + " " + select.user_lastname}</h6>
                  <h6>หน่วยงาน : {select.user_agency}</h6>
                  <h6>เบอร์โทรศัพท์ :  {select.user_tel}</h6>
                  <h6>แฟกซ์ : {select.user_fax}</h6>
                  <h6>อีเมล : {select.user_email}</h6>
                  <h6>สถานที่ : {select.user_address}</h6>
                  <h6>กำหนดยืนราคา (วัน) : {select.paymentTerms}</h6>
                  <h6>เครดิต : {select.credit}</h6>

                </Col>

                <Col sm="4" className="text-right">
                  <Button
                    outline
                    size="sm"
                    color="success"
                    onClick={() => {
                      setEditUser(selectUser)
                    }}
                  >แก้ไข</Button>{" "}
                  <Button
                    outline
                    size="sm"
                    color="danger"
                    onClick={() => {
                      setSelectUser([])
                    }}
                  >ลบ</Button>

                </Col>
              </Row>
            </Alert>

          </div>
        </>
      )
    } else if (editUser.length != 0) {
      return (
        <>
          <div>
            <form onSubmit={handleSubmit(onchangeDataUser)}>
              <Alert style={{ backgroundColor: "#FFFFFF", borderColor: "#FFFFFF", color: "#000000" }}>
                <Row>
                  <Col>
                    <InputGroup>
                      <Input
                        size="sm"
                        style={{ fontSize: "15px" }}
                        type="select"
                        placeholder="คำนำหน้าชื่อ"
                        defaultValue={select.user_title}
                        name="user_title"
                        innerRef={register}
                      >
                        <option>นาย</option>
                        <option>นาง</option>
                        <option>นางสาว</option>
                      </Input>
                      <Input size="sm"
                        placeholder="ชื่อ"
                        defaultValue={select.user_firstname}
                        name="user_firstname"
                        innerRef={register}
                      />
                      <Input size="sm"
                        placeholder="นามสกุล"
                        defaultValue={select.user_lastname}
                        name="user_lastname"
                        innerRef={register}
                      />
                    </InputGroup>
                    <InputGroup>
                      <Input size="sm"
                        placeholder="หน่วยงาน"
                        defaultValue={select.user_agency}
                        name="user_agency"
                        innerRef={register}
                      />
                    </InputGroup>
                    <InputGroup>
                      <Input size="sm"
                        placeholder="เบอร์โทรศัพท์"
                        defaultValue={select.user_tel}
                        name="user_tel"
                        innerRef={register}
                      />
                    </InputGroup>
                    <InputGroup>
                      <Input size="sm"
                        placeholder="แฟกซ์"
                        defaultValue={select.user_fax}
                        name="user_fax"
                        innerRef={register}
                      />
                    </InputGroup>
                    <InputGroup>
                      <Input size="sm"
                        placeholder="อีเมล"
                        defaultValue={select.user_email}
                        name="user_email"
                        innerRef={register}
                      />
                    </InputGroup>
                    <InputGroup>
                      <Input size="sm"
                        placeholder="สถานที่"
                        type="textarea"
                        defaultValue={select.user_address}
                        name="user_address"
                        innerRef={register}
                      />
                    </InputGroup>

                    <Label className="mt-1">กำหนดยืนราคา (วัน)</Label>

                    <InputGroup>
                      <Input size="sm"
                        placeholder="กำหนดยืนราคา (วัน)"
                        type="select"
                        defaultValue={select.paymentTerms}
                        name="paymentTerms"
                        innerRef={register}
                      >
                        <option>7</option>
                        <option>15</option>
                        <option>30</option>
                        <option>45</option>
                        <option>60</option>
                      </Input>


                    </InputGroup>

                    <Label className="mt-1">เครดิต</Label>

                    <InputGroup>

                      <Input size="sm"
                        placeholder="เครดิต"
                        type="select"
                        defaultValue={select.credit}
                        name="credit"
                        innerRef={register}
                      >
                        <option>เงินสด</option>
                        <option>7 วัน</option>
                        <option>15 วัน</option>
                        <option>30 วัน</option>
                        <option>45 วัน</option>
                        <option>60 วัน</option>
                      </Input>
                    </InputGroup>
                  </Col>

                  <Col sm="4" className="text-right">
                    <Button
                      size="sm"
                      color="primary"
                      type="submit"
                      onClick={handleSubmit(onchangeDataUser)}
                    >บันทึก</Button>


                  </Col>
                </Row>
              </Alert>
            </form>
          </div>
        </>
      )
    }
    else {
      return (
        <>
          <br />
          <h5 className="text-center text-danger">
            กรุณาเลือกรายชื่อลูกค้า
      </h5>
        </>
      )
    }

  }

  const resetSelectUser = () => {
    toggleModalSelectUser();
    setSelectUser([]);
  }
  if (allowAccess) {
    return (
      <Container
        style={{
          maxWidth: "750px",
        }} >
        <ModalCreateUser
          modal={modalCreateUser}
          toggle={toggleModalCreateUser}
          setNewUser={setNewUser}
        />
        <ModalSelectUser
          modal={modalSelectUser}
          toggle={toggleModalSelectUser}
          selectUser={selectUser}
          setSelectUser={setSelectUser}
          userData={userData}
          setUserData={setUserData}
          allUser={allUser}
        />

        <h2 className='text-center light-th'> แจ้งซ่อมออนไลน์ </h2>
        <br />
        <div
          className='p-4'
          style={{
            backgroundColor: '#ffffff',
            boxShadow: '1px 1px 3px #d9d9d9',
          }}
        >
          <form onSubmit={handleSubmit(repairNotification)}>
            <Row className='m-1'>

              <Col >
                <Row className="ml-1">
                  <h4 className='light-th'> ข้อมูลลูกค้า</h4>
                  {" "}
                  <Button
                    className="ml-2"
                    size="sm"
                    color="success"
                    onClick={
                      () => {
                        resetSelectUser()
                      }
                    }
                  >เลือกลูกค้า</Button>

                </Row>
                <br />

                {showUser(userData, selectUser)}

                <br />
                <h4 className='light-th'> ข้อมูลการแจ้งซ่อม</h4>
                <br />
                <Label>โครงการ</Label>
                <Input
                  name='project'
                  type='text'
                  placeholder="ชื่อโครงการ"
                  innerRef={register}
                />
                <br />

                <Label>ประเภทงานซ่อม</Label>
                <br />
                <Input
                  type='select'
                  name='type'
                  innerRef={register}
                  onChange={e => setSelectRepairtype(e.target.value)}>

                  {repairtypeList()}
                </Input>
                <br />
                <Label>ปัญหา/งานซ่อม</Label>
                <Input
                  name='problem'
                  type='select'
                  placeholder="ปัญหา/งานซ่อม"
                  innerRef={register}
                >
                  {problemList()}
                </Input>

                <br />
                <Label>ความเร่งด่วน</Label>

                <Input
                  name='speed'
                  disabled
                  type='text'
                  innerRef={register}
                  value="เร่งด่วน"
                >

                </Input>
                <br />
                <Label>รายละเอียด </Label>
                <Input
                  type='textarea'
                  name='detail'
                  placeholder="รายละเอียด"
                  innerRef={register}
                />
                <br />
                <Label>รูปภาพ </Label>
                <Input
                  type='file'
                  name='image'
                  accept="image/*"
                  multiple
                  innerRef={register}
                />
              </Col>

            </Row>
            <br />
            <Row className='m-1'>
              <Col>
                <Button color='success' block type="submit"> ยืนยัน</Button>
              </Col>
            </Row>
          </form>
        </div>
      </Container>
    )
  } else {
    return (
      <>
      </>
    )
  }
}