import React from "react";
import { Input } from "reactstrap";
export default function SearchProductLog(props) {

  const { productLogs, setLogData } = props;
  const onSeach = (word) => {
    const res = productLogs.filter(
      (logs) =>
      logs.type.includes(word) 
        || logs.transactionDate.includes(word) 
        || logs.handler.includes(word) 
        || logs.quotationId.includes(word) 

       
    ); 
    setLogData(res);
  };
  return (
    <div className="d-flex">
      <Input
        style={{ maxWidth: "400px" }}
        className="ml-1"
        type="text"
        placeholder="ค้นหา"
        onChange={(e) => {
          productLogs&&onSeach(e.target.value)
        }}
      />
    </div>
  );
}
