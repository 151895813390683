import React, { useState, useEffect } from "react";
import { Button, Container, Table, Input, Alert, Row, Col, Label, Card } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import * as role from "../../util/UserRole";
import { useHistory, useParams } from "react-router-dom";
import * as actions from '../../actions'
import _ from "lodash";
import { RemoveIcon, AddIcon, TrashIcon, TextInput, Strong } from 'evergreen-ui'

// ดูรายละเอียดข้อมูลการซ่อม ประเมินราคาเบื้องต้น

const ServiceChargeMobile = ({ selectProduct, selectProductStore, productInventory, productStoreInventory, cost, productCost, productCostInventory, productBorrow, productBorrowInventory, setProductBorrow, setProductBorrowInventory, setProductCost, setProductCostInventory, setCost, setPrice, setVat, setOperatingCost }) => {
  const orders = useSelector((state) => state.orders);
  const user = useSelector((state) => state.user);
  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams();

  const [width, setWidth] = React.useState(window.innerWidth);
  const breakpoint = 620;

  const allowAccess = user
    ? user.role == role.MECHANIC_ROLE ||
      user.role == role.ADMIN_ROLE ||
      user.role == role.SUPER_USER_ROLE
      ? true
      : false
    : history.push('/login');

  const filterOrder = _.find(orders, (_order) => {
    return (
      _order._id == params.id
    )
  })

  useEffect(() => {
    dispatch(actions.productsFetch());
    window.addEventListener('resize', () => setWidth(window.innerWidth));

    return () => {
    }
  }, []);

  const resetProductBorrow = () => {
    if (productBorrow.length != 0) {
      setProductBorrow([]);
      setProductBorrowInventory([]);
      setProductCost([]);
      setProductCostInventory([]);
      setCost([]);
      setPrice([]);
      setVat([]);
      setOperatingCost([]);
    }
  }

  const showListproduct = (productFromStock, productFromStore, productInventoryFromStock, productInventoryFromStore) => {

    if (productFromStock.length === 0 && productFromStore.length === 0) {
      return (
        <>
          <div className="text-center">
            <Label
              className="text-danger"
              style={{ fontSize: "30px" }}
            >กรุณาเลือกวัสดุและอุปกรณ์ก่อน</Label>

          </div>
          <br />
        </>
      );
    } else {

      return (
        <>

          {productFromStock.map((stock) => { //เอาอุปกรณืในร้านค้ากับคลัง มาคิดราคารวมกันที่เดียว
            if (productFromStore.indexOf(stock) === -1) { //ไม่มีเลือกการเลือกอุปกรณ์จากร้านค้า
              productBorrow.push(stock);
              productBorrowInventory.push(productInventoryFromStock[productFromStock.indexOf(stock)])
            } else {       //เลือกอุปกรณ์ทั้งจากร้านค้าและคลัง
              productBorrow.push(stock);
              productBorrowInventory.push(productInventoryFromStock[productFromStock.indexOf(stock)] + productInventoryFromStore[productFromStore.indexOf(stock)])
            }

          })}
          {productFromStore.map((store) => {
            if (productFromStock.indexOf(store) === -1) { //ไม่มีเลือกการเลือกอุปกรณ์จากคลัง
              productBorrow.push(store);
              productBorrowInventory.push(productInventoryFromStore[productFromStore.indexOf(store)])
            }
          })}
          {productBorrow.map((borrow) => {
            if (borrow.product_price != 0) {
              productCost.push(borrow);
              productCostInventory.push(productBorrowInventory[productBorrow.indexOf(borrow)])
              cost.push(0);
            }
          })
          }
          <Table striped bordered hover responsive style={{ fontSize: "18px" }} className="text-center">
            <thead className="light-th" >
              <tr>
                <th>#</th>
                <th>ชื่อวัสดุ</th>
                <th>จำนวน</th>
                <th>ค่าแรง</th>
              </tr>
            </thead>
            <tbody>


              {productCost.map((_product, index) => {
                return (
                  <>
                    <tr>
                      <th >{index + 1}</th>
                      <td>{_product.product_name}</td>
                      <td>{productCostInventory[productCost.indexOf(_product)]}</td>
                      <td>
                        <TextInput
                          name="cost"
                          width={"35%"}
                          className="text-center"
                          type="number"
                          placeholder="0"
                          defaultvalue={cost[productCost.indexOf(_product)]}
                          onChange={event => cost[productCost.indexOf(_product)] = (event.target.value)}
                        />
                      </td>
                    </tr>
                  </>
                );
              })} </tbody>
          </Table>
        </>
      );
    }

  }


  if (allowAccess)
    return (
      <>
        <Label
          className="ml-2"
          style={{ fontSize: "25px" }}
        >ค่าบริการ</Label>
        <h4 className="ml-2">{resetProductBorrow()}</h4>
        <h4 className="ml-2">{showListproduct(selectProduct, selectProductStore, productInventory, productStoreInventory)}</h4>
      </>
    )
}
export default ServiceChargeMobile;