import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../actions";
import { useForm } from "react-hook-form";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  InputGroup,
  Input,
  ModalFooter,
  Button,
  Col,
} from "reactstrap";
import hash from "object-hash";
import { authen } from "../../assets/api/firebase";
import * as UserStatus from "../../util/UserStatus";
import { useHistory, useParams } from "react-router-dom";

const ModalSelectQuotation = (props) => {
  const { modal, toggle, order, } = props;
  const { register, handleSubmit, err } = useForm();
  const dispatch = useDispatch()
  const history = useHistory()
  const user = useSelector((state) => state.user);
  
  return (
    <div>
      <Modal
        isOpen={modal}
        toggle={toggle}
        centered={true}
      >
        <ModalHeader toggle={toggle}>เลือกใบเสนอราคา</ModalHeader>
        <ModalBody className="text-center">

          <Col>
            <Button
              outline
              color="info"
              className="mb-2"
              onClick={() => {
                let comfirm = window.confirm("ยืนยันการเลือกใบเสนอราคา")
                if (comfirm) {
                  const data = {
                    initialQuotation: true,
                  }
                  dispatch(actions.orderPut(order._id, data))
                  dispatch(actions.ordersFetch(user));
                  toggle()
                }

              }}
            >
              ใช้ใบเสนอราคาเบื้องต้น
            </Button>{" "}
          </Col>
          <Col>
            <Button
              outline
              color="info"
              className="mb-2"
              onClick={() => {
                let comfirm = window.confirm("ยืนยันการเลือกใบเสนอราคา")
                if (comfirm) {
                  const data = {
                    initialQuotation: false,

                  }
                  dispatch(actions.orderPut(order._id, data))
                  dispatch(actions.ordersFetch(user));
                  toggle()
                }
              }}
            >
              ใช้ใบเสนอราคา
            </Button>
          </Col>

        </ModalBody>
        <ModalFooter>
          <Button variant="contained" onClick={toggle}>
            ยกเลิก
          </Button>
        </ModalFooter>
      </Modal>

    </div>
  );
};

export default ModalSelectQuotation;
