import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../actions";
import * as role from "../../util/UserRole";
import { Container, Col, Table } from "reactstrap";
import { useHistory } from "react-router-dom";
import TablePagination from "../../components/TablePagination/TablePagination";
import HistoryTables from "../tables/HistoryTables";
import SearchHistory from "../../components/Search/SearchHistory";

export default function ManagementHistory() {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const History = useSelector((state) => state.history);
  const [pageSize, setPageSize] = useState(20)
  const [currentPage, setCurrentPage] = useState(0)
  const [historyData, setHistoryData] = useState([]);

  const allowAccess = user
    ? user.role == role.ADMIN_ROLE || user.role == role.SUPER_USER_ROLE
      ? true
      : false
    : history.push("/login");


  useEffect(() => {
    dispatch(actions.getHistory());
    return () => { };
  }, []);

  useEffect(() => {
    setHistoryData(History)
    return () => { };
  }, [History]);


  if (allowAccess) {
    return (
      <Container fluid className="light-th">
        <h2 className="text-center"> ประวัติการแจ้งซ่อม </h2>
        <Col className="text-left">
          <SearchHistory History={History} setHistoryData={setHistoryData} />
        </Col>
        <hr />
        { historyData.length === 0 ? (
          <>
            <div className="container col-md-8 text-center">
              <br />
              <br />
              <br />
              <h2 className="text-danger">ไม่พบประวัติการแจ้งซ่อม</h2>
            </div>
          </>
        ) : (
          <>
            <div
              style={{
                maxHeight: "100vh",
                overflowY: "auto",
                backgroundColor: "#ffffff",
                boxShadow: "1px 1px 3px #d9d9d9",
              }}>
              <Table striped bordered hover responsive>
                <thead>
                  <tr className="text-center">
                    <th>#</th>
                    <th>วันที่แจ้งซ่อม</th>
                    <th>เลขที่ใบเสนอราคา</th>
                    <th>ข้อมูลลูกค้า</th>
                    <th>สถานะงานซ่อม</th>
                    <th>รายละเอียด</th>

                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(historyData) ? (
                    <>
                      {historyData
                        .slice(
                          currentPage * pageSize,
                          (currentPage + 1) * pageSize
                        )
                        .map((eachHistory, index) => {
                          return (
                            <HistoryTables
                              eachHistory={eachHistory}
                              index={index + 1}
                            />
                          );
                        })}
                    </>
                  ) : (
                    <>
                      <h2>ไม่พบประวัติการแจ้งซ่อม</h2>
                    </>
                  )}
                </tbody>
              </Table>
            </div>
            <div className="d-flex justify-content-center m-4 ">
              <TablePagination
                pagesCount={Math.ceil(historyData.length / pageSize)}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </div>
          </>)}
      </Container>
    );
  } else {
    return (
      <Container fluid className="light-th">
        <div className="container col-md-8 text-center">
          <h1 className="text-danger"> ขออภัย </h1>
          <h3>
            คุณไม่มีสิทธิในการเข้าถึง <br />
              กรุณาลงทะเบียนด้วย User ที่ได้รับสิทธิเป็นผู้ดูแลระบบ
          </h3>
        </div>
      </Container>
    )
  }

}
