import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../actions";
import { useForm } from "react-hook-form";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  InputGroup,
  Label,
  CustomInput,
  Col,
} from "reactstrap";
import hash from "object-hash";
import * as roles from "../../util/UserRole";
import { authen } from "../../assets/api/firebase";
import { useHistory } from "react-router-dom"

const firebase = authen // For more Understandable

const ModalCreateUser = (props) => {
  const { buttonLabel, className, modal, toggle, setReRender, setNewUser } = props;
  const dispatch = useDispatch();
  const { register, handleSubmit, err } = useForm();
  const [unmountOnClose, setUnmountOnClose] = useState(true);
  const history = useHistory();
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const authData = useSelector((state) => state.auth)
  const userCurrent = authData.uid
  const user = useSelector((state) => state.user)

  useEffect(() => {
    dispatch(actions.categoriesFetch());
    return () => { };
  }, []);

  const onCreateUser = (data) => {

    let confirm = window.confirm("ยืนยันการเพิ่มรายชื่อลูกค้า")

    if (!confirm) {
      toggle();
    } else {
      firebase
        .auth()
        .createUserWithEmailAndPassword(data.email, data.password)
        .then(async (res) => {
          const date = new Date();
          const hashID = hash({
            user_firstname: data.firstname,
            user_lastname: data.lastname,
            date: date,
          });

          const user_data = {
            user_title: data.title,
            user_firstname: data.firstname,
            user_lastname: data.lastname,
            user_address: data.address,
            user_tel: data.phoneNumber,
            user_email: data.email,
            user_agency: data.agency,
            user_fax: data.fax,
            id: res.user.uid,
            user_image: res.user.photoURL,
            role: roles.USER_ROLE,
          };

          dispatch(actions.createUserData(user_data, userCurrent));
          dispatch(actions.fetchAllUser());
          setNewUser(res.user.uid);
          dispatch(actions.authCurrent(userCurrent));
          // toggle();
        
          // if(userCurrent != res.user.uid){
          //   dispatch(actions.authCurrent(userCurrent));
          //   toggle();
          // }

        })
        .catch((err) => {
          if (err.code == 'auth/email-already-in-use') {
            alert('อีเมลนี้ถูกใช้เเล้ว')
          } else if (err.code == 'auth/weak-password') {
            alert('กรุณาตั้งรหัสผ่านอย่างน้อย 6 ตัวอักษร')
          }
        })
        history.push("/profile/" +user.uid);
    }

  };


  return (
    <div>
      <Modal
        isOpen={modal}
        toggle={toggle}
        className={className}
        unmountOnClose={unmountOnClose}
      >
        <ModalHeader toggle={toggle}>เพิ่มรายชื่อลูกค้า</ModalHeader>
        <ModalBody>
          <form>
            <InputGroup size="lg" className="mb-4">
              <Input
                style={{ fontSize: "15px" }}
                type="email"
                placeholder="Email"
                name="email"
                innerRef={register}
              />
            </InputGroup>

            <InputGroup size="lg" className="mb-4">
              <Input
                style={{ fontSize: "15px" }}
                type="password"
                placeholder="Password"
                name="password"
                onChange={(event) => setPassword(event.target.value)}
                innerRef={register}
              />
            </InputGroup>

            <InputGroup size="lg" className="mb-4">
              <Input
                style={{ fontSize: "15px" }}
                type="password"
                placeholder="Confirm Password"
                name="confirmPassword"
                onChange={(event) => setConfirmPassword(event.target.value)}
                innerRef={register}
              />
            </InputGroup>
            <div className="text-danger text-center">
              {password == confirmPassword
                ? ''
                : 'กรุณาใส่รหัสผ่านสำหรับการยืนยันให้ตรงกันกับรหัสผ่าน'}
            </div>


            <InputGroup size="lg" className="mt-4 mb-4">
              <Input
                style={{ fontSize: "15px" }}
                type="select"
                placeholder="คำนำหน้าชื่อ"
                name="title"
                innerRef={register}
              >
                <option>นาย</option>
                <option>นาง</option>
                <option>นางสาว</option>
              </Input>
              <Input
                style={{ fontSize: "15px" }}
                type="text"
                placeholder="ชื่อ"
                name="firstname"
                innerRef={register}
              />

              <Input
                style={{ fontSize: "15px" }}
                type="text"
                placeholder="นามสกุล"
                name="lastname"
                innerRef={register}
              />
            </InputGroup>

            <InputGroup size="lg" className="mb-4">
              <Input
                style={{ fontSize: "15px" }}
                type="text"
                placeholder="หน่วยงาน (ถ้ามี)"
                name="agency"
                innerRef={register}
              />
            </InputGroup>
            <InputGroup size="lg" className="mb-4">
              <Input
                style={{ fontSize: "15px" }}
                type="textarea"
                placeholder="สถานที่"
                name="address"
                innerRef={register}
              />
            </InputGroup>

            <InputGroup size="lg" className="mb-4">
              <Input
                style={{ fontSize: "15px" }}
                type="number"
                placeholder="เบอร์โทรศัพท์"
                name="phoneNumber"
                innerRef={register}
              />
            </InputGroup>

            <InputGroup size="lg" className="mb-4">
              <Input
                style={{ fontSize: "15px" }}
                type="number"
                placeholder="แฟกซ์"
                name="fax"
                innerRef={register}
              />
            </InputGroup>

          </form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            disabled={password !== confirmPassword || password == ''}
            onClick={handleSubmit(onCreateUser)}>
            เพิ่ม
          </Button>{" "}
          <Button
            color="secondary"
            onClick={toggle}>
            ยกเลิก
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ModalCreateUser;
