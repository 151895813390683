import axios from "axios";
import {
  REPAIRTYPES_FETCH,
  REPAIRTYPE_CREATE,
  REPAIRTYPE_REMOVE,
  REPAIRTYPE_EDIT
} from "./types";


export const repairtypesFetch = () => {
  return (dispatch) => {
    axios.get(process.env.REACT_APP_API_URL + "/repairtype/all").then((res) => {
      dispatch({ type:  REPAIRTYPES_FETCH, payload: res.data });
    });
  };
};

export const repairtypeFetch = (id) => {
  return (dispatch) => {
    axios.get(process.env.REACT_APP_API_URL + "/repairtype/" + id).then((res) => {
      dispatch({ type: REPAIRTYPES_FETCH, payload: res.data });
    })
  }
}
  
export const createRepairtype = (data) => {
    return (dispatch) => {
      axios
        .post(process.env.REACT_APP_API_URL + "/repairtype", data)
        .then((res) => {
          dispatch({ type:  REPAIRTYPE_CREATE, payload: res.data });
        })
        .catch((err) => {
          console.log("Error Creating Repairtype");
          console.error(err);
        });
    };
  };

  export const editRepairtypeName = (data) => {

    return (dispatch) => {
      axios
        .put(
          process.env.REACT_APP_API_URL + "/repairtype/" + data._id,
          data
        )
        .then((res) => {
          alert("แก้ไขชื่อสำเร็จ");
          dispatch({ type: REPAIRTYPE_CREATE, payload: res.data });
          // window.location.reload();
        })
        .catch((err) => {
          alert("ไม่สามารถเปลี่ยนชื่อประเภทงานซ่อมได้");
          console.log("Cannot Edit Repairtype Name");
          console.error(err);
        });
    };
  };

  export const editRepairtypeProblem = (id,data) => {

    return (dispatch) => {
      axios
        .put(
          process.env.REACT_APP_API_URL + "/repairtype/problem/" + id,
          data
        )
        .then((res) => {
          
          axios.get(process.env.REACT_APP_API_URL + "/repairtype/all").then((res) => {
            dispatch({ type:  REPAIRTYPE_EDIT, payload: res.data });
          });
          
          // window.location.reload();
        })
        .catch((err) => {
          console.log("Cannot Edit Repairtype Problem");
          console.error(err);
        });
    };
  };

  export const editRepairtypeProblemName = (id,data) => {

    return (dispatch) => {
      axios
        .put(
          process.env.REACT_APP_API_URL + "/repairtype/" + id,
          data
        )
        .then((res) => {
          dispatch({ type: REPAIRTYPE_EDIT, payload: res.data });
          // window.location.reload();
        })
        .catch((err) => {
          console.log("Cannot Edit Repairtype Problem");
          console.error(err);
        });
    };
  };

  export const repairtypeDelete = (_id) => {
    console.log("User Request to delete Repairtype| ", _id);
  return (dispatch) => {
    axios
      .delete(process.env.REACT_APP_API_URL + "/repairtype/" + _id)
      .then((res) => {
        axios.get(process.env.REACT_APP_API_URL + "/repairtype/").then((res) => {
          dispatch({ type: REPAIRTYPE_REMOVE, payload: res.data });
          window.location.reload();
        });
      })
      .catch((err) => {
        alert("ลบประเภทงานซ่อมไม่สำเร็จ");
        console.log("Error Remove Repairtype");
        console.error(err);
      });
  };
  };