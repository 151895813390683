import React, { useState, useEffect } from "react";
import { Container, Table, Row, Col, Badge, Button, Card, CardSubtitle, Label } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import * as role from "../../util/UserRole";
import { useHistory, useParams } from "react-router-dom";
import * as actions from '../../actions'
import * as UserStatus from "../../util/UserStatus";
import _ from "lodash";
import { ArrowLeftIcon } from 'evergreen-ui'
import RepairDetailDesktop from "./RepairDetailDesktop";
import RepairDetailMobile from "./RepairDetailMobile";

// รายละเอียดข้อมูลการซ่อม ดูประวัติการซ่อมย้อนหลัง
const RepairDetail = () => {
  const orders = useSelector((state) => state.orders);
  const user = useSelector((state) => state.user);
  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams();
  const [width, setWidth] = React.useState(window.innerWidth);
  const breakpoint = 620;

  const allowAccess = user
    ? user.role == role.MECHANIC_ROLE ||
      user.role == role.CONTRATOR_ROLE ||
      user.role == role.STORE_ROLE ||
      user.role == role.ADMIN_ROLE ||
      user.role == role.SUPER_USER_ROLE
      ? true
      : false
    : history.push('/login');

  const filterOrder = _.find(orders, (_order) => {
    return (
      _order._id == params.id
    )
  })

  useEffect(() => {
    // dispatch(actions.ordersFetch(user));
    window.addEventListener('resize', () => setWidth(window.innerWidth));
    return () => { }
  }, [])

  const showByMechanic = () => {
    if (filterOrder.byMechanic !== role.USER_ROLE) {
      return (
        <>
          <Row>
            <h5 className="ml-3"><strong>{filterOrder.user_name}</strong></h5>
            <Col ><Badge color="info" pill>แจ้งซ่อมผ่าน {filterOrder.nameByMechanic}</Badge></Col>

          </Row>
        </>
      )
    } else {
      return (
        <h5><strong>{filterOrder.user_name}</strong></h5>
      )
    }

  }

  const showByMechanicMoblie = () => {
    if (filterOrder.byMechanic !== role.USER_ROLE) {
      return (
        <>
          <p className="ml-1"><Badge color="info" pill>แจ้งซ่อมผ่าน {filterOrder.nameByMechanic}</Badge></p>

        </>
      )
    }
  }

  const colorSpeed = () => {
    if (filterOrder.speed === "ปกติ") {
      return <strong className="text-success">{filterOrder.speed}</strong>
    } else {
      return <strong className="text-danger">{filterOrder.speed}</strong>
    }
  }

  const showImage = (image) => {
    if (image != 0) {
      return (

        <div >
          <p><strong>รูปภาพ</strong></p>
          <div className="text-center">
            {image.map(_image => (
              <img src={_image} width="70%" className="mb-4" />
            ))}
          </div>
        </div>);
    }
  }
  if (allowAccess) {
    return width < breakpoint ? (
      //moblie
      <>
        <RepairDetailMobile />

      </>
    ) : (
      //desktop
      <>
        <RepairDetailDesktop />
      </>
    )
  } else {
    return (
      <>
      </>
    )
  }
}
export default RepairDetail;