import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Container,
  Row,
  Col,
  Input,
  Label,
  Button,
  InputGroup,
} from 'reactstrap'
import { useForm } from 'react-hook-form'
import * as actions from '../../actions'
import { authen } from "../../assets/api/firebase";
import hash from "object-hash";
import { useHistory } from "react-router-dom";
import * as role from "../../util/UserRole";
import _ from "lodash";
import RepairNotificationFromUserMobile from './RepairNotificationFromUserMobile'
import RepairNotificationFromUserDesktop from './RepairNotificationFromUserDesktop'

export default function RepairNotificationFromUser() {
  const { register, handleSubmit } = useForm()
  const user = useSelector((state) => state.user)
  const dispatch = useDispatch()
  const history = useHistory();
  const repairtype = useSelector((state) => state.repairtype);
  const [width, setWidth] = React.useState(window.innerWidth);
  const breakpoint = 620;

  const allowAccess = user
    ? user.role == role.USER_ROLE ||
      user.role == role.CONTRATOR_ROLE ||
      user.role == role.MECHANIC_ROLE ||
      user.role == role.ADMIN_ROLE ||
      user.role == role.SUPER_USER_ROLE
      ? true
      : false
    : history.push('/login');

  useEffect(() => {
    dispatch(actions.repairtypesFetch());
    window.addEventListener('resize', () => setWidth(window.innerWidth));
    return () => { }
  }, []);



  if (allowAccess) {
    return width < breakpoint ? (
      //mobile
      <RepairNotificationFromUserMobile />
    ) : (
      //desktop
      <RepairNotificationFromUserDesktop />
    )
  } else {
    return (
      <>
      </>
    )
  }
}