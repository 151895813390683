import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileAlt } from '@fortawesome/free-solid-svg-icons'
import { useHistory, Link } from 'react-router-dom'
import { Badge, Button, Row } from 'reactstrap';
import * as UserStatus from "../../util/UserStatus";
import * as UserRole from "../../util/UserRole";
import { useSelector, useDispatch } from "react-redux";
import * as actions from '../../actions'

const OrderTables = ({ order, index }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);

    const showSpeed = () => {
        if (order.speed === "เร่งด่วน") {
            return (
                <>
                    <Badge color="danger"  >เร่งด่วน</Badge>
                </>
            )
        }

    }
    const showByMechanic = () => {
        if (order.byMechanic !== UserRole.USER_ROLE) {
            return (
                <>
                    <Badge color="primary">แจ้งซ่อมผ่าน {order.nameByMechanic}</Badge>
                </>
            )
        }

    }
    const changStatus = () => {
       
        if (user.role === UserRole.STORE_ROLE && order.productBorrowStatus === true){
            return (
                <>
                    <Button
                        color="info"
                        onClick={() => {
                            history.push("/borrow/" + order._id);
                        }}
                    >เตรียมอุปกรณ์แล้ว</Button>
                </>
            )
        }else if (user.role === UserRole.STORE_ROLE && order.productBorrowStatus != true) {
            return (
                <>
                    <Button
                        color="success"
                        onClick={() => {
                            history.push("/borrow/" + order._id);
                        }}
                    >เช็คอุปกรณ์</Button>
                </>
            )
        }
        else if (order.statusMechanic === UserStatus.MECHANIC_ESTIMATEPRICE || order.statusMechanic === UserStatus.MECHANIC_CONFIRM) {
            return (
                <>
                    <Button
                        color="success"
                        onClick={() => {
                            history.push("/borrow/" + order._id);
                        }}
                    >เบิกอุปกรณ์</Button>
                </>
            )
        } 

    }
    return (
        <tr>
            {/* <th scope="row">{index}</th> */}
            <td>
                {new Date(order.orderDate).toLocaleDateString("th", { day: '2-digit', month: '2-digit', year: "numeric" })}
                <p>เวลา {new Date(order.orderDate).toLocaleTimeString("th", { hour: '2-digit', minute: '2-digit' })} น.</p>
            </td>
            <td className="text-left">
                <strong>ชื่อ {order.user_name} {showByMechanic()} </strong>
                <p className="mb-1"><strong>โทร</strong> {order.user_tel}</p>
                <p><strong>สถานที่</strong> {order.place}</p>
                <p><strong>ผู้รับผิดชอบ</strong> {order.mechanic_name}</p>
            </td>
            <td >
                {order.problem} {showSpeed()}
                <div className="mt-2"

                >
                    <Link
                        style={{
                            backgroundColor: "#ffffff",
                            boxShadow: "1px 1px 3px #d9d9d9",
                        }}
                        title='ดูรายละเอียด'
                        className='btn btn-light'
                        to={'/repair_detail/' + order._id}
                    >

                        <FontAwesomeIcon icon={faFileAlt} />
                    </Link>
                </div>
            </td>

            <td>{changStatus()}</td>
        </tr>
    )
}
export default OrderTables;