import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAddressBook, faAddressCard, faDollarSign, faFile, faFileAlt, faPrint } from '@fortawesome/free-solid-svg-icons'
import { useHistory, Link } from 'react-router-dom'
import { Badge, Button, Row } from 'reactstrap';
import * as UserStatus from "../../util/UserStatus";
import * as UserRole from "../../util/UserRole";

const RepairRecordTables = ({ order, index }) => {
    const history = useHistory();

    const showSpeed = () => {
        if (order.speed === "เร่งด่วน") {
            return (
                <>
                    <Badge color="danger"  >เร่งด่วน</Badge>
                </>
            )
        }

    }
    const showByMechanic = () => {
        if (order.byMechanic !== UserRole.USER_ROLE) {
            return (
                <>
                    <Badge color="primary">แจ้งซ่อมผ่าน {order.nameByMechanic}</Badge>
                </>
            )
        }

    }
    const changStatus = () => {
        if (order.statusMechanic === UserStatus.MECHANIC_WAITING) {
            return (
                <>
                    <Button
                        color="info"
                        onClick={() => {
                            history.push("/repair_notification/" + order._id)
                        }}
                    >ตรวจสอบ</Button>
                </>
            )
        } else if (order.statusMechanic === UserStatus.MECHANIC_CHECK) {
            return (
                <Button
                    color="success"
                    onClick={() => {
                        history.push("/estimate_price/" + order._id)
                    }}
                >ประเมินราคา</Button>
            )
        }
        else if (order.statusMechanic === UserStatus.MECHANIC_ESTIMATEPRICE && order.statusUser === UserStatus.USER_ESTIMATEPRICE) {
            return (
                <Button
                    disabled
                >ประเมินราคา</Button>
            )
        }
        else if (order.statusMechanic === UserStatus.MECHANIC_ESTIMATEPRICE || order.statusMechanic === UserStatus.MECHANIC_CONFIRM) {
            return (
                <Button
                    color="warning"
                    onClick={() => {
                        history.push("/borrow/" + order._id)
                    }}
                >เบิกอุปกรณ์</Button>
            )
        }
        else if (order.statusMechanic === UserStatus.MECHANIC_ACTIONS) {
            return (
                <Button
                    color="primary"
                    onClick={() => {
                        history.push("/repair_record/" + order._id)
                    }}
                >บันทึกการซ่อม</Button>
            )
        }
        else if (order.statusMechanic === UserStatus.MECHANIC_COMPLETED && order.statusUser === UserStatus.USER_ACTIONS) {
            return (
                <Button
                    color="warning"
                    onClick={() => {
                        history.push("/return/" + order._id)
                    }}
                >คืนอุปกรณ์</Button>
            )
        }
        else if (order.statusMechanic === UserStatus.MECHANIC_COMPLETED && order.statusUser === UserStatus.USER_REPAIRCOMPLETED) {
            return (
                <Button
                    color="primary"
                    onClick={() => {
                        history.push("/deliver_work/" + order._id)
                    }}
                >ส่งมอบงาน</Button>
            )
        }

    }

    const showQuatation = () => {
        if (order.statusMechanic != UserStatus.MECHANIC_WAITING && order.statusMechanic != UserStatus.MECHANIC_CHECK && order.statusUser != UserStatus.USER_REPAIRCOMPLETED) {
            return (
                <Link
                    style={{
                        backgroundColor: "#ffffff",
                        boxShadow: "1px 1px 3px #d9d9d9",
                    }}
                    title='ใบเสนอราคา'
                    className='btn btn-light'
                    to={'/quotation/' + order._id}
                >

                    <FontAwesomeIcon icon={faFileAlt} />
                </Link>
            )
        }
    }
    return (
        <tr>
            {/* <th scope="row">{index}</th> */}
            <td>
                {new Date(order.orderDate).toLocaleDateString("th", { day: '2-digit', month: '2-digit', year: "numeric" })}
                <p>เวลา {new Date(order.orderDate).toLocaleTimeString("th", { hour: '2-digit', minute: '2-digit' })} น.</p>
            </td>
            <td className="text-left">
                <strong>ชื่อ {order.user_name} {showByMechanic()} </strong>
                <p className="mb-1"><strong>โทร</strong> {order.user_tel}</p>
                <p><strong>สถานที่</strong> {order.place}</p>
                <p><strong>ผู้รับผิดชอบ</strong> {order.mechanic_name}</p>
            </td>
            <td >
                {order.problem} {showSpeed()}
                <div className="mt-2"

                >
                    <Link
                        style={{
                            backgroundColor: "#ffffff",
                            boxShadow: "1px 1px 3px #d9d9d9",
                        }}
                        title='ดูรายละเอียด'
                        className='btn btn-light'
                        to={'/repair_detail/' + order._id}
                    >

                        <FontAwesomeIcon icon={faAddressBook} />
                    </Link>{" "}
                    {/* {showQuatation()} */}

                </div>
            </td>


            <td>{changStatus()}</td>
        </tr>
    )
}
export default RepairRecordTables;