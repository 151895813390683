import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Card, CardBody, Alert, Input, Label } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import * as role from "../../util/UserRole";
import { useHistory, useParams } from "react-router-dom";
import * as actions from '../../actions'
import * as UserStatus from "../../util/UserStatus";
import _ from "lodash";
import ModalSelectQuotation from "../../components/modal/ModalSelectUseQuotation";
import { Switch } from 'evergreen-ui'

const DeliverWork = () => {
  const orders = useSelector((state) => state.orders);
  const user = useSelector((state) => state.user);
  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams();


  const allowAccess = user
    ? user.role == role.MECHANIC_ROLE ||
      user.role == role.CONTRATOR_ROLE ||
      user.role == role.ADMIN_ROLE ||
      user.role == role.SUPER_USER_ROLE
      ? true
      : false
    : history.push('/login');

  const filterOrder = _.find(orders, (_order) => {
    return (
      _order._id == params.id
    )
  })



  const [modalSelectQuotation, setModalSelectQuotation] = useState(false); // props สำหรับเปิดปิด modal
  const toggleModalSelectQuotation = () => {
    setModalSelectQuotation(!modalSelectQuotation); // method สำหรับ set เปิดหรือปิด modal
  }

  useEffect(() => {
    dispatch(actions.ordersFetch(user))
    return () => { }
  }, [])

  useEffect(() => {
    return () => { }
  }, [modalSelectQuotation])

  const checkEstimatePrice = () => {
    if (filterOrder.estimatePrice === true) {
      return (
        <>
          <div className="mt-2 mb-2 text-center" onClick={() => history.push("/quotation/" + filterOrder._id)}>
            <Card style={{ padding: "50px", backgroundColor: '#1589FF', borderColor: '#1589FF' }}>
              <CardBody style={{ fontSize: "25px", color: "white" }}>ใบเสนอราคา</CardBody>
            </Card>
          </div>
        </>
      )
    } else {
      return (
        <>
          <div className="mt-2 mb-2 text-center" onClick={() => history.push("/reestimate_price/" + filterOrder._id)}>
            <Card style={{ padding: "50px", backgroundColor: "#D1D0CE", borderColor: "#D1D0CE" }} >
              <CardBody style={{ fontSize: "25px" }}>ใบเสนอราคา</CardBody>
            </Card>
          </div>
        </>
      )
    }

  }

  const checkEstimatePriceContrator = () => {
    if (filterOrder.estimatePrice === true) {
      return (
        <>
          <div className="mt-2 mb-2 text-center">
            <Card style={{ padding: "50px", backgroundColor: '#1589FF', borderColor: '#1589FF' }}>
              <CardBody style={{ fontSize: "25px", color: "white" }}>ใบเสนอราคา</CardBody>
            </Card>
          </div>
        </>
      )
    } else {
      return (
        <>
          <div className="mt-2 mb-2 text-center">
            <Card style={{ padding: "50px", backgroundColor: "#D1D0CE", borderColor: "#D1D0CE" }} >
              <CardBody style={{ fontSize: "25px" }}>ใบเสนอราคา</CardBody>
            </Card>
          </div>
        </>
      )
    }

  }

  const checkDeliveryWork = () => {
    if (filterOrder.deliveryWork === true) {
      return (
        <>
          <div className="mt-2 mb-2 text-center" onClick={() => history.push("/delivery/" + filterOrder._id)}>
            <Card style={{ padding: "50px", backgroundColor: '#1589FF', borderColor: '#1589FF' }}>
              <CardBody style={{ fontSize: "25px", color: "white" }}>ใบส่งมอบงาน</CardBody>
            </Card>
          </div>
        </>
      )
    } else {
      return (
        <>
          <div className="mt-2 mb-2 text-center" onClick={() => history.push("/delivery/edit/" + filterOrder._id)}>
            <Card style={{ padding: "50px", backgroundColor: "#D1D0CE", borderColor: "#D1D0CE" }}>
              <CardBody style={{ fontSize: "25px" }}>ใบส่งมอบงาน</CardBody>
            </Card>
          </div>
        </>
      )
    }
  }

  const checkBeforeAndAfter = () => {
    if (filterOrder.beforeAndAfter === true) {
      return (
        <>
          <div className="mt-2 mb-2 text-center" onClick={() => history.push("/portfolio/" + filterOrder._id)}>
            <Card style={{ padding: "50px", backgroundColor: '#1589FF', borderColor: '#1589FF' }}>
              <CardBody style={{ fontSize: "25px", color: "white" }}>รูปภาพก่อนและหลังซ่อม</CardBody>
            </Card>
          </div>
        </>
      )
    } else {
      return (
        <>
          <div className="mt-2 mb-2 text-center" onClick={() => history.push("/portfolio/edit/" + filterOrder._id)}>
            <Card style={{ padding: "50px", backgroundColor: "#D1D0CE", borderColor: "#D1D0CE" }}>
              <CardBody style={{ fontSize: "25px" }}>รูปภาพก่อนและหลังซ่อม</CardBody>
            </Card>
          </div>
        </>
      )
    }
  }

  const checkProcess = () => {
    if (filterOrder.estimatePrice === true && filterOrder.deliveryWork === true && filterOrder.beforeAndAfter === true && filterOrder.initialQuotation != undefined) {
      return (
        <>
          <div className="mt-4 text-right">
            <Button
              color="success"
              onClick={() => {
                let comfirm = window.confirm("ยืนยันการส่งมอบงาน")
                if (comfirm) {
                  const data = {
                    statusMechanic: UserStatus.MECHANIC_DELIVERWORK,
                    statusUser: UserStatus.USER_REPAIRCOMPLETED,
                    deliveryWorkDate: new Date()
                  }

                  const notify = {
                    message: "ขณะนี้ได้ทำการส่งมอบงานสำหรับงานแจ้งซ่อมของท่านเรียบร้อยแล้ว โปรดตรวจสอบรายการส่งมอบงาน",

                  }
                  const notifyData = {
                    data: notify,
                    token: filterOrder.user_token
                  }
                  dispatch(actions.orderPut(filterOrder._id, data))
                  dispatch(actions.notifySend(notifyData))
                  dispatch(actions.ordersFetch(user));
                  history.push("/management/deliver_work")
                }
              }
              }
            >
              ส่งมอบงาน
            </Button>
          </div>
        </>
      )
    } else {
      return (
        <>
          <div className="mt-4 text-right">
            <Button color="secondary" disabled>
              ส่งมอบงาน
            </Button>
          </div>
        </>
      )
    }
  }

  const showEdit = () => {
    if (filterOrder.checkDeliveryWork.length != 0)
      return (
        <>
          <Row>
            <Col>
              <Card style={{ backgroundColor: "#FEF9E7", borderColor: '#F1C40F' }} className="mb-2">

                <CardBody style={{ fontSize: "25px" }} >
                  <h3>ผลการตรวจรับงาน</h3>

                  {filterOrder.deliveryWorkList.map((work, index) => {
                    return (
                      <>
                        <Row>
                          <Col>
                            <h4>ระบบงาน {work.workSystem}</h4>
                          </Col>
                          <Col sm="2">
                            <Input type="checkbox" name="checkJob" checked={filterOrder.checkDeliveryWork[index] === "ผ่าน"} style={{ height: "20px", width: "20px" }} disabled /> <Label className="ml-2">ผ่าน</Label>
                          </Col>
                          <Col sm="2">
                            <Input type="checkbox" name="checkJob" checked={filterOrder.checkDeliveryWork[index] === "แก้ไข"} style={{ height: "20px", width: "20px" }} disabled /> <Label className="ml-2">แก้ไข</Label>
                          </Col>
                        </Row>
                      </>)
                  })}

                  <h4>ความเห็นของผู้ตรวจรับงาน : {filterOrder.deliveryWorkComment}</h4>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </>
      )
  }

  const selectQuotation = () => {
    if (filterOrder.initialQuotation === true) {
      return <>
        <div className="mt-2 mb-2 text-center" onClick={toggleModalSelectQuotation}>
          <Card style={{ padding: "50px", backgroundColor: '#1589FF', borderColor: '#1589FF' }} >
            <CardBody style={{ fontSize: "25px", color: "white" }} >เลือกใช้ใบเสนอราคาเบื้องต้น
            </CardBody>
          </Card>
        </div>

      </>
    } else if (filterOrder.initialQuotation === false) {
      return <>
        <div className="mt-2 mb-2 text-center" onClick={toggleModalSelectQuotation}>
          <Card style={{ padding: "50px", backgroundColor: '#1589FF', borderColor: '#1589FF' }} >
            <CardBody style={{ fontSize: "25px", color: "white" }} >เลือกใช้ใบเสนอราคา
            </CardBody>
          </Card>
        </div>
      </>
    }
    else {
      return <div className="mt-2 mb-2 text-center" onClick={toggleModalSelectQuotation}>
        <Card style={{ padding: "50px", backgroundColor: "#D1D0CE", borderColor: "#D1D0CE" }}>
          <CardBody style={{ fontSize: "25px" }}>เลือกใบเสนอราคา</CardBody>
        </Card>
      </div>
    }


  }

  if (allowAccess) {
    return (
      <>
        <Container fluid className="light-th"
          style={{
            marginTop: "50px",
            maxWidth: "900px",
          }}
        >
          <ModalSelectQuotation
            modal={modalSelectQuotation}
            toggle={toggleModalSelectQuotation}
            order={filterOrder}
          />
          <Row>
            <Col xs="2">
              <Button
                outline
                color="secondary"
                onClick={
                  history.goBack
                }
              >ย้อนกลับ</Button>
            </Col>
            <Col xs="8">
              <h2 className="text-center">ส่งมอบงาน</h2>
            </Col>
            {/* <Col className="text-right">
              <Button color="info">พิมพ์</Button>
            </Col> */}
          </Row>

          <br />
          <div
            style={{
              backgroundColor: "#ffffff",
              boxShadow: "1px 1px 3px #d9d9d9",
              padding: "30px",
            }}>

            {showEdit()}

            {user.role == role.CONTRATOR_ROLE ? (
              <>
                {/* <Row>
                  <Col>
                    <div className="mt-2 mb-2 text-center">
                      <Card style={{ padding: "50px", backgroundColor: '#1589FF', borderColor: '#1589FF' }}>
                        <CardBody style={{ fontSize: "25px", color: "white" }} >ใบเสนอราคาเบื้องต้น</CardBody>
                      </Card>
                    </div>
                  </Col>
                  <Col>
                    {checkEstimatePriceContrator()}
                  </Col>
                </Row> */}
              </>) : (
              <>
                {selectQuotation()}
                <Row>
                  <Col>
                    <div className="mt-2 mb-2 text-center" onClick={() => history.push("/initail_quotation/" + filterOrder._id)} >
                      <Card style={{ padding: "50px", backgroundColor: '#1589FF', borderColor: '#1589FF' }}>
                        <CardBody style={{ fontSize: "25px", color: "white" }} >ใบเสนอราคาเบื้องต้น</CardBody>
                      </Card>
                    </div>
                  </Col>
                  <Col>
                    {checkEstimatePrice()}
                  </Col>
                </Row>
              </>
            )}


            <Row>
              <Col>
                {checkDeliveryWork()}
              </Col>
              <Col>
                {checkBeforeAndAfter()}
              </Col>
            </Row>
            {checkProcess()}
          </div>
        </Container>

      </>
    )
  } else {
    return (
      <>
      </>
    )
  }
}
export default DeliverWork;