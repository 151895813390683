import {
  SYSTEM_EDIT,
  SYSTEM_FETCH,
  SYSTEM_CREATE
} from "../actions/types";
export default function (state = [], action) {
  switch (action.type) {
    case SYSTEM_FETCH:
      return action.payload;
    case SYSTEM_CREATE:
      return state;
    case SYSTEM_EDIT:
      return action.payload;
    default:
      return state; // ค่าเดิม
  }
}