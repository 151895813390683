import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileAlt } from '@fortawesome/free-solid-svg-icons'
import { useHistory, Link } from 'react-router-dom'
import { Badge, Button, Row, Alert } from 'reactstrap';
import * as UserStatus from "../../util/UserStatus";
import * as UserRole from "../../util/UserRole";

const ProductLogTables = ({ productLog, index }) => {
    const history = useHistory();


    const showType = () => {
        if (productLog.type === "เบิก") {
            return (
            <h5 className="text-danger">{productLog.type}</h5>
            )
        } else if (productLog.type === "คืน") {
            return (<h5 className="text-success">{productLog.type}</h5>)
        }else if (productLog.type === "เพิ่มสต๊อก") {
            return (<h5 className="text-success">{productLog.type}</h5>)
        }else if (productLog.type === "ลดสต๊อก") {
            return (<h5 className="text-danger">{productLog.type}</h5>)
        }else if (productLog.type === "แก้ไขสต๊อก") {
            return (<h5 className="text-warning">{productLog.type}</h5>)
        }
      

    }

    const showStore = (log) => {
        if (log.product_from != null) {
            if (log.product_from === "ร้านค้า") {
                return (
                    <>
                        <Badge color="info" pill>ร้านค้า</Badge>
                    </>
                )
            }

        }


    }

    return (
        <tr className="text-center">
            <th scope="row" className="text-center">{index}</th>
            <td>
                {new Date(productLog.transactionDate).toLocaleDateString("th", { day: '2-digit', month: '2-digit', year: "numeric" })}
                <p>เวลา {new Date(productLog.transactionDate).toLocaleTimeString("th", { hour: '2-digit', minute: '2-digit' })} น.</p>
            </td>
            <td className="text-primary">{productLog.handler}</td>
            <td>{showType()}</td>
            <td>{productLog.quotationId}</td>
            <td>
                <div>
                    {productLog.product.map((_log) => {
                        return (
                            <>
                                <p >{_log.product_name} {showStore(_log)}</p>
                                {/* <p>{_log.product_from}</p> */}
                            </>
                        )
                    })}
                </div>
            </td>
            <td>
                <div>
                    {productLog.product.map((_log) => {
                        return (
                            <>
                                <p>{_log.inventory} {_log.product_unit}</p>

                            </>
                        )
                    })}
                </div>
            </td>
            <td>
                <div>
                    {productLog.product.map((_log) => {
                        return (
                            <>
                                <p>{_log.prev_inventory} {_log.product_unit}</p>

                            </>
                        )
                    })}
                </div>
            </td>
            <td>
                <div>
                    {productLog.product.map((_log) => {
                        return (
                            <>
                                <p>{_log.next_inventory} {_log.product_unit}</p>

                            </>
                        )
                    })}
                </div>
            </td>
            <td>
                <div>
                    {productLog.product.map((_log) => {
                        if (_log.note != null) {
                            return (
                                <>
                                    <p className="text-danger">{_log.note}</p>
                                </>
                            )
                        } else {
                            return (
                                <>
                                    <p className="text-danger">-</p>
                                </>
                            )
                        }

                    })}
                </div>
            </td>



        </tr>
    )
}
export default ProductLogTables;