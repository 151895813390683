import React, { useState, useEffect } from "react";
import { Button, Container } from "reactstrap";
import * as actions from "../../actions";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

const NotifyAccept = () => {
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch()
    const user = useSelector((state) => state.user);
    const accessCode = new URLSearchParams(location.search).get("code")
    // console.log('Line Access Code ' + accessCode)

    useEffect(() => {
        dispatch(actions.fetchAllUser());
        return () => { };
    }, []);

    useEffect(() => {
        dispatch(actions.registerLineNotify(accessCode, user.id))
    }, [accessCode])

    return (
        <Container fluid className="light-th"
            style={{
                marginTop: "50px",
                maxWidth: "900px",
            }}
        >
            <div className="text-center"
                style={{
                    backgroundColor: "#ffffff",
                    boxShadow: "1px 1px 3px #d9d9d9",
                    padding: "30px",
                }}>

                <img
                    style={{ width: "20%" }}
                    src={require("../../assets/images/line-notify.png")}
                />
                <h2 className="mt-4">ลงทะเบียนรับแจ้งเตือนผ่าน LINE</h2>
                <h5 className="mt-4">เชื่อมต่อบัญชีของท่านกับระบบเรียบร้อยแล้ว</h5>
                <br />
                <Button outline color="secondary" onClick={() => { history.push("/profile/" + user.id) }}>กลับสู่หน้าโปรไฟล์</Button>

            </div>


        </Container>
    )
};
export default NotifyAccept;