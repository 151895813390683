import {
    HISTORY_GET, 
    HISTORY_RESET, 
    HISTORY_USER_GET
  } from "../actions/types";
  export default function (state = [], action) {
    switch (action.type) {
      case HISTORY_GET:
        return action.payload;
      case HISTORY_RESET:
        return [];
      case HISTORY_USER_GET:
        return action.payload;
      default:
        return state; // ค่าเดิม
    }
  }
  