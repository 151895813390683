import React, { useState, useEffect } from "react";
import { Container, Label, Col, Row, Input, Card, CardBody, CardSubtitle, CardTitle, Button } from "reactstrap";
import * as UserStatus from "../../../util/UserStatus";

//แก้ไข ยกเลิกได้ 
const detailStatusMobile = ({ order, setOrder, setQuatation, toggleModalBargain, toggleModalCancle, comfirmOrder, toggleModalCancleJob, checkJob }) => {

  const openToggle = () => {
    toggleModalCancle(order._id);
  }
  const confirmJob = () => {
    let confirmOrder = window.confirm("ยืนยันการซ่อม");
    if (confirmOrder) {
      const changStatus = {
        statusUser: UserStatus.USER_CONFIRM,
        statusMechanic: UserStatus.MECHANIC_CONFIRM,
        comfirmRepairDate: new Date()

      }
      const data = {
        status: changStatus,
        _id: order._id,
        token: order.mechanic_token,
        user_name: order.user_name,
        user_agency: order.user_agency,
      }
      comfirmOrder(data);
      alert("ยืนยันสำเร็จ")
    }

  }
  const cancleJob = () => {
    toggleModalCancleJob(order._id, order.mechanic_token);
  }
  const bargainJob = () => {
    toggleModalBargain(order._id, order.mechanic_token);
  }
  const colorSpeed = () => {
    if (order.speed === "ปกติ") {
      return <strong className="text-success">{order.speed}</strong>
    } else {
      return <strong className="text-danger">{order.speed}</strong>
    }
  }
  const showImage = (image) => {
    if (image != 0) {
      return (
        <div >
          <h6><strong>รูปภาพ</strong></h6>
          {image.map(_image => (
            <img src={_image} width="100vw" className="mr-2" />
          ))}
        </div>);
    }
  }

  const showReason = () => {
    if (order.mechanic_reason != null) {
      return (
        <div>
          <h6 className="ml-2"> เนื่องจาก {order.mechanic_reason}</h6>
        </div>
      )
    } else if (order.causeCancle != null) {
      return (
        <div>
          <h6 className="ml-2"> เนื่องจาก {order.causeCancle}</h6>
        </div>
      )
    }
  }

  const showQuatation = () => {
    if (order.statusUser === UserStatus.USER_ESTIMATEPRICE
      || order.statusUser === UserStatus.USER_CONFIRM
      || order.statusUser === UserStatus.USER_ACTIONS) {
      return (
        <>

          <Button
            color="link"
            size="sm"
            onClick={() => {
              setQuatation(order._id);
            }}
          >
            ดูใบเสนอราคา
        </Button>
        </>
      )
    }
  }

  const onSubmitCancel = () => {
    if (order.statusUser === UserStatus.USER_REPAIRNOTIFICATION) {
      return (
        <div className="text-right">
          <Button color="danger"

            onClick={openToggle}

          >
            ยกเลิกการแจ้งซ่อม</Button>
        </div>
      )
    }
  }

  const onSubmitConfirm = () => {
    if (order.statusUser === UserStatus.USER_ESTIMATEPRICE) {
      return (
        <div className="text-right">
          <Button color="success" className="mb-2"
            size="sm"
            onClick={confirmJob}
          >
            ยืนยันการซ่อม</Button>{" "}
          <Button color="warning" className="mb-2"
            size="sm"
            onClick={bargainJob}
          >
            ต่อรองราคา</Button>{" "}
          <Button color="danger" className="mb-2"
            size="sm"
            onClick={cancleJob}
          >
            ยกเลิกการซ่อม</Button>
        </div>
      )
    }
  }

  const onCheckJob = () => {
    if (order.statusUser === UserStatus.USER_REPAIRCOMPLETED) {
      return (
        <div className="text-right">
          <Button color="primary" className="mb-2"

            onClick={() => checkJob(order._id)}
          >
            ตรวจรับงาน</Button>
        </div>
      )
    }
  }

  return (
    <Container>
      <div style={{
        backgroundColor: '#ffffff',
        boxShadow: '1px 1px 3px #d9d9d9',
        padding: '30px',
      }}
        className="mb-4">

        <Row className='ml-1 pt-2 pr-2'>
          <Col sm='8'>
            <Row>
              <Col >
                <Row>
                  <Card style={{ border: "none", boxShadow: "none" }} >
                    <CardTitle >
                      <h6><strong>ปัญหา/งานซ่อม</strong> <strong className="text-danger">{order.problem}</strong></h6>
                    </CardTitle>
                  </Card>
                </Row>

                <Row>
                  <Card style={{ border: "none", boxShadow: "none" }} >
                    <CardSubtitle>
                      <h6 className="mt-1 mr-1" ><strong>รายละเอียด</strong> {order.detail}</h6>
                    </CardSubtitle>
                  </Card>
                </Row>
                <br />
                <Row className="mt-1 mr-1">
                  <Card style={{ border: "none", boxShadow: "none" }}>
                    <CardSubtitle>
                      <h6><strong>สถานที่</strong> {order.place}</h6>
                    </CardSubtitle>
                  </Card>
                </Row>
              </Col>
            </Row>
          </Col>

          <Col sm='4' className="mb-2">
            <Row>

              <h6><strong>สถานะการซ่อม</strong><h5 className='pt-3 pb-2 text-info'><strong>{order.statusUser}</strong></h5></h6>

              {showReason()}
            </Row>
            <div className="text-right">
              {showQuatation()}
            </div>
          </Col>
        </Row>

        <Row className="mt-2 ">
          <Col><h6><strong>ประเภทงานซ่อม</strong> <strong className="text-primary">{order.type}</strong></h6></Col>
        </Row>

        <Row className="mt-2 ">
          <Col ><h6><strong>ความเร่งด่วน</strong> {colorSpeed()}</h6></Col>
        </Row>

        {showImage(order.imageFormUser)}



        <Row>
          <Col className="text-left mt-2" sm="8">
            <p><strong>ชื่อผู้แจ้ง</strong> {order.user_name} </p>
            <p><strong>เบอร์โทรศัพท์</strong> {order.user_tel} </p>
            <p><strong>วันที่แจ้งซ่อม</strong> {new Date(order.orderDate).toLocaleDateString("th")}</p>
          </Col>
          <Col >
            {onSubmitCancel()}
            {onSubmitConfirm()}
            {onCheckJob()}
          </Col>
        </Row>



      </div>

    </Container>
  )
}
export default detailStatusMobile;