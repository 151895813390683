//USER
export const USER_REPAIRNOTIFICATION = "แจ้งซ่อมเรียบร้อยแล้ว รอช่างรับงาน"; //---> MECHANIC_WAITING 

//ปกติ
export const USER_WAITING = "ช่างรับงานเรียบร้อยแล้ว รอการประเมินราคาเบื้องต้น";
export const USER_ESTIMATEPRICE = "ประเมินราคาเบื้องต้นเรียบร้อยแล้ว รอลูกค้ายืนยันการซ่อม";
export const USER_CONFIRM = "ยืนยันการซ่อมเรียบร้อยแล้ว รอช่างดำเนินการซ่อม"; //---->USER_ACTIONS , MECHANIC_CONFIRM
export const USER_BARGAIN = "ต่อรองราคาเรียบร้อยแล้ว รอการประเมินราคาอีกครั้ง";
//เร่งด่วน
export const USER_CHECK = "ช่างรับงานเรียบร้อยแล้ว รอช่างดำเนินการซ่อม"; //---->USER_ACTIONS 

export const USER_ACTIONS = "กำลังดำเนินการซ่อม";
export const USER_REPAIRCOMPLETED = "การซ่อมเสร็จสิ้น รอการตรวจรับงาน";
export const USER_WAITEDIT = "รอช่างแก้ไขงานซ่อม"; //---->MECHANIC_EDIT 
export const USER_FINISHED = "ดำเนินการเสร็จสิ้น"; //END ----> MECHANIC_FINISHED
export const USER_REFUSEDWORK = "ช่างปฏิเสธงานซ่อม";
export const USER_CANCEL = "ยกเลิกงานซ่อม"; //ลูกค้ายกเลิกงานซ่อมเอง ออเดอร์จะหายไป

//MECHANIC
export const MECHANIC_WAITING = "รอตรวจสอบ"; 
export const MECHANIC_CHECK = "รับงานแล้ว รอการประเมินราคาเบื้องต้น"; //---> USER_WAITING (ปกติ)
export const MECHANIC_ESTIMATEPRICE = "ประเมินราคาเบื้องต้นเรียบร้อยแล้ว"; //---> USER_ESTIMATEPRICE (ปกติ) , MECHANIC_REQUEST (เร่งด่วน)
export const MECHANIC_CONFIRM = "ลูกค้ายืนยันการซ่อมเรียบร้อยแล้ว"; // เฉพาะปกติ

export const MECHANIC_REQUEST = "ขอเบิกอุปกรณ์แล้ว"; //---> ADMIN_PREPARE
export const MECHANIC_PICKUP = "เบิกอุปกรณ์เรียบร้อยแล้ว"; //--->  USER_ACTIONS
export const MECHANIC_ACTIONS = "กำลังดำเนินการซ่อม"; 
export const MECHANIC_COMPLETED = "ซ่อมเสร็จแล้ว รอการส่งมอบงาน"; 
export const MECHANIC_DELIVERWORK = "ส่งมอบงานแล้ว รอลูกค้าตรวจรับงาน"; //---> USER_REPAIRCOMPLETED, ADMIN_RETURNS
export const MECHANIC_EDIT = "รอการแก้ไขงานซ่อม"; //---> USER_REPAIRCOMPLETED 
export const MECHANIC_FINISHED = "ดำเนินการเสร็จสิ้น" //END
export const MECHANIC_REFUSEDWORK = "ปฏิเสธงาน"
export const MECHANIC_BARGAIN = "ลูกค้าต้องการต่อรองราคา โปรดประเมินราคาอีกครั้ง";

//ADMIN
export const ADMIN_PREPARE = "เตรียมอุปกรณ์";
export const ADMIN_LEND = "จ่ายอุปกรณ์เรียบร้อยแล้ว"; //---> MECHANIC_PICKUP
export const ADMIN_RETURNS = "รับคืนอุปกรณ์เรียบร้อยแล้ว"; 

