import React, { useState, useEffect } from "react";
import { Container, Table, Badge, Col, Row, Button, Modal, ModalBody, ModalFooter, ModalHeader, Input, Card } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import * as role from "../../util/UserRole";
import { useHistory, useParams } from "react-router-dom";
import * as actions from '../../actions'
import * as UserStatus from "../../util/UserStatus";
import _ from "lodash";
import { useForm } from "react-hook-form";



const GetJobRepairDesktop = () => {
  const orders = useSelector((state) => state.orders);
  const user = useSelector((state) => state.user);
  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams();
  const { register, handleSubmit, err } = useForm();
  const [width, setWidth] = React.useState(window.innerWidth);
  const breakpoint = 620;
  const allUser = useSelector((state) => state.userManagement);
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    dispatch(actions.fetchAllUser());
    window.addEventListener('resize', () => setWidth(window.innerWidth));
    return () => { }
  }, [])

  useEffect(() => {
    if (allUser.length != 0) {
      const filterUser = _.filter(allUser, (_user) => {
        return (
          _user.role === role.CONTRATOR_ROLE
        )
      })
      setUserData(filterUser)
    }
    return () => { };
  }, [allUser]);

  const allowAccess = user
    ? user.role == role.MECHANIC_ROLE ||
      user.role == role.CONTRATOR_ROLE ||
      user.role == role.ADMIN_ROLE ||
      user.role == role.SUPER_USER_ROLE
      ? true
      : false
    : history.push('/login');

  const filterOrder = _.find(orders, (_order) => {
    return (
      _order._id == params.id
    )
  })

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [assignWorkModal, setAssignWorkModal] = useState(false);
  const assignWorkToggle = () => setAssignWorkModal(!assignWorkModal);

  const showByMechanic = () => {
    if (filterOrder.byMechanic !== role.USER_ROLE) {
      return (
        <>
          <Row>
            <h5 className="ml-3"><strong>{filterOrder.user_name}</strong></h5>
            <Col ><Badge color="info" pill>แจ้งซ่อมผ่าน {filterOrder.nameByMechanic}</Badge></Col>

          </Row>
        </>
      )
    } else {
      return (
        <h5 ><strong>{filterOrder.user_name}</strong></h5>
      )
    }

  }

  const showByMechanicMoblie = () => {
    if (filterOrder.byMechanic !== role.USER_ROLE) {
      return (
        <>
          <p className="ml-1"><Badge color="info" pill>แจ้งซ่อมผ่าน {filterOrder.nameByMechanic}</Badge></p>

        </>
      )
    }
  }

  const showImage = (image) => {
    if (image != 0) {
      return (

        <div >
          <p><strong>รูปภาพ</strong></p>
          <div className="text-center">
            {image.map(_image => (
              <img src={_image} width="70%" className="mb-4" />
            ))}
          </div>
        </div>);
    }
  }

  const colorSpeed = () => {
    if (filterOrder.speed === "ปกติ") {
      return <strong className="text-success">{filterOrder.speed}</strong>
    } else {
      return <strong className="text-danger">{filterOrder.speed}</strong>
    }
  }

  const acceptWork = () => {

    if (user.role == role.CONTRATOR_ROLE) {
      return (
        <>
          <div className="text-center">
            <Button color="success" className="mr-4" size="lg" onClick={handleSubmit(onSubmitComfirm)} >รับงาน</Button>
            <Button color="danger" size="lg" onClick={toggle} disabled={user.role == role.CONTRATOR_ROLE}>ปฏิเสธงาน</Button>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="text-center">
            {/* <Button color="success" className="mr-4" size="lg" onClick={assignWorkToggle} >รับงาน</Button> */}
            <Button color="success" className="mr-4" size="lg" onClick={onSubmitAssignWorkComfirm} >รับงาน</Button>
            <Button color="danger" size="lg" onClick={toggle} disabled={user.role == role.CONTRATOR_ROLE}>ปฏิเสธงาน</Button>
          </div>

        </>
      );
    }
  }

  const onSubmitComfirm = () => {
    //ปกติ
    const dataNormal = {
      mechanicId: user.id,
      mechanic_name: user.user_title + user.user_firstname + " " + user.user_lastname,
      mechanic_token: user.accessToken,
      statusUser: UserStatus.USER_WAITING,
      statusMechanic: UserStatus.MECHANIC_CHECK,
      getJobDate: new Date(),
    }

    //เร่งด่วน
    const dataUrgent = {
      mechanicId: user.id,
      mechanic_name: user.user_title + user.user_firstname + " " + user.user_lastname,
      mechanic_token: user.accessToken,
      statusUser: UserStatus.USER_CHECK,
      statusMechanic: UserStatus.MECHANIC_CHECK,
      getJobDate: new Date(),
    }

    const token = "ESTIMATE PRICE"
    const dataNotification = user.user_title + user.user_firstname + " " + user.user_lastname + " ได้รับงานแจ้งซ่อม" + filterOrder.problem + " ของลูกค้าชื่อ" + filterOrder.user_name + " หน่วยงาน " + filterOrder.user_agency + "เรียบร้อยแล้ว โปรดประเมินราคาเพื่อดำเนินการต่อ"

    if (filterOrder.speed === "ปกติ") {
      dispatch(actions.orderPut(filterOrder._id, dataNormal))
      dispatch(actions.ordersFetch(user));
      dispatch(actions.notifyMultiSend(dataNotification, token, allUser))

      // history.push("/estimate_price/" + filterOrder._id)
    } else {
      dispatch(actions.orderPut(filterOrder._id, dataUrgent))
      dispatch(actions.ordersFetch(user));
      dispatch(actions.notifyMultiSend(dataNotification, token, allUser))

      // history.push("/estimate_price/" + filterOrder._id)
    }

    if (user.role == role.CONTRATOR_ROLE) {
      alert("ท่านได้รับงานนี้เรียบร้อยแล้ว โปรดรอการยืนยันการซ่อม")
      history.push("/management/job");
    }

  };

  const onSubmitAssignWorkComfirm = (data) => {
    const selectUser = _.filter(userData, (_user) => {
      return (
        _user.id === data.mechanicId
      )
    })

    // //ปกติ
    // const dataNormal = {
    //   mechanicId: data.mechanicId,
    //   mechanic_name: selectUser[0].user_title + selectUser[0].user_firstname + " " + selectUser[0].user_lastname,
    //   mechanic_token: selectUser[0].accessToken,
    //   statusUser: UserStatus.USER_WAITING,
    //   statusMechanic: UserStatus.MECHANIC_CHECK,
    //   getJobDate: new Date(),
    // }

    // //เร่งด่วน
    // const dataUrgent = {
    //   mechanicId: data.mechanicId,
    //   mechanic_name: selectUser[0].user_title + selectUser[0].user_firstname + " " + selectUser[0].user_lastname,
    //   mechanic_token: selectUser[0].accessToken,
    //   statusUser: UserStatus.USER_CHECK,
    //   statusMechanic: UserStatus.MECHANIC_CHECK,
    //   getJobDate: new Date(),
    // }

    //ปกติ
    const dataNormal = {
      mechanicId: user.id,
      mechanic_name: user.user_title + user.user_firstname + " " + user.user_lastname,
      mechanic_token: user.accessToken,
      statusUser: UserStatus.USER_WAITING,
      statusMechanic: UserStatus.MECHANIC_CHECK,
      getJobDate: new Date(),
    }

    //เร่งด่วน
    const dataUrgent = {
      mechanicId: user.id,
      mechanic_name: user.user_title + user.user_firstname + " " + user.user_lastname,
      mechanic_token: user.accessToken,
      statusUser: UserStatus.USER_CHECK,
      statusMechanic: UserStatus.MECHANIC_CHECK,
      getJobDate: new Date(),
    }

    // const notify = {
    //   message: user.user_title + user.user_firstname + " " + user.user_lastname + " ได้มอบหมายงานซ่อม" + filterOrder.problem + " ของลูกค้าชื่อ" + filterOrder.user_name + " หน่วยงาน " + filterOrder.user_agency + "ให้ท่าน",
    // }
    // const notifyData = {
    //   data: notify,
    //   token: selectUser[0].accessToken
    // }


    if (filterOrder.speed === "ปกติ") {
      dispatch(actions.orderPut(filterOrder._id, dataNormal))
      dispatch(actions.ordersFetch(user));
      // dispatch(actions.notifySend(notifyData))
    } else {
      dispatch(actions.orderPut(filterOrder._id, dataUrgent))
      dispatch(actions.ordersFetch(user));
      // dispatch(actions.notifySend(notifyData))
    }


    let navigate = window.confirm("เข้าสู่หน้าประเมินราคาต่อหรือไม่")
    if (navigate) {
      history.push("/estimate_price/" + filterOrder._id);
    } else {
      history.push("/management/job");
    }

  };

  const onSubmitReason = (data) => {
    // toggle();
    const dataReason = {
      mechanic_name: user.user_title + user.user_firstname + " " + user.user_lastname,
      mechanic_reason: data.mechanic_reason,
      mechanic_token: user.accessToken,
      statusUser: UserStatus.USER_REFUSEDWORK,
      statusMechanic: UserStatus.MECHANIC_REFUSEDWORK,
      cancleDate: new Date(),
    }
    const notify = {
      message: "ช่างได้ปฏิเสธงานซ่อม" + filterOrder.problem + "ของท่าน" + " เนื่องจาก" + data.mechanic_reason,

    }
    const notifyData = {
      data: notify,
      token: filterOrder.user_token
    }
    dispatch(actions.orderPut(filterOrder._id, dataReason))
    // dispatch(actions.handlingWork(filterOrder._id))
    dispatch(actions.notifySend(notifyData))
    dispatch(actions.getHistory());
    dispatch(actions.ordersFetch(user));
    history.push("/management/job")
  };

  const showContrator = () => {
    return userData.map((contrator) => {
      return <option value={contrator.id}>{contrator.user_title + contrator.user_firstname + " " + contrator.user_lastname}</option>
    })
  }

  if (allowAccess) {
    return (
      //desktop
      <>

        <Container fluid className="light-th"
          style={{


            marginTop: "50px",
            maxWidth: "900px",
          }}
        >
          <Row>
            <Col >
              <Button
                outline
                color="secondary"
                onClick={
                  history.goBack
                }
              >ย้อนกลับ</Button>
            </Col>
            <Col xs="7">
              <h2 className="text-left">ใบแจ้งซ่อม</h2>
            </Col>


          </Row>

          <br />
          <div
            style={{
              backgroundColor: "#ffffff",
              boxShadow: "1px 1px 3px #d9d9d9",
              padding: "30px",

            }}>

            <br />
            <h4 className="mb-4"><strong>ข้อมูลผู้แจ้ง</strong></h4>
            <Row>
              <Col sm="2"><p><strong>ชื่อผู้แจ้ง</strong></p></Col>

              <Col >{showByMechanic()}</Col>

            </Row>
            <Row>
              <Col sm="2"><p><strong>วันที่แจ้งซ่อม</strong></p></Col>
              <Col sm="2"><h5> {new Date(filterOrder.orderDate).toLocaleDateString("th")}</h5></Col>
              <Col ><strong>เวลา</strong> {new Date(filterOrder.orderDate).toLocaleTimeString("th", { hour: '2-digit', minute: '2-digit' })} น.</Col>
            </Row>
            <Row>
              <Col sm="2"><p><strong>เบอร์โทรศัพท์</strong></p></Col>
              <Col> <h5> {filterOrder.user_tel}</h5></Col>
            </Row>
            <Row>
              <Col sm="2"><p><strong>แฟกซ์</strong></p></Col>
              <Col> <h5> {filterOrder.user_fax}</h5></Col>
            </Row>
            <Row>
              <Col sm="2"><p><strong>อีเมล</strong></p></Col>
              <Col> <h5> {filterOrder.user_email}</h5></Col>
            </Row>
            <Row>
              <Col sm="2"><p><strong>สถานที่</strong></p></Col>
              <Col><h5> {filterOrder.place}</h5></Col>
            </Row>

            <hr />
            <h4 className="mt-4 mb-4"><strong>ข้อมูลการแจ้งซ่อม</strong></h4>
            <Row>
              <Col sm="2"><p><strong>ประเภทงานซ่อม</strong> </p></Col>
              <Col><h5>{filterOrder.type}</h5> </Col>
            </Row>
            <Row>
              <Col sm="2"><p><strong>ปัญหา/งานซ่อ</strong>ม</p></Col>
              <Col><h5 className="text-primary"><strong>{filterOrder.problem}</strong></h5></Col>
            </Row>
            <Row>
              <Col sm="2"><p><strong>ความเร่งด่วน</strong></p></Col>
              <Col><h5>{colorSpeed()}</h5></Col>
            </Row>

            <Row>
              <Col sm="2"> <p><strong>รายละเอียด</strong></p></Col>
              <Col sm="10"><Card style={{ border: "none", boxShadow: "none" }}><h5>{filterOrder.detail}</h5></Card></Col>
            </Row>

            {showImage(filterOrder.imageFormUser)}
            <br />
            {acceptWork()}

            <Modal isOpen={modal} toggle={toggle} >
              <ModalHeader toggle={toggle}>เหตุผล</ModalHeader>
              <ModalBody>
                <form>
                  <Input
                    name="mechanic_reason"
                    placeholder="เหตุผล"
                    type="textarea"
                    innerRef={register}
                  />
                </form>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={handleSubmit(onSubmitReason)}>ยืนยัน</Button>{' '}
                <Button color="secondary" onClick={toggle}>ยกเลิก</Button>
              </ModalFooter>
            </Modal>

            <Modal isOpen={assignWorkModal} toggle={assignWorkToggle} >
              <ModalHeader toggle={assignWorkToggle}>มอบหมายงานให้</ModalHeader>
              <ModalBody>
                <form>
                  <Input
                    name="mechanicId"
                    type="select"
                    innerRef={register}
                  >
                    {showContrator()}
                  </Input>
                </form>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={handleSubmit(onSubmitAssignWorkComfirm)}>ยืนยัน</Button>{' '}
                <Button color="secondary" onClick={assignWorkToggle}>ยกเลิก</Button>
              </ModalFooter>
            </Modal>

          </div>
        </Container>

      </>
    )
  } else {
    return (
      <>
      </>
    )
  }
}
export default GetJobRepairDesktop;