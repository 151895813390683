import {
    PRODUCT_CREATE,
    PRODUCT_EDIT,
    PRODUCTS_FETCH,
    PRODUCT_REMOVE,
    PRODUCTS_RESET,
    PRODUCT_FETCH,
  } from "../actions/types";
  export default function (state = [], action) {
    switch (action.type) {
      case PRODUCTS_FETCH:
        return action.payload;
      case PRODUCT_FETCH:
        return action.payload;
      case PRODUCT_EDIT:
        return action.payload;
      case PRODUCTS_RESET:
        return action.payload;
      case PRODUCT_CREATE:
        return state;
      case PRODUCT_REMOVE:
        return action.payload;
      default:
        return state; // ค่าเดิม
    }
  }
  