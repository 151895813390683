import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../actions'
import { useHistory } from "react-router-dom";
import * as role from "../../util/UserRole";
import RepairNotificationFromUser from "./RepairNotificationFromUser";
import RepairNotificationFromMechanic from "./RepairNotificationFromMechanic";

export default function RepairNotification() {
  const user = useSelector((state) => state.user)
  const dispatch = useDispatch()
  const history = useHistory();

  const allowAccess = user
    ? user.role == role.USER_ROLE ||
      user.role == role.CONTRATOR_ROLE ||
      user.role == role.MECHANIC_ROLE ||
      user.role == role.ADMIN_ROLE ||
      user.role == role.SUPER_USER_ROLE
      ? true
      : false
    : history.push('/login');

  useEffect(() => {
    dispatch(actions.repairtypesFetch());
    dispatch(actions.fetchAllUser());
    dispatch(actions.getUser(user.id));
    return () => { }
  }, []);

  if (allowAccess) {
    if (user.role == role.USER_ROLE) {
      return (
        <RepairNotificationFromUser />
      );
    } else {
      return (
        <RepairNotificationFromMechanic />
      );
    }

  } else {
    return (
      <>
      </>
    )
  }
}