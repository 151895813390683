import { FETCH_USER, EDIT_USER } from "../actions/types"
export default function (state = null, action) {
  switch (action.type) {
    case FETCH_USER:
      return action.payload
    case EDIT_USER:
      return action.payload
    default:
      return state
  }
}
