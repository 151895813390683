import axios from "axios";
import {
  NOTIFY,
} from "./types";
import _ from "lodash";

export const notifySend = (data) => {
  return (dispatch) => {
    axios
      .post(process.env.REACT_APP_API_URL + "/notify", data)
      .then((res) => {
        dispatch({ type: NOTIFY, payload: res.data });
      })
      .catch((err) => {
        console.log("Error Can't send nofify");
        console.error(err);
      });
  };
};

export const notifyMultiSend = (dataNotification, token, user) => {

  if (token === "REPAIR NOTIFICATION") {
    return (dispatch) => {
      const filterUser = _.filter(user, (_user) => {
        return (
          _user.role != "USER" && _user.role != "STORE"&& _user.role != "CONTRATOR" && _user.accessToken != null
        )
      })

      for (let count = 0; count < filterUser.length; count++) {

        const notify = {
          message: dataNotification,

        }

        const notifyData = {
          data: notify,
          token: filterUser[count].accessToken
        }
        axios
          .post(process.env.REACT_APP_API_URL + "/notify", notifyData)
          .then((res) => {
            dispatch({ type: NOTIFY, payload: res.data });
          })
          .catch((err) => {
            console.log("Error Can't send nofify");
            console.error(err);
          });
      }
    }
  } else if (token === "CANCLE" || token === "CONFIRM" || token === "BARGAIN") {
    return (dispatch) => {
      const filterUser = _.filter(user, (_user) => {
        return (
          _user.role != "USER" && _user.role != "STORE" && _user.role != "CONTRATOR" && _user.accessToken != null
        )
      })

      for (let count = 0; count < filterUser.length; count++) {

        const notify = {
          message: dataNotification,

        }

        const notifyData = {
          data: notify,
          token: filterUser[count].accessToken
        }
        axios
          .post(process.env.REACT_APP_API_URL + "/notify", notifyData)
          .then((res) => {
            dispatch({ type: NOTIFY, payload: res.data });
          })
          .catch((err) => {
            console.log("Error Can't send nofify");
            console.error(err);
          });
      }
    }
  } else if (token === "ESTIMATE PRICE") {
    return (dispatch) => {
      const filterUser = _.filter(user, (_user) => {
        return (
          _user.role != "USER" && _user.role != "STORE" && _user.role != "CONTRATOR" && _user.accessToken != null
        )
      })

      for (let count = 0; count < filterUser.length; count++) {

        const notify = {
          message: dataNotification,

        }

        const notifyData = {
          data: notify,
          token: filterUser[count].accessToken
        }
        axios
          .post(process.env.REACT_APP_API_URL + "/notify", notifyData)
          .then((res) => {
            dispatch({ type: NOTIFY, payload: res.data });
          })
          .catch((err) => {
            console.log("Error Can't send nofify");
            console.error(err);
          });
      }
    }
  }
};

export const notifyMultiSendStore = (dataNotification, token, user) => {

  if (token === "STORE") {
    return (dispatch) => {
      const filterUser = _.filter(user, (_user) => {
        return (
          _user.role === "STORE" && _user.accessToken != null
        )
      })

      for (let count = 0; count < filterUser.length; count++) {

        const notify = {
          message: dataNotification,

        }

        const notifyData = {
          data: notify,
          token: filterUser[count].accessToken
        }
        axios
          .post(process.env.REACT_APP_API_URL + "/notify", notifyData)
          .then((res) => {
            dispatch({ type: NOTIFY, payload: res.data });
          })
          .catch((err) => {
            console.log("Error Can't send nofify");
            console.error(err);
          });
      }
    }
  }
};

export const notifyMultiSendAdmin = (dataNotification, token, user) => {

  if (token === "ADMIN") {
    return (dispatch) => {
      const filterUser = _.filter(user, (_user) => {
        return (
          (_user.role === "ADMIN" || _user.role === "SUPERUSER") && _user.accessToken != null
        )
      })

      for (let count = 0; count < filterUser.length; count++) {

        const notify = {
          message: dataNotification,

        }

        const notifyData = {
          data: notify,
          token: filterUser[count].accessToken
        }
        axios
          .post(process.env.REACT_APP_API_URL + "/notify", notifyData)
          .then((res) => {
            dispatch({ type: NOTIFY, payload: res.data });
          })
          .catch((err) => {
            console.log("Error Can't send nofify");
            console.error(err);
          });
      }
    }
  }
};
