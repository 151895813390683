import React from "react";
import { Input } from "reactstrap";
export default function SearchUser(props) {

  const { allProduct, setProductData } = props;
  const onSeach = (word) => {
    const res = allProduct.filter(
      (product) =>
      product.product_name.includes(word) 
    );
    setProductData(res);
  };
  return (
    <div className="d-flex">
      <Input
        style={{ maxWidth: "400px" }}
        className="ml-1"
        type="text"
        placeholder="ค้นหา"
        onChange={(e) => {
          allProduct&&onSeach(e.target.value)
        }}
      />
    </div>
  );
}
