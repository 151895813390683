import React, { useState, useEffect } from "react";
import { Container, Label, Col, Row, Input, Card, CardBody, CardSubtitle, CardTitle, Button } from "reactstrap";
import { Bar, Line, Pie, HorizontalBar } from 'react-chartjs-2'
import moment from 'moment'
import 'moment/locale/th'
import _ from 'lodash'

const RepairNotificationStatistics = ({ orders, histories }) => {

  const generateGraphLabel = () => {
    let yearBeginner = moment().subtract(1, 'years').add(1, 'months')
    let yearlyLabel = []
    for (let months = 0; months < 12; months++) {
      let thatDayLabel = moment(yearBeginner).add(months, 'months').format('MMM YY')
      yearlyLabel.push(thatDayLabel)
    }

    return yearlyLabel
  }
  const graphLabel = generateGraphLabel()

  const generateRepairNotification = () => {
    //นับการแจ้งซ่อมทั้งหมดในแต่ละเดือนจาก orders และ histories ย้อนหลังไป 11 เดือน

    let yearlyGraphData = []

    _.map(orders, (data) => {


      let yearBeginner = moment().subtract(1, 'years').add(1, 'months')
      let dataMoment = moment(data.orderDate)

      for (let months = 0; months < 12; months++) {
        let thatDayMoment = moment(yearBeginner).add(months, 'month')

        if (!yearlyGraphData[months]) {
          yearlyGraphData[months] = 0
        }

        if (dataMoment.isSame(thatDayMoment, 'month')) {
          yearlyGraphData[months] = yearlyGraphData[months] + 1
        }
      }


    })

    _.map(histories, (data) => {


      let yearBeginner = moment().subtract(1, 'years').add(1, 'months')
      let dataMoment = moment(data.orders.orderDate)

      for (let months = 0; months < 12; months++) {
        let thatDayMoment = moment(yearBeginner).add(months, 'month')

        if (!yearlyGraphData[months]) {
          yearlyGraphData[months] = 0
        }

        if (dataMoment.isSame(thatDayMoment, 'month')) {
          yearlyGraphData[months] = yearlyGraphData[months] + 1
        }
      }


    })

    return yearlyGraphData

  }
  const repairNotificationData = generateRepairNotification()

  const repairAmount = {
    labels: graphLabel,
    datasets: [
      {
        label: 'การแจ้งซ่อม',
        backgroundColor:
          '#0080FF',

        hoverBackgroundColor:
          '#0703FF',

        data: repairNotificationData
      }
    ]
  }
  return (
    <Card>
      <CardBody>
        <h4 className="text-left"><strong>สถิติการแจ้งซ่อมรายเดือน</strong></h4>
        <Bar
          data={repairAmount}
          options={{
            legend: {
              display: true,
              position: 'bottom'
            },

          }}
          width={50}
          height={20}
        />
      </CardBody>
    </Card>
  )
}
export default RepairNotificationStatistics;