import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../actions";
import { useForm } from "react-hook-form";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  InputGroup,
  Label,
  CustomInput,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Row, CardTitle, CardText
} from "reactstrap";
import hash from "object-hash";
import { authen } from "../../assets/api/firebase";
import classnames from 'classnames';

const ModalCreateProduct = (props) => {
  const { buttonLabel, className, modal, toggle, setReRender } = props;
  const dispatch = useDispatch();
  const { register, handleSubmit, err } = useForm();
  const [unmountOnClose, setUnmountOnClose] = useState(true);
  const categories = useSelector((state) => state.categories);

  useEffect(() => {
    dispatch(actions.categoriesFetch());
    return () => { };
  }, []);


  const onCreateProduct = (data) => {

    let confirm = window.confirm("ยืนยันการเพิ่มวัสดุ")

    if (!confirm) {
      toggle();
    } else {
      let curentSelectCategories = categories.find(category => {
        return category.category_id === data.category_id
      })

      const name = data.product_name;
      const date = new Date();
      const hashID = hash({
        name: name,
        date: date,
      });

      const uploadTask = authen
        .storage()
        .ref(`EandE/${hashID}`)
        .put(data.product_image[0]);

      uploadTask.on(
        "state_changed",
        (snapshot) => { },
        (error) => {
          console.log(error);
        },
        () => {
          authen
            .storage()
            .ref("EandE")
            .child(hashID)
            .getDownloadURL()
            .then((url) => {

              data.product_image = url
              data.product_id = hashID;
              data.product_category = curentSelectCategories._id;
              data.product_inventory = 0;
              data.store_list = { store: data.product_store }
              dispatch(actions.productCreate(data));
              dispatch(actions.productsFetch());
              toggle();
              alert("บันทึกสำเร็จ")



            })

        });

    }
    dispatch(actions.productsFetch());
  };
  const categoriesList = () => {
    if (Array.isArray(categories)) {
      return (
        <>
          {categories.map((category) => {
            return (
              <option key={category.category_id} value={category.category_id}>
                {category.category_name}
              </option>
            );
          })}
        </>
      );
    } else {
      return "";
    }
  };

  return (
    <div>
      <Modal
        isOpen={modal}
        toggle={toggle}
        className={className}
        unmountOnClose={unmountOnClose}
      >
        <ModalHeader toggle={toggle}>เพิ่มวัสดุ</ModalHeader>
        <ModalBody>

          <div>

            <form>
              <br />
              <InputGroup size="lg">
                <Input
                  style={{ fontSize: "15px" }}
                  type="text"
                  placeholder="ชื่อ"
                  name="product_name"
                  innerRef={register}
                />
              </InputGroup>
              <br />
              <InputGroup size="lg">
                <Input
                  style={{ fontSize: "15px" }}
                  type="textarea"
                  placeholder="รายละเอียด"
                  name="product_detail"
                  innerRef={register}
                />
              </InputGroup>
              <br />
              <InputGroup size="md">
                <Input
                  type="text"
                  placeholder="หน่วยของวัสดุ"
                  name="product_unit"
                  innerRef={register}>
                </Input>
              </InputGroup>
              <br />

              <InputGroup size="md">

                <Input
                  type="number"
                  placeholder="ราคาซื้อ(ต่อหน่วย)"
                  defaultValues={0}
                  name="product_buy"
                  innerRef={register}>
                </Input>
              </InputGroup>
              <br />

              <InputGroup size="md">

                <Input
                  type="number"
                  placeholder="ราคาขาย(ต่อหน่วย)"
                  defaultValues={0}
                  name="product_price"
                  innerRef={register}>
                </Input>
              </InputGroup>
              {/* <div className="mt-1 ml-2 text-secondary">
              <Label>ถ้าเป็นอุปกรณ์ใส่ราคาเท่ากับ 0</Label>
            </div> */}
              <br />
              <InputGroup size="md">
                <Input
                  type="text"
                  placeholder="ยี่ห้อ"
                  name="product_brand"
                  innerRef={register}>
                </Input>
              </InputGroup>
              <br />
              <InputGroup size="md">
                <Input
                  type="text"
                  placeholder="แหล่งจัดซื้อ (ร้านค้า)"
                  name="product_store"
                  innerRef={register}>
                </Input>
              </InputGroup>
              <br />
              <Label style={{ fontSize: "15px", color: "black" }}>หมวดหมู่</Label>
              <InputGroup size="md">
                <Input
                  type="select"
                  name="category_id"
                  innerRef={register}>
                  {categoriesList()}
                </Input>
              </InputGroup>
              <br />
              <Label style={{ fontSize: "15px", color: "black" }}>รูปภาพ</Label>
              <InputGroup size="md">
                <Input
                  type="file"
                  name="product_image"
                  accept="image/*"
                  innerRef={register}>
                </Input>
              </InputGroup>
            </form>

          </div>

        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={handleSubmit(onCreateProduct)}>
            เพิ่ม
          </Button>{" "}
          <Button
            color="secondary"
            onClick={toggle}>
            ยกเลิก
          </Button>
        </ModalFooter>
      </Modal>



    </div>
  );
};

export default ModalCreateProduct;
