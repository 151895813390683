import React, { useState, useEffect } from "react";
import { Button, Container, Table, Input, Alert, Row, Col, Label, Card } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import * as role from "../../util/UserRole";
import { useHistory, useParams } from "react-router-dom";
import * as actions from '../../actions'
import * as UserStatus from "../../util/UserStatus";
import _ from "lodash";
import { RemoveIcon, AddIcon, TrashIcon, TextInput, Strong } from 'evergreen-ui'
import MaterialandProductMobile from "./MaterialandProductMobile";
import ServiceChargeMobile from "./ServiceChargeMobile";
import WorkingConditionsMobile from "./WorkingConditionsMobile";
import OverviewMobile from "./OverviewMobile";

// ดูรายละเอียดข้อมูลการซ่อม ประเมินราคาเบื้องต้น

const EstimatePriceMobile = () => {
  const orders = useSelector((state) => state.orders);
  const user = useSelector((state) => state.user);
  const quotation = useSelector((state => state.quotation));
  const system = useSelector((state) => state.system);
  const [systemData, setSystemData] = useState([])
  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams();
  const [selectProduct, setSelectProduct] = useState([]); //เก็บอุปกรณ์ที่เลือกจากคลัง
  const [productInventory, setProductInventory] = useState([]);
  const [reload, setReload] = useState(true);
  const [selectProductStore, setSelectProductStore] = useState([]); //เก็บอุปกรณ์ที่เลือกจากร้านค้า
  const [productStoreInventory, setProductStoreInventory] = useState([]);
  const [page, setPage] = useState(0);
  const [productBorrow, setProductBorrow] = useState([]);  //เก็บอุปกรณ์ที่เลือกจากคลังและร้านค้า
  const [productBorrowInventory, setProductBorrowInventory] = useState([]);
  const [productCost, setProductCost] = useState([]);  //เก็บอุปกรณ์ที่เป็นวัสดุ
  const [productCostInventory, setProductCostInventory] = useState([]);
  const [cost, setCost] = useState([]); //ค่าแรงต่อชิ้น
  const [ot, setOt] = useState([]); //เก็บค่าสภาพการทำงาน
  const [price, setPrice] = useState([0]); //มูลค่างาน
  const [vat, setVat] = useState([]); //ภาษี
  const [operatingCost, setOperatingCost] = useState([]); //ค่าดำเนินงาน
  const [taxFree, setTaxFree] = useState(true);
  const [noOperatingCost, setNoOperatingCost] = useState(true);
  const allUser = useSelector((state) => state.userManagement);
  const [userData, setUserData] = useState([]);
  const [selectUser, setSelectUser] = useState([]);
  const [approvedPerson, setApprovedPerson] = useState();
  const [imageURL, setImageURL] = useState();

  const allowAccess = user
    ? user.role == role.MECHANIC_ROLE ||
      user.role == role.ADMIN_ROLE ||
      user.role == role.SUPER_USER_ROLE
      ? true
      : false
    : history.push('/login');

  const filterOrder = _.find(orders, (_order) => {
    return (
      _order._id == params.id
    )
  })

  useEffect(() => {
    dispatch(actions.productsFetch());
    dispatch(actions.quotationsFetch());
    dispatch(actions.systemsFetch());
    dispatch(actions.fetchAllUser());
    return () => {
    }
  }, []);

  useEffect(() => {
    // console.log("Use Effect:",reload);
    setSystemData(system[0])
    return () => { }
  }, [reload, system])

  useEffect(() => {
    if (allUser.length != 0) {
      const filterUser = _.filter(allUser, (_user) => {
        return (
          _user.role === role.CONTRATOR_ROLE
        )
      })
      setUserData(filterUser)
    }
    return () => { };
  }, [allUser]);

  //บันทึกการประเมินราคาเบื้องต้น ประกอบไปด้วย รายการและจำนวนอุปกรณ์ที่ยืมทั้งหมด, คลัง, ร้านค้า, ค่าบริการ , ค่าสภาพการทำงาน , ค่าภาษี , ราคารวม
  //เปลี่ยนสถานะของช่างและผู้ใช้ โดยแยกตามความเร่งด่วนของงานซ่อม 
  // ปกติ ---> ต้องรอลูกค้ายืนยันก่อน ถึงจะสามารถดำเนินการซ่อมได้ ---> ตัดสต็อก หลังจากลูกค้ากดยืนยัน(หน้าเบิกอุปกรณ์)
  // เร่งด่วน ---> ช่างสามารถเบิกอุปกรณืได้เลย ---> ตัดสต็อกได้เลย(หน้าเบิกอุปกรณ์)
  const sendProduct = () => {

    const selectMechanic = _.find(userData, (_user) => {
      if (selectUser.length != 0) {
        return (
          _user.id === selectUser
        )
      } else {
        return userData[0]
      }

    })

    if (approvedPerson === undefined) {
      alert("โปรดใส่ชื่อผู้อนุมัติราคา")
    } else if (imageURL === undefined) {
      alert("โปรดเซ็นอนุมัติราคา")
    } else {
      let comfirm = window.confirm("ยืนยันการประเมินราคาเบื้องต้น")

      if (comfirm) {

        //ปกติ
        const dataNormal = {
          statusUser: UserStatus.USER_ESTIMATEPRICE,
          statusMechanic: UserStatus.MECHANIC_ESTIMATEPRICE,

          productBorrow: { //อุปกรณ์ที่ยืม
            product: productBorrow,
            inventory: productBorrowInventory,
          },
          productFormStore: { //อุปกรณ์จากร้านค้า
            product: selectProductStore,
            inventory: productStoreInventory,
          },
          productFormStock: { //อุปกรณ์จากคลัง
            product: selectProduct,
            inventory: productInventory,
          },
          material: { //วัสดุทั้งหมดที่เบิก
            product: productCost,
            inventory: productCostInventory,
          },
          estimate_price: { //การคิดราคาเบื้องต้น
            cost: cost, //ค่าบริการ
            ot: ot, //ค่าสภาพการทำงาน
            vat: vat, //ค่าภาษี
            priceOfProduct: price[0], //ค่าวัสดุ
            operatingCost: operatingCost, //เปอร์เซ็นของค่าดำเนินการ
            operatingPrice: (operatingCost / 100 * price[0]).toFixed(2), //ค่าดำเนินการ
          },
          IntroductoryPrice: (vat[0] + price[0] + operatingCost / 100 * price[0]).toFixed(2), //ราคารวม
          taxFree: taxFree,   //เปิด/ปิด คิดค่าภาษี
          noOperatingCost: noOperatingCost, //เปิด/ปิด ค่าดำเนินการ
          quotationDate: new Date(), //วันที่สร้างใบเสนอราคา
          expirationDate: new Date(new Date().setDate(new Date().getDate() + filterOrder.paymentTerms)), //วันหมดอายุ การยืนราคา
          // quotationId: "hyn" + _.padStart(quotation.length + 1, 3, "0") + "-" + (new Date().getFullYear() + 543), //เลขที่ใบเสนอราคา
          quotationId: systemData.prefix + _.padStart(systemData.number, 3, "0") + "-" + (new Date().getFullYear() + 543), //เลขที่ใบเสนอราคา

          mechanicId: selectMechanic.id,
          mechanic_name: selectMechanic.user_title + selectMechanic.user_firstname + " " + selectMechanic.user_lastname,
          mechanic_token: selectMechanic.accessToken,
          approvedPerson: approvedPerson,
          approvedPersonSign: imageURL,

        }

        //เร่งด่วน
        const dataUrgent = {
          statusMechanic: UserStatus.MECHANIC_ESTIMATEPRICE,
          // statusMechanic: UserStatus.MECHANIC_REQUEST,
          // statusAdmin: UserStatus.ADMIN_PREPARE,
          productBorrow: { //อุปกรณ์ที่ยืม
            product: productBorrow,
            inventory: productBorrowInventory,
          },
          productFormStore: { //อุปกรณ์จากร้านค้า
            product: selectProductStore,
            inventory: productStoreInventory,
          },
          productFormStock: { //อุปกรณ์จากคลัง
            product: selectProduct,
            inventory: productInventory,
          },
          material: { //วัสดุทั้งหมดที่เบิก
            product: productCost,
            inventory: productCostInventory,
          },
          estimate_price: { //การคิดราคาเบื้องต้น
            cost: cost, //ค่าบริการ
            ot: ot, //ค่าสภาพการทำงาน
            vat: vat, //ค่าภาษี
            priceOfProduct: price[0], //ค่าวัสดุ
            operatingCost: operatingCost, //เปอร์เซ็นของค่าดำเนินการ
            operatingPrice: (operatingCost / 100 * price[0]).toFixed(2), //ค่าดำเนินการ
          },
          IntroductoryPrice: (vat[0] + price[0] + operatingCost / 100 * price[0]).toFixed(2), //ราคารวม
          taxFree: taxFree,   //เปิด/ปิด คิดค่าภาษี
          noOperatingCost: noOperatingCost, //เปิด/ปิด ค่าดำเนินการ
          quotationDate: new Date(), //วันที่สร้างใบเสนอราคา
          expirationDate: new Date(new Date().setDate(new Date().getDate() + filterOrder.paymentTerms)), //กำหนดชำระงิน
          // quotationId: "hyn" + _.padStart(quotation.length + 1, 3, "0") + "-" + (new Date().getFullYear() + 543), //เลขที่ใบเสนอราคา
          quotationId: systemData.prefix + _.padStart(systemData.number, 3, "0") + "-" + (new Date().getFullYear() + 543), //เลขที่ใบเสนอราคา

          mechanicId: selectMechanic.id,
          mechanic_name: selectMechanic.user_title + selectMechanic.user_firstname + " " + selectMechanic.user_lastname,
          mechanic_token: selectMechanic.accessToken,
          approvedPerson: approvedPerson,
          approvedPersonSign: imageURL,

        }

        const creatQuotation = {
          order_id: filterOrder._id,
          // quotationId: "hyn" + _.padStart(quotation.length + 1, 3, "0") + "-" + (new Date().getFullYear() + 543), //เลขที่ใบเสนอราคา
          quotationId: systemData.prefix + _.padStart(systemData.number, 3, "0") + "-" + (new Date().getFullYear() + 543), //เลขที่ใบเสนอราคา

        }

        const setquotationId = {
          _id: systemData._id,
          number: systemData.number + 1,
          year: new Date().getFullYear() + 543
        }

        const notify = {
          message: "ขณะนี้ได้ประเมินราคาสำหรับงานแจ้งซ่อมของท่านเรียบร้อยแล้ว",

        }
        const notifyData = {
          data: notify,
          token: filterOrder.user_token
        }

        const notifyContrator = {
          message: user.user_title + user.user_firstname + " " + user.user_lastname + " ได้มอบหมายงานซ่อม" + filterOrder.problem + "งานแจ้งซ่อมของลูกค้าชื่อ " + filterOrder.user_name +
            " หน่วยงาน " + filterOrder.user_agency + "ให้ท่าน ซึ่งได้ยืนยันการแจ้งซ่อมเรียบร้อยแล้ว",

        }

        const notifyContratorData = {
          data: notifyContrator,
          token: filterOrder.mechanic_token
        }

        const token = "STORE"
        const dataNotification = "งานแจ้งซ่อมของลูกค้าชื่อ " + filterOrder.user_name +
          " หน่วยงาน " + filterOrder.user_agency + "ได้ยืนยันการแจ้งซ่อมเรียบร้อยแล้ว โปรดจัดเตรียมอุปกรณ์สำหรับงานแจ้งซ่อมนี้"

        const notifytoContrator = {
          message: user.user_title + user.user_firstname + " " + user.user_lastname + " ได้มอบหมายงานซ่อม" + filterOrder.problem + " ของลูกค้าชื่อ" + filterOrder.user_name + " หน่วยงาน " + filterOrder.user_agency + "ให้ท่าน",
        }
        const notifyDatatoContrator = {
          data: notifytoContrator,
          token: selectMechanic.accessToken
        }

        if (filterOrder.speed === "ปกติ") {
          dispatch(actions.createQuotation(creatQuotation));
          dispatch(actions.editSystem(setquotationId))
          dispatch(actions.orderPut(filterOrder._id, dataNormal))
          dispatch(actions.notifySend(notifyData))
          dispatch(actions.notifySend(notifyDatatoContrator))
          dispatch(actions.ordersFetch(user));
          history.push("/management/repair")
        } else {
          dispatch(actions.createQuotation(creatQuotation));
          dispatch(actions.editSystem(setquotationId))
          dispatch(actions.orderPut(filterOrder._id, dataUrgent))
          dispatch(actions.notifySend(notifyContratorData))
          dispatch(actions.notifyMultiSendStore(dataNotification, token, allUser))
          dispatch(actions.ordersFetch(user));
          // history.push("/management/repair")
          let navigate = window.confirm("เข้าสู่หน้าเบิกอุปกรณ์ต่อหรือไม่")
          if (navigate) {
            history.push("/management/borrow");
          } else {
            history.push("/management/repair");
          }
        }

      }
    }
  }

  const resetProductBorrow = () => {
    if (productBorrow.length != 0) {
      setProductBorrow([]);
      setProductBorrowInventory([]);
      setProductCost([]);
      setProductCostInventory([]);
      setCost([]);
      setPrice([]);
      setVat([]);
      setOperatingCost([]);
    }
  }

  const showProcess = () => {
    if (page === 0) {
      return (
        <>
          <MaterialandProductMobile
            selectProduct={selectProduct}
            selectProductStore={selectProductStore}
            setSelectProduct={setSelectProduct}
            setProductInventory={setProductInventory}
            setProductStoreInventory={setProductStoreInventory}
            productInventory={productInventory}
            productStoreInventory={productStoreInventory}
            reload={reload}
            setReload={setReload}
            resetProductBorrow={resetProductBorrow}
          />
        </>
      );
    } else if (page === 1) {
      return (
        <>
          <ServiceChargeMobile
            selectProduct={selectProduct}
            selectProductStore={selectProductStore}
            productInventory={productInventory}
            productStoreInventory={productStoreInventory}
            productBorrow={productBorrow}
            productBorrowInventory={productBorrowInventory}
            cost={cost}
            productCost={productCost}
            productCostInventory={productCostInventory}
            setProductBorrow={setProductBorrow}
            setProductBorrowInventory={setProductBorrowInventory}
            setProductCost={setProductCost}
            setProductCostInventory={setProductCostInventory}
            setCost={setCost}
            setPrice={setPrice}
            setVat={setVat}
            setOperatingCost={setOperatingCost}
          />
        </>
      );
    } else if (page === 2) {
      return (
        <>
          <WorkingConditionsMobile
            reload={reload}
            setReload={setReload}
            ot={ot}
            setOt={setOt}
          />

        </>
      );
    } else if (page === 3) {
      return (
        <>
          <OverviewMobile
            productBorrow={productBorrow}
            productBorrowInventory={productBorrowInventory}
            cost={cost}
            productCost={productCost}
            productCostInventory={productCostInventory}
            ot={ot}
            price={price}
            vat={vat}
            operatingCost={operatingCost}
            setOperatingCost={setOperatingCost}
            taxFree={taxFree}
            setTaxFree={setTaxFree}
            noOperatingCost={noOperatingCost}
            setNoOperatingCost={setNoOperatingCost}
            userData={userData}
            setSelectUser={setSelectUser}
            setApprovedPerson={setApprovedPerson}
            imageURL={imageURL}
            setImageURL={setImageURL}
          />
        </>
      );
    }
  }

  const showbutton = () => {
    if (page != 0 && page != 3) {
      return (
        <div className="text-right">
          <br />
          <Button
            outline
            color="secondary"
            onClick={() => {
              setPage(page - 1)
            }}
          >ย้อนกลับ</Button>
          {" "}
          <Button
            outline
            color="primary"
            onClick={() => {
              setPage(page + 1)
            }}
          >หน้าถัดไป</Button>

        </div>);
    } else if (page != 0 && page === 3) {
      return (
        <div className="text-right">
          <br />
          <Button
            outline
            color="secondary"
            onClick={() => {
              setPage(page - 1)
            }}
          >ย้อนกลับ</Button>
          {" "}
          <Button
            outline
            color="success"
            onClick={() => {
              sendProduct();
            }}
          >บันทึก</Button>

        </div>);
    } else {
      return (
        <div className="text-right">
          <Button
            outline
            color="primary"
            onClick={() => {
              setPage(page + 1)
            }}
          >หน้าถัดไป</Button>

        </div>);
    }

  }

  if (allowAccess) {
    return (
      <>

        <Container fluid className="light-th"
          style={{
            backgroundColor: "#ffffff",
            boxShadow: "1px 1px 3px #d9d9d9",
            padding: "30px",
            marginTop: "50px",
            maxWidth: "900px",
          }}
        >
          <div >
            <h2 className="text-center">ประเมินราคาเบื้องต้น</h2>
            <br />
            {showProcess()}
            {showbutton()}
          </div>
        </Container>

      </>
    )
  } else {
    return (
      <>
      </>
    )
  }
}
export default EstimatePriceMobile;