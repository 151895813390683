import React, { useState,useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileAlt } from '@fortawesome/free-solid-svg-icons'
import { useHistory, Link } from 'react-router-dom'
import { Badge, Button, Row } from 'reactstrap';
import * as UserStatus from "../../util/UserStatus";
import * as UserRole from "../../util/UserRole";

const MaterialTables =({material, inventory, labour , index})=> {
const history = useHistory();

return(
    <tr className="text-center">
        <td scope="row">{index}</td>
        <td className="text-left">{material.product_name}</td>
        <td>{material.product_unit}</td>
        <td>{inventory}</td>
        <td>{inventory}</td>
        <td>{material.product_price}</td>
        <td>{inventory}</td>
        <td>{labour}</td>
        <td>{parseInt(material.product_price * inventory) + parseInt(labour * inventory)}</td>
    </tr>
)
}
export default MaterialTables;