import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as actions from '../../actions'
import {
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormGroup,
  Input,
  Form,
  Button,
  Col,
  InputGroup,
  ModalFooter,
  FormFeedback,
} from 'reactstrap'
import { useForm } from 'react-hook-form'

const ModalEditStock = (props) => {
  const { className, modal, toggle } = props
  const { register, handleSubmit, err } = useForm()
  const dispatch = useDispatch()
  const [selectedProduct, setSelectedProduct] = useState('')
  const [amount, setAmount] = useState(0)
  const products = useSelector((state) => state.products)
  const user = useSelector((state) => state.user);

  useEffect(() => {
    return () => { }
  })

  const changeAmount = (value) => {
    if (value < 0) {
      setAmount(0)
    } else {
      setAmount(value)
    }
  }
  const productList = () => {
    if (Array.isArray(products)) {
      return (
        <>
          {products.map((_product) => {
            return (
              <option key={_product._id} value={_product._id}>
                {_product.product_name} ({_product.product_inventory})
              </option>
            )
          })}
        </>
      )
    }
  }

  const onSubmitStockChange = (data) => {
    let confirm = window.confirm('ยืนยันการแก้ไขสต็อกอุปกรณ์')
    if (confirm) {
      products.find(_product => {
        if (_product._id === data._id) {
          if (data.radio_command === "add") {

            let dataLogs = {
              type: "เพิ่มสต๊อก",
              product: {
                _id: _product._id,
                product_name: _product.product_name,
                prev_inventory: parseInt(_product.product_inventory),
                next_inventory: parseInt(_product.product_inventory) + parseInt(data.amount),
                inventory: parseInt(data.amount),
                product_store: _product.product_store,
                product_unit: _product.product_unit,
              },

              transactionDate: new Date(),
              handler: user.user_firstname + " " + user.user_lastname,
              referenceOrder: "-",
              quotationId: "-",

            }

            data.product_inventory = parseInt(_product.product_inventory) + parseInt(data.amount);
            dispatch(actions.editStockProduct(dataLogs))
            dispatch(actions.productEdit(_product._id, data));
            dispatch(actions.productsFetch())
          } else if (data.radio_command === "remove") {
            let dataLogs = {
              type: "ลดสต๊อก",
              product: {
                _id: _product._id,
                product_name: _product.product_name,
                prev_inventory: parseInt(_product.product_inventory),
                next_inventory: parseInt(_product.product_inventory) - parseInt(data.amount),
                inventory: parseInt(data.amount),
                product_store: _product.product_store,
                product_unit: _product.product_unit,
              },

              transactionDate: new Date(),
              handler: user.user_firstname + " " + user.user_lastname,
              referenceOrder: "-",
              quotationId: "-",

            }
            data.product_inventory = parseInt(_product.product_inventory) - parseInt(data.amount);
            dispatch(actions.editStockProduct(dataLogs))
            dispatch(actions.productEdit(_product._id, data));
            dispatch(actions.productsFetch())
          } else {
            let dataLogs = {
              type: "แก้ไขสต๊อก",
              product: {
                _id: _product._id,
                product_name: _product.product_name,
                prev_inventory: parseInt(_product.product_inventory),
                next_inventory: parseInt(data.amount),
                inventory: parseInt(data.amount),
                product_store: _product.product_store,
                product_unit: _product.product_unit,
              },

              transactionDate: new Date(),
              handler: user.user_firstname + " " + user.user_lastname,
              referenceOrder: "-",
              quotationId: "-",

            }
            data.product_inventory = parseInt(data.amount);
            dispatch(actions.editStockProduct(dataLogs))
            dispatch(actions.productEdit(_product._id, data));
            dispatch(actions.productsFetch())
          }


        }
      })
      setAmount(0)
      toggle()
    }
  }
  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}>แก้ไขอุปกรณ์ในสต็อก</ModalHeader>
        <ModalBody>
          <form>
            <Label>เลือกอุปกรณ์</Label>
            <InputGroup size="lg">
              <Input
                style={{ fontSize: '15px' }}
                type="select"
                name="_id"
                innerRef={register}
                onChange={(event) => setSelectedProduct(event.target.value)}
              >
                {productList()}
              </Input>
            </InputGroup>
            <br />
            <Col sm={10}>
              <Label>ต้องการที่จะแก้ไข</Label>
              <FormGroup check>
                <Label check>
                  <Input
                    type="radio"
                    name="radio_command"
                    value="add"
                    innerRef={register}
                  />
                  เพิ่มสต็อก
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input
                    type="radio"
                    name="radio_command"
                    value="remove"
                    innerRef={register}
                  />
                  ลดสต็อก
                </Label>
              </FormGroup>

              <FormGroup check>
                <Label check>
                  <Input
                    type="radio"
                    name="radio_command"
                    value="change"
                    innerRef={register}
                  />
                  แก้ไขตัวเลขใหม่
                </Label>
              </FormGroup>
              <br />
              <FormGroup>
                <InputGroup>
                  <Label className="mt-2 mr-2">จำนวน</Label>
                  <Input
                    style={{ fontSize: '15px' }}
                    type="number"
                    name="amount"
                    placeholder="จำนวนที่ต้องการเพิ่ม ลด หรือ แก้ไข"
                    onChange={(event) => {
                      changeAmount(event.target.value)
                    }}
                    innerRef={register}
                    value={amount}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            <br />
          </form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSubmit(onSubmitStockChange)}>
            บันทึก
          </Button>{' '}
          <Button color="secondary" onClick={toggle}>
            ยกเลิก
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default ModalEditStock
