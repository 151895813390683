import React, { useState, useEffect } from "react";
import { Container, Table, Row, Col, Badge, Input, Label, InputGroup, Button } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import * as roles from "../../util/UserRole";
import { useHistory, useParams } from "react-router-dom";
import * as actions from '../../actions'
import * as UserStatus from "../../util/UserStatus";
import _ from "lodash";
import { useForm } from "react-hook-form";
import { authen } from "../../assets/api/firebase";
import hash from "object-hash";

const UserProfile = () => {
    const users = useSelector((state) => state.userManagement);
    const history = useHistory()
    const dispatch = useDispatch()
    const params = useParams();
    const [width, setWidth] = React.useState(window.innerWidth);
    const breakpoint = 620;

    const user = _.find(users, (_user) => {
        return (
            _user.id === params.id
        )
    })

    useEffect(() => {
        //dispatch(actions.fetchAllUser());
        window.addEventListener('resize', () => setWidth(window.innerWidth));
        return () => { };
    }, []);

    useEffect(() => {
        //  dispatch(actions.fetchAllUser());
        window.addEventListener('resize', () => setWidth(window.innerWidth));
        return () => { };
    }, [users]);

    const { register, handleSubmit, errors } = useForm({
        defaultValues: {
            user_title: user.user_title,
            user_firstname: user.user_firstname,
            user_lastname: user.user_lastname,
            user_tel: user.user_tel,
            user_address: user.user_address,
            user_email: user.user_email,
            user_fax: user.user_fax,
            user_agency: user.user_agency,
            paymentTerms: user.paymentTerms,
            taxpayerNumber: user.taxpayerNumber,
            credit: user.credit,
        }
    });


    return width < breakpoint ? (
        <>
            <Container fluid className="light-th"
                style={{
                    marginTop: "50px",
                    maxWidth: "750px",
                }}
            >
                <Row>
                    <Col >
                        <Button
                            outline
                            color="secondary"
                            onClick={
                                history.goBack
                            }
                        >ย้อนกลับ</Button>
                    </Col>
                    <Col xs="7">
                        <h5 className="text-left">โปรไฟล์</h5>
                    </Col>
                </Row>
                <br />
                <div
                    style={{
                        backgroundColor: "#ffffff",
                        boxShadow: "1px 1px 3px #d9d9d9",
                        padding: "30px",
                    }}>
                    <hr />
                    <Row>
                        <Col>
                            <h4 className="text-left text-danger">โปรไฟล์</h4>
                        </Col>
                        <Col className="text-right">
                            <Button
                                color="warning"
                                onClick={() => {
                                    history.push("/edit/customer_information/" + user.id)
                                }}
                            >
                                แก้ไข
                        </Button>
                        </Col>
                    </Row>

                    <hr />
                    <br />
                    <Row>
                        <Col>
                            <div className="ml-4">
                                <h5><strong>ชื่อผู้ติดต่อ : </strong>{user.user_title}{user.user_firstname} {user.user_lastname}</h5>
                                <h5 className="mt-2"><strong>หน่วยงาน : </strong>{user.user_agency}</h5>
                                <h5 className="mt-2"><strong>หมายเลขผู้เสียภาษี : </strong>{user.taxpayerNumber}</h5>
                                <h5 className="mt-2"><strong>เบอรโทร : </strong>{user.user_tel}</h5>
                                <h5 className="mt-2"><strong>แฟกซ์ : </strong>{user.user_fax}</h5>
                                <h5 className="mt-2"><strong>อีเมล : </strong>{user.user_email}</h5>
                                <h5 className="mt-2"><strong>ที่อยู่ : </strong>{user.user_address}</h5>
                                <h5 className="mt-2"><strong>กำหนดยืนราคา : </strong>{user.paymentTerms} วัน</h5>
                                <h5 className="mt-2"><strong>เครดิต : </strong>{user.credit}</h5>

                            </div>
                        </Col>
                        <Col sm="3">
                            <div className="text-center mr-4">
                                <img
                                    style={{ width: "100%" }}
                                    src={user.user_image}
                                />
                            </div>
                        </Col>
                    </Row>



                </div>
            </Container>

        </>
    ) : (  //desktop
        <>
            <Container fluid className="light-th"
                style={{
                    marginTop: "50px",
                    maxWidth: "750px",
                }}
            >
                <Row>
                    <Col >
                        <Button
                            outline
                            color="secondary"
                            onClick={
                                history.goBack
                            }
                        >ย้อนกลับ</Button>
                    </Col>
                    <Col xs="7">
                        <h2 className="text-left">โปรไฟล์</h2>
                    </Col>
                </Row>
                <br />
                <div
                    style={{
                        backgroundColor: "#ffffff",
                        boxShadow: "1px 1px 3px #d9d9d9",
                        padding: "30px",
                    }}>
                    <hr />
                    <Row>
                        <Col>
                            <h2 className="text-left text-danger">โปรไฟล์</h2>
                        </Col>
                        <Col className="text-right">
                            <Button
                                color="warning"
                                onClick={() => {
                                    history.push("/edit/customer_information/" + user.id)
                                }}
                            >
                                แก้ไข
                        </Button>
                        </Col>
                    </Row>

                    <hr />
                    <br />
                    <Row>
                        <Col>
                            <div className="ml-4">
                                <h4>ชื่อผู้ติดต่อ : {user.user_title}{user.user_firstname} {user.user_lastname}</h4>
                                <h4 className="mt-2">หน่วยงาน : {user.user_agency}</h4>
                                <h4 className="mt-2">หมายเลขผู้เสียภาษี : {user.taxpayerNumber}</h4>
                                <h4 className="mt-2">เบอรโทร : {user.user_tel}</h4>
                                <h4 className="mt-2">แฟกซ์ : {user.user_fax}</h4>
                                <h4 className="mt-2">อีเมล : {user.user_email}</h4>
                                <h4 className="mt-2">ที่อยู่ : {user.user_address}</h4>
                                <h4 className="mt-2">กำหนดยืนราคา : {user.paymentTerms} วัน</h4>
                                <h4 className="mt-2">เครดิต : {user.credit}</h4>

                            </div>
                        </Col>
                        <Col sm="3">
                            <div className="text-center mr-4">
                                <img
                                    style={{ width: "100%" }}
                                    src={user.user_image}
                                />
                            </div>
                        </Col>
                    </Row>



                </div>
            </Container>

        </>
    )

}
export default UserProfile;