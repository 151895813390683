import {
  PRODUCTLOGS_FETCH,
  PRODUCTLOG_FETCH,
  PRODUCTLOG_BORROW,
  PRODUCTLOG_RETURN,
} from "../actions/types";
export default function (state = [], action) {
  switch (action.type) {
    case PRODUCTLOG_FETCH:
      return action.payload;
    case PRODUCTLOGS_FETCH:
      return action.payload;
    case PRODUCTLOG_BORROW:
      return state;
    case PRODUCTLOG_RETURN:
      return state;
    default:
      return state; // ค่าเดิม
  }
}
