import axios from "axios";
import { FETCH_USER, EDIT_USER, UPDATE_USER, CREATE_USER, GET_USERDATA, AUTH_STATE } from "./types";
import { authen } from "../../src/assets/api/firebase";

const firebase = authen // For more Understandable
export const fetchAllUser = () => {
  return (dispatch) => {
    console.log("Get All User Detail |");

    axios.get(process.env.REACT_APP_API_URL + "/user/all/").then((res) => {
      dispatch({ type: FETCH_USER, payload: res.data });
    });
  };
};

export const editUserData = (uid, value) => {
  return (dispatch) => {
    console.log("Edit User Role ");
    axios
      .put(process.env.REACT_APP_API_URL + "/user/" + uid, value)
      .then(() => {
        console.log("Request to Put Send Successfully");
        axios.get(process.env.REACT_APP_API_URL + "/user/all/").then((res) => {
          window.alert("Edit User Success");
          dispatch({ type: EDIT_USER, payload: res.data });
        });

      })
      .catch((err) => {
        console.log("Error");
        console.error(err);
      });
  };
};

export const deleteUser = (uid) => {
  return (dispatch) => {
    console.log("Delete User");
    axios
      .delete(process.env.REACT_APP_API_URL + "/user/" + uid)
      .then((res) => {
        axios.get(process.env.REACT_APP_API_URL + "/user/all/").then((res) => {
          window.alert("Delete User Success");
          dispatch({ type: EDIT_USER, payload: res.data });
        });
      })
      .catch((err) => {
        window.alert("ลบไม่สำเร็จ");
        console.err(err);
      });
  };
};

export const updateUser = (uid, value, id) => {

  return (dispatch) => {
    console.log("Update User");
    axios
      .put(process.env.REACT_APP_API_URL + "/user/edit/" + uid, value)
      .then(() => {
        console.log("Request to Put Send Successfully");
        axios.get(process.env.REACT_APP_API_URL + "/user/all/").then((res) => {
          // window.alert("Edit User Success");
          dispatch({ type: FETCH_USER, payload: res.data });
        });

      })
      .catch((err) => {
        console.log("Error");
        console.error(err);
      });


  }
}

export const createUserData = (user,id) => {
  return (dispatch) => {
    axios.post(process.env.REACT_APP_API_URL + "/user", user).then((res) => {
      axios.get(process.env.REACT_APP_API_URL + "/user/all/").then((res) => {
        dispatch({ type: CREATE_USER, payload: res.data });
      });

    });
  };
};

export const authCurrent = (id) => {
  return (dispatch) => firebase.auth()
      .getUsers([
          { uid: id },
      ])
      .then((res) => {
          dispatch({ type: AUTH_STATE, payload: res.data });
      })

}

export const getUserData = (user_id) => {
  return (dispatch) => {
    axios
      .get(process.env.REACT_APP_API_URL + "/user/" + user_id)
      .then((res) => {
        dispatch({ type: GET_USERDATA, payload: res.data });
      });
  };
};

export const editStarRating = (uid, value, id) => {
  return (dispatch) => {
    axios
      .get(process.env.REACT_APP_API_URL + "/user/" + uid)
      .then((res) => {
        if (res.data.mechanicRating != undefined && res.data.ratingValue != undefined && res.data.ratingCount != undefined) {
          const ratingValue = parseInt(res.data.ratingValue) + parseInt(value);
          const ratingCount = parseInt(res.data.ratingCount) + 1;
          const mechanicRating = ratingValue / ratingCount
          const data = {
            ratingValue: ratingValue,
            ratingCount: ratingCount,
            mechanicRating: mechanicRating,
            role: res.data.role
          }
          axios
            .put(process.env.REACT_APP_API_URL + "/user/edit/" + uid, data)
            .then(() => {
              axios.put(process.env.REACT_APP_API_URL + "/order/" + id, data)
                .then((res) => {
                  axios.get(process.env.REACT_APP_API_URL + "/user/all/").then((res) => {
                    dispatch({ type: FETCH_USER, payload: res.data });
                  });
                })
            })
        } else {
          const ratingValue = parseInt(value);
          const ratingCount = 1;
          const mechanicRating = ratingValue / ratingCount
          const data = {
            ratingValue: ratingValue,
            ratingCount: ratingCount,
            mechanicRating: mechanicRating,
            role: res.data.role

          }
          axios
            .put(process.env.REACT_APP_API_URL + "/user/edit/" + uid, data)
            .then(() => {
              axios.put(process.env.REACT_APP_API_URL + "/order/" + id, data)
                .then((res) => {
                  axios.get(process.env.REACT_APP_API_URL + "/user/all/").then((res) => {
                    dispatch({ type: FETCH_USER, payload: res.data });
                  });
                })
            })
        }

      });
  };

};