import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { authen } from "../../../assets/api/firebase";
import { Container, Row, Col, Button, Form, Input } from "reactstrap";

const firebase = authen; // For more Understandable

const ForgottenPassword = () => {
  const history = useHistory();
  const { handleSubmit, register, errors } = useForm();
  const authData = useSelector((state) => state.auth);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (user && authData) {
      history.push("/");
    }
  });

  const onResetPassword = (data) => {
    const confirm = window.confirm(
      "คุณต้องการรีเซ็ตรหัสผ่านอีเมล : " + data.email
    );
    if (confirm) {
      firebase
        .auth()
        .sendPasswordResetEmail(data.email)
        .then(function (user) {
          alert(`รีเซ็ตรหัสผ่านสำเร็จ. กรุณาตรวจสอบอีเมลของท่าน`);
          history.push("/");
        })
        .catch(function (e) {
          console.log(e);
        });
    }
  };

  return (
    <Container
      style={{
        backgroundColor: "#ffffff",
        boxShadow: "1px 1px 3px #d9d9d9",
        padding: "30px",
        maxWidth: "500px",
      }}
      className="light-th"
    >
      <Row>
        <Col md="5" lg>
          <h4>ลืมรหัสผ่าน</h4>
        </Col>
        <Col md="7" style={{ fontSize: "12px" }} className="text-right mt-2">
          มีบัญชีแล้วใช่หรือไม่{" "}
          <Button
            color="link"
            tag={Link}
            style={{ fontSize: "12px" }}
            className="text-danger"
            to={"/login"}
          >
            ลงชื่อเข้าใช้
          </Button>
        </Col>
      </Row>
      <hr />
      <Form onSubmit={handleSubmit(onResetPassword)}>
        <Input
          style={{ fontSize: "15px" }}
          type="email"
          placeholder="Email"
          name="email"
          innerRef={register({ required: true, pattern: /^\S+@\S+$/i })}
          required
        ></Input>
        <br />
        <Button className="light-th" color="danger" block>
          ยืนยัน
        </Button>
      </Form>
    </Container>
  );
};

export default ForgottenPassword;