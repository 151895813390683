import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import _ from "lodash";
import NormalQuatation from "./NormalQuotation";
import NoVatQuatation from "./NoVatQuotation";
import NoOverheadQuatation from "./NoOverheadQuotation";
import NoVatNoOverheadQuatation from "./NoVatNoOverheadQuotation";
import * as actions from '../../../actions'
import { Container, Table, Row, Col, Badge, Label, InputGroup, Card, Button, CardBody, ListGroup, ListGroupItem, } from "reactstrap";
import MaterialTables from "../../tables/MaterialTables";
import OtTables from "../../tables/OtTables";
import THBText from 'thai-baht-text';

// ใบเสนอราคาเบื้องต้น
const Quotation = () => {
  const orders = useSelector((state) => state.orders);
  const user = useSelector((state) => state.user);
  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams();
  const [width, setWidth] = React.useState(window.innerWidth);
  const breakpoint = 620;
  const system = useSelector((state) => state.system);
  const [systemData, setSystemData] = useState([])

  const filterOrder = _.find(orders, (_order) => {
    return (
      _order._id == params.id
    )
  })

  useEffect(() => {
    dispatch(actions.ordersFetch(user))
    dispatch(actions.systemsFetch());
    window.addEventListener('resize', () => setWidth(window.innerWidth));
    return () => { }
  }, [])

  useEffect(() => {
    setSystemData(system[0])
    return () => { };
  }, [system]);

  const material = filterOrder.materialUse[0];
  const estimate = filterOrder.reestimate_price[0];
  const indexInventory = material.product.length;

  function printDiv(divName) {
    var printContents = document.getElementById(divName).innerHTML;
    var originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;

    window.print();

    document.body.innerHTML = originalContents;
    window.location.reload()
  }

  const showCredit = () => {
    if (filterOrder.credit === "เงินสด") {
      return (
        <>ชำระเงินเมื่อส่งมอบงาน</>
      )
    } else {
      return <>{filterOrder.credit}เมื่อเสร็จสิ้นงาน</>
    }
  }

  const showButton = () => {
    if (user.role != "USER") {
      return (
        <>
          <Row className="print-disable">
            <Col xs="2">
              <Button
                outline
                color="secondary"
                onClick={
                  history.goBack
                }
              >ย้อนกลับ</Button>
            </Col>
            <Col xs="7">
              <h2 className="text-center">ใบเสนอราคา</h2>
            </Col>
            <Col className="text-right">
              <Button color="info" onClick={() => printDiv("printarea")}>พิมพ์</Button>{" "}
              <Button
                color="warning"
                onClick={() =>
                  history.push("/reestimate_price/" + filterOrder._id)
                }
              >แก้ไข</Button>
            </Col>
          </Row>
        </>
      )
    } else {
      return (
        <>
          <Row className="print-disable">
            <Col xs="2">
              <Button
                outline
                color="secondary"
                onClick={
                  history.goBack
                }
              >ย้อนกลับ</Button>
            </Col>
            <Col xs="7">
              <h2 className="text-center">ใบเสนอราคา</h2>
            </Col>
            <Col className="text-right">
              <Button color="info" onClick={() => printDiv("printarea")}>พิมพ์</Button>{" "}
            </Col>
          </Row>
        </>
      )
    }
  }

  const showButtonOnMobile = () => {
    if (user.role != "USER") {
      return (
        <>
          <Row className="print-disable">
            <Col xs="3">
              <Button
                size="sm"
                outline
                color="secondary"
                onClick={
                  history.goBack
                }
              >ย้อนกลับ</Button>
            </Col>
            <Col xs="5">
              <h5 className="text-center">ใบเสนอราคา</h5>
            </Col>
            <Col className="text-right">
              <Button size="sm" color="info" onClick={() => printDiv("printarea")}>พิมพ์</Button>{" "}
              <Button
                size="sm"
                color="warning"
                onClick={() =>
                  history.push("/reestimate_price/" + filterOrder._id)
                }
              >แก้ไข</Button>
            </Col>
          </Row>
        </>
      )
    } else {
      return (
        <>
          <Row className="print-disable">
            <Col xs="3">
              <Button
                size="sm"
                outline
                color="secondary"
                onClick={
                  history.goBack
                }
              >ย้อนกลับ</Button>
            </Col>
            <Col xs="6">
              <h5 className="text-center">ใบเสนอราคา</h5>
            </Col>
            <Col className="text-right">
              <Button size="sm" color="info" onClick={() => printDiv("printarea")}>พิมพ์</Button>{" "}
            </Col>
          </Row>
        </>
      )
    }
  }

  const showInitaialQuotation = () => {
    if (filterOrder.reNoOperatingCost === true && filterOrder.reTaxFree === true) {

      return (
        <>
          <NormalQuatation />
        </>
      )
    }
    else if (filterOrder.reNoOperatingCost === true && filterOrder.reTaxFree === false) {

      return (
        <>
          <NoVatQuatation />
        </>
      )
    }
    else if (filterOrder.reNoOperatingCost === false && filterOrder.reTaxFree === true) {

      return (
        <>
          <NoOverheadQuatation />
        </>
      )
    }
    else if (filterOrder.reNoOperatingCost === false && filterOrder.reTaxFree === false) {

      return (
        <>
          <NoVatNoOverheadQuatation />
        </>
      )
    }
  }

  const showSign = () => {
    if (user.role === "USER") {
      return (
        <>
          <Table className="text-center" >
            <tr >
              <td className="border-top-0">
                <Row >
                  <Col><p><strong>ผู้อนุมัติราคา</strong></p></Col>
                  <Col md="8" className="text-left">
                    <img
                      src={filterOrder.approvedPersonSign}
                      width="50%"
                    />
                  </Col>
                </Row>
                <p><strong>( {filterOrder.approvedPerson} )</strong></p>
                <p>วันที่ {new Date(filterOrder.quotationDate).toLocaleDateString("th")}</p>
              </td>
              <td className="border-top-0">
                <Row>
                  <Col className="text-left"><p><strong>ผู้ว่าจ้างอนุมัติ</strong></p></Col>
                </Row>
                <p><strong>( {filterOrder.user_name} )</strong></p>
                <p>วันที่.........................................................................................</p>
              </td>

            </tr>
          </Table>
        </>
      )
    } else {
      return (
        <>
          <Table className="text-center" >
            <tr >
              <td className="border-top-0">

                <p><strong>ผู้อนุมัติราคา......................................................</strong></p>
                <p><strong>( {filterOrder.approvedPerson} )</strong></p>
                <p>วันที่...................................................................</p>
              </td>
              <td className="border-top-0">
                <p><strong>ผู้ว่าจ้างอนุมัติ..................................................... (ประทับตรา)</strong></p>
                <p><strong>( {filterOrder.user_name} )</strong></p>
                <p>วันที่..................................................................................................</p>
              </td>

            </tr>
          </Table>
        </>
      )
    }
  }

  const showSignOnMoblie = () => {
    if (user.role === "USER") {
      return (
        <>
          <ListGroup className="text-center">
            <ListGroupItem>
              <Row >
                <Col xs="4"><p><strong>ผู้อนุมัติราคา</strong></p></Col>
                <Col className="text-left">
                  <img
                    src={filterOrder.mechanicSign}
                    width="50%"
                  />
                </Col>
              </Row>
              <p className="mt-2 text-center"><strong>( {filterOrder.mechanic_name} )</strong></p>
              <p>วันที่ {new Date(filterOrder.quotationDate).toLocaleDateString("th")}</p>
            </ListGroupItem>
            <ListGroupItem>
              <Row>
                <Col className="text-left"><p><strong>ผู้ว่าจ้างอนุมัติ</strong></p></Col>
              </Row>
              <p><strong>( {filterOrder.user_name} )</strong></p>
              <p>วันที่.........................................................................................</p>
            </ListGroupItem>
          </ListGroup>

        </>
      )
    } else {
      return (
        <>
          <ListGroup className="text-center">
            <ListGroupItem>
              <p><strong>ผู้อนุมัติราคา......................................................</strong></p>
              <p><strong>( {filterOrder.mechanic_name} )</strong></p>
              <p>วันที่ {new Date(filterOrder.quotationDate).toLocaleDateString("th")}</p>
            </ListGroupItem>
            <ListGroupItem>
              <Row>
                <Col className="text-left"><p><strong>ผู้ว่าจ้างอนุมัติ</strong></p></Col>
              </Row>
              <p><strong>( {filterOrder.user_name} )</strong></p>
              <p>วันที่.........................................................................................</p>
            </ListGroupItem>
          </ListGroup>
        </>
      )
    }
  }

  if (width < breakpoint) {
    return (
      //moblie
      <>

        <Container fluid className="light-th"
          id="printarea"
          style={{
            marginTop: "50px",
            maxWidth: "900px",
          }}
        >
          {showButtonOnMobile()}

          <br />
          <div
            style={{
              backgroundColor: "#ffffff",
              boxShadow: "1px 1px 3px #d9d9d9",
              padding: "30px",
            }}>

            <Row>
              <Col xs="3" className="mt-2">
                <img
                  src={systemData.company_logo}
                  width="150%"
                />
              </Col>
              <Col>
                <Label style={{ fontSize: "10px" }}><u>{systemData.company_name_thai}</u></Label>
                <Label style={{ fontSize: "10px" }}>{systemData.company_name_eng}</Label>
                <Label style={{ fontSize: "10px" }}>{systemData.company_address_thai}</Label>
                <Label style={{ fontSize: "10px" }}>{systemData.company_address_eng}</Label>
                <Label style={{ fontSize: "10px" }}><strong>E-mail :</strong> {systemData.company_email} <strong>Tel :</strong> {systemData.company_tel}</Label>
                <Label style={{ fontSize: "10px" }}><strong>เลขที่ผู้เสียภาษี</strong> {systemData.company_taxpayer_number}</Label>
              </Col>
            </Row>

            <hr />
            <div className="text-center" style={{ backgroundColor: "#e7feff" }}>
              <h6 ><strong>ใบเสนอราคา</strong></h6>
            </div>
            <hr />
            <Row>
              <Col>
                <p>เลขที่ : {filterOrder.quotationId}</p>
                <p>เรียน : {filterOrder.user_name}</p>
                <p>หน่วยงาน : {filterOrder.user_agency}</p>
                <p>โครงการ : {filterOrder.project}</p>
              </Col>
              <Col>
                <p>วันที่ {new Date(filterOrder.quotationDate).toLocaleDateString("th")}</p>
                <p>โทร : {filterOrder.user_tel}</p>
                <p>แฟกซ์ : {filterOrder.user_fax}</p>
              </Col>
            </Row>
            <br />

            {showInitaialQuotation()}
            <br />
            <Row className="mb-4">
              <Col ></Col>
              <Col>
                <Table>
                  <tr >
                    <td className="border-top-0 text-left" >
                      <p>กำหนดยืนราคา {filterOrder.paymentTerms}  วัน</p>
                      <p>กำหนดชำระเงิน {showCredit()}</p>
                      <p>กรุณาเซ็นต์ตอบรับ หรือ PO เมื่ออนุมัติ</p>
                    </td>
                  </tr>
                </Table>
              </Col>
            </Row>
            <br />
            {showSignOnMoblie()}

          </div>
        </Container>
      </>
    )
  } else {
    return (
      //desktop
      <>

        <Container fluid className="light-th"
          id="printarea"
          style={{
            marginTop: "50px",
            maxWidth: "900px",
          }}
        >
          {showButton()}

          <br />
          <div
            style={{
              backgroundColor: "#ffffff",
              boxShadow: "1px 1px 3px #d9d9d9",
              padding: "30px",
            }}>

            <Row >
              <Col>
                <div >
                  <img
                    src={systemData.company_logo}
                    width="100%"
                  />
                </div>
              </Col>
              <Col md="9">
                <h4><u>{systemData.company_name_thai}</u></h4>
                <h5>{systemData.company_name_eng}</h5>
                <h6>{systemData.company_address_thai}</h6>
                <h6>{systemData.company_address_eng}</h6>
                <h6><strong>E-mail :</strong> {systemData.company_email} <strong>Tel :</strong> {systemData.company_tel}</h6>
                <h6><strong>เลขที่ผู้เสียภาษี</strong> {systemData.company_taxpayer_number}</h6>
              </Col>
            </Row>
            <hr />
            <div className="text-center" style={{ backgroundColor: "#e7feff" }}>
              <h4 ><strong>ใบเสนอราคา</strong></h4>
            </div>
            <hr />
            <Row>
              <Col>
                <h6>เลขที่ : {filterOrder.quotationId}</h6>
                <h6>เรียน : {filterOrder.user_name}</h6>
                <h6>หน่วยงาน : {filterOrder.user_agency}</h6>
                <h6>โครงการ : {filterOrder.project}</h6>
              </Col>
              <Col sm="5">
                <h6>วันที่ {new Date(filterOrder.quotationDate).toLocaleDateString("th")}</h6>
                <h6>โทร : {filterOrder.user_tel}</h6>
                <h6>แฟกซ์ : {filterOrder.user_fax}</h6>
              </Col>
            </Row>
            <br />

            {showInitaialQuotation()}
            <br />
            <Row className="mb-4">
              <Col className="text-center"></Col>
              <Col sm="5">
                <Table>
                  <tr >
                    <td className="border-top-0 text-left" >
                      <p>กำหนดยืนราคา {filterOrder.paymentTerms}  วัน</p>
                      <p>กำหนดชำระเงิน {showCredit()}</p>
                      <p>กรุณาเซ็นต์ตอบรับ หรือ PO เมื่ออนุมัติ</p>
                    </td>
                  </tr>
                </Table>
              </Col>
            </Row>
            <br />
            {showSign()}

          </div>
        </Container>
      </>
    )
  }


}
export default Quotation;