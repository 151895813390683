import {
    ORDERS_PUT,
    ORDER_RESET,
    ORDERS_FETCH,
    ORDERS_DELETE,
    ORDER_CREATE,
  } from "../actions/types";
  export default function (state = [], action) {
    switch (action.type) {
      case ORDERS_FETCH:
        return action.payload;
      case ORDERS_PUT:
        return action.payload;
      case ORDER_CREATE:
        return state;
      case ORDER_RESET:
        return [];
      case ORDERS_DELETE:
        return action.payload;
      default:
        return state; // ค่าเดิม
    }
  }
  