import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../actions";
import * as role from "../../util/UserRole";
import { Container, Table, Col, Row, Button } from "reactstrap";
import { useHistory } from "react-router-dom"
import SearchGuest from "../../components/Search/SearchGuest"
import UserTables from "../tables/UserTables";
import TablePagination from "../../components/TablePagination/TablePagination";
import _ from "lodash";
import ModalCreateUser from "../../components/modal/ModalCreateUser";

export default function ManagementGuest() {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const allUser = useSelector((state) => state.userManagement);
  const [userData, setUserData] = useState([]);
  const [pageSize, setPageSize] = useState(10)
  const [currentPage, setCurrentPage] = useState(0)
  const [newUser, setNewUser] = useState([]);

  const allowAccess = user
    ? user.role == role.USER_ROLE || user.role == role.MECHANIC_ROLE || user.role == role.ADMIN_ROLE || user.role == role.SUPER_USER_ROLE
      ? true
      : false
    : history.push("/login");


  const [modalCreateUser, setModalCreateUser] = useState(false);
  const toggleModalCreateUser = () => {
    setModalCreateUser(!modalCreateUser);
    dispatch(actions.fetchAllUser());

  };

  useEffect(() => {
    dispatch(actions.fetchAllUser());
    return () => { };
  }, []);

  useEffect(() => {
    const filterUser = _.filter(allUser, (_user) => {
      return (
        _user.role === "USER"
      )
    })
    setUserData(filterUser)
    return () => { };
  }, [allUser]);

  if (allowAccess) {
    return (
      <Container fluid className="light-th">
        <h2 className="text-center"> ข้อมูลลูกค้า </h2>
        <ModalCreateUser
          modal={modalCreateUser}
          toggle={toggleModalCreateUser}
          setNewUser={setNewUser}
        />
        <Row>
          <Col className="text-left">
            <SearchGuest allUser={allUser} setUserData={setUserData} />
          </Col>
          <Col sm="12" md="6" className="text-right ">
            <Button
              className="btn-success"
              onClick={() => {
                toggleModalCreateUser(); // เปิด modal สำหรับเพิ่มรายชื่อลูกค้า
              }}
            >
              เพิ่มรายชื่อลูกค้า
              </Button>{" "}
          </Col>
        </Row>

        <hr />
        {userData.length === 0 ? (
          <>
            <div className="container col-md-8 text-center text-danger">
              <br />
              <br />
              <br />
              <h2>ไม่พบรายชื่อลูกค้า</h2>
            </div>

          </>
        ) : (
            <>
              <div
                style={{
                  maxHeight: "100vh",
                  overflowY: "auto",
                  backgroundColor: "#ffffff",
                  boxShadow: "1px 1px 3px #d9d9d9",
                }}>
                <Table striped bordered hover responsive>
                  <thead>
                    <tr className="text-center">
                      <th>#</th>
                      <th>ชื่อ</th>
                      <th>หน่วยงาน</th>
                      <th>เบอรโทร</th>
                      <th>ดำเนินการ</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(userData) ? (
                      <>
                        {userData
                          .slice(
                            currentPage * pageSize,
                            (currentPage + 1) * pageSize
                          )
                          .map((eachUser, index) => {
                            return (
                              <UserTables
                                user={eachUser}
                                index={index + 1}
                              />
                            );
                          })}
                      </>
                    ) : (
                        <>
                          <h2>ไม่พบรายชื่อลูกค้า</h2>
                        </>
                      )}
                  </tbody>
                </Table>
              </div>
              <div className="d-flex justify-content-center m-4 ">
                <TablePagination
                  pagesCount={Math.ceil(userData.length / pageSize)}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              </div>

            </>
          )}

      </Container>
    );
  } else {
    return (
      <Container fluid className="light-th">
        <div className="container col-md-8 text-center">
          <h1 className="text-danger"> ขออภัย </h1>
          <h3>
            คุณไม่มีสิทธิในการเข้าถึง <br />
              กรุณาลงทะเบียนด้วย User ที่ได้รับสิทธิเป็นผู้ดูแลระบบ
          </h3>
        </div>
      </Container>
    )
  }

}
