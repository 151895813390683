import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Label } from "reactstrap";

const ProductsListTable = ({ order, product, index, setSelectProduct, toggle, selectProduct, productInventory, selectProductStore, productStoreInventory, setProductData }) => {
  const products = useSelector((state) => state.products);

  const select = (product) => {    

    if (product.product_inventory != 0 && selectProduct.indexOf(product) == -1) { //เพิ่ม product ใหม่ในคลัง
      selectProduct.push(product)
      productInventory.push(1)
      toggle()
    } else if ((product.product_inventory != 0 && selectProduct.indexOf(product) != -1 && productInventory[selectProduct.indexOf(product)] != product.product_inventory)) { //เพิ่มจำนวน product ในคลัง
      productInventory[selectProduct.indexOf(product)] = productInventory[selectProduct.indexOf(product)] + 1;
      toggle()
    } else if ((product.product_inventory != 0 && selectProduct.indexOf(product) != -1 && productInventory[selectProduct.indexOf(product)] == product.product_inventory && selectProductStore.indexOf(product) == -1)) { //เกินจำนวน product ในคลัง เพิ่ม product ใหม่ในร้านค้า
      selectProductStore.push(product)
      productStoreInventory.push(1)
      toggle()
    } else if (product.product_inventory == 0 && selectProductStore.indexOf(product) == -1) {  //เพิ่ม product ใหม่ในร้านค้า
      selectProductStore.push(product)
      productStoreInventory.push(1)
      toggle()
    } else { //เพิ่มจำนวน product ในร้านค้า
      productStoreInventory[selectProductStore.indexOf(product)] = productStoreInventory[selectProductStore.indexOf(product)] + 1;
      toggle()
    }
    setProductData(products)
  }

  return (
    <tr className="text-center">
      <th scope="row">{index}</th>
      <td>{product.product_name}</td>
      <td>{product.product_detail}</td>
      {/* <td>{product.product_store}</td> */}
      {/* <td>{product.product_price} บาท</td> */}
      <td ><strong className="text-danger">{product.product_inventory} </strong><Label >{product.product_unit}</Label></td>
      {/* <td>
        
          <img src={product.product_image} width="10%" />
        
      </td>   */}
      <td>
        <Button
          className="m-1"
          color="success"
          onClick={() => {
            select(product)
          }}
        >
          เลือก
      {/* <AddSharpIcon /> */}
        </Button>
      </td>

    </tr>
  );
};

export default ProductsListTable;
