import React from "react";
import { Input } from "reactstrap";
export default function SearchCustomer(props) {

  const { allUser, setUserData, setSelectUser } = props;

  const onSeach = (word) => {

    const res = allUser.filter(
      (user) => 
        ((user.user_firstname.includes(word) 
        || user.user_lastname.includes(word) 
        || user.user_agency.includes(word) 
        ||  user.user_tel.includes(word) ))
        && user.role === "USER"
    );
    setUserData(res);
    setSelectUser([]);
  };
  return (
    <div className="d-flex">
      <Input
        style={{ maxWidth: "150px" }}
        className="ml-1"
        type="text"
        placeholder="ค้นหา"
        onChange={(e) => {
            allUser&&onSeach(e.target.value)
        }}
      />
    </div>
  );
}
