import React, { useState, useEffect } from "react";
import { Container } from "reactstrap";
import * as actions from "../actions";
import { useSelector, useDispatch } from "react-redux";

const Home = () => {
  const system = useSelector((state) => state.system);
  const [systemData, setSystemData] = useState([])
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.systemsFetch());
    return () => { };
  }, []);

  useEffect(() => {
    setSystemData(system[0])
    return () => { };
  }, [system]);

  return (
    <Container fluid style={{ minHeight: "80vh" }} className="light-th">
      <div className="text-center">
        {/* <h2 className="text-center">Welcome to E&E SERVICE</h2> */}
        {systemData != undefined ? (
          <h2
          >Welcome to {systemData.system_name}
          </h2>

        ) : (

          <h2
          >Welcome to E&E SERVICE
          </h2>
        )}
        <br />
        {/* <img
          src={require("../assets/images/E_E Service.jpg")}
          width="40%"
        >
        </img> */}
        {systemData != undefined ? (
          <img
            src={systemData.system_image}
            className="img-fluid ml-2 mr-3"
            width="40%"
          />

        ) : (

          <img
            src={require("../assets/images/E_E Service.jpg")}
            className="img-fluid ml-2 mr-3"
            width="40%"
          />
        )}
      </div>


    </Container>
  )
};
export default Home;