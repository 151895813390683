import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../actions";
import { useForm } from "react-hook-form";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  InputGroup,
  Label,
  Col,
  Card,
  Row,
  Table,
  ButtonGroup
} from "reactstrap";
import hash from "object-hash";
import { authen } from "../../assets/api/firebase";
import classnames from 'classnames';
import { TrashIcon } from 'evergreen-ui'
import ButtonImage from '@material-ui/core/Button';


const ModalCreateProduct = (props) => {
  const { buttonLabel, className, modal, toggle, setReRender } = props;
  const dispatch = useDispatch();
  const { register, handleSubmit, err } = useForm();
  const [unmountOnClose, setUnmountOnClose] = useState(true);
  const categories = useSelector((state) => state.categories);
  const [reload, setReload] = useState(false);
  const [imageLenght, setImageLenght] = useState([]);
  const [sameImage, setSameImage] = useState(true);
  const [product, setProduct] = useState([]);


  useEffect(() => {
    dispatch(actions.categoriesFetch());
    return () => { };
  }, []);

  useEffect(() => {
    // console.log("Use Effect:", reload);
    if (product.length === 0) {
      const data = {
        brand: null,
        size: null,
        color: null,
        product_buy: null,
        product_price: null
      }
      product.push(data)
    }
    return () => { }
  }, [reload])

  const onCreateProduct = (data) => {

    let confirm = window.confirm("ยืนยันการเพิ่มวัสดุ")

    if (!confirm) {
      toggle();
    } else {
      let curentSelectCategories = categories.find(category => {
        return category.category_id === data.category_id
      })

      if (sameImage === true) {
        const name = data.product_name;
        const date = new Date();
        const hashID = hash({
          name: name,
          date: date,
        });

        const uploadTask = authen
          .storage()
          .ref(`EandE/${hashID}`)
          .put(data.product_image[0]);

        uploadTask.on(
          "state_changed",
          (snapshot) => { },
          (error) => {
            console.log(error);
          },
          () => {
            authen
              .storage()
              .ref("EandE")
              .child(hashID)
              .getDownloadURL()
              .then((url) => {

                {
                  product.map((_product) => {
                    if (_product.brand === null && _product.size === null && _product.color === null) {

                    } else {
                      const product_data = {
                        product_name: data.product_name + " " + rename(_product.brand, _product.size, _product.color),
                        product_image: url,
                        product_category: curentSelectCategories._id,
                        product_inventory: 0,
                        store_list: { store: data.product_store },
                        product_id: hash({
                          name: data.product_name,
                          brand: _product.brand,
                          size: _product.size,
                          color: _product.color,
                          date: date,
                        }),
                        product_brand: _product.brand,
                        product_unit: data.product_unit,
                        product_buy: _product.product_buy,
                        product_price: _product.product_price,
                        product_store: data.product_store,
                        product_detail: data.product_detail,
                      }

                      dispatch(actions.productCreate(product_data));
                      dispatch(actions.productsFetch());

                    }


                  })
                }


                dispatch(actions.productsFetch());
                toggle();
                alert("บันทึกสำเร็จ")



              })

          });
      } else if (sameImage === false) {
        if (imageLenght[0] != null) {
          let imageSuccessCount = 0;
          for (let i = 0; i < imageLenght.length; i++) {
            const name = imageLenght[i].name;
            const date = new Date();
            const pictureName = hash({
              name: name,
              date: date,
            });

            const uploadTask = authen
              .storage()
              .ref(`EandE/${pictureName}`)
              .put(imageLenght[i]);

            uploadTask.on(
              "state_changed",
              (snapshot) => { },
              (error) => {
                console.log(error);
              },
              () => {
                authen
                  .storage()
                  .ref("EandE")
                  .child(pictureName)
                  .getDownloadURL()
                  .then((url) => {

                    imageLenght[i] = url;
                    imageSuccessCount++;

                    if (imageSuccessCount === imageLenght.length) {
                      {
                        product.map((_product, index) => {

                          if (_product.brand === null && _product.size === null && _product.color === null) {

                          } else {
                            const product_data = {
                              product_name: data.product_name + " " + rename(_product.brand, _product.size, _product.color),
                              product_image: imageLenght[index],
                              product_category: curentSelectCategories._id,
                              product_inventory: 0,
                              store_list: { store: data.product_store },
                              product_id: hash({
                                name: data.product_name,
                                brand: _product.brand,
                                size: _product.size,
                                color: _product.color,
                                date: date,
                              }),
                              product_brand: _product.brand,
                              product_unit: data.product_unit,
                              product_buy: _product.product_buy,
                              product_price: _product.product_price,
                              product_store: data.product_store,
                              product_detail: data.product_detail,
                            }

                            dispatch(actions.productCreate(product_data));
                            dispatch(actions.productsFetch());

                          }

                        })
                      }
                      dispatch(actions.productsFetch());
                      toggle();
                      alert("บันทึกสำเร็จ")
                    }


                  });
              });



          }
        }
      }

      dispatch(actions.productsFetch());

    }
  };
  const categoriesList = () => {
    if (Array.isArray(categories)) {
      return (
        <>
          {categories.map((category) => {
            return (
              <option key={category.category_id} value={category.category_id}>
                {category.category_name}
              </option>
            );
          })}
        </>
      );
    } else {
      return "";
    }
  };

  const rename = (brand, size, color) => {
    const name = []
    if (brand != null) {
      name[0] = brand + " "
    }
    if (size != null) {
      name[0] = name[0] + size + " "
    }
    if (color != null) {
      name[0] = name[0] + color
    }

    if (name[0] != null) {
      return name[0]
    } else {
      return ""
    }
  }

  const options = () => {
    return (
      <>
        <Label style={{ fontSize: "15px", color: "black" }}>ตัวเลือกแบบวัสดุ</Label>
        <br />
        <Table striped bordered hover responsive style={{ fontSize: "18px" }} className="text-center mt-2" size="sm">
          <thead className="light-th" >
            <tr>
              <th>#</th>
              <th>ยี่ห้อ</th>
              <th>ขนาด</th>
              <th>สี</th>
              <th>ราคาซื้อ</th>
              <th>ราคาขาย</th>
              <th>ดำเนินการ</th>
            </tr>
          </thead>
          <tbody>
            {product.map((_product, index) => {
              return (
                <>
                  <tr>
                    <th>{index + 1}</th>
                    <td><Input type="text" className="text-center" placeholder="ยี่ห้อ" value={_product.brand} onChange={(e) => _product.brand = e.target.value} /></td>
                    <td><Input type="text" className="text-center" placeholder="ขนาด (ถ้ามี)" value={_product.size} onChange={(e) => _product.size = e.target.value} /></td>
                    <td><Input type="text" className="text-center" placeholder="สี (ถ้ามี)" value={_product.color} onChange={(e) => _product.color = e.target.value} /></td>
                    <td><Input type="number" className="text-center" placeholder="ราคาซื้อ" onChange={(e) => _product.product_buy = e.target.value} /></td>
                    <td><Input type="number" className="text-center" placeholder="ราคาขาย" onChange={(e) => _product.product_price = e.target.value} /></td>
                    <td>
                      <TrashIcon
                        className=" text-right"
                        color="danger"
                        onClick={() => {
                          let confirmRemove = window.confirm("ยืนยันการลบ")
                          if (confirmRemove) {
                            product.splice(index, 1); //ลบออก
                            setReload(!reload);
                          }
                        }}
                        size={30} />
                    </td>

                  </tr>
                </>
              );
            })}
            <Button className="mt-1 mb-1" size="sm" color="primary" outline onClick={() => addIndex("product")}>เพิ่ม</Button>{" "}

          </tbody>
        </Table>

      </>
    )
  }

  const addIndex = (value) => {
    if (value === "product") {
      const data = {
        brand: null,
        size: null,
        color: null,
        product_buy: null,
        product_price: null
      }
      product.push(data)
    }
    setReload(!reload)
  }

  const selectImage = () => {
    return (
      <>
        <br />
        <ButtonGroup className="mb-4">
          <Button
            color={
              sameImage === true
                ? "info"
                : "secondary"
            }
            onClick={() => { setSameImage(true) }}>
            ใช้รูปภาพเดียวกันทั้งหมด
          </Button>
          <Button
            color={
              sameImage === false
                ? "info"
                : "secondary"
            }
            onClick={() => { setSameImage(false) }}>
            ใช้รูปภาพต่างกัน
          </Button>
        </ButtonGroup>
        <br />

        {sameImage === true ? (
          <>
            <Row>
              <Col md="3" className="text-right"></Col>
              <Col>
                <InputGroup size="md">
                  <Input
                    type="file"
                    name="product_image"
                    accept="image/*"
                    innerRef={register}>
                  </Input>
                </InputGroup>
              </Col>
            </Row>
          </>
        ) : (
          <>
            {product.map((_product, index) => {
              // if (_product.color != null && _product.color != " " && _product.color != []) {
              return (
                <>
                  <Row className="mb-1">
                    <Col md="3" className="text-right">{index + 1}</Col>
                    <Col>
                      <InputGroup size="md">
                        <Input
                          type="file"
                          // name="product_image"
                          // value={imageLenght[index]}
                          onChange={(e) => imageLenght[index] = e.target.files[0]}
                          accept="image/*"
                          innerRef={register}>
                        </Input>
                      </InputGroup>
                    </Col>
                  </Row>

                </>
              )
              // }
            })}
          </>
        )}

      </>
    )
  }

  return (
    <div>
      <Modal
        isOpen={modal}
        toggle={toggle}
        className={className}
        unmountOnClose={unmountOnClose}
        size="lg"
      >
        <ModalHeader toggle={toggle}>เพิ่มวัสดุ</ModalHeader>
        <ModalBody>

          <div>
            <form>
              <br />
              <InputGroup size="lg">
                <Input
                  style={{ fontSize: "15px" }}
                  type="text"
                  placeholder="ชื่อ"
                  name="product_name"
                  innerRef={register}
                />
              </InputGroup>
              <br />
              <InputGroup size="lg">
                <Input
                  style={{ fontSize: "15px" }}
                  type="textarea"
                  placeholder="รายละเอียด"
                  name="product_detail"
                  innerRef={register}
                />
              </InputGroup>
              <br />
              <InputGroup size="md">
                <Input
                  type="text"
                  placeholder="หน่วยของวัสดุ"
                  name="product_unit"
                  innerRef={register}>
                </Input>
              </InputGroup>
              <br />
              <InputGroup size="md">
                <Input
                  type="text"
                  placeholder="แหล่งจัดซื้อ (ร้านค้า)"
                  name="product_store"
                  innerRef={register}>
                </Input>
              </InputGroup>
              <br />
              <Label style={{ fontSize: "15px", color: "black" }}>หมวดหมู่</Label>
              <InputGroup size="md">
                <Input
                  type="select"
                  name="category_id"
                  innerRef={register}>
                  {categoriesList()}
                </Input>
              </InputGroup>

              <br />
              {options()}

              <Label style={{ fontSize: "15px", color: "black" }}>รูปภาพ</Label>
              {selectImage()}

            </form>
          </div>

        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={handleSubmit(onCreateProduct)}>
            เพิ่ม
          </Button>{" "}
          <Button
            color="secondary"
            onClick={toggle}>
            ยกเลิก
          </Button>
        </ModalFooter>
      </Modal>

    </div>
  );
};

export default ModalCreateProduct;
