import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../actions";
import { useForm } from "react-hook-form";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  InputGroup,
  Label,
  CustomInput,
  Col,
  Table,
  Row,
  Alert,
} from "reactstrap";
import hash from "object-hash";
import { authen } from "../../assets/api/firebase";
import _ from "lodash";
import { useHistory, useParams } from "react-router-dom";
import ProductsListTables from "../../containers/tables/ProductsListTable"
import SearchCustomer from '../../components/Search/SearchCustomer'
import ModalCreateUser from "./ModalCreateUser";

const ModalSelectUser = (props) => {
  const { className, modal, toggle, setSelectUser, allUser, setUserData, userData, selectUser } = props;
  const dispatch = useDispatch();
  const { register, handleSubmit, err } = useForm();
  const [unmountOnClose, setUnmountOnClose] = useState(true);
  const orders = useSelector((state) => state.orders);
  const [newUser, setNewUser] = useState([]);
  const [editUser, setEditUser] = useState([]);

  const params = useParams();
  const filterOrder = _.find(orders, (_order) => {
    return (
      _order._id == params.id
    )
  })

  useEffect(() => {
    dispatch(actions.fetchAllUser());
    return () => { }
  }, []);

  useEffect(() => {
    setUserData(allUser)
    return () => { };
  }, [allUser]);


  const [modalCreateUser, setModalCreateUser] = useState(false);
  const toggleModalCreateUser = () => {
    setModalCreateUser(!modalCreateUser);

  };


  const sentUser = (data) => {
    setSelectUser(data);
    setUserData(allUser);
    toggle();
  }



  const showUser = (user, select) => {
    if (newUser.length != 0 && allUser.length === userData.length + 1) {
      const filterUser = _.find(allUser, (_user) => {
        return (
          _user.id === newUser
        )
      })
      setSelectUser(filterUser);
      setNewUser([]);
      toggle();
    }
    if (Array.isArray(user) &&user.length != 0 && select&&select.length === 0 && editUser&&editUser.length === 0) {
      return user.map((data) => {
        if (data.role === "USER") {
          return (
            <>
              <div>
                <Alert style={{ backgroundColor: "#FFFFFF", borderColor: "#FFFFFF", color: "#000000" }}>
                  <Row className="ml-2">
                    <Col>
                      <h6>ชื่อลูกค้า : {data.user_title + data.user_firstname + " " + data.user_lastname}</h6>
                      <h6>หน่วยงาน : {data.user_agency}</h6>
                      <h6>เบอร์โทรศัพท์ :  {data.user_tel}</h6>
                    </Col>

                    <Col sm="2" className="text-right">
                      <Button
                        outline
                        size="sm"
                        color="primary"
                        onClick={() => {
                          sentUser(data)
                        }}
                      >เลือก</Button>
                    </Col>
                  </Row>
                </Alert>

              </div>
            </>
          )
        }
      })
    }
    else {
      return (
        <>
          <br />
          <h5 className="text-center text-danger">
            ไม่พบรายชื่อลูกค้า
      </h5>
        </>
      )
    }

  }


  return (
    <div>
      <ModalCreateUser
        modal={modalCreateUser}
        toggle={toggleModalCreateUser}
        setNewUser={setNewUser}
      />
      <Modal
        size="lg"
        isOpen={modal}
        toggle={toggle}
        className={className}
        unmountOnClose={unmountOnClose}
      >
        <ModalHeader toggle={toggle}>เลือกรายชื่อลูกค้า</ModalHeader>
        <ModalBody>
          <form>
            <Label style={{ fontSize: "15px", color: "black" }}>ค้นหารายชื่อลูกค้า</Label>

            <Row className="ml-2">
              <div className="mt-2">
                <SearchCustomer allUser={allUser} setUserData={setUserData} setSelectUser={setSelectUser} />
              </div>
              <Button
                className="ml-1 mt-2"
                color="success"
                onClick={
                  toggleModalCreateUser
                }
              >เพิ่มรายชื่อลูกค้า</Button>
            </Row>
            <br />
            {showUser(userData, selectUser)}

          </form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ModalSelectUser;
