import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

import React from 'react'

export default function TablePagination(props) {
    const {
        pagesCount,
        currentPage,
        setCurrentPage,
        handlePreviousClick,
        handleNextClick
    } = props
    const handlePageClick = (e, index) => {
        e.preventDefault();
        setCurrentPage(index);
    };
    return (
        <>
            <p className="p-2">
                จำนวนหน้า
            </p>
            < Pagination >
                {[...Array(pagesCount)].map((page, i) => (
                    <PaginationItem active={i === currentPage} key={i}>
                        <PaginationLink onClick={e => handlePageClick(e, i)} href="#">
                            {i + 1}
                        </PaginationLink>
                    </PaginationItem>
                ))}
            </ Pagination >
        </>


    )
}
