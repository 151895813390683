import React, { useState, useEffect } from "react";
import { Container, Table, Badge, Col, Row, Button, Modal, ModalBody, ModalFooter, ModalHeader, Input, Card } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import * as role from "../../util/UserRole";
import { useHistory, useParams } from "react-router-dom";
import * as actions from '../../actions'
import * as UserStatus from "../../util/UserStatus";
import _ from "lodash";
import { useForm } from "react-hook-form";
import GetJobRepairMobile from "./GetJobRepairMoblie";
import GetJobRepairDesktop from "./GetJobRepairDesktop";


const GetJobRepair = () => {
  const orders = useSelector((state) => state.orders);
  const user = useSelector((state) => state.user);
  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams();
  const { register, handleSubmit, err } = useForm();
  const [width, setWidth] = React.useState(window.innerWidth);
  const breakpoint = 620;

  useEffect(() => {
    window.addEventListener('resize', () => setWidth(window.innerWidth));
    return () => { }
  }, [])

  const allowAccess = user
    ? user.role == role.CONTRATOR_ROLE ||
      user.role == role.MECHANIC_ROLE ||
      user.role == role.ADMIN_ROLE ||
      user.role == role.SUPER_USER_ROLE
      ? true
      : false
    : history.push('/login');

  const filterOrder = _.find(orders, (_order) => {
    return (
      _order._id == params.id
    )
  })
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const showByMechanic = () => {
    if (filterOrder.byMechanic !== role.USER_ROLE) {
      return (
        <>
          <Row>
            <h5 className="ml-3"><strong>{filterOrder.user_name}</strong></h5>
            <Col ><Badge color="info" pill>แจ้งซ่อมผ่าน {filterOrder.nameByMechanic}</Badge></Col>

          </Row>
        </>
      )
    } else {
      return (
        <h5 ><strong>{filterOrder.user_name}</strong></h5>
      )
    }

  }

  const showByMechanicMoblie = () => {
    if (filterOrder.byMechanic !== role.USER_ROLE) {
      return (
        <>
          <p className="ml-1"><Badge color="info" pill>แจ้งซ่อมผ่าน {filterOrder.nameByMechanic}</Badge></p>

        </>
      )
    }
  }

  const showImage = (image) => {
    if (image != 0) {
      return (

        <div >
          <p><strong>รูปภาพ</strong></p>
          <div className="text-center">
            {image.map(_image => (
              <img src={_image} width="70%" className="mb-4" />
            ))}
          </div>
        </div>);
    }
  }

  const colorSpeed = () => {
    if (filterOrder.speed === "ปกติ") {
      return <strong className="text-success">{filterOrder.speed}</strong>
    } else {
      return <strong className="text-danger">{filterOrder.speed}</strong>
    }
  }

  const acceptWork = () => {
    return (
      <>
        <div className="text-center">
          <Button color="success" className="mr-4" size="lg" onClick={handleSubmit(onSubmitComfirm)} >รับงาน</Button>
          <Button color="danger" size="lg" onClick={toggle}>ปฏิเสธงาน</Button>
        </div>
      </>
    );

  }

  const onSubmitComfirm = () => {
    //ปกติ
    const dataNormal = {
      mechanic_name: user.user_title + user.user_firstname + " " + user.user_lastname,
      statusUser: UserStatus.USER_WAITING,
      statusMechanic: UserStatus.MECHANIC_CHECK,
    }

    //เร่งด่วน
    const dataUrgent = {
      mechanic_name: user.user_title + user.user_firstname + " " + user.user_lastname,
      statusUser: UserStatus.USER_CHECK,
      statusMechanic: UserStatus.MECHANIC_CHECK,
    }

    if (filterOrder.speed === "ปกติ") {
      dispatch(actions.orderPut(filterOrder._id, dataNormal))
      // history.push("/estimate_price/" + filterOrder._id)
    } else {
      dispatch(actions.orderPut(filterOrder._id, dataUrgent))
      // history.push("/estimate_price/" + filterOrder._id)
    }

    let navigate = window.confirm("เข้าสู่หน้าประเมินราคาต่อหรือไม่")
    if (navigate) {
      history.push("/estimate_price/" + filterOrder._id);
    } else {
      history.push("/management/job");
    }
  };

  const onSubmitReason = (data) => {
    // toggle();
    const dataReason = {
      mechanic_name: user.user_title + user.user_firstname + " " + user.user_lastname,
      mechanic_reason: data.mechanic_reason,
      statusUser: UserStatus.USER_REFUSEDWORK,
      statusMechanic: UserStatus.MECHANIC_REFUSEDWORK,
    }
    dispatch(actions.orderPut(filterOrder._id, dataReason))
    history.push("/management/job")
  };

  if (allowAccess) {
    return width < breakpoint ? (
      //moblie
      <>

        <GetJobRepairMobile />

      </>
    ) : (
      //desktop
      <>

        <GetJobRepairDesktop />

      </>
    )
  } else {
    return (
      <>
      </>
    )
  }
}
export default GetJobRepair;