import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../actions";
import { useForm } from "react-hook-form";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
  ModalFooter,
  Button,
  FormGroup
} from "reactstrap";
import hash from "object-hash";
import * as UserStatus from "../../util/UserStatus";

const ModalBargain = (props) => {
  const dispatch = useDispatch();
  const { className, modal, toggle, order, setOrder, mechanic_token, allUser } = props;
  const user = useSelector((state) => state.user)
  const { register, handleSubmit, err } = useForm({
    defaultValues: {
      cancle: "การทำงานล่าช้า",
    },
  });

  const onSendPrice = (data) => {
    const changStatus = {
      statusUser: UserStatus.USER_BARGAIN,
      bargain: data.bargain,
    }

    const token = "BARGAIN"
    const dataNotification = "งานแจ้งซ่อมของลูกค้าชื่อ " + user.user_firstname + " " + user.user_lastname +
      " หน่วยงาน " + user.user_agency + "ต้องการต่อรองราคาเป็น" + data.bargain + "บาท"

    dispatch(actions.orderPut(order, changStatus))
    dispatch(actions.notifyMultiSend(dataNotification, token, allUser))
    dispatch(actions.ordersFetch(user));

    toggle()
  };

  return (
    <div>
      <Modal
        backdrop={false}
        isOpen={modal}
        toggle={toggle}
        className={className}
      >

        <ModalHeader toggle={toggle}>ราคาที่ต้องการ</ModalHeader>
        <ModalBody>
          <from>
          <Input
            type="text"
            name="bargain"
            placeholder="ราคาที่ต้องการ(บาท)"
            innerRef={register}
          />
          </from>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSubmit(onSendPrice)}>
            ยืนยัน
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            ยกเลิก
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ModalBargain;
