import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Card, CardBody, Alert, Label, Table, InputGroup, Input, CardImg, } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import * as role from "../../util/UserRole";
import { useHistory, useParams } from "react-router-dom";
import * as actions from '../../actions'
import * as UserStatus from "../../util/UserStatus";
import _ from "lodash";
import { TrashIcon, } from 'evergreen-ui'
import ModalSelectImage from "../../components/modal/ModalSelectImage";

const EditBeforeAndAfterPictures = () => {
  const orders = useSelector((state) => state.orders);
  const user = useSelector((state) => state.user);
  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams();
  const [width, setWidth] = React.useState(window.innerWidth);
  const breakpoint = 620;
  const [reload, setReload] = useState(true);

  useEffect(() => {
    // console.log("Use Effect:",reload);
    dispatch(actions.ordersFetch(user))
    window.addEventListener('resize', () => setWidth(window.innerWidth));
    return () => { }
  }, [reload])

  const allowAccess = user
    ? user.role == role.MECHANIC_ROLE ||
      user.role == role.CONTRATOR_ROLE ||

      user.role == role.ADMIN_ROLE ||
      user.role == role.SUPER_USER_ROLE
      ? true
      : false
    : history.push('/login');

  const filterOrder = _.find(orders, (_order) => {
    return (
      _order._id == params.id
    )
  })

  const [count, setCount] = useState(0);
  const [imageFormUser, setImageFormUser] = useState(filterOrder.imageFormUser);
  const [imageFormMechanic, setImageFormMechanic] = useState(filterOrder.imageFormMechanic);
  const [imageBefore, setImageBefore] = useState([]);
  const [imageAfter, setImageAfter] = useState([]);
  const [subtitle, setSubtitle] = useState([]);
  const [imageData, setImageData] = useState([]);


  const [modalSelectImage, setModalSelectImage] = useState(false); // props สำหรับเปิดปิด modal
  const toggleModalSelectImage = (type, index) => {

    const data = {
      type: type,
      index: index,
    }

    setImageData(data)
    setModalSelectImage(!modalSelectImage); // method สำหรับ set เปิดหรือปิด modal

  }

  const setBeforeAndAfter = () => {

    if (filterOrder.beforeAndAfter === true && count === 0) { //แก้ไขการส่งมอบงาน
      setSubtitle(filterOrder.subtitle);
      setImageBefore(filterOrder.imageBefore);
      setImageAfter(filterOrder.imageAfter);
      setCount(1);

    }
  }

  const displayImage = (data, type, index) => {
    if (data != undefined) {
      return (
        <>
          <Card onClick={() => toggleModalSelectImage(type, index)}>
            <CardImg width="100%" src={data} />
          </Card>
        </>
      )
    } else {
      return (
        <>
          <Card onClick={() => toggleModalSelectImage(type, index)}>
            <CardImg width="100%" src={require("../../assets/images/logo-eservice.png")} />
          </Card>
        </>
      )
    }
  }

  const handleOnChangeSubtitle = (value, index) => {

    subtitle[index] = value
    setSubtitle(subtitle)
    setReload(!reload)

  }

  const showBefore = (before) => {

    return (
      <>
        <Row>
          <Col>
            {displayImage(before[0], "before", "0")}
            <br />
            <Label>คำบรรยายภาพ</Label>
            <Input
              style={{ fontSize: "15px" }}
              placeholder="คำบรรยายภาพ"
              onChange={e => handleOnChangeSubtitle(e.target.value, 0)}
              value={subtitle[0]}
            />
          </Col>
          <Col>
            {displayImage(before[1], "before", "1")}
            <br />
            <Label>คำบรรยายภาพ</Label>
            <Input
              style={{ fontSize: "15px" }}
              placeholder="คำบรรยายภาพ"
              onChange={e => handleOnChangeSubtitle(e.target.value, 1)}
              value={subtitle[1]}
            />
          </Col>
          <Col>
            {displayImage(before[2], "before", "2")}
            <br />
            <Label>คำบรรยายภาพ</Label>
            <Input
              style={{ fontSize: "15px" }}
              placeholder="คำบรรยายภาพ"
              onChange={e => handleOnChangeSubtitle(e.target.value, 2)}
              value={subtitle[2]}
            />
          </Col>
          <Col>
            {displayImage(before[3], "before", "3")}
            <br />
            <Label>คำบรรยายภาพ</Label>
            <Input
              style={{ fontSize: "15px" }}
              placeholder="คำบรรยายภาพ"
              onChange={e => handleOnChangeSubtitle(e.target.value, 3)}
              value={subtitle[3]}
            />
          </Col>
        </Row>

      </>
    )


  }

  const showAfter = (after) => {
    return (
      <>
        <Row>
          <Col>
            {displayImage(after[0], "after", "0")}
            <br />
            <Label>คำบรรยายภาพ</Label>
            <Input
              style={{ fontSize: "15px" }}
              placeholder="คำบรรยายภาพ"
              onChange={e => handleOnChangeSubtitle(e.target.value, 4)}
              value={subtitle[4]}
            />
          </Col>
          <Col>
            {displayImage(after[1], "after", "1")}
            <br />
            <Label>คำบรรยายภาพ</Label>
            <Input
              style={{ fontSize: "15px" }}
              placeholder="คำบรรยายภาพ"
              onChange={e => handleOnChangeSubtitle(e.target.value, 5)}
              value={subtitle[5]}
            />
          </Col>
          <Col>
            {displayImage(after[2], "after", "2")}
            <br />
            <Label>คำบรรยายภาพ</Label>
            <Input
              style={{ fontSize: "15px" }}
              placeholder="คำบรรยายภาพ"
              onChange={e => handleOnChangeSubtitle(e.target.value, 6)}
              value={subtitle[6]}
            />
          </Col>
          <Col>
            {displayImage(after[3], "after", "3")}
            <br />
            <Label>คำบรรยายภาพ</Label>
            <Input
              style={{ fontSize: "15px" }}
              placeholder="คำบรรยายภาพ"
              onChange={e => handleOnChangeSubtitle(e.target.value, 7)}
              value={subtitle[7]}
            />
          </Col>
        </Row>

      </>
    )

  }

  if (allowAccess) {
    return (
      <>
        <Container fluid className="light-th"
          style={{
            marginTop: "50px",
            maxWidth: "900px",
          }}
        >
          <ModalSelectImage
            modal={modalSelectImage}
            toggle={toggleModalSelectImage}
            imageFormUser={imageFormUser}
            imageFormMechanic={imageFormMechanic}
            imageBefore={imageBefore}
            imageAfter={imageAfter}
            imageData={imageData}
            reload={reload}
            setReload={setReload}
          />
          <Row>
            <Col xs="2">
              <Button
                outline
                color="secondary"
                onClick={
                  history.goBack
                }
              >ย้อนกลับ</Button>
            </Col>
            <Col xs="8">
              <h2 className="text-center">แก้ไขรูปภาพก่อนและหลังซ่อม</h2>
            </Col>
          </Row>


          <br />
          <div style={{
            backgroundColor: "#ffffff",
            boxShadow: "1px 1px 3px #d9d9d9",
            padding: "30px",
          }}>

            {setBeforeAndAfter()}

            <Label
              style={{ fontSize: "25px" }}
            >รูปภาพก่อนซ่อม
            </Label>
            <br />
            {showBefore(imageBefore)}

            <br />
            <Label
              style={{ fontSize: "25px" }}
            >รูปภาพหลังซ่อม
            </Label>
            <br />
            {showAfter(imageAfter)}

            <br />
            <div className="text-right">
              <Button
                onClick={() => {
                  let comfirm = window.confirm("ยืนยันการแก้ไขรูปภาพ")
                  if (comfirm) {
                    const data = {
                      beforeAndAfter: true,
                      imageBefore: imageBefore,
                      imageAfter: imageAfter,
                      subtitle: subtitle,
                      imageFormMechanic: imageFormMechanic,
                    }
                    dispatch(actions.orderPut(filterOrder._id, data))
                    dispatch(actions.ordersFetch(user))
                    history.push("/deliver_work/" + filterOrder._id)
                  }
                }}
                color="success"
              >
                บันทึก
            </Button>
            </div>

          </div>
        </Container>

      </>
    )
  } else {
    return (
      <>
      </>
    )
  }
}
export default EditBeforeAndAfterPictures;