import React, { useState, useEffect } from "react";
import { Button, Container, Table, Input, Alert, Row, Col, Label, Card } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import * as role from "../../util/UserRole";
import { useHistory, useParams } from "react-router-dom";
import * as actions from '../../actions'
import _ from "lodash";
import ModalSelectProduct from "../../components/modal/ModalSelectProduct";
import { RemoveIcon, AddIcon, TrashIcon, TextInput, Strong } from 'evergreen-ui'

// ดูรายละเอียดข้อมูลการซ่อม ประเมินราคาเบื้องต้น

const MaterialandProductMobile = ({ selectProduct,setSelectProduct, selectProductStore, setProductInventory, setProductStoreInventory, productInventory, productStoreInventory, reload, setReload, resetProductBorrow }) => {
  const orders = useSelector((state) => state.orders);
  const user = useSelector((state) => state.user);
  const products = useSelector((state) => state.products);
  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams();

  const [width, setWidth] = React.useState(window.innerWidth);
  const breakpoint = 620;

  const [modalSelectProduct, setModalSelectProduct] = useState(false); // props สำหรับเปิดปิด modal
  const toggleModalSelectProduct = () => {
    setModalSelectProduct(!modalSelectProduct); // method สำหรับ set เปิดหรือปิด modal
  }

  const [modalAddOT, setModalAddOT] = useState(false); // props สำหรับเปิดปิด modal
  const toggleModalAddOT = () => {
    setModalAddOT(!modalAddOT); // method สำหรับ set เปิดหรือปิด modal
  }

  const allowAccess = user
    ? user.role == role.MECHANIC_ROLE ||
      user.role == role.ADMIN_ROLE ||
      user.role == role.SUPER_USER_ROLE
      ? true
      : false
    : history.push('/login');

  const filterOrder = _.find(orders, (_order) => {
    return (
      _order._id == params.id
    )
  })

  useEffect(() => {
    dispatch(actions.productsFetch());
    window.addEventListener('resize', () => setWidth(window.innerWidth));

    return () => {
    }
  }, []);

  useEffect(() => {
    // console.log("Use Effect:",reload);
    window.addEventListener('resize', () => setWidth(window.innerWidth));
    return () => { }
  }, [reload])

  const showSelectProduct = (select) => {
    return (
      <>
        {select.map((product) => (
          <>
            <div style={{
              backgroundColor: '#F7F9FD',
              boxShadow: '1px 1px 3px #d9d9d9',
              padding: '30px',
            }}
              className="mb-4 text-center">
              <Row>
                <Col><img src={product.product_image} width="100vw" />
                </Col>
                <Col md="6">
                  <span>{product.product_name}</span>
                  <p style={{ fontSize: "18px", color: "#7B8B9A" }}>รายละเอียด {product.product_detail}
                    <p style={{ fontSize: "18px", color: "#BF0E08" }}>ยอดคงเหลือ {product.product_inventory}</p>
                  </p>
                </Col>
                <Col >
                  <Row>
                    <Button
                      size="sm"
                      color="danger"
                      onClick={() => {
                        if (productInventory[selectProduct.indexOf(product)] != 1) {
                          productInventory[selectProduct.indexOf(product)] = productInventory[selectProduct.indexOf(product)] - 1;
                          setReload(!reload);
                        } else if (productInventory[selectProduct.indexOf(product)] == 1) {
                          let confirmRemove = window.confirm("ยืนยันการลบอุปกรณ์")
                          if (confirmRemove) {

                            productInventory.splice(selectProduct.indexOf(product), 1); //ลบจำนวนออก
                            selectProduct.splice(selectProduct.indexOf(product), 1); //ลบอุปกรณ์ที่เลือก
                            setReload(!reload);

                          }
                        }

                      }}
                    ><RemoveIcon /></Button>
                    <TextInput
                      disabled
                      className="text-center"
                      width={"35%"}
                      name="productInventory"
                      value={productInventory[selectProduct.indexOf(product)]}
                    />
                    <Button

                      size="sm"
                      color="success"
                      onClick={() => {

                        let temp = productInventory;
                        if (temp[selectProduct.indexOf(product)] != product.product_inventory) {
                          temp[selectProduct.indexOf(product)] = temp[selectProduct.indexOf(product)] + 1;
                          setProductInventory(temp);
                          setReload(!reload); //สั่งให้ use effect ทำงาน  
                        } else if (temp[selectProduct.indexOf(product)] == product.product_inventory && selectProductStore.indexOf(product) === -1) {
                          selectProductStore.push(product);
                          productStoreInventory.push(1);
                          setReload(!reload);
                        } else {
                          productStoreInventory[selectProductStore.indexOf(product)] = productStoreInventory[selectProductStore.indexOf(product)] + 1;
                          setReload(!reload);
                        }




                      }}
                    ><AddIcon /></Button>
                  </Row>
                </Col>
                <Col>
                  <TrashIcon
                    className=" text-right"
                    color="danger"
                    onClick={() => {
                      let confirmRemove = window.confirm("ยืนยันการลบอุปกรณ์")
                      if (confirmRemove) {

                        productInventory.splice(selectProduct.indexOf(product), 1); //ลบจำนวนออก
                        selectProduct.splice(selectProduct.indexOf(product), 1); //ลบอุปกรณ์ที่เลือก
                        setReload(!reload);

                      }
                    }}
                    size={30} />
                </Col>
              </Row>
            </div>
          </>
        )
        )}
      </>
    )
  }

  const showTopic = (select) => {
    if (select.length != 0) {
      return <p className="text-left" style={{ fontSize: "18px", color: "#7B8B9A" }}>จัดซื้อจากร้านค้า</p>
    }
  }

  const showSelectProductStore = (select) => {
    return (
      <>
        {showTopic(select)}
        {select.map((product) => {
          return (
            <>
              <div style={{
                backgroundColor: '#F7F9FD',
                boxShadow: '1px 1px 3px #d9d9d9',
                padding: '30px',
              }}
                className="mb-4 text-center">

                <Row>
                  <Col><img src={product.product_image} width="100vw" />
                  </Col>
                  <Col md="6">
                    <span>{product.product_name}</span>
                    <p style={{ fontSize: "18px", color: "#7B8B9A" }}>รายละเอียด {product.product_detail}</p>
                    <Alert>
                      <p style={{ fontSize: "18px", color: "#BF0E08" }}>แหล่งจัดซื้อ : {product.product_store}</p>
                    </Alert>
                  </Col>
                  <Col >
                    <Row>
                      <Button
                        size="sm"
                        color="danger"
                        onClick={() => {
                          if (productStoreInventory[selectProductStore.indexOf(product)] != 1) {
                            productStoreInventory[selectProductStore.indexOf(product)] = productStoreInventory[selectProductStore.indexOf(product)] - 1;
                            setReload(!reload);
                          } else if (productStoreInventory[selectProductStore.indexOf(product)] == 1) {
                            let confirmRemove = window.confirm("ยืนยันการลบอุปกรณ์")
                            if (confirmRemove) {

                              productStoreInventory.splice(selectProductStore.indexOf(product), 1); //ลบจำนวนออก
                              selectProductStore.splice(selectProduct.indexOf(product), 1); //ลบอุปกรณ์ที่เลือก
                              setReload(!reload);

                            }
                          }

                        }}
                      ><RemoveIcon /></Button>
                      <TextInput
                        disabled
                        className="text-center"
                        width={"35%"}
                        name="productInventoryStore"
                        value={productStoreInventory[selectProductStore.indexOf(product)]}
                      />
                      <Button
                        size="sm"
                        color="success"
                        onClick={() => {

                          let temp = productStoreInventory;
                          // if( temp[selectProductStore.indexOf(product)] != product.product_inventory){
                          temp[selectProductStore.indexOf(product)] = temp[selectProductStore.indexOf(product)] + 1;
                          setProductStoreInventory(temp);
                          setReload(!reload); //สั่งให้ use effect ทำงาน  
                          // }




                        }}
                      ><AddIcon /></Button>
                    </Row>
                  </Col>
                  <Col>
                    <TrashIcon
                      className=" text-right"
                      color="danger"
                      onClick={() => {
                        let confirmRemove = window.confirm("ยืนยันการลบอุปกรณ์")
                        if (confirmRemove) {

                          productStoreInventory.splice(selectProductStore.indexOf(product), 1); //ลบจำนวนออก
                          selectProductStore.splice(selectProduct.indexOf(product), 1); //ลบอุปกรณ์ที่เลือก
                          setReload(!reload);

                        }
                      }}
                      size={30} />
                  </Col>
                </Row>
              </div>
            </>
          );
        })}
      </>
    )

  }


  const pleaseSelectProduct = (productFromStock, productFromStore) => {
    if (productFromStock.length === 0 && productFromStore.length === 0) {
      return (
        <>
          <div className="text-center">
            <Label
              className="text-danger"
              style={{ fontSize: "30px" }}
            >กรุณาเลือกวัสดุและอุปกรณ์</Label>

          </div>
          <br />
        </>
      );
    }
  }


  if (allowAccess)
    return (
      <>
        <ModalSelectProduct
          modal={modalSelectProduct}
          toggle={toggleModalSelectProduct}
          setSelectProduct={setSelectProduct}
          selectProduct={selectProduct}
          productInventory={productInventory}
          selectProductStore={selectProductStore}
          productStoreInventory={productStoreInventory}
        />
        <Row className="ml-2">
          <Label
            style={{ fontSize: "25px" }}
          >ค่าวัสดุและอุปกรณ์</Label>
          <Button
            className="ml-2"
            size="sm"
            color="success"
            onClick={() => {
              toggleModalSelectProduct(); // เปิด modal สำหรับเลือกอุปกรณ์
            }}
          >เพิ่มอุปกรณ์</Button>
        </Row>
        <br />
        <h4 className="ml-2">{pleaseSelectProduct(selectProduct, selectProductStore)}</h4>
        <h4 className="ml-2">{showSelectProduct(selectProduct)}</h4>
        <h4 className="ml-2">{showSelectProductStore(selectProductStore)}</h4>
        <h4 >{resetProductBorrow()}</h4>
      </>
    )

}
export default MaterialandProductMobile;