import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../actions";
import { useForm } from "react-hook-form";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  InputGroup,
  Input,
  ModalFooter,
  Button,
} from "reactstrap";
import hash from "object-hash";

const ModalAddDeliverWork = (props) => {
  const { modal, toggle, deliverWork, setDeliverWork } = props;
  const { register, handleSubmit, err } = useForm();

  const onAddDeliverWork = (data) => {
    
    const inputData = {
      building: data.building,
      workSystem: data.workSystem,
      detail: data.detail,
      amount: data.amount,
      unit: data.unit,
    }
    deliverWork.push(inputData);
    toggle();

  };

  return (
    <div>
      <Modal
        isOpen={modal}
        toggle={toggle}
      >
        <ModalHeader toggle={toggle}>เพิ่มรายการส่งมอบงาน</ModalHeader>
        <ModalBody>
          <br />
          <Input
            type="text"
            style={{ fontSize: "15px" }}
            placeholder="อาคาร"
            name="building"
            innerRef={register}
          />
          <br />
          <Input
            type="text"
            style={{ fontSize: "15px" }}
            placeholder="ระบบงาน"
            name="workSystem"
            innerRef={register}
          />
          <br />
          <Input
            type="textarea"
            style={{ fontSize: "15px" }}
            placeholder="รายละเอียดการส่งมอบ"
            name="detail"
            innerRef={register}
          />
          <br />
          <Input
            min="0"
            type="number"
            style={{ fontSize: "15px" }}
            placeholder="ปริมาณ"
            name="amount"
            innerRef={register}
          />
          <br />
          <Input
            type="text"
            style={{ fontSize: "15px" }}
            placeholder="หน่วย"
            name="unit"
            innerRef={register}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSubmit(onAddDeliverWork)}>
            เพิ่ม
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            ยกเลิก
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ModalAddDeliverWork;
