import axios from "axios";
import {
  PRODUCT_CREATE,
  PRODUCT_EDIT,
  PRODUCTS_FETCH,
  PRODUCT_REMOVE,
  PRODUCTS_RESET,
  PRODUCT_FETCH,
} from "./types"

export const productFetch = (id) => {
  return (dispatch) => {
    axios.get(process.env.REACT_APP_API_URL + "/products/" + id).then((res) => {
      dispatch({ type: PRODUCT_FETCH, payload: res.data });
    });
  }
}

export const productsFetch = () => {
  return (dispatch) => {
    axios.get(process.env.REACT_APP_API_URL + "/products/all").then((res) => {
      dispatch({ type: PRODUCTS_FETCH, payload: res.data });
    });
  }
}

export const productsFetchFromCategory = (id) => {
  return (dispatch) => {
    axios
      .get(process.env.REACT_APP_API_URL + "/products/category/" + id)
      .then((res) => {

        dispatch({ type: PRODUCTS_FETCH, payload: res.data });
      });
  };
};

export const productsReset = () => {
  return (dispatch) => {
    dispatch({ type: PRODUCTS_RESET, payload: null });
  };
};

// เพิ่มอุปกรณ์
export const productCreate = (values) => {
  return (dispatch) => {
    axios
      .post(process.env.REACT_APP_API_URL + "/products", values)
      .then((res) => {

        axios
          .get(process.env.REACT_APP_API_URL + "/products/all")
          .then((res) => {
            dispatch({ type: PRODUCT_CREATE, payload: res.data });
          });
      });
    // alert("บันทึกสำเร็จ")
  };

};

//เเก้ไข
export const productEdit = (id, values) => {

  // console.log(values);
  return (dispatch) => {
    axios
      .put(process.env.REACT_APP_API_URL + "/products/" + id, values)
      .then((res) => {
        axios
          .get(process.env.REACT_APP_API_URL + "/products/all")
          .then((res) => {
            dispatch({ type: PRODUCT_EDIT, payload: res.data });
          });
      });
  };
};


// ลบ
export const productRemove = (id) => {
  return (dispatch) => {
    axios
      .delete(process.env.REACT_APP_API_URL + "/products/" + id)
      .then((res) => {
        axios
          .get(process.env.REACT_APP_API_URL + "/products/all")
          .then((res) => {
            dispatch({ type: PRODUCT_REMOVE, payload: res.data });
          });
      });
  };
};

//เบิกอุปกรณ์ เร่งด่วน
//เพิ่มสต็อกจากร้านค้าเข้าคลังก่อน แล้วเบิกอุปกรณ์จากคลัง จากนั้นเปลี่ยนสถานะ
//id == order._id
export const productBorrowUrgent = (id, status, borrow, buy) => {

  addProductFormStore(buy, borrow);

  //เปลี่ยนสถานะ
  return (dispatch) => {
    axios.put(process.env.REACT_APP_API_URL + "/order/" + id, status).then((res) => {
      dispatch({ type: PRODUCT_FETCH, payload: res.data });
    });
  }
};

//เบิกอุปกรณ์ ปกติ
//เช็คในคลังก่อนเหลือเท่าไหร่ เพิ่มอุปกรณ์จากร้าน เบิกอุปกรณ์ จากนั้นเปลี่ยนสถานะ

export const productBorrowNormal = (id, status, borrow, buy) => {

  addProductFormStore(buy, borrow);

  //เปลี่ยนสถานะ
  return (dispatch) => {
    axios.put(process.env.REACT_APP_API_URL + "/order/" + id, status).then((res) => {
      dispatch({ type: PRODUCT_FETCH, payload: res.data });
    });
  }
};

//เพิ่มของเข้าสต้อกและเรียกใช้ฟังก์ชันตัดสต้อก
export const addProductFormStore = (buy, borrow) => {

  if (buy.product.length != null) {
    if (buy.product.length === 0) {
      productBorrow(borrow);
    } else {
      for (let count = 0; count < buy.product.length; count++) {
        if (buy.product[count] != null) {
          axios
            .get(process.env.REACT_APP_API_URL + "/products/" + buy.product[count]._id)
            .then((res) => {

              const inventory = {
                product_inventory: parseInt(res.data.product_inventory) + buy.inventory[count],
              }
              axios
                .put(process.env.REACT_APP_API_URL + "/products/" + buy.product[count]._id, inventory)
                .then((res) => {
                  if (count === buy.product.length - 1) {
                    productBorrow(borrow);
                  }
                })
            });
        }
      }
    }
  }
}

//ตัดของออกจากสต้อก
export const productBorrow = (borrow) => {
  if (borrow.product.length != null) {
    for (let count = 0; count < borrow.product.length; count++) {
      axios
        .get(process.env.REACT_APP_API_URL + "/products/" + borrow.product[count]._id)
        .then((res) => {

          const inventory = {
            product_inventory: parseInt(res.data.product_inventory) - borrow.inventory[count],
          }
          axios
            .put(process.env.REACT_APP_API_URL + "/products/" + borrow.product[count]._id, inventory)
            .then((res) => {
            })
        });
    }
  }
}

//เพิ่มอุปกรณ์ที่คืนเข้าสต็อกสต้อก
export const productReturn = (addProduct) => {

  return (dispatch) => {
    let product = addProduct;
    if (product.product.length != null) {
      for (let count = 0; count < product.product.length; count++) {

        axios
          .get(process.env.REACT_APP_API_URL + "/products/" + product.product[count]._id)
          .then((res) => {

            const inventory = {
              product_inventory: parseInt(res.data.product_inventory) + product.inventory[count],
            }

            axios
              .put(process.env.REACT_APP_API_URL + "/products/" + product.product[count]._id, inventory)
              .then((res) => {
                axios.get(process.env.REACT_APP_API_URL + "/products/all").then((res) => {
                  dispatch({ type: PRODUCTS_FETCH, payload: res.data });
                });
              })
          });
      }
    }

  }
}

export const productAddFormStore = (buy, addFormStore, addFormStock, productOfReturn) => {
  if (buy.product.length != null) {
    if (buy.product.length === 0) {
      productAddStoreBorrow(addFormStore, addFormStock, productOfReturn);

    } else {
      for (let count = 0; count < buy.product.length; count++) {
        if (buy.product[count] != null) {
          axios
            .get(process.env.REACT_APP_API_URL + "/products/" + buy.product[count]._id)
            .then((res) => {

              const inventory = {
                product_inventory: parseInt(res.data.product_inventory) + buy.inventory[count],
              }
              axios
                .put(process.env.REACT_APP_API_URL + "/products/" + buy.product[count]._id, inventory)
                .then((res) => {
                  if (count === buy.product.length - 1) {
                    productAddStoreBorrow(addFormStore, addFormStock, productOfReturn);

                  }
                })
            });
        }
      }
    }
  }
}

//ตัดของออกจากสต้อก
export const productAddStoreBorrow = (addFormStore, addFormStock, productOfReturn) => {

  if (addFormStore != null && addFormStore != [] && addFormStore.product.length != null) {
    if (addFormStore.product.length === 0) {
      productAddStockBorrow(addFormStock, productOfReturn);
    }
    else {
      for (let count = 0; count < addFormStore.product.length; count++) {
        axios
          .get(process.env.REACT_APP_API_URL + "/products/" + addFormStore.product[count]._id)
          .then((res) => {

            const inventory = {
              product_inventory: parseInt(res.data.product_inventory) - addFormStore.inventory[count],
            }
            axios
              .put(process.env.REACT_APP_API_URL + "/products/" + addFormStore.product[count]._id, inventory)
              .then((res) => {
                if (count === addFormStore.product.length - 1) {
                  productAddStockBorrow(addFormStock, productOfReturn);
                }
              })
          });
      }
    }

  }
}
//ตัดของออกจากสต้อก
export const productAddStockBorrow = (addFormStock, productOfReturn) => {
  if (addFormStock != null && addFormStock != [] && addFormStock.product.length != null) {
    if (addFormStock.product.length === 0) {
      productReturn(productOfReturn);
    }
    for (let count = 0; count < addFormStock.product.length; count++) {

      axios
        .get(process.env.REACT_APP_API_URL + "/products/" + addFormStock.product[count]._id)
        .then((res) => {

          const inventory = {
            product_inventory: parseInt(res.data.product_inventory) - addFormStock.inventory[count],
          }
          axios
            .put(process.env.REACT_APP_API_URL + "/products/" + addFormStock.product[count]._id, inventory)
            .then((res) => {
              if (count === addFormStock.product.length - 1) {

                productReturn(productOfReturn);
              }
            })
        });
    }

  }
}

export const handlingWork = (id) => {
  // console.log("id", id);
  return (dispatch) => {
    axios
      .get(process.env.REACT_APP_API_URL + "/history/keep/" + id)
      .then((res) => {
        console.log("เก็บข้อมูลไว้ใน History แล้ว | ", res);
        // window.location.reload();
      });
  }

}

export const productAdd = (id, data) => {


  productAddFormStore(data.productAddFormStore, data.productAddFormStore, data.productAddFormStock, data.productReturn)

  //  เปลี่ยนสถานะ
  return (dispatch) => {
    axios.put(process.env.REACT_APP_API_URL + "/order/" + id, data).then((res) => {
      //จัดเก็บงาน
      axios
        .get(process.env.REACT_APP_API_URL + "/history/keep/" + id)
        .then((res) => {
          console.log("เก็บข้อมูลไว้ใน History แล้ว | ", res);
          axios.get(process.env.REACT_APP_API_URL + "/products/all").then((res) => {
            dispatch({ type: PRODUCTS_FETCH, payload: res.data });
          });
        });

    });
  }

};
