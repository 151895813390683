import {
  QUOTATION_EDIT,
  QUOTATIONS_FETCH,
  QUOTATION_CREATE
} from "../actions/types";
export default function (state = [], action) {
  switch (action.type) {
    case QUOTATIONS_FETCH:
      return action.payload;
    case QUOTATION_CREATE:
      return state;
    case QUOTATION_EDIT:
      return action.payload;
    default:
      return state; // ค่าเดิม
  }
}