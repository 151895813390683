import React, { useState, useEffect, useRef } from "react";
import { Container, Table, Row, Col, Badge, Label, InputGroup, Card, Button, Input, Modal, ModalBody, ModalHeader, ModalFooter, CardTitle, CustomInput } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import _ from "lodash";
import * as UserStatus from "../../util/UserStatus";
import * as actions from "../../actions";
import SignaturePad from "react-signature-canvas";
import { useForm } from "react-hook-form";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ModalStartRating from "../../components/modal/ModalStarRating";

// ใบเสนอราคา ใบส่งมอบงาน รูปภาพก่่อนและหลังซ่อม คอมเม้น ยอมรับงาน
const CheckJob = () => {
  const orders = useSelector((state) => state.orders);
  const user = useSelector((state) => state.user);
  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams();
  const [width, setWidth] = React.useState(window.innerWidth);
  const breakpoint = 620;
  const allUser = useSelector((state) => state.userManagement);

  const day = new Date();
  const timelogs = day.getDate().toString() + "/" + day.getMonth().toString() + "/" + parseInt(day.getFullYear() + 543) + " " + day.getHours().toString() + ":" + day.getMinutes().toString() + "น.";

  const filterOrder = _.find(orders, (_order) => {
    return (
      _order._id == params.id
    )
  })

  useEffect(() => {
    dispatch(actions.fetchAllUser());
    window.addEventListener('resize', () => setWidth(window.innerWidth));
    return () => { }
  }, [])

  const [imageURL, setImageURL] = useState(filterOrder.deliveryWorkSign);
  const sigCanvas = useRef({})
  const clear = () => sigCanvas.current.clear();

  const save = () => {
    setImageURL(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"))
    setOpen(!open);
  }

  const [open, setOpen] = useState(false);
  const toggleOpen = () => {
    setOpen(!open);
  }

  const [star, setStar] = useState(0);
  const [modalStarRating, setModalStarRating] = useState(false);
  const toggleStarRating = () => {
    setModalStarRating(!modalStarRating);
  }

  const [checkDeliveryWork, setCheckDeliveryWork] = useState([]);
  const { register, handleSubmit, err } = useForm({

  });

  const onCheck = (data) => {
    if (checkDeliveryWork.length != filterOrder.deliveryWorkList.length) {
      alert("โปรดตรวจรับงานให้ครบ")
    } else {

      if (imageURL === undefined) {
        alert("โปรดเซ็นรับงาน")
      } else {

        let comfirm = window.confirm("ยืนยันการส่งมอบงาน")
        if (comfirm) {

          const check = _.find(checkDeliveryWork, (deliveryWork) => {
            return (
              deliveryWork == "แก้ไข"
            )
          })

          if (checkDeliveryWork.length === filterOrder.deliveryWorkList.length && check === undefined) {
            // console.log("ผ่าน");
            // //ผ่าน
            const pastData = {
              initialQuotation: true,
              statusMechanic: UserStatus.MECHANIC_DELIVERWORK,
              statusUser: UserStatus.USER_FINISHED,
              checkDeliveryWork: checkDeliveryWork,
              deliveryWorkSign: imageURL,
              deliveryWorkComment: data.deliveryWorkComment,
              comfirmDeliverWork: new Date()
            }
            const notify = {
              message: "งานแจ้งซ่อมของลูกค้าชื่อ " + filterOrder.user_name +
                " หน่วยงาน " + filterOrder.user_agency + "ได้รับยืนยันการส่งมอบงานเรียบร้อยแล้ว โปรดส่งคืนอุปกรณ์ที่ไม่ได้ใช้",
        
            }
            const notifyData = {
              data: notify,
              token: filterOrder.mechanic_token
            }

            const token = "ADMIN"
            const dataNotification = timelogs + "งานแจ้งซ่อมของ " + filterOrder.user_name + " ได้รับยืนยันการส่งมอบงานเรียบร้อยแล้ว"
            dispatch(actions.notifySend(notifyData));
            dispatch(actions.notifyMultiSendAdmin(dataNotification, token, allUser))
            dispatch(actions.orderPut(filterOrder._id, pastData))
            // history.push("/status")
            toggleStarRating()
          } else {
            // console.log("แก้ไข");
            //แก้ไข
            const notPastData = {
              initialQuotation: true,
              statusMechanic: UserStatus.MECHANIC_EDIT,
              statusUser: UserStatus.USER_WAITEDIT,
              checkDeliveryWork: checkDeliveryWork,
              deliveryWorkSign: imageURL,
              deliveryWorkComment: data.deliveryWorkComment,
              editDeliveryWorkDate: new Date()
            }

            const notify = {
              message: "ลูกค้าชื่อ " + filterOrder.user_name +
                " หน่วยงาน " + filterOrder.user_agency + " ต้องการให้แก้ไขงานซ่อม",

            }
            const notifyData = {
              data: notify,
              token: filterOrder.mechanic_token
            }
            const confirmToken = "CONFIRM"
            const confirmDataNotification = "ลูกค้าชื่อ " + filterOrder.user_name +
            " หน่วยงาน " + filterOrder.user_agency + " ต้องการให้แก้ไขงานซ่อม"

            dispatch(actions.notifyMultiSend(confirmDataNotification, confirmToken, allUser))
            dispatch(actions.notifySend(notifyData));
            dispatch(actions.orderPut(filterOrder._id, notPastData))
            // history.push("/status")
            if (user.role === "USER") {
              history.push("/status")

            } else {
              history.push("/management/deliver_work")

            }
          }

        }
      }

    }

  }
  const handleOnChange = (index, value) => {
    checkDeliveryWork[index] = value;
  }

  const nav = () => {
    return <Row>
      <Button size="sm" outline color="primary" className="ml-2 mr-2 mb-2"
        onClick={() => {
          if (filterOrder.initialQuotation === true) {
            history.push("/initail_quotation/" + filterOrder._id)
          } else {
            history.push("/quotation/" + filterOrder._id)
          }
        }}>ใบเสนอราคา</Button>
      <Button size="sm" outline color="primary" className="ml-2 mr-2 mb-2" onClick={() => { history.push("/delivery/" + filterOrder._id) }}>ใบส่งมอบงาน</Button>
      <Button size="sm" outline color="primary" className="ml-2 mr-2 mb-2" onClick={() => { history.push("/portfolio/" + filterOrder._id) }}>รูปภาพก่อนและหลังซ่อม</Button>
    </Row>

  }
  return (
    <>
      <Container fluid className="light-th"
        style={{
          marginTop: "50px",
          maxWidth: "900px",
        }}
      >
        <ModalStartRating
          modal={modalStarRating}
          toggle={toggleStarRating}
          star={star}
          setStar={setStar}
          mechanicId={filterOrder.mechanicId}
          id={filterOrder._id}
        />

        <Row>
          <Col xs="2">
            <Button
              outline
              color="secondary"
              onClick={
                history.goBack
              }
            >ย้อนกลับ</Button>
          </Col>
          <Col xs="8">
            <h2 className="text-center">ตรวจรับงาน</h2>
          </Col>
        </Row>
        <br />

        <div
          style={{
            backgroundColor: "#ffffff",
            boxShadow: "1px 1px 3px #d9d9d9",
            padding: "30px",
          }}>

          <form onSubmit={handleSubmit(onCheck)}>

            <h4 ><strong>รายการส่งมอบงาน</strong></h4>
            <br />

            {nav()}

            <br />

            {filterOrder.deliveryWorkList.map((_delivery, index) => {
              return (
                <>

                  <Row>
                    <Col>
                      <h5>{index + 1}. ระบบงาน {_delivery.workSystem}</h5>
                      <h5>รายละเอียดการส่งมอบ {_delivery.detail}</h5>
                    </Col>
                    <Col xs="4">
                      <h5> อาคาร {_delivery.building} </h5>
                      <h5>ปริมาณ {_delivery.amount} {_delivery.unit}</h5>
                    </Col>
                  </Row>

                  <div className="ml-4">

                    <RadioGroup name={index} >
                      <FormControlLabel value="ผ่าน" control={<Radio color="primary" />} label="ผ่าน" onChange={() => handleOnChange(index, "ผ่าน")} />
                      <FormControlLabel value="แก้ไข" control={<Radio />} label="แก้ไข" onChange={() => handleOnChange(index, "แก้ไข")} />
                    </RadioGroup>

                    <br />
                  </div>



                </>
              );
            })}

            <Input type="textarea" placeholder="ความเห็นของผู้ตรวจรับงาน" name="deliveryWorkComment" innerRef={register} />
            <br />
            <Row className="ml-2">
              <h4 ><strong>ลายเซ็น</strong></h4>

              <Button className="ml-2" color="primary" onClick={() => toggleOpen()}>เพิ่มลายเซ็น</Button>
            </Row>

            <Modal isOpen={open} toggle={toggleOpen}>
              <ModalHeader>ลายเซ็น</ModalHeader>
              <ModalBody>
                <SignaturePad
                  ref={sigCanvas}
                  canvasProps={{ width: 450, height: 200 }} />
              </ModalBody>
              <ModalFooter>
                <Button color="success" onClick={save}>บันทึก</Button>{" "}
                <Button color="warning" onClick={clear}>ล้างค่า</Button>{" "}
                <Button onClick={() => toggleOpen()}>ยกเลิก</Button>

              </ModalFooter>
            </Modal>
            <br />
            <br />
            {imageURL ? (
              <img
                src={imageURL}
                alt="my signature"
                style={{
                  display: "block",
                  margin: "0 auto",
                  border: "1px solid black",
                  width: "300px"
                }}
              />
            ) : null}
            <br />

            <div className="text-right">

              <Button color="success" >บันทึก</Button>

            </div>
          </form>
        </div>
      </Container>
    </>
  )

}
export default CheckJob;