import { combineReducers } from "redux"
import { reducer as reduxForm } from "redux-form"
import UserReducer from "./UserReducer"
import AuthStateReducer from "./AuthStateReducer"
import UserManagerReducer from "./UserManagerReducer"
import CategoriesReducer from "./CategoriesReducer"
import ProductsReducer from "./ProductsReducer"
import OrdersReducer from "./OrdersReducer"
import HistoryReducer from "./HistoryReducer"
import ProductLogReducer from "./ProductLogReducer"
import RepairtypeReducer from "./RepairtypeReducer"
import QuotationReducer from "./QuotationReducer"
import SystemReducer from "./SystemReducer"

const rootReducer = combineReducers({
    auth: AuthStateReducer,
    user: UserReducer,
    userManagement: UserManagerReducer,
    categories: CategoriesReducer,
    products: ProductsReducer,
    orders: OrdersReducer,
    productLogs: ProductLogReducer,
    history: HistoryReducer,
    repairtype: RepairtypeReducer,
    quotation: QuotationReducer,
    system: SystemReducer,
    form: reduxForm,
}) 
export default rootReducer