import axios from "axios";
import {
  QUOTATION_EDIT,
  QUOTATIONS_FETCH,
  QUOTATION_CREATE
} from "./types";


export const quotationsFetch = () => {
  return (dispatch) => {
    axios.get(process.env.REACT_APP_API_URL + "/quotation/all").then((res) => {
      dispatch({ type: QUOTATIONS_FETCH, payload: res.data });
    });
  };
};

export const quotationFetch = (id) => {
  return (dispatch) => {
    axios.get(process.env.REACT_APP_API_URL + "/quotation/" + id).then((res) => {
      dispatch({ type: QUOTATIONS_FETCH, payload: res.data });
    })
  }
}
  
export const createQuotation = (data) => {
    return (dispatch) => {
      axios
        .post(process.env.REACT_APP_API_URL + "/quotation", data)
        .then((res) => {
          dispatch({ type: QUOTATION_CREATE, payload: res.data });
        })
        .catch((err) => {
          console.log("Error Creating Quotation");
          console.error(err);
        });
    };
  };

  export const editquotation = (data) => {
    console.log(data);
    return (dispatch) => {
      axios
        .put(
          process.env.REACT_APP_API_URL + "/quotation/" + data._id,
          data
        )
        .then((res) => {
          // alert("แก้ไขใบเสนอราคาสำเร็จ");
          dispatch({ type: QUOTATION_EDIT, payload: res.data });
          // window.location.reload();
        })
        .catch((err) => {
          alert("ไม่สามารถแก้ไขใบเสนอราคาได้");
          console.log("Cannot Edit Quotation");
          console.error(err);
        });
    };
  };

 