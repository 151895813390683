import React, { useState, useEffect } from "react";
import { Button, Container, Table, Input, Alert, Row, Col, Label, Card } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import * as role from "../../util/UserRole";
import { useHistory, useParams } from "react-router-dom";
import * as actions from '../../actions'
import _ from "lodash";
import ModalSelectProduct from "../../components/modal/ModalEditSelectProduct";
import { RemoveIcon, AddIcon, TrashIcon, TextInput, Strong } from 'evergreen-ui'

// ดูรายละเอียดข้อมูลการซ่อม ประเมินราคาเบื้องต้น

const EditProductAndServiceCharge = ({ selectProduct, setSelectProduct, selectProductStore, setProductInventory, setProductStoreInventory, productInventory, productStoreInventory, reload, setReload, productBorrow, productBorrowInventory, productCost, cost, productCostInventory, setProductBorrow, setProductBorrowInventory, setProductCost, setProductCostInventory, setCost, setPrice, setVat, setOperatingCost, vat, ot, price, taxFree, setTaxFree, noOperatingCost, setNoOperatingCost, operatingCost }) => {
  const orders = useSelector((state) => state.orders);
  const user = useSelector((state) => state.user);
  const products = useSelector((state) => state.products);
  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams();

  const [width, setWidth] = React.useState(window.innerWidth);
  const breakpoint = 620;

  const [modalSelectProduct, setModalSelectProduct] = useState(false); // props สำหรับเปิดปิด modal
  const toggleModalSelectProduct = () => {
    setModalSelectProduct(!modalSelectProduct); // method สำหรับ set เปิดหรือปิด modal
  }

  const allowAccess = user
    ? user.role == role.MECHANIC_ROLE ||
      user.role == role.ADMIN_ROLE ||
      user.role == role.SUPER_USER_ROLE
      ? true
      : false
    : history.push('/login');

  const filterOrder = _.find(orders, (_order) => {
    return (
      _order._id == params.id
    )
  })

  const [selectproductCost, setSelectProductCost] = useState(filterOrder.material[0].product);
  const [selectCost, setSelectCost] = useState(filterOrder.estimate_price[0].cost);

  useEffect(() => {
    dispatch(actions.productsFetch());
    window.addEventListener('resize', () => setWidth(window.innerWidth));

    return () => {
    }
  }, []);

  useEffect(() => {
    // console.log("Use Effect:",reload);
    window.addEventListener('resize', () => setWidth(window.innerWidth));

    return () => { }
  }, [reload, cost])

  const resetProductBorrow = () => {
    if (productBorrow.length != 0) {
      setProductBorrow([]);
      setProductBorrowInventory([]);
      setProductCost([]);
      setProductCostInventory([]);
      // setCost([]);
      // setPrice([]);
      // setVat([]);
      // setOperatingCost([]);
    }
  }

  const showPrice = (cost) => {
    price[0] = 0;
    vat[0] = 0;
    return (
      <>
        {productCost.map((_product) => {
          if (_product.product_price != 0) {
            price[0] = price[0] + parseInt(_product.product_price * productCostInventory[productCost.indexOf(_product)]) + parseInt(cost[productCost.indexOf(_product)] * productCostInventory[productCost.indexOf(_product)]);
            vat[0] = price[0] * 0.07;
          }
        })}

        {ot.map((_ot) => {
          price[0] = price[0] + parseInt(_ot.ot_price) * parseInt(_ot.ot_inventory);
          vat[0] = price[0] * 0.07;
        })}

      </>
    );
  }

  const resetProduct = (productFromStock, productFromStore, productInventoryFromStock, productInventoryFromStore) => {
    let Stock = [];
    let StockInventory = [];
    let Store = [];
    let StoreInventory = [];
    {
      productFromStock.map((_product) => {
        const filterProduct = _.find(products, (product) => {
          return (
            product._id == _product._id
          )
        })
        Stock.push(_product)
        StockInventory.push(productInventoryFromStock[productFromStock.indexOf(_product)])
      })
    }
    {
      productFromStore.map((_product) => {
        const filterProduct = _.find(products, (product) => {
          return (
            product._id == _product._id
          )
        })
        Store.push(_product)
        StoreInventory.push(productInventoryFromStore[productFromStore.indexOf(_product)])
      })
    }

    if (Stock.length != 0) {
      productInventoryFromStock = StockInventory;
      productFromStock = Stock;

    }
    if (Store.length != 0) {
      productFromStore = Store;
      productInventoryFromStore = StoreInventory;
    }

  }

  const mapCost = (productCost, cost, selectCost, selectproductCost) => {

    if (cost.length === selectCost.length) {
      {
        selectproductCost.map((select) => {
          const filterProduct = _.find(productCost, (product) => {
            return (
              product._id == select._id
            )
          })
          if (selectCost[selectproductCost.indexOf(select)] != -1) {
            cost[productCost.indexOf(filterProduct)] = selectCost[selectproductCost.indexOf(select)];
          }

        })
      }
    }
  }

  const handleOnChange = (value, productIndex) => {

    if (value != null && value != "") {
      cost.splice(productIndex, 1);
      cost.splice(productIndex, 0, parseInt(value));
      setReload(!reload)
    }


  }

  const showListproduct = (productFromStock, productFromStore, productInventoryFromStock, productInventoryFromStore) => {
    let indexInventory = productFromStock.length;

    if (productFromStock.length != 0 || productFromStore.length != 0) {
      return (
        <>
          {resetProductBorrow()}
          {productFromStock.map((stock) => { //เอาอุปกรณืในร้านค้ากับคลัง มาคิดราคารวมกันที่เดียว
            if (productFromStore.indexOf(stock) === -1) { //ไม่มีเลือกการเลือกอุปกรณ์จากร้านค้า
              productBorrow.push(stock);
              productBorrowInventory.push(productInventoryFromStock[productFromStock.indexOf(stock)])
            } else {       //เลือกอุปกรณ์ทั้งจากร้านค้าและคลัง
              productBorrow.push(stock);
              productBorrowInventory.push(productInventoryFromStock[productFromStock.indexOf(stock)] + productInventoryFromStore[productFromStore.indexOf(stock)])
            }

          })}
          {productFromStore.map((store) => {
            if (productFromStock.indexOf(store) === -1) { //ไม่มีเลือกการเลือกอุปกรณ์จากคลัง
              productBorrow.push(store);
              productBorrowInventory.push(productInventoryFromStore[productFromStore.indexOf(store)])
            }
          })}
          {productBorrow.map((borrow) => {
            if (borrow.product_price != 0) {
              productCost.push(borrow);
              productCostInventory.push(productBorrowInventory[productBorrow.indexOf(borrow)])
              cost.push(0);
            }
          })
          }
          {mapCost(productCost, cost, selectCost, selectproductCost)}
          <Table striped bordered hover responsive style={{ fontSize: "18px" }} className="text-center">
            <thead className="light-th" >
              <tr>
                <th>#</th>
                <th>ชื่อ</th>
                <th>ราคา</th>
                <th>จำนวน</th>
                <th>ค่าแรง</th>
                <th>ดำเนินการ</th>
              </tr>
            </thead>
            <tbody>


              {productFromStock.map((_product, index) => {
                return (
                  <>
                    <tr>
                      <th >{index + 1}</th>
                      <td>{_product.product_name}</td>
                      <td>{_product.product_price} บาท</td>
                      <td>
                        <Button
                          size="sm"
                          color="danger"
                          onClick={() => {
                            if (productInventory[selectProduct.indexOf(_product)] != 1) {
                              productInventory[selectProduct.indexOf(_product)] = productInventory[selectProduct.indexOf(_product)] - 1;
                              setReload(!reload);
                            } else if (productInventory[selectProduct.indexOf(_product)] == 1) {
                              let confirmRemove = window.confirm("ยืนยันการลบอุปกรณ์")
                              if (confirmRemove) {

                                productInventory.splice(selectProduct.indexOf(_product), 1); //ลบจำนวนออก
                                selectProduct.splice(selectProduct.indexOf(_product), 1); //ลบอุปกรณ์ที่เลือก
                                setReload(!reload);

                              }
                            }

                          }}
                        ><RemoveIcon /></Button>
                        <TextInput
                          disabled
                          className="text-center"
                          width={"35%"}
                          name="productInventory"
                          value={productInventory[selectProduct.indexOf(_product)]}
                        />
                        <Button

                          size="sm"
                          color="success"
                          onClick={() => {

                            let temp = productInventory;
                            if (temp[selectProduct.indexOf(_product)] != _product.product_inventory) {
                              temp[selectProduct.indexOf(_product)] = temp[selectProduct.indexOf(_product)] + 1;
                              setProductInventory(temp);
                              setReload(!reload); //สั่งให้ use effect ทำงาน  
                            } else if (temp[selectProduct.indexOf(_product)] == _product.product_inventory && selectProductStore.indexOf(_product) === -1) {
                              selectProductStore.push(_product);
                              productStoreInventory.push(1);
                              setReload(!reload);
                            } else {
                              productStoreInventory[selectProductStore.indexOf(_product)] = productStoreInventory[selectProductStore.indexOf(_product)] + 1;
                              setReload(!reload);
                            }




                          }}
                        ><AddIcon /></Button></td>
                      <td>
                        <TextInput
                          name="cost"
                          width={"60%"}
                          disabled={!(_product.product_price != 0)}
                          className="text-center"
                          type="number"
                          placeholder={cost[productCost.indexOf(_product)]}
                          defaultvalue={cost[productCost.indexOf(_product)]}
                          onChange={event => handleOnChange(event.target.value, productCost.indexOf(_product))}
                        
                        />
                      </td>
                      <td>
                        <TrashIcon
                          className=" text-right"
                          color="danger"
                          onClick={() => {
                            let confirmRemove = window.confirm("ยืนยันการลบอุปกรณ์")
                            if (confirmRemove) {

                              productInventory.splice(selectProduct.indexOf(_product), 1); //ลบจำนวนออก
                              selectProduct.splice(selectProduct.indexOf(_product), 1); //ลบอุปกรณ์ที่เลือก
                              setReload(!reload);

                            }
                          }}
                          size={30} />

                      </td>
                    </tr>
                  </>
                );
              })}

              {productFromStore.map((_product, index) => {
                return (
                  <>
                    <tr>
                      <th >{indexInventory + index + 1}</th>
                      <td>{_product.product_name}
                        <p style={{ fontSize: "15px", color: "#BF0E08" }}>แหล่งจัดซื้อ : {_product.product_store}</p>
                      </td>
                      <td>{_product.product_price} บาท</td>
                      <td>
                        <Button
                          size="sm"
                          color="danger"
                          onClick={() => {
                            if (productStoreInventory[selectProductStore.indexOf(_product)] != 1) {
                              productStoreInventory[selectProductStore.indexOf(_product)] = productStoreInventory[selectProductStore.indexOf(_product)] - 1;
                              setReload(!reload);
                            } else if (productStoreInventory[selectProductStore.indexOf(_product)] == 1) {
                              let confirmRemove = window.confirm("ยืนยันการลบอุปกรณ์")
                              if (confirmRemove) {

                                productStoreInventory.splice(selectProductStore.indexOf(_product), 1); //ลบจำนวนออก
                                selectProductStore.splice(selectProduct.indexOf(_product), 1); //ลบอุปกรณ์ที่เลือก
                                setReload(!reload);

                              }
                            }

                          }}
                        ><RemoveIcon /></Button>
                        <TextInput
                          disabled
                          className="text-center"
                          width={"35%"}
                          name="productInventoryStore"
                          value={productStoreInventory[selectProductStore.indexOf(_product)]}
                        />
                        <Button
                          size="sm"
                          color="success"
                          onClick={() => {

                            let temp = productStoreInventory;
                            // if( temp[selectProductStore.indexOf(product)] != product.product_inventory){
                            temp[selectProductStore.indexOf(_product)] = temp[selectProductStore.indexOf(_product)] + 1;
                            setProductStoreInventory(temp);
                            setReload(!reload); //สั่งให้ use effect ทำงาน  
                            // }

                          }}
                        ><AddIcon /></Button></td>
                      <td>
                        <TextInput
                          name="cost"
                          disabled={!(_product.product_price != 0)}
                          width={"60%"}
                          className="text-center"
                          type="number"
                          placeholder={cost[productCost.indexOf(_product)]}
                          defaultvalue={cost[productCost.indexOf(_product)]}
                          onChange={event => handleOnChange(event.target.value, productCost.indexOf(_product))}
                       

                        />
                      </td>
                      <td> <TrashIcon
                        className=" text-right"
                        color="danger"
                        onClick={() => {
                          let confirmRemove = window.confirm("ยืนยันการลบอุปกรณ์")
                          if (confirmRemove) {
                            productStoreInventory.splice(selectProductStore.indexOf(_product), 1); //ลบจำนวนออก
                            selectProductStore.splice(selectProduct.indexOf(_product), 1); //ลบอุปกรณ์ที่เลือก
                            setReload(!reload);
                          }
                        }}
                        size={30} /></td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </Table>
        </>

      );
    } else {
      return (
        <>
          <div className="text-center">
            <Label
              className="text-danger"
              style={{ fontSize: "30px" }}
            >กรุณาเลือกวัสดุและอุปกรณ์</Label>

          </div>
        </>
      );
    }
  }

  if (allowAccess)
    return (
      <>
        <ModalSelectProduct
          modal={modalSelectProduct}
          toggle={toggleModalSelectProduct}
          setSelectProduct={setSelectProduct}
          selectProduct={selectProduct}
          productInventory={productInventory}
          selectProductStore={selectProductStore}
          productStoreInventory={productStoreInventory}
        />



        <Row className="ml-2">
          <Label
            style={{ fontSize: "25px" }}
          >รายการวัสดุและอุปกรณ์</Label>
          <Button
            className="ml-2 m-1"
            size="sm"
            color="success"
            onClick={() => {
              toggleModalSelectProduct(); // เปิด modal สำหรับเลือกอุปกรณ์
            }}
          >เพิ่ม</Button>
        </Row>
        <br />
        <h4 className="ml-2">{showListproduct(selectProduct, selectProductStore, productInventory, productStoreInventory)}</h4>
        <br />
      </>
    )

}
export default EditProductAndServiceCharge;