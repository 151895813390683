import React, { useState, useEffect } from "react";
import { Container, Table, Row, Col, Badge, Label, InputGroup, ListGroup, ListGroupItem, Card, Button, Input, CardTitle } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams, useLocation } from "react-router-dom";
import _ from "lodash";
import MaterialTables from "../../tables/MaterialTables";
import OtTables from "../../tables/OtTables";
import * as actions from '../../../actions'
import NormalInitialQuotation from "./initialtables/NormalQuotation";
import NoVatInitialQuotation from "./initialtables/NoVatQuotation";
import NoOverheadInitialQuotation from "./initialtables/NoOverheadQuotation";
import NoVatNoOverheadInitialQuotation from "./initialtables/NoVatNoOverheadQuotation";

import NormalQuatation from "./quotationtables/NormalQuotation";
import NoVatQuatation from "./quotationtables/NoVatQuotation";
import NoOverheadQuatation from "./quotationtables/NoOverheadQuotation";
import NoVatNoOverheadQuatation from "./quotationtables/NoVatNoOverheadQuotation";

// เอกสารใบเสนอราคา
const QuotationDocument = () => {
    const orders = useSelector((state) => state.orders);
    const user = useSelector((state) => state.user);
    const history = useHistory()
    const dispatch = useDispatch()
    const params = useParams();
    const [width, setWidth] = React.useState(window.innerWidth);
    const breakpoint = 620;
    const system = useSelector((state) => state.system);
    const [systemData, setSystemData] = useState([])
    const location = useLocation();
    const History = location.state.History;

    useEffect(() => {
        window.addEventListener('resize', () => setWidth(window.innerWidth));
        return () => { }
    }, [])

    useEffect(() => {
        setSystemData(system[0])
        return () => { };
    }, [system]);


    function printDiv(divName) {
        var printContents = document.getElementById(divName).innerHTML;
        var originalContents = document.body.innerHTML;

        document.body.innerHTML = printContents;

        window.print();

        document.body.innerHTML = originalContents;
        window.location.reload()
    }

    const showButton = () => {
        return (
            <>
                <Row className="print-disable">
                    <Col xs="2">
                        <Button
                            outline
                            color="secondary"
                            onClick={
                                history.goBack
                            }
                        >ย้อนกลับ</Button>
                    </Col>
                    <Col xs="7">
                        <h2 className="text-center">ใบเสนอราคา</h2>
                    </Col>
                    <Col className="text-right">
                        <Button color="info" onClick={() => printDiv("printarea")}>พิมพ์</Button>{" "}

                    </Col>
                </Row>
            </>
        )

    }

    const showButtonOnMobile = () => {

        return (
            <>
                <Row className="print-disable">
                    <Col xs="3">
                        <Button
                            size="sm"
                            outline
                            color="secondary"
                            onClick={
                                history.goBack
                            }
                        >ย้อนกลับ</Button>
                    </Col>
                    <Col xs="6">
                        <h5 className="text-center">ใบเสนอราคา</h5>
                    </Col>
                    <Col className="text-right">
                        <Button size="sm" color="info" onClick={() => printDiv("printarea")}>พิมพ์</Button>{" "}
                    </Col>
                </Row>
            </>
        )

    }

    const showCredit = () => {
        if (History.credit === "เงินสด") {
            return (
                <>ชำระเงินเมื่อส่งมอบงาน</>
            )
        } else {
            return <>{History.credit}เมื่อเสร็จสิ้นงาน</>
        }
    }

    const showData = () => {
        if (History.initialQuotation != false) { //ใช้ใบเสนอราคาเบื้องต้น
            return showInitaialQuotation()
        } else if (History.initialQuotation === false) { //ใช้ใบเสนอราคา
            return showQuotation()
        }
    }

    const showInitaialQuotation = () => {
        if (History.noOperatingCost === true && History.taxFree === true) {

            return (
                <>
                    <NormalInitialQuotation History={History}/>
                </>
            )
        }
        else if (History.noOperatingCost === true && History.taxFree === false) {

            return (
                <>
                    <NoVatInitialQuotation History={History}/>
                </>
            )
        }
        else if (History.noOperatingCost === false && History.taxFree === true) {

            return (
                <>
                    <NoOverheadInitialQuotation History={History}/>
                </>
            )
        }
        else if (History.noOperatingCost === false && History.taxFree === false) {

            return (
                <>
                    <NoVatNoOverheadInitialQuotation History={History}/>
                </>
            )
        }
    }

    const showQuotation = () => {
        if (History.reNoOperatingCost === true && History.reTaxFree === true) {

            return (
                <>
                    <NormalQuatation History={History}/>
                </>
            )
        }
        else if (History.reNoOperatingCost === true && History.reTaxFree === false) {

            return (
                <>
                    <NoVatQuatation History={History}/>
                </>
            )
        }
        else if (History.reNoOperatingCost === false && History.reTaxFree === true) {

            return (
                <>
                    <NoOverheadQuatation History={History}/>
                </>
            )
        }
        else if (History.reNoOperatingCost === false && History.reTaxFree === false) {

            return (
                <>
                    <NoVatNoOverheadQuatation History={History}/>
                </>
            )
        }
    }

    const showSign = () => {

        return (
            <>
                <Table className="text-center" >
                    <tr >
                        <td className="border-top-0">

                            <Row >
                                <Col><p><strong>ผู้อนุมัติราคา</strong></p></Col>
                                <Col md="7" className="text-left">
                                    <img
                                        src={History.approvedPersonSign}
                                        width="50%"
                                    />
                                </Col>
                            </Row>
                            <p><strong>( {History.approvedPerson} )</strong></p>
                            <p>วันที่ {new Date(History.quotationDate).toLocaleDateString("th")}</p>
                        </td>
                        <td className="border-top-0">
                            <Row >
                                <Col><p><strong>ผู้ว่าจ้างอนุมัติ</strong></p></Col>
                                <Col md="8" className="text-left">
                                    <img
                                        src={History.deliveryWorkSign}
                                        width="50%"
                                    /></Col>
                            </Row>
                            <p><strong>( {History.user_name} )</strong></p>
                            <p>วันที่ {new Date(History.comfirmDeliverWork).toLocaleDateString("th")}</p>
                        </td>

                    </tr>
                </Table>
            </>
        )

    }

    const showSignOnMoblie = () => {
        return (
            <>
                <ListGroup>
                    <ListGroupItem>
                        <div style={{ padding: "10px" }}>
                            <Row >
                                <Col><p><strong>ผู้อนุมัติราคา</strong></p></Col>
                                <Col md="7" className="text-center">
                                    <img
                                        src={History.mechanicSign}
                                        width="50%"
                                    />
                                </Col>
                            </Row>
                            <p className="mt-2 text-center"><strong>( {History.mechanic_name} )</strong></p>
                            <p className="text-center">วันที่ {new Date(History.quotationDate).toLocaleDateString("th")}</p>
                        </div>
                    </ListGroupItem>
                    <ListGroupItem>
                        <div style={{ padding: "10px" }}>
                            <Row >
                                <Col><p><strong>ผู้ว่าจ้างอนุมัติ</strong></p></Col>
                                <Col md="8" className="text-center">
                                    <img
                                        src={History.deliveryWorkSign}
                                        width="50%"
                                    />
                                </Col>
                            </Row>
                            <p className="mt-2 text-center"><strong>( {History.user_name} )</strong></p>
                            <p className="text-center">วันที่ {new Date(History.comfirmDeliverWork).toLocaleDateString("th")}</p>
                        </div>
                    </ListGroupItem>

                </ListGroup>
            </>
        )

    }

    if (width < breakpoint) {
        return (
            //moblie
            <>
                <Container fluid className="light-th"
                    id="printarea"
                    style={{
                        marginTop: "50px",
                        maxWidth: "900px",
                    }}
                >
                    {showButtonOnMobile()}

                    <br />
                    <div
                        style={{
                            backgroundColor: "#ffffff",
                            boxShadow: "1px 1px 3px #d9d9d9",
                            padding: "30px",
                        }}>
                        <div className="page-header">

                            <Row>
                                <Col xs="3" className="mt-2">
                                    <img
                                        src={systemData.company_logo}
                                        width="150%"
                                    />
                                </Col>
                                <Col>
                                    <Label style={{ fontSize: "10px" }}><u>{systemData.company_name_thai}</u></Label>
                                    <Label style={{ fontSize: "10px" }}>{systemData.company_name_eng}</Label>
                                    <Label style={{ fontSize: "10px" }}>{systemData.company_address_thai}</Label>
                                    <Label style={{ fontSize: "10px" }}>{systemData.company_address_eng}</Label>
                                    <Label style={{ fontSize: "10px" }}><strong>Tel :</strong> {systemData.company_tel} <strong>Fax :</strong> {systemData.company_fax} <strong>เลขประจำตัวผู้เสียภาษี</strong> {systemData.company_taxpayer_number}</Label></Col>
                            </Row>
                            <hr />
                            <div className="text-center" style={{ backgroundColor: "#e7feff" }}>
                                <h4 ><strong>ใบเสนอราคา</strong></h4>
                            </div>
                            <hr />
                            <Row>
                                <Col>
                                    <Label>เลขที่ : {History.quotationId}</Label>
                                    <Label>เรียน : {History.user_name}</Label>
                                    <Label>หน่วยงาน : {History.user_agency}</Label>
                                    <Label>โครงการ : {History.project}</Label>
                                </Col>
                                <Col>
                                    <Label>วันที่ {new Date(History.quotationDate).toLocaleDateString("th")}</Label>
                                    <Label>โทร : {History.user_tel}</Label>
                                    <Label>แฟกซ์ : {History.user_fax}</Label>
                                </Col>
                            </Row>
                            {/* <br /> */}
                        </div>

                        {showData()}
                        <br />
                        <Row className="mb-4">
                            <Col className="text-center"></Col>
                            <Col sm="5">
                                <Table>
                                    <tr >
                                        <td className="border-top-0 text-left" >
                                            <p>กำหนดยืนราคา {History.paymentTerms}  วัน</p>
                                            <p>กำหนดชำระเงิน {showCredit()}</p>
                                            <p>กรุณาเซ็นต์ตอบรับ หรือ PO เมื่ออนุมัติ</p>
                                        </td>
                                    </tr>
                                </Table>
                            </Col>
                        </Row>
                        <br />
                        {showSignOnMoblie()}
                    </div>
                </Container>
            </>
        )

    } else {
        return (
            //desktop
            <>
                <Container fluid className="light-th"
                    id="printarea"
                    style={{
                        marginTop: "50px",
                        maxWidth: "900px",
                    }}
                >
                    {showButton()}

                    <br />
                    <div
                        style={{
                            backgroundColor: "#ffffff",
                            boxShadow: "1px 1px 3px #d9d9d9",
                            padding: "30px",
                        }}>
                        <div className="page-header">

                            <Row >
                                <Col>
                                    <div >
                                        <img
                                            src={systemData.company_logo}
                                            width="100%"
                                        />
                                    </div>
                                </Col>
                                <Col md="9">
                                    <h4><u>{systemData.company_name_thai}</u></h4>
                                    <h5>{systemData.company_name_eng}</h5>
                                    <h6>{systemData.company_address_thai}</h6>
                                    <h6>{systemData.company_address_eng}</h6>
                                    <h6><strong>E-mail :</strong> {systemData.company_email} <strong>Tel :</strong> {systemData.company_tel}</h6>
                                    <h6><strong>เลขที่ผู้เสียภาษี</strong> {systemData.company_taxpayer_number}</h6>
                                </Col>
                            </Row>
                            <hr />
                            <div className="text-center" style={{ backgroundColor: "#e7feff" }}>
                                <h4 ><strong>ใบเสนอราคา</strong></h4>
                            </div>
                            <hr />
                            <Row>
                                <Col>
                                    <h6>เลขที่ : {History.quotationId}</h6>
                                    <h6>เรียน : {History.user_name}</h6>
                                    <h6>หน่วยงาน : {History.user_agency}</h6>
                                    <h6>โครงการ : {History.project}</h6>
                                </Col>
                                <Col sm="5">
                                    <h6>วันที่ {new Date(History.quotationDate).toLocaleDateString("th")}</h6>
                                    <h6>โทร : {History.user_tel}</h6>
                                    <h6>แฟกซ์ : {History.user_fax}</h6>
                                </Col>
                            </Row>
                            {/* <br /> */}
                        </div>
                        {showData()}
                        <br />
                        <Row className="mb-4">
                            <Col className="text-center"></Col>
                            <Col sm="5">
                                <Table>
                                    <tr >
                                        <td className="border-top-0 text-left" >
                                            <p>กำหนดยืนราคา {History.paymentTerms}  วัน</p>
                                            <p>กำหนดชำระเงิน {showCredit()}</p>
                                            <p>กรุณาเซ็นต์ตอบรับ หรือ PO เมื่ออนุมัติ</p>
                                        </td>
                                    </tr>
                                </Table>
                            </Col>
                        </Row>
                        <br />
                        {showSign()}
                    </div>
                </Container>
            </>
        )

    }

}
export default QuotationDocument;