import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { authen } from '../../../assets/api/firebase'
import * as actions from '../../../actions'
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  InputGroup,
  Input,
} from 'reactstrap'

const firebase = authen // For more Understandable

const RegisterFromBlank = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const authData = useSelector((state) => state.auth)
  const user = useSelector((state) => state.user)
  const { register, handleSubmit } = useForm()
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  useEffect(() => {
    if (user && authData) {
      history.push('/')
    }
  })

  const onRegister = (data) => {

    firebase
      .auth()
      .createUserWithEmailAndPassword(data.email, data.password)
      .then(async () => {
        console.log('Register Success | User ', firebase.auth().currentUser.uid)
        dispatch(actions.getUser(firebase.auth().currentUser.uid))
        history.push('/login/register')
      })
      .catch((err) => {
        console.log('Register Error |', err)
        if (err.code == 'auth/email-already-in-use') {
          alert('อีเมลนี้ถูกใช้เเล้ว')
        } else if (err.code == 'auth/weak-password') {
          alert('กรุณาตั้งรหัสผ่านอย่างน้อย 6 ตัวอักษร')
        }
      })
  }

  return (
    <Container
      style={{
        backgroundColor: '#ffffff',
        boxShadow: '1px 1px 3px #d9d9d9',
        padding: '30px',
        maxWidth: '500px',
      }}
      className="light-th"
    >
      <Row>
        <Col md="5" lg>
          <h4>ลงทะเบียน</h4>
        </Col>
        <Col md="7" style={{ fontSize: '12px' }} className="text-right mt-2">
          มีบัญชีแล้วใช่หรือไม่{' '}
          <Button
            color="link"
            tag={Link}
            style={{ fontSize: '12px' }}
            className="text-danger"
            to={'/login'}
          >
            ลงชื่อเข้าใช้
          </Button>
        </Col>
      </Row>
      <hr />
      <Form>
        <Input
          style={{ fontSize: '15px' }}
          type="email"
          placeholder="Email"
          innerRef={register}
          name="email"
        ></Input>
        <br />
        <Input
          style={{ fontSize: '15px' }}
          type="password"
          placeholder="Password"
          innerRef={register}
          name="password"
          onChange={(event) => setPassword(event.target.value)}
        ></Input>
        <br />
        <Input
          style={{ fontSize: '15px' }}
          type="password"
          placeholder="Confirm Password"
          innerRef={register}
          name="confirmPassword"
          onChange={(event) => setConfirmPassword(event.target.value)}
        ></Input>
        <br />
        {password == confirmPassword
          ? ''
          : 'กรุณาใส่รหัสผ่านสำหรับการยืนยันให้ตรงกันกับรหัสผ่าน'}
        <br />
        <Button
          className="light-th"
          color="secondary"
          block
          disabled={password !== confirmPassword || password == ''}
          onClick={handleSubmit(onRegister)}
        >
          สมัครเข้าใช้งาน
        </Button>
      </Form>
    </Container>
  )
}

export default RegisterFromBlank