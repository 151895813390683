import React, { useState, useEffect } from "react";
import { Container, Table, Col, Row, Button, Alert } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import * as role from "../../util/UserRole";
import * as actions from '../../actions'
import * as UserStatus from "../../util/UserStatus";
import BorrowTables from "../tables/BorrowTables";
import SearchOrder from "../../components/Search/SearchOrder";
import _ from "lodash";
import { useHistory, useParams } from "react-router-dom";

// จ่ายอุปกรณ์ ตัดสต๊อก
const BorrowProduct = () => {
  const orders = useSelector((state) => state.orders);
  const user = useSelector((state) => state.user);
  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams();
  const products = useSelector((state) => state.products)
  const [productInventory, setProductInventory] = useState([]);
  const [stock, setStock] = useState([]); //คลัง
  const [stockInventory, setStockInventory] = useState([]);
  const [store, setStore] = useState([]); //ร้านค้า  
  const [storeInventory, setStoreInventory] = useState([]);


  useEffect(() => {
    dispatch(actions.productsFetch());
    dispatch(actions.ordersFetch(user));
    return () => { };
  }, []);

  const allowAccess = user
    ?
    user.role == role.CONTRATOR_ROLE ||
      user.role == role.MECHANIC_ROLE ||
      user.role == role.STORE_ROLE ||
      user.role == role.ADMIN_ROLE ||
      user.role == role.SUPER_USER_ROLE
      ? true
      : false
    : history.push('/login');

  const filterOrder = _.find(orders, (_order) => {
    return (
      _order._id == params.id
    )
  })

  const checkInventory = (product, inventory, borrow, count) => {
    if (inventory === 0) { //ของในคลังหมด ให้เบิกจากร้านค้าเลย
      store[count] = product;
      storeInventory[count] = borrow;

    }
    else if (inventory - borrow > 0 || inventory - borrow === 0) { //มีของในคลัง ให้เบิกจากคลัง
      stock[count] = product;
      stockInventory[count] = borrow;

    } else if (inventory != 0 && inventory - borrow < 0) { //ของในคลังมีไม่พอ ส่วนที่เหลือให้เบิกจากร้านค้า
      stock[count] = product;
      stockInventory[count] = inventory;
      store[count] = product;
      storeInventory[count] = borrow - inventory;

    }
  }

  const showProduct = (productFormStock, inventoryOfStock, productFormStore, inventoryOfSore, productBorrow, inventoryOfBorrow) => {  //คลัง

    // if (filterOrder.speed === "เร่งด่วน") {
    //   if (productFormStock.length != 0 || productFormStore.length != 0) {
    //     return (
    //       <>
    //         <div className="ml-4 text-left">
    //           <Table striped bordered hover style={{ fontSize: "18px" }} className="text-center">
    //             <thead className="light-th" >
    //               <tr>

    //                 <th>ชื่อ</th>
    //                 <th>จำนวน</th>
    //                 <th>หมายเหตุ</th>
    //               </tr>
    //             </thead>
    //             <tbody>



    //               {productFormStock.map((_product) => {
    //                 return (
    //                   <>
    //                     <tr>
    //                       <td>{_product.product_name}</td>
    //                       <td>{inventoryOfStock[productFormStock.indexOf(_product)]}</td>
    //                       <td>คลัง</td>
    //                     </tr>
    //                   </>
    //                 );
    //               })}

    //               {productFormStore.map((_product) => {
    //                 return (
    //                   <>
    //                     <tr>
    //                       <td>{_product.product_name}</td>
    //                       <td>{inventoryOfSore[productFormStore.indexOf(_product)]}</td>
    //                       <td className="text-danger">{_product.product_store}</td>
    //                     </tr>
    //                   </>
    //                 );
    //               })}
    //             </tbody>
    //           </Table>
    //         </div>
    //       </>
    //     );

    //   }
    // } else { //ปกติ 
    //เช็คจำนวนก่อนในคลัง
    if (productFormStock.length != 0 || productFormStore.length != 0 || productBorrow.length != 0) {

      {
        productBorrow.map((_product) => {
          productInventory[productBorrow.indexOf(_product)] = _.find(products, (Product) => {
            return (
              Product._id === _product._id
            );
          }).product_inventory;
        })
      }

      for (let count = 0; count < productBorrow.length; count++) {
        checkInventory(productBorrow[count], parseInt(productInventory[count]), parseInt(inventoryOfBorrow[count]), count);
      }

      return (
        <>
          <div className="ml-4 text-left">
            <Table striped bordered hover style={{ fontSize: "18px" }} className="text-center">
              <thead className="light-th" >
                <tr>

                  <th>ชื่อ</th>
                  <th>จำนวน</th>
                  <th>หมายเหตุ</th>
                </tr>
              </thead>
              <tbody>

                {stock.map((_product) => {
                  return (
                    <>
                      <tr>
                        <td>{_product.product_name}</td>
                        <td>{stockInventory[stock.indexOf(_product)]}</td>
                        <td>คลัง</td>
                      </tr>
                    </>
                  );
                })}

                {store.map((_product) => {
                  return (
                    <>
                      <tr>
                        <td>{_product.product_name}</td>
                        <td>{storeInventory[store.indexOf(_product)]}</td>
                        <td className="text-danger">{_product.product_store}</td>
                      </tr>
                    </>
                  );
                })}

              </tbody>
            </Table>
          </div>
        </>
      );
    }
    // }
  }

  const showButton = () => {
    if (user.role === role.STORE_ROLE && filterOrder.productBorrowStatus != true) {
      return (
        <Button color="success"
          onClick={() => {
            let comfirm = window.confirm("ยืนยันการเตรียมอุปกรณ์")
            if (comfirm) {
              const data = {
                productBorrowStatus: true
              }
              const notify = {
                message: "ฝ่าย STORE ได้จัดเตรียมอุปกรณ์ สำหรับงานแจ้งซ่อมของลูกค้าชื่อ " + filterOrder.user_name +
                  "หน่วยงาน " + filterOrder.user_agency + "เรียบร้อยแล้ว",

              }
              const notifyData = {
                data: notify,
                token: filterOrder.mechanic_token
              }
              dispatch(actions.orderPut(filterOrder._id, data))
              dispatch(actions.notifySend(notifyData));
              history.push("/management/borrow")
            }
          }}
        >
          เตรียมอุปกรณ์
        </Button>
      )
    } else if (user.role === role.STORE_ROLE && filterOrder.productBorrowStatus === true) {
      return (
        <Button color="success" disabled
        >
          เตรียมอุปกรณ์
        </Button>
      )
    } else if (user.role != role.STORE_ROLE && filterOrder.productBorrowStatus != true) {
      return (
        <Button color="success" disabled
        >
          เบิกอุปกรณ์
        </Button>
      )
    } else {
      return (
        <Button color="success"
          onClick={() => {
            let comfirm = window.confirm("ยืนยันการเบิกอุปกรณ์")
            if (comfirm) {
              // if (filterOrder.speed === "ปกติ") {
              // เช็คในคลังก่อนเหลือเท่าไหร่ เพิ่มอุปกรณ์จากร้าน เบิกอุปกรณ์
              const productBorrow = {
                product: filterOrder.productBorrow[0].product,
                inventory: filterOrder.productBorrow[0].inventory,
              }

              const addProduct = {
                product: store,
                inventory: storeInventory,
              }

              const addStock = {
                product: stock,
                inventory: stockInventory,
              }

              const dataconvertStore = {
                product: _.filter(store, (_store) => {
                  return (
                    _store != undefined
                  )
                }),
                inventory: _.filter(storeInventory, (_storeInventory) => {
                  return (
                    _storeInventory != undefined
                  )
                }),
              }

              const dataconvertStock = {
                product: _.filter(stock, (_stock) => {
                  return (
                    _stock != undefined
                  )
                }),
                inventory: _.filter(stockInventory, (_stockInnentory) => {
                  return (
                    _stockInnentory != undefined
                  )
                }),
              }

              const data = {
                statusUser: UserStatus.USER_ACTIONS,
                statusMechanic: UserStatus.MECHANIC_ACTIONS,
                productFormStore: dataconvertStore,
                productFormStock: dataconvertStock,
                borrowDate: new Date(),
              }
              const dataLogs = {
                type: "เบิก",
                transactionDate: new Date(),
                referenceOrder: filterOrder._id,
                handler: user.user_firstname + " " + user.user_lastname,
                quotationId: filterOrder.quotationId,
              }

              dispatch(actions.productLogBorrow(dataLogs, productBorrow))
              dispatch(actions.productBorrowNormal(filterOrder._id, data, productBorrow, addProduct));
              dispatch(actions.ordersFetch(user));
              // history.push("/management/repair")

              // } else if (filterOrder.speed === "เร่งด่วน") {
              //   //เพิ่มสต็อกจากร้านค้าเข้าคลังก่อน แล้วเบิกอุปกรณ์จากคลัง

              //   const addProduct = {
              //     product: filterOrder.productFormStore[0].product,
              //     inventory: filterOrder.productFormStore[0].inventory,
              //   }

              //   const productBorrow = {
              //     product: filterOrder.productBorrow[0].product,
              //     inventory: filterOrder.productBorrow[0].inventory,
              //   }
              //   const data = {
              //     statusUser: UserStatus.USER_ACTIONS,
              //     statusMechanic: UserStatus.MECHANIC_ACTIONS,
              //     borrowDate: new Date(),
              //   }

              //   const dataLogs = {
              //     type: "เบิก",
              //     transactionDate: new Date(),
              //     referenceOrder: filterOrder._id,
              //     handler: user.user_firstname + " " + user.user_lastname,
              //     quotationId: filterOrder.quotationId,
              //   }

              //   dispatch(actions.productLogBorrow(dataLogs, productBorrow))
              //   dispatch(actions.productBorrowUrgent(filterOrder._id, data, productBorrow, addProduct));
              //   dispatch(actions.ordersFetch(user));


              // }

              let navigate = window.confirm("เข้าสู่หน้าบันทึกการซ่อมต่อหรือไม่")
              if (navigate) {
                history.push("/management/repair_record");
              } else {
                history.push("/management/borrow");
              }

            }

          }}
        >เบิกอุปกรณ์</Button>
      )
    }
  }

  if (allowAccess) {
    return (
      <>
        <Container fluid className="light-th"
          style={{
            marginTop: "50px",
            maxWidth: "900px",
          }}>

          <h2 className="text-center">เบิกอุปกรณ์</h2>
          <br />
          <div
            className="text-center"
            style={{
              backgroundColor: "#ffffff",
              boxShadow: "1px 1px 3px #d9d9d9",
              padding: "30px",
            }}>

            <h3 className="ml-2 text-left">รายการอุปกรณ์</h3>
            <br />
            <h4>{showProduct(filterOrder.productFormStock[0].product, filterOrder.productFormStock[0].inventory, filterOrder.productFormStore[0].product, filterOrder.productFormStore[0].inventory, filterOrder.productBorrow[0].product, filterOrder.productBorrow[0].inventory)}</h4>

            <div className="text-right">
              {showButton()}
            </div>
          </div>

        </Container>

      </>
    )
  } else {
    return (
      <>
      </>
    )
  }
}
export default BorrowProduct;