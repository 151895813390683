import React, { useState, useEffect } from "react";
import { Container, Table, Col, Row, Button, Alert, Input } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import * as role from "../../util/UserRole";
import * as actions from '../../actions'
import * as UserStatus from "../../util/UserStatus";
import SearchOrder from "../../components/Search/SearchOrder";
import _ from "lodash";
import { useHistory, useParams } from "react-router-dom";
import { TextInput } from 'evergreen-ui'

// คืนอุปกรณ์ เพิ่มสต๊อก คิดเงินอีกครั้ง
const ReturnProduct = () => {
  const orders = useSelector((state) => state.orders);
  const user = useSelector((state) => state.user);
  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams();
  const allUser = useSelector((state) => state.userManagement);

  const day = new Date();
  const timelogs = day.getDate().toString() + "/" + day.getMonth().toString() + "/" + parseInt(day.getFullYear() + 543) + " " + day.getHours().toString() + ":" + day.getMinutes().toString() + "น.";

  useEffect(() => {
    dispatch(actions.fetchAllUser());
    dispatch(actions.ordersFetch(user));
    return () => { };
  }, []);


  const allowAccess = user
    ?
    user.role == role.CONTRATOR_ROLE ||
      user.role == role.MECHANIC_ROLE ||
      user.role == role.STORE_ROLE ||
      user.role == role.ADMIN_ROLE ||
      user.role == role.SUPER_USER_ROLE
      ? true
      : false
    : history.push('/login');

  const filterOrder = _.find(orders, (_order) => {
    return (
      _order._id == params.id
    )
  })


  const [productAdd, setProductAdd] = useState([]);
  const [productAddInventory, setProductAddInventory] = useState([]);
  const [productAddFormStock, setProductAddFromStock] = useState([]);
  const [productAddFormStockInventory, setProductAddFormStockInventory] = useState([]);
  const [productAddFormStore, setProductAddFormStore] = useState([]);
  const [productAddFormStoreInventory, setProductAddFormStoreInventory] = useState([]);
  const [productShow, setProductShow] = useState([]);
  const [count, setCount] = useState(0);

  const [productOfReturn, setProductOfReturn] = useState([]);
  const [inventoryOfReturn, setInventoryOfReturn] = useState([]);
  const [note, setNote] = useState([]);


  const findProductAdd = (borrow, borrowInventory, use, useInventory, useStock, useStockInventory, useStore, useStoreInventory) => {

    // console.log("----------------------------------------");
    // console.log("เบิก", borrow, borrowInventory);
    // console.log("เพิ่ม", use, useInventory,);
    // console.log("เพิ่มจากคลัง", useStock, useStockInventory);
    // console.log("เพิ่มจากร้าน", useStore, useStoreInventory);
    // console.log("----------------------------------------");

    if (count === 0) { //เช็คอุปกรณ์ที่เพิ่มใหม่

      //เช็คว่ามีอุปกรณ์ที่เพิ่มใหม่ไหม
      for (let countUse = 0; countUse < use.length; countUse++) {
        for (let countBorrow = 0; countBorrow < borrow.length; countBorrow++) {
          if (use[countUse]._id !== borrow[countBorrow]._id) {
            //ถ้ามีการเพิ่มอุปกรณ์ใหม่

            let product = {
              product: use[countUse],
              borrow: 0,
              add: useInventory[countUse],
              use: useInventory[countUse],
              stock: 0,
              store: 0,
            }
            productShow[countUse] = product;
            productOfReturn[countUse] = use[countUse];
          }
        }
      }

      //เช็คจำนวนที่เพิ่มจากของเดิม
      for (let countUse = 0; countUse < use.length; countUse++) {
        for (let countBorrow = 0; countBorrow < borrow.length; countBorrow++) {
          if (use[countUse]._id === borrow[countBorrow]._id) {
            ////ถ้ามีการเพิ่มจากของเดิม
            let checkAdd;

            if ((useInventory[countUse] - borrowInventory[countBorrow]) < 0) {
              checkAdd = 0;
            } else {
              checkAdd = useInventory[countUse] - borrowInventory[countBorrow];
            }

            let product = {
              product: use[countUse],
              borrow: borrowInventory[countBorrow],
              add: checkAdd,
              use: useInventory[countUse],
              stock: 0,
              store: 0,
            }
            productShow[countUse] = product;
            productOfReturn[countUse] = use[countUse];

          }
        }
      }

      setCount(1);
    }

    const findProductNotUse = [...borrow];
    const findProductNotUseInventory = [...borrowInventory];

    function arrayEquals(a, b) {
      return Array.isArray(a) &&
        Array.isArray(b) &&
        a.length === b.length &&
        a.every((val, index) => val._id === b[index].product._id);
    }

    if (count === 1 && arrayEquals(findProductNotUse, productShow) === true) { //ไม่มีการเพิ่ม/ลดอุปกรณ์
      setCount(3);
    } else if (count === 1 && arrayEquals(findProductNotUse, productShow) === false) { //หาอุปกรณ์ที่เบิกมาแล้วไม่ได้ใช้

      for (let countBorrow = 0; countBorrow < borrow.length; countBorrow++) {

        for (let countShow = 0; countShow < productShow.length; countShow++) {

          if (borrow[countBorrow]._id === productShow[countShow].product._id && productShow.indexOf(borrow[countBorrow]) == -1) {
            // console.log(borrow[countBorrow].product_name, productShow.indexOf(borrow[countBorrow]));

            // findProductNotUse.splice(countBorrow, 1);
            // findProductNotUseInventory.splice(countBorrow, 1);

            findProductNotUse[countBorrow] = 0
            findProductNotUseInventory[countBorrow] = 0
          }
        }
      }

      // setCount(2);
      if (findProductNotUse != null && findProductNotUseInventory != null) {


        {
          findProductNotUse.map((_product, index) => { //เพิ่มอุปกรณ์ที่เบิกมาแล้วไม่ได้ใช้
            if (_product != 0) {
              let product = {
                product: _product,
                borrow: findProductNotUseInventory[index],
                add: 0,
                use: 0,
              }
              productShow.push(product);
              productOfReturn.push(_product)
            }


          })
        }
        setCount(3);

      }

    }

    if (productShow != null && count === 3) { //แยกอุปกรณ์จากแหล่งที่เพิ่ม

      for (let countShow = 0; countShow < productShow.length; countShow++) {

        if (productShow[countShow].add != 0) {

          for (let countStock = 0; countStock < useStock.length; countStock++) {

            if (useStock[countStock]._id === productShow[countShow].product._id) {

              productAddFormStock.push(productShow[countShow].product)
              productAddFormStockInventory.push(useStockInventory[countStock] - productShow[countShow].borrow)


            }

          }

          for (let countStore = 0; countStore < useStore.length; countStore++) {
            if (useStore[countStore]._id === productShow[countShow].product._id) {

              productAddFormStore.push(productShow[countShow].product)
              productAddFormStoreInventory.push(useStoreInventory[countStore] - productShow[countShow].borrow)

            }
          }
        }
      }

      setCount(4)
    }


  }

  // console.log(productAddFormStock, productAddFormStockInventory);
  // console.log(productAddFormStore, productAddFormStoreInventory);
  // console.log(productOfReturn);

  const handleOnChange = (value, index) => {
    if (value != null && value != []) {
      inventoryOfReturn[index] = parseInt(value)

    } else {
      inventoryOfReturn[index] = 0
    }
  }

  const showProductShow = (productShow) => {

    if (productShow.length != 0) {
      return (
        <>
          <div className="ml-4 text-left">
            <Table striped bordered hover responsive style={{ fontSize: "18px" }} className="text-center">
              <thead className="light-th" >
                <tr>

                  <th>ชื่อ</th>
                  <th>จำนวนที่เบิก</th>
                  <th>จำนวนที่เบิกเพิ่ม</th>
                  {/* <th>จำนวนที่ใช้</th> */}
                  <th>จำนวนที่คืน</th>
                  <th>หมายเหตุ</th>

                </tr>
              </thead>
              <tbody>



                {productShow.map((_product, index) => {
                  return (
                    <>
                      <tr>
                        <td>{_product.product.product_name}</td>
                        <td>{_product.borrow}</td>
                        <td>{_product.add}</td>
                        {/* <td>{_product.use}</td> */}
                        <td>

                          <div className="text-center">
                            <Input
                              name="inventoryOfReturn"
                              type="number"
                              className="text-center"
                              size="sm"
                              placeholder="0"
                              min={0}
                              defaultValue={inventoryOfReturn[index] = 0}
                              onChange={event => handleOnChange(event.target.value, index)}
                            />
                          </div>
                        </td>
                        <td>
                          <Input
                            name="note"
                            type="text"
                            className="text-center"
                            size="sm"
                            placeholder="หมายเหตุ (ถ้ามี)"
                            defaultValue={note[index] = null}
                            onChange={event => note[index] = event.target.value}
                          />

                        </td>

                      </tr>
                    </>
                  );
                })}



              </tbody>
            </Table>
          </div>
        </>
      )
    }


  }

  const showButton = () => {
    if (user.role != role.CONTRATOR_ROLE) {
      return (
        <Button color="success"
          onClick={() => {
            let comfirm = window.confirm("ยืนยันการคืนอุปกรณ์")
            if (comfirm) {


              const data = {
                productReturn: {
                  product: productOfReturn,
                  inventory: inventoryOfReturn,
                  note: note,
                },
                productAddFormStock: {
                  product: productAddFormStock,
                  inventory: productAddFormStockInventory,
                },
                productAddFormStore: {
                  product: productAddFormStore,
                  inventory: productAddFormStoreInventory,
                },

                status: {
                  statusMechanic: UserStatus.MECHANIC_FINISHED,

                },
                returnDate: new Date(),
              }
              const dataLogs = {
                type: "คืน",
                transactionDate: new Date(),
                referenceOrder: filterOrder._id,
                handler: user.user_firstname + " " + user.user_lastname,
                quotationId: filterOrder.quotationId,
                note: note,
              }
              // console.log(data);
              const token = "ADMIN"
              const dataNotification = timelogs + "งานแจ้งซ่อมของ " + filterOrder.user_name + " ดำเนินการเสร็จสิ้น"
              dispatch(actions.notifyMultiSendAdmin(dataNotification, token, allUser))
              dispatch(actions.productAdd(filterOrder._id, data))
              dispatch(actions.orderPut(filterOrder._id, data));
              dispatch(actions.productLogReturn(dataLogs, data))
              dispatch(actions.ordersFetch(user));
              history.push("/management/return");

            }


          }}
        >คืนอุปกรณ์</Button>
      )
    } else {
      return (
        <>
        {/* <Button
          color="success"
          disabled
        >
          คืนอุปกรณ์
        </Button> */}
        </>
      )
    }
  }

  if (allowAccess) {
    return (
      <>
        <Container fluid className="light-th"
          style={{
            marginTop: "50px",
            maxWidth: "900px",
          }}>

          <Row>
            <Col xs="2">
              <Button
                outline
                color="secondary"
                onClick={
                  history.goBack
                }
              >ย้อนกลับ</Button>
            </Col>
            <Col xs="8">
              <h2 className="text-center">คืนอุปกรณ์</h2>
            </Col>
          </Row>

          <br />
          <div
            className="text-center"
            style={{
              backgroundColor: "#ffffff",
              boxShadow: "1px 1px 3px #d9d9d9",
              padding: "30px",
            }}>

            <h3 className="ml-2 text-left">รายการอุปกรณ์</h3>
            <br />


            <h4>{findProductAdd(filterOrder.productBorrow[0].product, filterOrder.productBorrow[0].inventory, filterOrder.productUse[0].product, filterOrder.productUse[0].inventory, filterOrder.productUseFormStock[0].product, filterOrder.productUseFormStock[0].inventory, filterOrder.productUseFormStore[0].product, filterOrder.productUseFormStore[0].inventory)}</h4>
            <h4>{showProductShow(productShow)}</h4>
            <div className="text-right">
              {showButton()}
            </div>
          </div>

        </Container>

      </>
    )
  } else {
    return (
      <>
      </>
    )
  }
}
export default ReturnProduct;