import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileAlt } from '@fortawesome/free-solid-svg-icons'
import { useHistory, Link } from 'react-router-dom'
import { Badge, Button, Row, Alert } from 'reactstrap';
import * as UserStatus from "../../util/UserStatus";
import * as UserRole from "../../util/UserRole";

const DeliveryTables = ({ eachHistory, index }) => {
    const history = useHistory();
    const [History, setHistory] = useState(eachHistory.orders);

    return (
        <tr className="text-center">
            <th scope="row" className="text-center">{index}</th>
            <td>
                {History.quotationId}
            </td>
            <td>
                {History.statusUser}
            </td>
            <td >
                <Button
                    size="sm"
                    color="success"
                    onClick={() => {
                        history.push({
                            pathname: '/quotationdocument/' + eachHistory._id,
                            state: { History: History }
                        }
                        )
                    }}
                >ใบเสนอราคา</Button>
            </td>
            <td >

                <Button
                    size="sm"
                    onClick={() => {
                        history.push({
                            pathname: '/deliverydocument/' + eachHistory._id,
                            state: { History: History }
                        }
                        )
                    }}
                    color={
                        History.statusUser === "ยกเลิกงานซ่อม"
                            ? 'btn btn-sm btn-secondary'
                            : 'btn btn-sm btn-primary'
                    }
                    disabled={History.statusUser === "ยกเลิกงานซ่อม"}
                >ใบส่งมอบงาน</Button>
            </td>
            <td >
                <Button
                    size="sm"
                    onClick={() => {
                        history.push({
                            pathname: '/imagedocument/' + eachHistory._id,
                            state: { History: History }
                        }
                        )
                    }}
                    color={
                        History.statusUser === "ยกเลิกงานซ่อม"
                            ? 'btn btn-sm btn-secondary'
                            : 'btn btn-sm btn-info'
                    }
                    disabled={History.statusUser === "ยกเลิกงานซ่อม"}
                >รูปภาพก่อนและหลังซ่อม</Button>
            </td>
        </tr >
    )
}
export default DeliveryTables;