import firebase from "firebase";

var firebaseConfig = {
  apiKey: "AIzaSyAtuFu7UpUulhWf_cGHEFWRy3kcdUufnbk",
  authDomain: "eapp-da9e1.firebaseapp.com",
  databaseURL: "https://eapp-da9e1.firebaseio.com",
  projectId: "eapp-da9e1",
  storageBucket: "eapp-da9e1.appspot.com",
  messagingSenderId: "103300656488",
  appId: "1:103300656488:web:a0ffb640a149367f413add",
  measurementId: "G-J3W87J558B"
};

firebase.initializeApp(firebaseConfig);

export const authen = firebase;
export default firebaseConfig;
