import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../actions";
import { useForm } from "react-hook-form";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
  ModalFooter,
  Button,
  FormGroup
} from "reactstrap";
import hash from "object-hash";
import * as UserStatus from "../../util/UserStatus";

const ModalCancle = (props) => {
  const dispatch = useDispatch();
  const { className, modal, toggle, order, setOrder } = props;
  const user = useSelector((state) => state.user)
  const { register, handleSubmit, err } = useForm({
    defaultValues: {
      cancle: "การทำงานล่าช้า",
    },
  });
  const allUser = useSelector((state) => state.userManagement);

  const day = new Date();
  const timelogs = day.getDate().toString() + "/" + day.getMonth().toString() + "/" + parseInt(day.getFullYear() + 543) + " " + day.getHours().toString() + ":" + day.getMinutes().toString() + "น.";

  useEffect(() => {
    dispatch(actions.fetchAllUser());
    return () => { }
  }, []);

  const onCreateProblem = (data) => {
    if (order != [] && order != undefined) {

      const changStatus = {
        statusUser: UserStatus.USER_CANCEL,
        causeCancle: data.cancle,
        cancleDate: new Date(),

      }
      const token = "CANCLE"
      const dataNotification = timelogs + user.user_firstname + " " + user.user_lastname + " ยกเลิกงานซ่อมเนื่องจาก" + data.cancle
      dispatch(actions.notifyMultiSend(dataNotification, token, allUser))
      dispatch(actions.orderPut(order, changStatus))
      // dispatch(actions.handlingWork(order))
      dispatch(actions.ordersFetch(user))
      setOrder([])
      alert("ยกเลิกสำเร็จ");
      toggle()
    }

  };


  return (
    <div>
      <Modal
        backdrop={false}
        isOpen={modal}
        toggle={toggle}
        className={className}
      >

        <ModalHeader toggle={toggle}>เหตุผลการยกเลิก</ModalHeader>
        <ModalBody>
          <FormGroup tag="fieldset">

            <FormGroup check>
              <Label check>
                <Input type="radio" name="cancle" value={"การทำงานล่าช้า"} innerRef={register} />{' '}
                <h6>การทำงานล่าช้า</h6>
              </Label>
            </FormGroup>

            <FormGroup check>
              <Label check>
                <Input type="radio" name="cancle" value={"สามารถแก้ไขปัญหาได้แล้ว"} innerRef={register} />{' '}
                <h6>สามารถแก้ไขปัญหาได้แล้ว</h6>
              </Label>
            </FormGroup>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSubmit(onCreateProblem)}>
            ยืนยัน
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            ยกเลิก
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ModalCancle;
