import React, { useState, useEffect } from "react";
import { Container, Label, Col, Row, Input, Card, CardBody, CardSubtitle, CardTitle, Button } from "reactstrap";
import * as UserStatus from "../../../util/UserStatus";


//แก้ไข ยกเลิกได้ 
const KnowledgeDetailDesktop = ({ order }) => {

  const showImage = (beforeImage, afterImage, subtitle) => {
    if (beforeImage != 0) {
      return (
        <div >
          <p>รูปภาพก่อนซ่อม</p>
          <Row className="ml-1">
            {beforeImage.map((_image, index) => {
              return (
                <>
                  <figure>
                    <img src={_image} width="200vw" className="mr-2 mt-1" />
                    <figcaption className="text-center mt-1">{subtitle[index]}</figcaption>
                  </figure>
                </>
              )
            }
            )}
          </Row>
          <p>รูปภาพหลังซ่อม</p>
          <Row className="ml-1">
            {afterImage.map((_image, index) => {
              return (
                <>
                  <figure>
                    <img src={_image} width="200vw" className="mr-2 mt-1" />
                    <figcaption className="text-center mt-1">{subtitle[index + 4]}</figcaption>
                  </figure>
                </>
              )
            }
            )}
          </Row>

        </div>);
    }
  }

  const showProductUse = (productUse, inventoryUse) => {
    if (productUse != [] && productUse != undefined) {
      return (
        <>
          {productUse.map((product, index) => {
            return (
              <p>
                {product.product_name} จำนวน {inventoryUse[index]}
              </p>
            )
          }
          )}
        </>
      )
    }
  }

  return (
    <Container>
      <div style={{
        backgroundColor: '#ffffff',
        boxShadow: '1px 1px 3px #d9d9d9',
        padding: '30px',
        maxWidth: "900px",
        marginBottom: "30px"
      }}
      >

        <Row className="ml-1">
          <Card style={{ border: "none", boxShadow: "none" }} >
            <CardTitle >
              <h4>ปัญหา/งานซ่อม <strong className="text-danger">{order.problem}</strong></h4>
            </CardTitle>
          </Card>
        </Row>
        <Row className="ml-1">
          <Card style={{ border: "none", boxShadow: "none" }} >
            <CardSubtitle>
              <h5 className=" mt-1 mr-1" >รายละเอียด {order.detail}</h5>
            </CardSubtitle>
          </Card>
        </Row>
        <Row className="ml-1">
          <Card style={{ border: "none", boxShadow: "none" }} >
            <CardSubtitle>
              <h5 className=" mt-1 mr-1" >สาเหตุ {order.cause}</h5>
            </CardSubtitle>
          </Card>
        </Row>
        <Row className="ml-1">
          <Card style={{ border: "none", boxShadow: "none" }} >
            <CardSubtitle>
              <h5 className=" mt-1 mr-1" >วิธีแก้ไข {order.solutions}</h5>
            </CardSubtitle>
          </Card>
        </Row>
        <Row className="ml-1">
          <Card style={{ border: "none", boxShadow: "none" }} >
            <CardSubtitle>
              <h5 className=" mt-1 mr-1" >ข้อเสนอแนะ {order.suggestion}</h5>
            </CardSubtitle>
          </Card>
        </Row>
        <Row className="ml-1">
          <Card style={{ border: "none", boxShadow: "none" }} >
            <CardSubtitle>
              <h5 className=" mt-1 mr-1" >วัสดุและอุปกรณ์ที่ใช้</h5>
              <Col>
                {showProductUse(order.productUse[0]?.product, order.productUse[0]?.inventory)}
              </Col>
            </CardSubtitle>
          </Card>
        </Row>


        {showImage(order.imageBefore, order.imageAfter, order.subtitle)}

        <p className="text-right mt-2"><strong>ชื่อช่างที่รับงาน</strong> {order.mechanic_name}  </p>

      </div>

    </Container>
  )
}
export default KnowledgeDetailDesktop;