import React, { useState,useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileAlt } from '@fortawesome/free-solid-svg-icons'
import { useHistory, Link } from 'react-router-dom'
import { Badge, Button, Row } from 'reactstrap';
import * as UserStatus from "../../util/UserStatus";
import * as UserRole from "../../util/UserRole";

const StockTables =({product , index})=> {
const history = useHistory();



return(
    <tr>
        <th scope="row" className="text-center">{index}</th>
        {/* <th scope="row">{product.product_category.category_name}</th> */}
        <td>{product.product_name}</td>
        <td className="text-center">{product.product_inventory} {product.product_unit}</td>

    </tr>
)
}
export default StockTables;