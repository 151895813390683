import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../actions";
import * as role from "../../util/UserRole";
import { Container, Col, Table } from "reactstrap";
import { useHistory } from "react-router-dom";
import TablePagination from "../../components/TablePagination/TablePagination";
import ProductLogTables from "../tables/ProductLogTables";
import SearchProductLog from "../../components/Search/SearchProductLog";

export default function ManagementProductHistory() {
  const dispatch = useDispatch();
  const history = useHistory();
  const changeStatus = null;
  const user = useSelector((state) => state.user);
  const productLogs = useSelector((state) => state.productLogs);
  const [logData, setLogData] = useState([]);
  const [pageSize, setPageSize] = useState(20)
  const [currentPage, setCurrentPage] = useState(0)

  const allowAccess = user
    ? user.role === role.ADMIN_ROLE || user.role === role.SUPER_USER_ROLE || user.role == role.STORE_ROLE 
      ? true
      : false
    : history.push("/login");


  useEffect(() => {
    dispatch(actions.productLogsFetch());
    return () => { };
  }, []);

  useEffect(() => {
    setLogData(productLogs)
    return () => {};
  }, [productLogs]);


  if (allowAccess) {
    return (
      <Container fluid className="light-th">
        <h2 className="text-center"> ประวัติการเบิกจ่ายอุปกรณ์ </h2>
        <Col className="text-left">
        <SearchProductLog productLogs={productLogs} setLogData={setLogData} />
        </Col>
        <hr />
        { logData.length === 0 ? (
          <>
            <div className="container col-md-8 text-center">
              <br />
              <br />
              <br />
              <h2 className="text-danger">ไม่พบรายการเบิกจ่ายอุปกรณ์</h2>
            </div>
          </>
        ) : (
          <>
        <div
          style={{
            maxHeight: "100vh",
            overflowY: "auto",
            backgroundColor: "#ffffff",
            boxShadow: "1px 1px 3px #d9d9d9",
          }}>
          <Table striped bordered hover responsive>
            <thead>
              <tr className="text-center">
                <th>#</th>                
                <th>วันที่</th>  
                <th>ชื่อผู้เบิก/คืน</th>
                 <th>ประเภท</th>
                <th>เลขที่ใบเสนอราคา</th>
                <th>รายการอุปกรณ์</th>
                <th>จำนวน</th>
                <th>เดิม</th>
                <th>คงเหลือ</th>
                <th>หมายเหตุ</th>
                
              </tr>
            </thead>
            <tbody>
              {Array.isArray(logData) ? (
                <>
                  {logData
                    .slice(
                      currentPage * pageSize,
                      (currentPage + 1) * pageSize
                    )
                    .map((eachUser, index) => {
                      return (
                        <ProductLogTables
                          productLog={eachUser}
                          index={index + 1}
                        />
                      );
                    })}
                </>
              ) : (
                  <>
                    <h2>ไม่พบรายการเบิกจ่ายอุปกรณ์</h2>
                  </>
                )}
            </tbody>
          </Table>
        </div>
        <div className="d-flex justify-content-center m-4 ">
          <TablePagination
            pagesCount={Math.ceil(logData.length / pageSize)}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
        </>)}
      </Container>
    );
  } else {
    return (
      <Container fluid className="light-th">
        <div className="container col-md-8 text-center">
          <h1 className="text-danger"> ขออภัย </h1>
          <h3>
            คุณไม่มีสิทธิในการเข้าถึง <br />
              กรุณาลงทะเบียนด้วย User ที่ได้รับสิทธิเป็นผู้ดูแลระบบ
          </h3>
        </div>
      </Container>
    )
  }

}
