import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import * as actions from "../../actions";
import { useForm } from "react-hook-form";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  InputGroup,
  Input,
  ModalFooter,
  Button,
} from "reactstrap";
import _ from "lodash";

const ModalEditProblem = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { className, modal, toggle, repairtype, problem, index } = props;
  const [unmountOnClose, setUnmountOnClose] = useState(true);
  const { register, handleSubmit, err } = useForm();
  const selectProblem = repairtype.repairtype_problem;
  const [Problem, setProblem] = useState([problem]);

 

  const resetSelectProblem = (problem) => {
    if(problem != null || problem != []){
      setProblem(problem);
    }else {
      setProblem(selectProblem[index])
    }
  }
  
  const onSubmitNewName = (data) => {

    let confirm = window.confirm("ยืนยันการเพิ่มปัญหา")
    if (!confirm){
      toggle();
    }else{
    if(selectProblem != null && index != null){
      selectProblem[index].repairtype_problem = data.repairtype_problem;
    
    const repairtype_data = {
      repairtype_problem: selectProblem,
    }

    dispatch(actions.editRepairtypeProblemName(repairtype._id, repairtype_data));
    dispatch(actions.repairtypesFetch());
    toggle();
    // window.location.reload();
    }
  }
  };
  

  return (
    <div>
      <Modal
        isOpen={modal}
        toggle={toggle}
        className={className}
      >
        <ModalHeader toggle={toggle}>
          แก้ไขชื่อปัญหา {" "}
        </ModalHeader>
        <ModalBody>
            <Label>ประเภทงานซ่อม : {repairtype.repairtype_name}</Label>
            <InputGroup size="lg">
              <Input
                type="text"
                style={{ fontSize: "15px" }}
                placeholder="ชื่อปัญหาใหม่"
                name="repairtype_problem"
                defaultValue={Problem.repairtype_problem}
                innerRef={register}
              />
            </InputGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="warning" onClick={handleSubmit(onSubmitNewName)}>
            แก้ไข
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            ยกเลิก
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ModalEditProblem;
