import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Label } from "reactstrap";

const ProductsListTable = ({ order, product, index, setSelectProduct, toggle, selectProduct, productInventory, selectProductStore, productStoreInventory, setProductData }) => {
  const products = useSelector((state) => state.products);
  const [checkSelectProduct, setCheckSelectProduct] = useState([]);
  const [checkSelectProductStore, setSelectProductStore] = useState([]);
  const [checkStock, setCheckStock] = useState([]);
  const [checkStore, setCheckStore] = useState([]);

  const select = (product) => {


    if (selectProductStore.length != 0) {
      selectProductStore.map((selectFormStore, index) => {
        if (selectFormStore._id === product._id) { //ถ้า product ตรงกับในร้านค้า
          productStoreInventory[index] = productStoreInventory[index] + 1;
          checkSelectProduct[0] = false;
          checkStore[0] = false;
          toggle()
        } else if (selectFormStore._id != product._id) {
          checkSelectProductStore[0] = true
        }
      })

    } else {
      checkSelectProductStore[0] = true

    }

    if (selectProduct.length != 0) {
      selectProduct.map((select, index) => {
        if (select._id === product._id) { //ถ้า product ตรงกับในคลัง
          if (product.product_inventory != 0 && productInventory[index] != product.product_inventory) { //เพิ่มจำนวน
            productInventory[index] = productInventory[index] + 1;
            checkSelectProduct[0] = false;
            checkStock[0] = false;
            toggle()
          } else if ((product.product_inventory != 0 && productInventory[index] == product.product_inventory && selectProductStore.indexOf(product) == -1)) { //เพิ่ม product ใหม่ในร้านค้า
            selectProductStore.push(product)
            productStoreInventory.push(1)
            checkSelectProduct[0] = false;
            checkStock[0] = false;
            toggle()
          } else if ((product.product_inventory == 0 && selectProductStore.indexOf(product) == -1)) { //เพิ่ม product ใหม่ในร้านค้า ถ้าของในคลังหมดแล้ว
            selectProductStore.push(product)
            productStoreInventory.push(1)
            checkSelectProduct[0] = false;
            checkStock[0] = false;
            toggle()
          }
        } else if (select._id != product._id) {
          checkSelectProduct[0] = true;

        }
      })
    } else {
      checkSelectProduct[0] = true;
    }


    if (checkSelectProduct[0] === true && checkSelectProductStore[0] === true) {
      if (product.product_inventory != 0 && selectProduct.indexOf(product) == -1 && checkStock[0] != false && selectProductStore.indexOf(product) == -1) { //เพิ่ม product ใหม่ในคลัง
        selectProduct.push(product)
        productInventory.push(1)
        checkSelectProduct[0] = false;
        toggle()

      } else if (product.product_inventory == 0 && selectProductStore.indexOf(product) == -1 && checkStore[0] != false) { //เพิ่ม product ใหม่ในร้านค้า
        selectProductStore.push(product)
        productStoreInventory.push(1)
        checkSelectProduct[0] = false;
        toggle()
      }

    }

    setProductData(products)
  }

  return (
    <tr className="text-center">
      <th scope="row">{index}</th>
      <td>{product.product_name}</td>
      <td>{product.product_detail}</td>
      {/* <td>{product.product_store}</td> */}
      {/* <td>{product.product_price} บาท</td> */}
      <td ><strong className="text-danger">{product.product_inventory} </strong><Label >{product.product_unit}</Label></td>
      {/* <td>
        
          <img src={product.product_image} width="10%" />
        
      </td>   */}
      <td>
        <Button
          className="m-1"
          color="success"
          onClick={() => {
            select(product)
          }}
        >
          เลือก
      {/* <AddSharpIcon /> */}
        </Button>
      </td>

    </tr>
  );
};

export default ProductsListTable;
