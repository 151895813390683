import React, { useState, useEffect } from "react";
import { Button, Container, Table, Input, Alert, Row, Col, Label, Card, FormGroup, CustomInput } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import * as role from "../../util/UserRole";
import { useHistory, useParams } from "react-router-dom";
import * as actions from '../../actions'
import _ from "lodash";
import { RemoveIcon, AddIcon, TrashIcon, TextInput, Strong, Switch } from 'evergreen-ui'

// ดูรายละเอียดข้อมูลการซ่อม ประเมินราคาเบื้องต้น

const EditOverview = ({ productBorrow, productBorrowInventory, cost, productCost, productCostInventory, ot, price, vat, operatingCost, setOperatingCost, taxFree, setTaxFree, noOperatingCost, setNoOperatingCost, reload, setReload }) => {
  const orders = useSelector((state) => state.orders);
  const user = useSelector((state) => state.user);
  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams();



  const [width, setWidth] = React.useState(window.innerWidth);
  const breakpoint = 620;

  const allowAccess = user
    ? user.role == role.MECHANIC_ROLE ||
      user.role == role.ADMIN_ROLE ||
      user.role == role.SUPER_USER_ROLE
      ? true
      : false
    : history.push('/login');

  const filterOrder = _.find(orders, (_order) => {
    return (
      _order._id == params.id
    )
  })


  const showPrice = () => {
    price[0] = 0;
    vat[0] = 0;
    return (
      <>
        {productCost.map((_product) => {
          if (_product.product_price != 0) {
            price[0] = price[0] + parseInt(_product.product_price * productCostInventory[productCost.indexOf(_product)]) + parseInt(cost[productCost.indexOf(_product)] * productCostInventory[productCost.indexOf(_product)]);
            vat[0] = price[0] * 0.07;
          }
        })}

        {ot.map((_ot) => {
          price[0] = price[0] + parseInt(_ot.ot_price) * parseInt(_ot.ot_inventory);
          vat[0] = price[0] * 0.07;
        })}

      </>
    );
  }

  const showOperatingCost = () => {
    if (noOperatingCost === true) {
      // operatingCost=filterOrder.estimate_price[0].operatingCost;
      return (
        <>
          <Table striped bordered hover responsive style={{ fontSize: "18px" }} className="text-center">
            <thead className="light-th" >
              <tr>
                <th>ชื่อ</th>
                {/* <th>ค่าวัสดุและอุปกรณ์</th> */}
                <th>ร้อยละ</th>
                <th>รวม</th>
              </tr>
            </thead>
            <tbody>

              <>
                <tr>
                  <td>ค่าใช้จ่ายและผลกำไร</td>
                  {/* <td>{price[0]} บาท</td> */}
                  <td>ร้อยละ <TextInput
                    name="operatingCost"
                    width={"35%"}
                    type="number"
                    className="text-center"
                    placeholder="0"
                    defaultValue={operatingCost}
                    onChange={event => setOperatingCost(event.target.value)}
                  /> %</td>
                  <td>เท่ากับ {((operatingCost) / 100 * parseInt(price[0])).toFixed(2)} บาท</td>

                </tr>
              </>

            </tbody>
          </Table>
        </>
      )
    } else {
      // operatingCost = 0;
      setOperatingCost(0);
    }
  }

  const showOverhead = () => {
    if (noOperatingCost === true) {
      return (
        <>
          <Label
            style={{ fontSize: "20px" }}
          > ค่าใช้จ่ายและผลกำไร เท่ากับ {(operatingCost / 100 * price[0]).toFixed(2)} บาท</Label>
          <br />
        </>
      )
    }
  }
  const showTaxFree = () => {
    if (taxFree === true) {
      vat[0] = price[0] * 0.07;
      return (
        <>
          <div >
            <Col sm="12" className="text-right">

              <Label
                style={{ fontSize: "20px" }}
              > ค่าวัสดุและอุปกรณ์ เท่ากับ {price[0]} บาท</Label>
              <br />
              {showOverhead()}
              <Label
                style={{ fontSize: "20px" }}
              > ค่า VAT 7% เท่ากับ {vat[0].toFixed(2)} บาท</Label>
              <br />
              <Label
                style={{ fontSize: "20px" }}
              > รวมทั้งสิ้น <Strong style={{ fontSize: "25px" }} className="text-danger">{(vat[0] + price[0] + operatingCost / 100 * price[0]).toFixed(2)}</Strong> บาท</Label>
            </Col>
          </div>
        </>
      )
    } else {
      vat[0] = 0;
      return (
        <>
          <div >
            <Col sm="12" className="text-right">

              <Label
                style={{ fontSize: "20px" }}
              > ค่าวัสดุและอุปกรณ์ เท่ากับ {price[0]} บาท</Label>
              <br />
              {showOverhead()}
              <Label
                style={{ fontSize: "20px" }}
              > รวมทั้งสิ้น <Strong style={{ fontSize: "25px" }} className="text-danger">{(vat[0] + price[0] + operatingCost / 100 * price[0]).toFixed(2)}</Strong> บาท</Label>
            </Col>
          </div>
        </>
      )
    }
  }

  if (allowAccess)
    return (
      <>

        <h4 className="ml-2">{showPrice()}</h4>
        <Row className="ml-2">
          <Label
            style={{ fontSize: "25px" }}

          >ค่าดำเนินงาน</Label>
          <Switch
            height={20}
            className="mt-2 ml-2"
            checked={noOperatingCost}
            onChange={e => setNoOperatingCost(!noOperatingCost)}
          />
          <Label
            style={{ fontSize: "25px" }} className="ml-2"
          >VAT</Label>
          <Switch
            height={20}
            className="mt-2 ml-2"
            checked={taxFree}
            onChange={e => setTaxFree(!taxFree)}
          />
        </Row>
        <br />
        <h4 className="ml-2">{showOperatingCost()}</h4>
        <h4 className="ml-2">{showTaxFree()}</h4>


      </>
    )

}
export default EditOverview;