import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../actions";
import * as role from "../../util/UserRole";
import { Container, Col, Row, Button, Card, Badge } from "reactstrap";
import { useHistory, useParams } from "react-router-dom";
import _ from "lodash";
import { makeStyles } from '@material-ui/core/styles';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CreateIcon from '@material-ui/icons/Create';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import BuildIcon from '@material-ui/icons/Build';
import GavelIcon from '@material-ui/icons/Gavel';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';

export default function DetailHistory() {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const [userData, setUserData] = useState([]);
  const [width, setWidth] = React.useState(window.innerWidth);
  const breakpoint = 620;
  const histories = useSelector((state) => state.history)
  const params = useParams();

  const classes = makeStyles((theme) => ({
    paper: {
      padding: '6px 16px',
    },
    secondaryTail: {
      backgroundColor: theme.palette.secondary.main,
    },
  }));


  const allowAccess = user
    ? user.role == role.USER_ROLE || user.role == role.MECHANIC_ROLE || user.role == role.ADMIN_ROLE || user.role == role.SUPER_USER_ROLE
      ? true
      : false
    : history.push("/login");


  useEffect(() => {
    if (user != null) {
      dispatch(actions.getHistory());

    }
    return () => { }
  }, [])

  useEffect(() => {
    return () => { }
  }, [histories])


  const findHistory = _.find(histories, (_history) => {
    return (
      _history._id == params.id
    )
  })
  const [detailHistory, setDetailHistory] = useState(findHistory.orders)
  const showByMechanic = () => {
    if (detailHistory.byMechanic !== role.USER_ROLE) {
      return (
        <>
          <Row>
            <h5 className="ml-3"><strong>{detailHistory.user_name}</strong></h5>
            <Col ><Badge color="info" pill>แจ้งซ่อมผ่าน {detailHistory.nameByMechanic}</Badge></Col>

          </Row>
        </>
      )
    } else {
      return (
        <h5><strong>{detailHistory.user_name}</strong></h5>
      )
    }

  }

  const showByMechanicMoblie = () => {
    if (detailHistory.byMechanic !== role.USER_ROLE) {
      return (
        <>
          <p className="ml-1"><Badge color="info" pill>แจ้งซ่อมผ่าน {detailHistory.nameByMechanic}</Badge></p>

        </>
      )
    }
  }
  const colorSpeed = () => {
    if (detailHistory.speed === "ปกติ") {
      return <strong className="text-success">{detailHistory.speed}</strong>
    } else {
      return <strong className="text-danger">{detailHistory.speed}</strong>
    }
  }

  const showImage = (image) => {
    if (image != 0) {
      return (

        <div >
          <p><strong>รูปภาพ</strong></p>
          <div className="text-center">
            {image.map(_image => (
              <img src={_image} width="70%" className="mb-4" />
            ))}
          </div>
        </div>);
    }
  }

  const showTimeline = () => {
    return (
      <>
        <hr />
        <h4 className="mt-4 mb-4"><strong>ไทม์ไลน์ของงานซ่อม</strong></h4>

        <Timeline align="alternate" >
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot style={{ backgroundColor: "#e91e63" }}>
                <NotificationsActiveIcon />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={1} className={classes.paper} style={{ padding: "6px" }}>
                <Typography variant="body2" color="textSecondary">
                  {new Date(detailHistory.orderDate).toLocaleDateString("th", { day: '2-digit', month: '2-digit', year: "numeric" })}
                </Typography>
                <Typography>วันที่แจ้งซ่อม</Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>



          {(detailHistory.getJobDate === undefined) ? (
            <>
            </>
          ) : (
            <>
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineConnector />
                  <TimelineDot style={{ backgroundColor: "#ef6c00" }}>
                    <AssignmentIndIcon />
                  </TimelineDot>
                </TimelineSeparator>
                <TimelineContent>
                  <Paper elevation={1} className={classes.paper} style={{ padding: "6px" }}>
                    <Typography variant="body2" color="textSecondary">
                      {new Date(detailHistory.getJobDate).toLocaleDateString("th", { day: '2-digit', month: '2-digit', year: "numeric" })}
                    </Typography>
                    <Typography>วันที่รับงาน</Typography>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
            </>
          )}

          {(detailHistory.quotationDate === undefined) ? (
            <>
            </>
          ) : (
            <>
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineConnector />
                  <TimelineDot style={{ backgroundColor: "#01579b" }}>
                    <GavelIcon />
                  </TimelineDot>
                </TimelineSeparator>
                <TimelineContent>
                  <Paper elevation={1} className={classes.paper} style={{ padding: "6px" }}>
                    <Typography variant="body2" color="textSecondary">
                      {new Date(detailHistory.quotationDate).toLocaleDateString("th", { day: '2-digit', month: '2-digit', year: "numeric" })}
                    </Typography>
                    <Typography>วันที่ประเมินราคา</Typography>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
            </>
          )}

          {(detailHistory.cancleDate === undefined) ? (
            <>
            </>
          ) : (
            <>
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineConnector />
                  <TimelineDot style={{ backgroundColor: "#b71c1c" }}>
                    <CloseRoundedIcon />
                  </TimelineDot>
                </TimelineSeparator>
                <TimelineContent>
                  <Paper elevation={1} className={classes.paper} style={{ padding: "6px" }}>
                    <Typography variant="body2" color="textSecondary">
                      {new Date(detailHistory.cancleDate).toLocaleDateString("th", { day: '2-digit', month: '2-digit', year: "numeric" })}
                    </Typography>
                    <Typography>วันที่ยกเลิกงานซ่อม</Typography>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
            </>
          )}

          {(detailHistory.comfirmRepairDate === undefined) ? (
            <>
            </>
          ) : (
            <>
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineConnector />
                  <TimelineDot style={{ backgroundColor: "#00b8d4" }}>
                    <DoneAllIcon />
                  </TimelineDot>
                </TimelineSeparator>
                <TimelineContent>
                  <Paper elevation={1} className={classes.paper} style={{ padding: "6px" }}>
                    <Typography variant="body2" color="textSecondary">
                      {new Date(detailHistory.comfirmRepairDate).toLocaleDateString("th", { day: '2-digit', month: '2-digit', year: "numeric" })}
                    </Typography>
                    <Typography>วันที่ยืนยันการซ่อม</Typography>
                  </Paper>
                </TimelineContent>
              </TimelineItem>

            </>
          )}

          {(detailHistory.borrowDate === undefined) ? (
            <>
            </>
          ) : (
            <>
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineConnector />
                  <TimelineDot style={{ backgroundColor: "#8bc34a" }}>
                    <BuildIcon />
                  </TimelineDot>
                </TimelineSeparator>
                <TimelineContent>
                  <Paper elevation={1} className={classes.paper} style={{ padding: "6px" }}>
                    <Typography variant="body2" color="textSecondary">
                      {new Date(detailHistory.borrowDate).toLocaleDateString("th", { day: '2-digit', month: '2-digit', year: "numeric" })}
                    </Typography>
                    <Typography>วันที่เบิกอุปกรณ์</Typography>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
            </>
          )}

          {(detailHistory.repairRecordDate === undefined) ? (
            <>
            </>
          ) : (
            <>
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineConnector />
                  <TimelineDot style={{ backgroundColor: "#01579b" }}>
                    <CreateIcon />
                  </TimelineDot>
                </TimelineSeparator>
                <TimelineContent>
                  <Paper elevation={1} className={classes.paper} style={{ padding: "6px" }}>
                    <Typography variant="body2" color="textSecondary">
                      {new Date(detailHistory.repairRecordDate).toLocaleDateString("th", { day: '2-digit', month: '2-digit', year: "numeric" })}
                    </Typography>
                    <Typography>วันที่บันทึกการซ่อม</Typography>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
            </>
          )}

          {(detailHistory.deliveryWorkDate === undefined) ? (
            <>
            </>
          ) : (
            <>
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineConnector />
                  <TimelineDot style={{ backgroundColor: "#3e2723" }}>
                    <AssignmentIcon />
                  </TimelineDot>
                </TimelineSeparator>
                <TimelineContent>
                  <Paper elevation={1} className={classes.paper} style={{ padding: "6px" }}>
                    <Typography variant="body2" color="textSecondary">
                      {new Date(detailHistory.deliveryWorkDate).toLocaleDateString("th", { day: '2-digit', month: '2-digit', year: "numeric" })}
                    </Typography>
                    <Typography>วันที่ส่งมอบงาน</Typography>
                  </Paper>
                </TimelineContent>
              </TimelineItem>

            </>
          )}

          {(detailHistory.editDeliveryWorkDate === undefined) ? (
            <>
            </>
          ) : (
            <>
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineConnector />
                  <TimelineDot style={{ backgroundColor: "#ffc107" }}>
                    <AssignmentLateIcon />
                  </TimelineDot>
                </TimelineSeparator>
                <TimelineContent>
                  <Paper elevation={1} className={classes.paper} style={{ padding: "6px" }}>
                    <Typography variant="body2" color="textSecondary">
                      {new Date(detailHistory.editDeliveryWorkDate).toLocaleDateString("th", { day: '2-digit', month: '2-digit', year: "numeric" })}
                    </Typography>
                    <Typography>วันที่แก้ไขการส่งมอบงาน</Typography>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
            </>
          )}

          {(detailHistory.comfirmDeliverWork === undefined) ? (
            <>
            </>
          ) : (
            <>
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineConnector />
                  <TimelineDot style={{ backgroundColor: "#311b92" }}>
                    <AssignmentTurnedInIcon />
                  </TimelineDot>
                </TimelineSeparator>
                <TimelineContent>
                  <Paper elevation={1} className={classes.paper} style={{ padding: "6px" }}>
                    <Typography variant="body2" color="textSecondary">
                      {new Date(detailHistory.comfirmDeliverWork).toLocaleDateString("th", { day: '2-digit', month: '2-digit', year: "numeric" })}
                    </Typography>
                    <Typography>วันที่ยืนยันการส่งมอบงาน</Typography>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
            </>
          )}

          {(detailHistory.returnDate === undefined) ? (
            <>
            </>
          ) : (
            <>
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineConnector />
                  <TimelineDot style={{ backgroundColor: "green" }}>
                    <CheckIcon />
                  </TimelineDot>
                </TimelineSeparator>
                <TimelineContent>
                  <Paper elevation={1} className={classes.paper} style={{ padding: "6px" }}>
                    <Typography variant="body2" color="textSecondary">
                      {new Date(detailHistory.returnDate).toLocaleDateString("th", { day: '2-digit', month: '2-digit', year: "numeric" })}
                    </Typography>
                    <Typography>วันที่คืนอุปกรณ์</Typography>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
            </>
          )}

        </Timeline>
      </>
    )
  }
  const showRepairRecord = () => {
    if (detailHistory.statusUser === "ดำเนินการเสร็จสิ้น") {
      return (
        <>
          <br />
          <hr />
          <h4 className="mt-4 mb-4"><strong>บันทึกการซ่อม</strong></h4>

          <Row>
            <Col sm="2"><p><strong>ปัญหา/งานซ่อม</strong></p></Col>
            <Col><h5 className="text-primary"><strong>{detailHistory.problem}</strong></h5></Col>
          </Row>
          <Row>
            <Col sm="2"><p><strong>สาเหตุ</strong></p></Col>
            <Col><h5>{detailHistory.cause}</h5></Col>
          </Row>
          <Row>
            <Col sm="2"><p><strong>วิธีแก้ไข</strong></p></Col>
            <Col><h5>{detailHistory.solutions}</h5></Col>
          </Row>
          <Row>
            <Col sm="2"><p><strong>ข้อเสนอแนะ</strong></p></Col>
            <Col><h5>{detailHistory.suggestion}</h5></Col>
          </Row>
        </>
      )
    }
  }

  const showReport = () => {
    if (detailHistory.statusUser === "ดำเนินการเสร็จสิ้น") {
      return (
        <>
          <hr />
          <h4 className="mt-4 mb-4"><strong>รายงานการซ่อม</strong></h4>
          <Button
            className="mt-2 ml-2"
            color="success"
            onClick={() => {
              history.push({
                pathname: '/quotationdocument/' + detailHistory._id,
                state: { History: detailHistory }
              }
              )
            }}
          >ใบเสนอราคา</Button>
          <Button
            className="mt-2 ml-2"
            color="primary"
            onClick={() => {
              history.push({
                pathname: '/deliverydocument/' + detailHistory._id,
                state: { History: detailHistory }
              }
              )
            }}
          >ใบส่งมอบงาน</Button>
          <Button
            className="mt-2 ml-2"
            color="info"
            onClick={() => {
              history.push({
                pathname: '/imagedocument/' + detailHistory._id,
                state: { History: detailHistory }
              }
              )
            }}
          >รูปภาพก่อนและหลังซ่อม</Button>
        </>
      )
    }
  }

  if (allowAccess) {
    return (
      //desktop
      <Container className="light-th"
        style={{
          marginTop: "50px",
          maxWidth: "900px",
        }}>
        <Row>
          <Col className="text-left">
            <Button
              outline
              color="secondary"
              onClick={
                history.goBack
              }
            >ย้อนกลับ</Button>
          </Col>
          <Col md="7">
            <h2 className="text-left">รายละเอียดการซ่อม</h2>
          </Col>
        </Row>
        <br />
        <div
          style={{
            backgroundColor: "#ffffff",
            boxShadow: "1px 1px 3px #d9d9d9",
            padding: "30px",
          }}>

          <br />
          <h4 className="mb-4"><strong>ข้อมูลผู้แจ้ง</strong></h4>
          <div className="text-center mr-4 mb-4">
            <img
              style={{ width: "20%" }}
              src={detailHistory.user_image}
            />
          </div>
          <Row>
            <Col sm="2"><p><strong>ชื่อผู้แจ้ง</strong></p></Col>

            <Col >{showByMechanic()}</Col>

          </Row>
          <Row>
            <Col sm="2"><p><strong>หน่วยงาน</strong></p></Col>

            <Col ><h5>{detailHistory.user_agency}</h5></Col>

          </Row>
          <Row>
            <Col sm="2"><p><strong>วันที่แจ้งซ่อม</strong></p></Col>
            <Col sm="2"><h5> {new Date(detailHistory.orderDate).toLocaleDateString("th")}</h5></Col>
            <Col ><Row><strong>เวลา</strong><h5 className="ml-2"> {new Date(detailHistory.orderDate).toLocaleTimeString("th", { hour: '2-digit', minute: '2-digit' })} น.</h5></Row></Col>
          </Row>
          <Row>
            <Col sm="2"><p><strong>เบอร์โทรศัพท์</strong></p></Col>
            <Col> <h5> {detailHistory.user_tel}</h5></Col>
          </Row>
          <Row>
            <Col sm="2"><p><strong>แฟกซ์</strong></p></Col>
            <Col> <h5> {detailHistory.user_fax}</h5></Col>
          </Row>
          <Row>
            <Col sm="2"><p><strong>อีเมล</strong></p></Col>
            <Col> <h5> {detailHistory.user_email}</h5></Col>
          </Row>
          <Row>
            <Col sm="2"><p><strong>สถานที่</strong></p></Col>
            <Col><h5> {detailHistory.place}</h5></Col>
          </Row>
          <hr />
          <h4 className="mt-4 mb-4"><strong>ข้อมูลการแจ้งซ่อม</strong></h4>
          <Row>
            <Col sm="2"><p><strong>ประเภทงานซ่อม</strong> </p></Col>
            <Col><h5>{detailHistory.type}</h5> </Col>
          </Row>
          <Row>
            <Col sm="2"><p><strong>ปัญหา/งานซ่อม</strong></p></Col>
            <Col><h5 className="text-primary"><strong>{detailHistory.problem}</strong></h5></Col>
          </Row>
          <Row>
            <Col sm="2"><p><strong>ความเร่งด่วน</strong></p></Col>
            <Col ><h5>{colorSpeed()}</h5></Col>
          </Row>

          <Row>
            <Col sm="2"> <p><strong>รายละเอียด</strong></p></Col>
            <Col sm="10"><Card style={{ border: "none", boxShadow: "none" }}><h5>{detailHistory.detail}</h5></Card></Col>
          </Row>

          {/* {showImage(detailHistory.imageFormUser)} */}

          {/* <Row>
            <Col sm="2"><p><strong>รูปภาพหลังซ่อม</strong></p></Col>
            {showImage(detailHistory.imageAfter)}
          </Row> */}
          {showRepairRecord()}
          {showReport()}
          {showTimeline()}


        </div>
      </Container>
    )
  } else {
    return (
      <Container fluid className="light-th">
        <div className="container col-md-8 text-center">
          <h1 className="text-danger"> ขออภัย </h1>
          <h3>
            คุณไม่มีสิทธิในการเข้าถึง <br />
              กรุณาลงทะเบียนด้วย User ที่ได้รับสิทธิเป็นผู้ดูแลระบบ
          </h3>
        </div>
      </Container>
    )
  }

}
